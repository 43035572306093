import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
interface Params extends RouteComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

type State = {
  modalInfo: boolean;
};
class PurAlertModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalInfo: false,
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      modalInfo: props.isOpen,
    });
  }

  onClose = () => {
    this.setState({ modalInfo: false });
  };

  render() {
    const { onClose } = this.props;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody style={{ paddingBottom: 0 }}>
              <>
                <p>
                  It seems like you are trying to delete a bill but you are
                  unable to delete because a payment entry has already been
                  recorded against it. To resolve this issue, you have to delete
                  payment first.
                </p>
                <p>
                  Here is a step-by-step guide to deleting a bill that has a
                  payment recorded against it:
                </p>
                <p>Step 1: Click on the payment button</p>
                <p>Step 2: Delete or Edit the Payment</p>
              </>
            </ModalBody>
            <ModalFooter className="pt-0" style={{ textAlign: "center", display: "block" }}>
              <Button
                type="submit"
                className="bmt-btn-submit itemsubmit"
                style={{ width: "140px" }}
                onClick={onClose}
              >
                OK
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PurAlertModal);
