import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { connect } from "react-redux";
import { getCustomers, getPermissions } from "src/store/actions";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "./CustomerCreateFullModal";
import CompanyInfoModal from "../Invoices/CompanyInfoModal";
import CustomerInfoModal from "./CustomerInfoModal";
import Ledger from "../Ledger";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { number_format } from "src/helpers/common";
import ConfirmationModal from "src/components/ConfirmationModal";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import AlertModal from "src/components/AlertModal";

interface MyProps extends RouteComponentProps<any> {
  search?: string | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  permissions: Array<any>;
  getCustomers: () => void;
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  setCount: (count: number) => void;
  setTotal: (total: number) => void;
}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  loading: boolean;
  permissions: Array<any>;
  modal_cust_is_open: boolean;
  modal_info_is_open: boolean;
  customer_id: string;
  customer_name: string;
  phone: string;
  email: string;
  gstin: string;
  pan: string;
  modalInfo: boolean;
  modelLedger: boolean;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  search: string | null | undefined;
  startDate: Date | null;
  endDate: Date | null;
  blockdel: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

class CustomerList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "id",
      sortOrder: "asc",
      loading: true,
      permissions: [],
      modal_cust_is_open: false,
      modal_info_is_open: false,
      customer_id: "0",
      customer_name: "",
      phone: "",
      email: "",
      gstin: "",
      pan: "",
      modalInfo: false,
      modelLedger: false,
      isOpenConformModal: false,
      Rowdelete: null,
      search: "",
      startDate: null,
      endDate: null,
      blockdel: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/customer_list_grid`,
          param
        );
        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
              loading: false,
            });
            this.props.setCount(resp.data.count);
            this.props.setTotal(resp.data.total);
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
    this.fetchData({
      page: param.page,
      searchText: this.props.search,
      sizePerPage: param.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handleDelete = (row: any) => {
    if (row.invoice_count == 0) {
      this.setState({ isOpenConformModal: true });
      this.setState({ Rowdelete: row.id });
    } else {
      this.setState({ blockdel: true });
    }
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/customer_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.props.getCustomers();
    let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
    this.fetchData({
      page: 1,
      searchText: this.props.search,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      startDate: startDate,
      endDate: endDate,
    });
    this.props.setRefresh(!this.props.refresh);
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleCantDelClose = () => {
    this.setState({ blockdel: false });
  }
  handleDeleteClose = () => {
    let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
    this.fetchData({
      page: 1,
      searchText: this.props.search,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      startDate: startDate,
      endDate: endDate,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  sendGetRequest = async () => {
    try {
      let customerPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.CUSTOMER
      );
      if (this._isMounted) {
        this.setState({
          permissions: customerPermissions,
        });
      }
      let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
      this.fetchData({
        page: 1,
        searchText: this.props.search,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
        startDate: startDate,
        endDate: endDate,
      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.sendGetRequest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.permissions !== prevProps.permissions) {
      let customerPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.CUSTOMER
      );
      this.setState({
        permissions: customerPermissions,
      });
    }
    if (this.props.refresh !== prevProps.refresh) {
      let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
      this.fetchData({
        page: 1,
        searchText: this.props.search,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }
  openCustModal = (id: string) => {
    this.setState({ modal_cust_is_open: true, customer_id: id });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false, customer_id: "0" });
  };

  changeStatus = async (e: any) => {
    try {
      let value: any = [];
      value["id"] = e.target.value;
      value["status"] = e.target.checked;
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/customer_change_status",
        value
      );
      if (resp.success) {
        this.props.getCustomers();
        let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
        this.fetchData({
          page: 1,
          searchText: this.props.search,
          sizePerPage: this.state.sizePerPage,
          sortField: this.state.sortField,
          sortOrder: this.state.sortOrder,
          startDate: startDate,
          endDate: endDate,
        });
        this.props.setRefresh(!this.props.refresh);
      }
    } catch (err) {
      console.error(err);
    }
  };
  parentCallback = () => {
    this.closeCustModal();
    this.props.getCustomers();
    let startDate = this.props.startDate
    ? moment(this.props.startDate).format("YYYY-MM-DD")
    : null;
  let endDate = this.props.endDate
    ? moment(this.props.endDate).format("YYYY-MM-DD")
    : null;
    this.fetchData({
      page: 1,
      searchText: this.props.search,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      startDate: startDate,
      endDate: endDate,
    });
    this.props.setRefresh(!this.props.refresh);
  };

  viewInfo = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      this.setState({
        customer_name: row.customer_name,
        email: row.email,
        phone: row.phone,
        gstin: row.gstin,
        pan: row.pan,
        modalInfo: !this.state.modalInfo,
      });
    } catch (err) {
      console.error(err);
    }
  };

  toggleInfo = () => {
    try {
      this.setState({ modalInfo: !this.state.modalInfo });
    } catch (err) {
      console.error(err);
    }
  };

  ledgerOpen = (row: any) => {
    this.setState({
      modelLedger: true,
      customer_id: row.id,
      customer_name: row.customer_name,
    });
  };

  ledgerClose = () => {
    this.setState({ modelLedger: false });
  };
  ledgerToggle = () => {
    this.setState({ modelLedger: !this.state.modelLedger });
  };
  preventParentAction = (event: any) => {
    event.stopPropagation();
  };

  render() {
    const numberFormat = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "customer_name",
        text: "Customer Name",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <span>{row.customer_name}</span>
            </>
          );
        },
      },
      {
        dataField: "closing_balance",
        text: "Balance",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>₹ {number_format(row.closing_balance)}</>;
        },
      },
      {
        dataField: "actions",
        text: "",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button" style={{ float: "right" }}>
                {/* <Link to="#" ></Link> */}
                <button
                  onClick={(e: any) => this.viewInfo(e, row)}
                  className="normal-btn hash-button btn-info-new myorder-complete"
                  style={{ marginRight: 12 }}
                >
                  Info
                </button>
                <button
                  onClick={() => this.ledgerOpen(row)}
                  className="normal-btn hash-button"
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                   Ledger
                </button>
                <button
                  className="round-btn"
                  title="Actions"
                  onClick={this.preventParentAction}
                  style={{
                    marginRight: 12,
                    width: 35,
                    height: 35,
                  }}
                >
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "modify" && is_active == 1
                      ) && (
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.openCustModal(row.id)}
                        >
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          Edit
                        </DropdownItem>
                      )}
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "delete" && is_active == 1
                      ) && (
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleDelete(row)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
                <div
                  className="form-check form-switch mb-3"
                  dir="ltr"
                  style={{ float: "right", paddingRight: 12 }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitch1"
                    style={{ height: 22, width: 52 }}
                    // defaultChecked={row.is_active ? true : false}
                    checked={row.is_active ? true : false}
                    value={row.id}
                    onChange={this.changeStatus}
                  />
                </div>
              </div>
            </>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card style={{ border: "none" }}>
              <CardBody style={{ paddingTop: "0px" }}>
                {this.state.loading == true ? (
                  <>
                    <CardTitle className="h4">
                      <Skeleton height={"10%"} width={"10%"} />
                    </CardTitle>
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>
                            <Skeleton width={100} />
                          </th>
                          <th>
                            <Skeleton width={100} />
                          </th>
                          <th>
                            <Skeleton width={100} />
                          </th>
                          <th>
                            <Skeleton width={100} />
                          </th>
                          <th>
                            <Skeleton width={100} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton height={20} />
                          </td>
                          <td>
                            <Skeleton height={20} />
                          </td>
                          <td>
                            <Skeleton height={20} />
                          </td>
                          <td>
                            <Skeleton height={20} />
                          </td>
                          <td>
                            <Skeleton height={20} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.data}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle customer-table"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      onTableChange={this.handleTableChange}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={() => (
                                        <NoDataIndication />
                                      )}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            id={this.state.customer_id}
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        <AlertModal
          isOpen={this.state.blockdel}
          message="It seems like you are trying to delete a customer but you are unable to delete because an Invoice/payment entry has already been recorded in it. To resolve this issue, you have to delete the entry first."
          onClose={this.handleCantDelClose}
        ></AlertModal>
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        <CustomerInfoModal
          phone={this.state.phone}
          gstin={this.state.gstin}
          pan={this.state.pan}
          isOpen={this.state.modalInfo}
          toggle={this.toggleInfo}
          email={this.state.email}
        />
        {this.state.modelLedger && (
          <Ledger
            openModal={this.ledgerToggle}
            modal_is_open={this.state.modelLedger}
            closeModal={this.ledgerClose}
            customer_name={this.state.customer_name}
            customer_id={this.state.customer_id}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { permissions: any }) => {
  return {
    permissions: state.permissions.permissions,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCustomers: () => {
      dispatch(getCustomers());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerList));
