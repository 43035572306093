import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Modal, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

interface Params extends RouteComponentProps {
  note_modal_open: boolean;
  note_openModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  note_closeModal: () => void;
  parentCallback: (values: any) => void;
  prop_footer_note?: string;
  title?: string;
}

type State = {
  footer_note: string;
  title: string;
};

class CustomerNoteModal extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    this.state = {
      footer_note: "",
      title:"invoivce",
    };
  }

  componentDidUpdate(prevProps: Params) {
    if (prevProps.note_modal_open !== this.props.note_modal_open) {
      this.setState({
        footer_note: this.props.prop_footer_note || "",
        title: this.props.title || "invoivce",
      });
    }
  }
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  handleValidSubmit = async (values: any) => {
    values["footer_note"] = this.state.footer_note;
    this.props.parentCallback(values);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.note_modal_open}
        toggle={this.props.note_openModal}
        className="md-400"
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-body pt-1">
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="inv_form pt-0 pb-0">
                  <div className="row">
                    <div className="heading">Customer Note</div>
                    <span>This will be displayed on the {this.state.title}</span>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <AvField
                        name="footer_note"
                        type="textarea"
                        rows="10"
                        placeholder="Customer Note"
                        value={this.state.footer_note}
                        onChange={this.onChangehandler}
                        className="mb-1"
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <>
                      <Button
                        type="button"
                        className="bmt-btn-close itemsubmit"
                        onClick={this.props.note_closeModal}
                      >
                        Close
                      </Button>{" "}
                      <Button
                        type="submit"
                        className="bmt-btn-submit itemsubmit"
                      >
                        Save
                      </Button>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

export default withRouter(CustomerNoteModal);
