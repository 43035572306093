import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  BreadcrumbItem,
} from "reactstrap";

import "../Datatable/datatables.scss";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import MetaTags from "react-meta-tags";
// import InvoiceDetailsPdf from "./InvoiceDetailsPdf";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";
import MediaQuery from "../Media";
import PaymentReceviedList from "./PaymentReceviedList";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";


interface MyProps extends RouteComponentProps<any> {
  customers: Array<any>;
  permissions: Array<any>;
}

interface MyState {
  activeTab: number;
  totalAll: number;
  totalAllPaid: number;
  totalAllUnpaid: number;
  countAll: number;
  countAllPaid: number;
  countAllUnpaid: number;
  permissions: Array<any>;
  isLoading: boolean;
  valueCustomer: Option | null | undefined;
  options: Option[];
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  loading: boolean;
  isLoadedThis: boolean;
  isLoadedTabAll: boolean;
  isLoadedTabPaid: boolean;
  isLoadedTabUnpaid: boolean;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  isDownloadPdf: boolean;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});
class PaymentReceviedIndex extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      activeTab: 1,
      totalAll: 0.0,
      totalAllPaid: 0.0,
      totalAllUnpaid: 0.0,
      countAll: 0,
      countAllPaid: 0,
      countAllUnpaid: 0,
      permissions: [],
      isLoading: true,
      valueCustomer: null,
      options: [],
      customers: [],
      startDate: null,
      endDate: null,
      loading: true,
      isLoadedThis: false,
      isLoadedTabAll: false,
      isLoadedTabPaid: false,
      isLoadedTabUnpaid: false,
      isPlanExpired: 0,
      pdfId: "",
      isDownloadPdf: false,
      modalPlan: false,
      modal_cust_is_open: false,
      modal_item_is_open: false,
    };
  }
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };

  setDownloadpdf = (id: string) => {
    this.setState({ isDownloadPdf: true, pdfId: id });
    // console.log('setDownloadpdf');
  };

  isDownloadCompleted = () => {
    this.setState({ isDownloadPdf: false, pdfId: "" });
    // console.log('isDownloadCompleted');
  };
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
  };

  setTotalAmount = async (param: {}) => {
    this.setState(param);
    var loading = false;
    if (
      this.state.isLoadedThis &&
      this.state.isLoadedTabAll &&
      this.state.isLoadedTabPaid &&
      this.state.isLoadedTabUnpaid
    ) {
      loading: true;
    }
    this.setState({ loading });
  };

  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
  }

  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };
  loadData = async () => {
    try {
      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map(customer => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      if (this._isMounted) this.setState({ customers: customers, options: customerOptions });

      let invoicePermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.INVOICE
      );
      if (this._isMounted) this.setState({ permissions: invoicePermissions });

      var loading = false;
      if (
        this.state.isLoadedThis &&
        this.state.isLoadedTabAll &&
        this.state.isLoadedTabPaid &&
        this.state.isLoadedTabUnpaid
      ) {
        loading: true;
      }
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          isLoadedThis: true,
          loading,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  componentWillReceiveProps(props: MyProps) {
    this.loadData();
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const title = "Payment Received";
    const breadcrumbItem = "Payment Received List";
    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "685px" }}>
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    {" "}
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
       

                  <CardBody className="pt-0">
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="text-muted"
                    >
                      <TabPane tabId={1}>
                        <Row>
                          <Col sm="12">
                            <MediaQuery
                              mobile={
                                <PaymentReceviedList
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                />
                              }
                              desktop={
                                <PaymentReceviedList
                                  valueCustomer={this.state.valueCustomer}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  setTotalAmount={this.setTotalAmount}
                                  setDownloadpdf={this.setDownloadpdf}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      
        {/* {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )} */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { customers: any; permissions: any }) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
  };
};
export default connect(mapStateToProps)(withRouter(PaymentReceviedIndex));
