import { call, put, takeEvery } from "redux-saga/effects"

import {
  getbankorcashSuccess,
  getbankorcashFail,
  getbankorcashs, 
} from "./actions"

import { BankOrCashTypes } from "./actionTypes"


interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchbankorcash() {
  try {
    const response : apiRes = yield call(getbankorcashs);  
    if (response.success) {
      yield put(getbankorcashSuccess(response))
    } 
  } catch (error) {
    yield put(getbankorcashFail(error))
  }
}


function* bankorcashaga() {  
  yield takeEvery(BankOrCashTypes.GET_BANK_OR_CASH, fetchbankorcash);
}

export {fetchbankorcash};
export default bankorcashaga
