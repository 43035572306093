import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  BreadcrumbItem,
} from "reactstrap";

import "../Datatable/datatables.scss";
import classnames from "classnames";
import Select from "react-select";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { predefinedRanges } from "src/helpers/common";
import TDSDeductedAll from "./TDSDeductedAll";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

interface MyProps extends RouteComponentProps<any> {
  customers: Array<any>;
  permissions: Array<any>;
}

interface MyState {
  activeTab: number;
  totalAll: number;
  countAll: number;
  permissions: Array<any>;
  isLoading: boolean;
  valueCustomer: Option | null | undefined;
  options: Option[];
  customers: Array<any>;
  startDate: Date;
  endDate: Date;
  loading: boolean;
  isLoadedThis: boolean;
  isLoadedTabAll: boolean;
  isLoadedTabPaid: boolean;
  isLoadedTabUnpaid: boolean;
  isLoadedTabDelete: boolean;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  isDownloadPdf: boolean;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
  refresh: boolean;
  totalAmount: number;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});
class TDSDeductedList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      activeTab: 1,
      totalAll: 0.0,
      countAll: 0,
      permissions: [],
      isLoading: true,
      valueCustomer: null,
      options: [],
      customers: [],
      startDate: new Date(new Date().getFullYear(), 3, 1),
      endDate: endOfMonth(new Date(new Date().getFullYear() + 1, 2, 1)),
      loading: true,
      isLoadedThis: false,
      isLoadedTabAll: false,
      isLoadedTabPaid: false,
      isLoadedTabUnpaid: false,
      isLoadedTabDelete: false,
      isPlanExpired: 0,
      pdfId: "",
      isDownloadPdf: false,
      modalPlan: false,
      modal_cust_is_open: false,
      modal_item_is_open: false,
      refresh: false,
      totalAmount: 0,
    };
  }
  setRefresh = (param: any) => {
    if (param == true) {
      this.setState({ refresh: !this.state.refresh });
    }
  };
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
  };

  setTotalAmount = async (param: {}) => {
    this.setState(param);
    var loading = false;
    if (
      this.state.isLoadedThis &&
      this.state.isLoadedTabAll &&
      this.state.isLoadedTabPaid &&
      this.state.isLoadedTabUnpaid &&
      this.state.isLoadedTabDelete
    ) {
      loading = true;
    }
    this.setState({ loading });
  };

  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
  }
  setStartDateNew(dateRange: any) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({ startDate: startDate, endDate: endDate });
  }
  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };
  loadData = async () => {
    try {
      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map(customer => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      if (this._isMounted)
        this.setState({ customers: customers, options: customerOptions });

      let invoicePermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.INVOICE
      );
      if (this._isMounted) this.setState({ permissions: invoicePermissions });

      var loading = false;
      if (
        this.state.isLoadedThis &&
        this.state.isLoadedTabAll &&
        this.state.isLoadedTabPaid &&
        this.state.isLoadedTabUnpaid &&
        this.state.isLoadedTabDelete
      ) {
        loading = true;
      }
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          isLoadedThis: true,
          loading,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  componentWillReceiveProps(props: MyProps) {
    this.loadData();
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const title = "TDS Deducted";
    const { startDate, endDate } = this.state;
    const breadcrumbItem = "TDS Deducted List";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ minHeight: "685px", background: "#F1F1F1" }}
        >
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    {" "}
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <Select
                                isClearable={true}
                                isDisabled={this.state.isLoading}
                                isLoading={this.state.isLoading}
                                onChange={this.handleChange}
                                options={this.state.options}
                                value={this.state.valueCustomer}
                                name="customer"
                                styles={{
                                  option: (provided: any, state: any) => ({
                                    ...provided,
                                    ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                    backgroundColor: state.isSelected
                                      ? "#10a37f"
                                      : "inherit",
                                  }),
                                }}
                                placeholder="Customer"
                              />
                            )}
                          </Col>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <>
                                <DateRangePicker
                                  ranges={predefinedRanges}
                                  format="dd/MM/yyyy"
                                  placeholder=" "
                                  value={
                                    startDate != null && endDate != null
                                      ? [startDate, endDate]
                                      : null
                                  }
                                  style={{ width: 280 }}
                                  onChange={(date: DateRange | null) =>
                                    this.setStartDateNew(date)
                                  }
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                    <div className="d-inline-block mx-lg-2">
                      <span className="font-weight-500 text-18 text-primary">
                        Total : {this.state.totalAmount}
                      </span>
                    </div>
                  </div>

                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="text-muted"
                    >
                      <TabPane tabId={1}>
                        <Row>
                          <Col sm="12">
                            <TDSDeductedAll
                              valueCustomer={this.state.valueCustomer}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              setTotalAmount={this.setTotalAmount}
                              refresh={this.state.refresh}
                              setRefresh={this.setRefresh}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { customers: any; permissions: any }) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
  };
};
export default connect(mapStateToProps)(withRouter(TDSDeductedList));
