import React, { useCallback, useState } from "react";

import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import InvoiceList from "./InvoiceList";

const Invoices = () => { 

  return (
    <React.Fragment>      
          
          <InvoiceList />          
            
    </React.Fragment>
  );
};

export default Invoices;
