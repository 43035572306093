import { call, put, takeEvery } from "redux-saga/effects"

import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { PaymentMethodsTypes } from "./actionTypes"

export const getPaymentMethods = () => get(process.env.REACT_APP_API_URL + '/api/get_payment_methods')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchPaymentMethods() {
  try {
    const response : apiRes = yield call(getPaymentMethods);      
    if (response.success) {
      yield put(getPaymentMethodsSuccess(response))
    } 
  } catch (error) {
    yield put(getPaymentMethodsFail(error))
  }
}


function* PaymentMethodsaga() {  
  yield takeEvery(PaymentMethodsTypes.GET_PAYMENT_METHODS, fetchPaymentMethods);
}

export {fetchPaymentMethods}
export default PaymentMethodsaga
