import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { post } from "src/helpers/api_helper";
import "react-loading-skeleton/dist/skeleton.css";
import { number_format, predefinedRanges } from "src/helpers/common";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import moment from "moment";
import InventoryLedger from "./InventoryLedger";
import StockConversion from "./StockConversion";
import { AvForm, AvField } from "availity-reactstrap-validation";
import NoDataIndication from "../Datatable/NoDataIndication";

interface Params extends RouteComponentProps {
  units: Array<any>;
  taxes: Array<any>;
  permissions: Array<any>;
  getProducts: () => void;
}

type State = {
  data: any[];
  startDate: Date | null;
  endDate: Date | null;
  dateRange: [Date, Date];
  isSubmit: boolean;
  modelgstLedger: boolean;
  inventory_id: string;
  inventory_name: string;
  searchText: string | null;
  openModalCon: boolean;
};

class Inventory extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    const today = new Date();
    this.state = {
      data: [],
      startDate: startOfMonth(today),
      endDate: endOfMonth(today),
      dateRange: [startOfMonth(today), endOfMonth(today)],
      isSubmit: false,
      modelgstLedger: false,
      inventory_id: "",
      inventory_name: "",
      searchText: "",
      openModalCon: false,
    };
  }

  setStartDateNew = (dateRange: [Date, Date] | null) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({
      startDate,
      endDate,
      dateRange: dateRange || this.state.dateRange,
    });
    const searchText = this.state.searchText;
    this.search(startDate, endDate, searchText);
  };
  handleSearchInputChange = (searchText: string | null) => {
    this.setState({
      searchText: searchText,
    });
    const { startDate, endDate } = this.state;
    this.search(startDate, endDate, searchText);
  };
  stockConversionOpen = () => {
    this.setState({
      openModalCon: true,
    });
  };
  stockConversionClose = () => {
    this.setState({
      openModalCon: false,
    });
  };
  stockConversionCallback = () => {
    this.search(
      this.state.startDate,
      this.state.endDate,
      this.state.searchText
    );
  };
  search = async (
    startD: Date | null,
    endD: Date | null,
    searchText: string | null
  ) => {
    try {
      const start_date = startD ? moment(startD).format("YYYY-MM-DD") : null;
      const end_date = endD ? moment(endD).format("YYYY-MM-DD") : null;
      const postValues = {
        startDate: start_date,
        endDate: end_date,
        searchText: searchText,
      };
      const resp = await post(
        `${process.env.REACT_APP_API_URL}/api/inventory_summary`,
        postValues
      );
      if (resp.success) {
        this.setState({
          data: resp.data,
          isSubmit: true,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  InventoryModalOpen = (statement: any) => {
    this.setState({
      modelgstLedger: true,
      inventory_id: statement.inventory_id,
      inventory_name: statement.reference,
    });
  };
  ledgerClose = () => {
    this.setState({ modelgstLedger: false });
  };
  ledgerToggle = () => {
    this.setState({ modelgstLedger: !this.state.modelgstLedger });
  };

  componentDidMount() {
    const { startDate, endDate, searchText } = this.state;
    this.search(startDate, endDate, searchText);
  }

  componentDidUpdate(prevProps: Params, prevState: State) {
    const { startDate, endDate, searchText } = this.state;
    // Prevent unnecessary re-fetch
    if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
      this.search(startDate, endDate, searchText);
    }
  }

  render() {
    const { data, dateRange, isSubmit } = this.state;
    const title = "Inventory";
    const breadcrumbItem = "Inventory Summary";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ minHeight: "685px", background: "#F1F1F1" }}
        >
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="searchText"
                              type="text"
                              placeholder="Search..."
                              onChange={(e: any) =>
                                this.handleSearchInputChange(e.target.value)
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <DateRangePicker
                              ranges={predefinedRanges}
                              format="dd/MM/yyyy"
                              placeholder=" "
                              value={dateRange}
                              style={{ width: 280 }}
                              onChange={(date: DateRange | null) =>
                                this.setStartDateNew(date)
                              }
                            />
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                    <Col lg={12}>
                      <button
                        onClick={(e: any) => this.stockConversionOpen()}
                        type="button"
                        className="btn btn-primary create"
                      >
                        Stock Conversion
                      </button>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12 p-2">
                        <div className="table-responsive">
                          {isSubmit && (
                            <Table className="inventory_tbl table align-middle table-nowrap">
                              <thead>
                                <tr>
                                  <th>Inventory Item</th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Unit
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Opening Stock
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    <i className="fas fa-plus-circle stock-in-icon"></i>
                                    Stock IN
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-minus-circle stock-in-icon text-danger"></i>
                                    Stock OUT
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Stock On Hand
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Stock Value
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className="p-0">
                                {data.length > 0 ? (
                                  data.map(
                                    (
                                      statement: {
                                        reference: string;
                                        unit: string;
                                        OpeningStock: number;
                                        StockIN: number;
                                        StockOUT: number;
                                        ClosingStock: number;
                                        inventory_id: string;
                                        stockValue: number;
                                      },
                                      index: number
                                    ) => (
                                      <tr key={index}>
                                        <td>{statement.reference}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {statement.unit}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {number_format(
                                            statement.OpeningStock
                                          )}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {number_format(statement.StockIN)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {number_format(statement.StockOUT)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {number_format(
                                            statement.ClosingStock
                                          )}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          {number_format(statement.stockValue)}
                                        </td>
                                        <td className="table-button py-1">
                                          <button
                                            className="normal-btn hash-button"
                                            onClick={() =>
                                              this.InventoryModalOpen(statement)
                                            }
                                          >
                                            Ledger
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={8}
                                      style={{ textAlign: "center" }}
                                    >
                                      <NoDataIndication />
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.modelgstLedger && (
          <InventoryLedger
            modal_is_open={this.state.modelgstLedger}
            openModal={this.InventoryModalOpen}
            closeModal={this.ledgerClose}
            inventory_id={this.state.inventory_id}
            inventory_name={this.state.inventory_name}
            callback={this.stockConversionCallback}
          />
        )}
        {this.state.openModalCon && (
          <StockConversion
            modal_is_open={this.state.openModalCon}
            openModal={this.stockConversionOpen}
            closeModal={this.stockConversionClose}
            callback={this.stockConversionCallback}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Inventory);
