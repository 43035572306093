import { call, put, takeEvery } from "redux-saga/effects"

import {
  getCompaniesSuccess,
  getCompaniesFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { CompaniesTypes } from "./actionTypes"

export const getCompanies = () => get(process.env.REACT_APP_API_URL + '/api/company')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchCompanies() {
  try {
    const response : apiRes = yield call(getCompanies);  
    if (response.success) {
      yield put(getCompaniesSuccess(response))
    } 
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}


function* companySaga() {  
  yield takeEvery(CompaniesTypes.GET_COMPANIES, fetchCompanies);
}

export {fetchCompanies}
export default companySaga
