import axios from "axios"
import moment from "moment";
// import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
})


// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          localStorage.removeItem("authUser");
          location.href = '/login'
          /* const rs = await post(process.env.REACT_APP_API_URL + '/api/refresh', {});*/

        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
)

//new code start
export function getAccessToken() {
  // return localStorage.getItem('token');
  const getAuthUser = localStorage.getItem("authUser");
  var accessToken = "";
  if (getAuthUser) {
    const obj = JSON.parse(getAuthUser);
    /* const curDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const expire_time = moment(obj.expire_time).format('YYYY-MM-DD HH:mm:ss');    
    if (moment(expire_time).isSameOrBefore(curDateTime)) {
      localStorage.removeItem("authUser");
      location.href = '/login'
    } */
    accessToken = `${obj.token_type} ${obj.access_token}`;
  }
  return accessToken;
}
axiosApi.interceptors.request.use(request => {
  request.headers['Authorization'] = getAccessToken();
  return request;
}
);
//new code end

export async function get(url: string, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export function isValidToken() {  
  const getAuthUser = localStorage.getItem("authUser");
  var isValid = false;
  if (getAuthUser) {
    /* const obj = JSON.parse(getAuthUser);
    const curDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const expire_time = moment(obj.expire_time).format('YYYY-MM-DD HH:mm:ss');    
    if (moment(expire_time).isSameOrBefore(curDateTime)) {
      isValid = false;
    } else { */
      isValid = true;
    // }    
  }
  return isValid;
}