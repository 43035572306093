import {PermissionsTypes} from "./actionTypes"

export const getPermissions = () => ({
  type: PermissionsTypes.GET_PERMISSIONS,
})

export const getPermissionsSuccess = (permissions : any) => ({
  type: PermissionsTypes.GET_PERMISSIONS_SUCCESS,
  payload: permissions,
})

export const getPermissionsFail = (error : any) => ({
  type: PermissionsTypes.GET_PERMISSIONS_FAIL,
  payload: error,
})



