import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Table } from "reactstrap";

import "../Datatable/datatables.scss";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

import { post } from "src/helpers/api_helper";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";
import { connect } from "react-redux";
import { number_format } from "src/helpers/common";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import SweetAlert from "react-bootstrap-sweetalert";
import MatchTDS from "./MatchTDS";

interface MyProps extends RouteComponentProps<any> {
  valueCustomer?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  setTotalAmount: ({}) => void;
  isPaginationRequire?: boolean;
}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  page: number;
  totalAmount: number;
  data: any;
  payData: any;
  sortField: string;
  sortOrder: string;
  invoice_prefix: string;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  modal_is_open: boolean;
  active_inv_id: string;
  active_cust_id: string;
  active_cust_name: string;
  active_invoice_no: string;
  active_invoice_amount: any;
  modal: boolean;
  modalInfo: boolean;
  isOpenPayModal: boolean;
  isSending: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  pdfId: string;
  active_inv_no: string;
  active_ref_no: string;
  showOptions: string;
  isOpenAlert: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

class TDSDeductedAll extends Component<MyProps, MyState> {
  static defaultProps: {
    isPaginationRequire: boolean;
  };
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      page: 1,
      totalAmount: 0,
      data: [],
      payData: [],
      sortField: "invoice_no",
      sortOrder: "desc",
      invoice_prefix: "",
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      startDate: null,
      endDate: null,
      modal_is_open: false,
      active_inv_id: "",
      active_cust_id: "",
      active_cust_name: "",
      active_invoice_no: "",
      active_invoice_amount: "",
      modal: false,
      modalInfo: false,
      isOpenPayModal: false,
      isSending: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      pdfId: "",
      company_name: "",
      active_inv_no: "",
      active_ref_no: "",
      showOptions: "",
      isOpenAlert: false,
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/tds_deducted_list`,
          param
        );

        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
              invoice_prefix: resp.data.invoice_prefix,
              instance_id: resp.data.instance_id,
              company_name: resp.data.company_name,
              totalAmount: resp.data.total,
            });
            this.props.setTotalAmount({
              totalAll: resp.data.total,
              countAll: resp.data.count,
              isLoadedTabAll: true,
              totalAmount: resp.data.total,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    sendGetRequest();
  };
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.setState({ page: param.page });
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: param.page,
      searchText: "",
      sizePerPage: param.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      customer: this.props.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
    });
  };
  view = async (row: any) => {
    try {
      this.setState({
        active_inv_id: row.id,
        active_ref_no: row.payment_reference_no,
        email: row.email,
        modalView: !this.state.modalView,
      });
    } catch (err) {
      console.error(err);
    }
  };
  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    this.view(row);
  };
  componentDidUpdate(prevProps: MyProps) {
    if (
      this.props.valueCustomer !== prevProps.valueCustomer ||
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.refresh !== prevProps.refresh
    ) {
      let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
      this.setState({ page: 1 });
      this.fetchData({
        page: 1,
        searchText: "",
        customer: this.props.valueCustomer?.value,
        startDate: startDate,
        endDate: endDate,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
    this.setState({ page: 1 });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: newValue?.value,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
  };
  async componentDidMount() {
    try {
      this._isMounted = true;
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleOptionChange = async (row_id: any, selectedValue: any) => {
    let postValues = {
      invoice_id: row_id,
      data: selectedValue,
    };
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/tds_match`,
          postValues
        );
        if (resp.success == true) {
          // this.setState({ isOpenAlert: true });
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: this.state.page,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      customer: this.props.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
    });
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      page: this.state.page,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "All", value: this.state.totalSize }, // Option to show all rows
      ],
      custom: true,
    };
    const { SearchBar } = Search;
    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "payment_date",
        text: "Entry Date ",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <span>{moment(row.payment_date).format("DD MMM YYYY")}</span>;
        },
      },
      {
        dataField: "invoice_date",
        text: "Invoice Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <span>{moment(row.invoice_date).format("DD MMM YYYY")}</span>;
        },
      },
      {
        dataField: "invoice_no",
        text: "Invoice No ",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <span>
              {row.prefix
                ? row.prefix + row.invoice_no
                : "INV" + row.invoice_no}
            </span>
          );
        },
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: cellContent.match(/.{1,30}/g).join("<br />"),
                }}
              ></span>
            </>
          );
        },
      },
      {
        dataField: "net_total",
        text: "Taxable Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          const net_total = row.net_total;
          const tax_amount = row.tax_amount;
          return (
            <div style={{ textAlign: "right" }}>
              <span>{`₹ ${number_format(net_total - tax_amount)}`}</span>
            </div>
          );
        },
      },
      {
        dataField: "tdsAmount",
        text: "TDS",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <div style={{ textAlign: "right" }}>
              <span>{`₹ ${number_format(cellContent)}`}</span>
            </div>
          );
        },
      },
      {
        dataField: "26as",
        text: "Matched with 26AS",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
              <MatchTDS
                row_id={row.id}
                active_id={this.state.showOptions}
                cellContent={cellContent}
                handleOptionChangeFromParent={this.handleOptionChange}
              />
          );
        },
      },
    ];
    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>

                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            selectRow={{
                              mode: "radio",
                              hideSelectColumn: true,
                              clickToSelect: true,
                              onSelect: this.selectRow,
                            }}
                            remote
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes="table align-middle table-nowrap tbl-tbs"
                            headerWrapperClasses="thead-light"
                            onTableChange={this.handleTableChange}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Col>
                    </Row>

                    {this.props.isPaginationRequire && (
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline col-md-3">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                            <PaginationTotalStandalone {...paginationProps} />
                          </div>
                          <div className="text-md-right col-md-3 ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {/* <div className="col-md-12 text-center">
                      <span className="font-weight-500 text-18">
                        Total : {this.state.totalAmount}
                      </span>
                    </div> */}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}
        {this.state.isOpenAlert ? (
          <SweetAlert
            success
            showCancel
            title="Changed successfully"
            customClass="font-weight-normal"
            confirmBtnText="Match 26AS"
            onCancel={() => {
              this.setState({ isOpenAlert: false });
            }}
            onConfirm={() => {}}
            customButtons={
              <span className="d-flex justify-content-center gap-2 mb-4 mt-2">
                <button
                  className="bmt-btn-close closesubmit btn btn-secondary"
                  onClick={() => {
                    this.setState({ isOpenAlert: false });
                  }}
                >
                  OK
                </button>
              </span>
            }
          >
            Invoiced Match with 26AS
          </SweetAlert>
        ) : null}
      </React.Fragment>
    );
  }
}
TDSDeductedAll.defaultProps = {
  isPaginationRequire: true,
};

export default withRouter(TDSDeductedAll);
