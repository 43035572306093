import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { get, post } from "src/helpers/api_helper";

interface CategoryProps {
  modal_is_open: boolean;
  openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: Function;
  id: string;
}

const ExpCategoryModal = (props: CategoryProps) => {
  const [isProgress, setIsProgress] = useState(0);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    category_name: null,
    parent_category_id: null,
    description: null,
  });

  const handleValidSubmit = (event: any, values: any) => {
    setIsProgress(1);
    values["id"] = props.id;
    const resp = post(
      process.env.REACT_APP_API_URL + "/api/exp_category_save",
      values
    );
    props.closeModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resCs: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_exp_categories"
        );
        setCategories(resCs.data);
        if (props.id !== "0") {
          const resC: any = await get(
            process.env.REACT_APP_API_URL + "/api/exp_category_get/" + props.id
          ).then();
          setCategory(resC.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className="md-700"
      >
        <AvForm onValidSubmit={handleValidSubmit}>
          <div className="modal-body">
            <div className="item_form pt-0 pb-1" style={{ borderLeft: "none" }}>
              <div className="row green_form pt-0 pb-1">
                <div className="heading">
                  {props.id === "0" ? "Create Category" : "Modify Category"}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-firstname-input"
                  >
                    Category name
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      name="category_name"
                      type="text"
                      placeholder="Enter Category name"
                      errorMessage="Enter Category name"
                      validate={{ required: { value: true } }}
                      value={category.category_name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Parent Category
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      type="select"
                      name="parent_category_id"
                      placeholder="Select a Parent Category"
                      errorMessage="Select a Parent Category"
                      validate={{ required: { value: true } }}
                      value={category.parent_category_id}
                    >
                      <option value="" key="">
                        Parent Category
                      </option>
                      {categories.map(
                        (category: { id: string; category_name: string }) => (
                          <option value={category.id} key={category.id}>
                            {category.category_name}
                          </option>
                        )
                      )}
                    </AvField>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Description
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      type="text"
                      name="description"
                      value={category.description}
                    />
                  </div>
                </div>
              </div>
              {/* Add more rows for other form fields here */}
            </div>
            <div className="modal-footer justify-content-center p-0">
              {isProgress ? (
                <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit"
                    disabled={true}
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    Submitting
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => props.closeModal()}
                    className="bmt-btn-close itemsubmit"
                    data-dismiss="modal"
                  >
                    Close
                  </Button>{" "}
                  <Button
                    type="submit"
                    className="bmt-btn-submit itemsubmit"
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default ExpCategoryModal;
