import { call, put, takeEvery } from "redux-saga/effects"

import {
  getTaxesSuccess,
  getTaxesFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { TaxesTypes } from "./actionTypes"

export const getTaxes = () => get(process.env.REACT_APP_API_URL + '/api/taxes')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchTaxes() {
  try {
    const response : apiRes = yield call(getTaxes);  
    if (response.success) {
      yield put(getTaxesSuccess(response))
    } 
  } catch (error) {
    yield put(getTaxesFail(error))
  }
}


function* taxSaga() {  
  yield takeEvery(TaxesTypes.GET_TAXES, fetchTaxes);
}

export {fetchTaxes}
export default taxSaga
