import {UnitsTypes} from "./actionTypes"

const INIT_STATE = {
  units: [],  
  error: {},
}

const Units = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case UnitsTypes.GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload.data,
      }
    case UnitsTypes.GET_UNITS:
      return {
        ...state,
        units: state.units,
      }
    case UnitsTypes.GET_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Units
