import {ExpItemsTypes} from "./actionTypes"

export const getExpItems = () => ({
  type: ExpItemsTypes.GET_EXP_ITEMS,
})

export const getExpItemsSuccess = (products : any) => ({
  type: ExpItemsTypes.GET_EXP_ITEMS_SUCCESS,
  payload: products,
})

export const getExpItemsFail = (error : any) => ({
  type: ExpItemsTypes.GET_EXP_ITEMS_FAIL,
  payload: error,
})



