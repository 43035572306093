import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface Params extends RouteComponentProps {
  row_id: string;
  active_id: string;
  cellContent: number;
  handleOptionChangeFromParent: (row_id: string, selectedValue: string) => void;
}

type State = {
  row_id: string;
  active_id: string | null;
  cellContent: number;
};

class MatchTDS extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    this.state = {
      row_id: props.row_id,
      active_id: props.active_id,
      cellContent: props.cellContent,
    };
  }

  componentDidUpdate(prevProps: Params) {
    if (
      prevProps.row_id !== this.props.row_id ||
      prevProps.active_id !== this.props.active_id ||
      prevProps.cellContent !== this.props.cellContent
    ) {
      this.setState({
        row_id: this.props.row_id,
        active_id: this.props.active_id,
        cellContent: this.props.cellContent,
      });
    }
  }
  handleOptionChange = (event: any) => {
    const { row_id } = this.state;
    const selectedValue = event.target.value;
    this.setState({ active_id: "" });
    this.props.handleOptionChangeFromParent(row_id, selectedValue);
  };
  handleClose = () => {
    this.setState({ active_id: "" });
  };

  handleDoubleClick = () => {
    this.setState({ active_id: this.state.row_id });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.row_id === this.state.active_id ? (
          <div className="d-flex">
            <select
              className="form-select tds-select"
              value={this.state.cellContent || ""}
              onChange={event => this.handleOptionChange(event)}
            >
              <option value="">Not Checked</option>
              <option value="1">Matched</option>
              <option value="2">Unmatched</option>
            </select>
            <button
              className="btn btn-danger btn-sm mx-2"
              onClick={this.handleClose}
            >
              <i className="bx bx-x"></i>
            </button>
          </div>
        ) : (
          <div className="icon-wrapper">
            <span
              onDoubleClick={this.handleDoubleClick}
              className={
                this.state.cellContent === 1
                  ? "status matched"
                  : this.state.cellContent === 2
                  ? "status unmatched"
                  : "status sent"
              }
            >
              {this.state.cellContent === 1
                ? "Matched"
                : this.state.cellContent === 2
                ? "Unmatched"
                : "Not Checked"}
            </span>
            <div className="tooltip-tds">Double Click to Edit</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(MatchTDS);
