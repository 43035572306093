import {CountriesTypes} from "./actionTypes"

const INIT_STATE = {
  countries: [],  
  error: {},
}

const Countries = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case CountriesTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.data,
      }
    case CountriesTypes.GET_COUNTRIES:
      return {
        ...state,
        countries: state.countries,
      }
    case CountriesTypes.GET_COUNTRIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Countries
