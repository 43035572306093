import notFoundImg from "../../assets/images/notfound-icon.png";
const NoDataIndication = () => {
    return (
        <div className="text-center my-3">
            <img className="rounded avatar-lg mb-2" src={notFoundImg} alt="Not Found" /> 
            <h3>SORRY!</h3>
            We Haven't Found Any Document
        </div>
    )
};

export default NoDataIndication;