import { call, put, takeEvery } from "redux-saga/effects"

import {
  getCountriesSuccess,
  getCountriesFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { CountriesTypes } from "./actionTypes"

export const getCountries = () => get(process.env.REACT_APP_API_URL + '/api/countries')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchCountries() {
  try {    
    const response : apiRes = yield call(getCountries);  
    if (response.success) {
      yield put(getCountriesSuccess(response))
    } 
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}


function* countrySaga() {  
  fetchCountries();
  yield takeEvery(CountriesTypes.GET_COUNTRIES, fetchCountries);
}

export {fetchCountries};
export default countrySaga;
