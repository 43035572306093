import {TaxesTypes} from "./actionTypes"

export const getTaxes = () => ({
  type: TaxesTypes.GET_TAXES,
})

export const getTaxesSuccess = (taxes : any) => ({
  type: TaxesTypes.GET_TAXES_SUCCESS,
  payload: taxes,
})

export const getTaxesFail = (error : any) => ({
  type: TaxesTypes.GET_TAXES_FAIL,
  payload: error,
})



