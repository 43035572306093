import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  ModalFooter,
  Button,
  NavLink,
  Table,
  BreadcrumbItem,
  TabContent,
  TabPane,
} from "reactstrap";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import classnames from "classnames";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";

import Select, { components } from "react-select";
import {
  ActionMeta,
  ControlProps,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
/* import EstimationDetailsModel from "./EstimationDetailsModel"; */
import SendEstModel from "./SendEstModel";
import ExpenseIcon from "src/assets/images/icons/Expense";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import RenewPlan from "../Payments/RenewPlan";
import EstimationDetailsPdf from "./EstimationDetailsPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";
import MediaQuery from "../Media";
import {
  getLetters,
  number_format,
  padLeadingZeros,
  predefinedRanges,
} from "src/helpers/common";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import EstimationAll from "./EstimationAll";
import EstimationGenerated from "./EstimationGenerated";
import EstimationInvoiced from "./EstimationInvoiced";
import ConfirmationModal from "src/components/ConfirmationModal";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import CompanyForm from "../GST/CompanyForm";
import { getCompanies } from "src/store/actions";
import EstimationAllMobile from "./EstimationAllMobile";
import EstimationGeneratedMobile from "./EstimationGeneratedMobile";
import EstimationInvoicedMobile from "./EstimationInvoicedMobile";

interface MyProps extends RouteComponentProps<any> {
  getCompanies: () => void;
  customers: Array<any>;
  permissions: Array<any>;
  companies: Company;
}
interface Company {
  orders: any;
  logoFile: string;
  company_state: string | undefined;
}

interface MyState {
  id: string;
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  estimation_prefix: string;
  permissions: Array<any>;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  totalAmount: number;
  modal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  active_est_no: string;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
  activeTab: number;
  apiResponse: any;
  generatedLen: number;
  invoicedLen: number;
  sumStatus1: any;
  sumStatus4: any;
  isOpenConformModal: boolean;
  convertToInvoiceModal: boolean;
  Rowdelete: string | null;
  RowConvert: string | null;
  refresh: boolean;
  verifyCompanyData: boolean;
  isLoadingTab: boolean;
  valueTab: Option | null | undefined;
  optionsTab: Option[];
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}
interface Entry {
  status: number;
  gross_total_base: any;
}
const createOption = (label: string, value: string) => ({
  label,
  value,
});
const Control = ({ children, ...props }: ControlProps<Option, false>) => {
  
  return (
    <components.Control {...props}>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="30"
          viewBox="0 0 32.815 49.055"
        >
          <path
            id="Path_441"
            data-name="Path 441"
            d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
            transform="translate(-1.415 49.055)"
          />
        </svg>
      </span>
      {children}
    </components.Control>
  );
};
class EstimationList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "",
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "estimation_no",
      sortOrder: "desc",
      estimation_prefix: "",
      permissions: [],
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      // startDate: new Date(),
      // endDate: new Date(moment().add(6, 'days').format("YYYY-MM-DD"))
      startDate: null,
      endDate: null,
      totalAmount: 0,
      modal: false,
      isSending: false,
      editorState: EditorState.createEmpty(),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      company_name: "",
      isPlanExpired: 0,
      pdfId: "",
      modalPlan: false,
      active_est_no: "",
      modal_cust_is_open: false,
      modal_item_is_open: false,
      activeTab: 1,
      apiResponse: null,
      generatedLen: 0,
      invoicedLen: 0,
      sumStatus1: 0,
      sumStatus4: 0,
      isOpenConformModal: false,
      convertToInvoiceModal: false,
      Rowdelete: null,
      RowConvert: null,
      refresh: false,
      verifyCompanyData: false,
      isLoadingTab: true,
      valueTab: null,
      optionsTab: [],
    };
  }

  setRefresh = (param: any) => {
    if (param == true) {
      this.setState({ refresh: !this.state.refresh });
    }
  };
  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };

  setTotalAmount = async (param: {}) => {
    this.setState(param);

    let optionsTab = [
      { value: '1', label: `TOTAL(${this.state.totalSize})\n ${number_format(this.state.totalAmount)}` },
      { value: '2', label: `GENERATED(${this.state.generatedLen})\n ${number_format(this.state.sumStatus1)}` },
      { value: '3', label: `INVOICED(${this.state.invoicedLen})\n ${number_format(this.state.sumStatus4)}` },
    ];
    let valueTab = optionsTab[0];
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        loading: false,
        isLoadingTab: false,
        optionsTab,
        valueTab
      });
    }
  };

  downloadPdf = (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ pdfId: row.id });
  };

  send = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let est_no = this.state.estimation_prefix
        ? this.state.estimation_prefix + row.estimation_no
        : "INV" + row.estimation_no;
      this.setState({
        id: row.id,
        active_est_no: est_no,
        email: row.email,
        modal: !this.state.modal,
      });
    } catch (err) {
      console.error(err);
    }
  };

  view = async (row: any) => {
    try {
      // this.setState({ id: row.id, modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };

  toggleView = () => {
    try {
      this.setState({ modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  toggleSend = () => {
    try {
      this.setState({ modal: !this.state.modal });
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
  };
  getCompanies = () => {
    this.props.getCompanies();
  };
  loadData = async () => {
    try {
      const respCom = this.props.companies;
      if (!respCom) {
        if (this._isMounted) this.setState({ verifyCompanyData: false });
      } else if (respCom.company_state === null) {
        if (this._isMounted) this.setState({ verifyCompanyData: true });
      } else {
        if (this._isMounted) this.setState({ verifyCompanyData: false });
      }
      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers: any = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map((customer: any) => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      let estmationPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.ESTIMATION
      );
      if (this._isMounted) {
        this.setState({
          customers: customers,
          options: customerOptions,
          permissions: estmationPermissions,
        });
      }

      let optionsTab = [
        { value: '1', label: `TOTAL(${this.state.totalSize})\n ${number_format(this.state.totalAmount)}` },
        { value: '2', label: `GENERATED(${this.state.generatedLen})\n ${number_format(this.state.sumStatus1)}` },
        { value: '3', label: `INVOICED(${this.state.invoicedLen})\n ${number_format(this.state.sumStatus4)}` },
      ];
      let valueTab = optionsTab[0];
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          loading: false,
          isLoadingTab: false,
          optionsTab,
          valueTab
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  componentWillReceiveProps(props: MyProps) {
    this.loadData();
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
  }
  setStartDateNew(dateRange: any) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({ startDate: startDate, endDate: endDate });
  }
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/estimation_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };

  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    this.props.history.push("/estimation_details/" + row.id);
  };

  preventParentAction = (event: any) => {
    event.stopPropagation();
  };
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  handleEvent = () => {
    const apiResponse: any = this.state.data;
    this.setState({ apiResponse });
  };
  handleChangeTab = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueTab: newValue });
    this.toggleTab(Number(newValue?.value));
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
      showTotal: true,
    };
    const title = "Estimate";
    const breadcrumbItem = "Estimate List";

    return (
      <React.Fragment>
        <div className="page-content" style={{ minHeight: "685px" }}>
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header custom-1">
                    {" "}
                    <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <Select
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                  backgroundColor: state.isSelected
                                    ? "#10a37f"
                                    : "inherit",
                                }),
                              }}
                                isClearable={true}
                                isDisabled={this.state.isLoading}
                                isLoading={this.state.isLoading}
                                onChange={this.handleChange}
                                options={this.state.options}
                                value={this.state.valueCustomer}
                                name="customer"
                              />
                            )}
                          </Col>
                          <Col lg={6}>
                            {this.state.loading == true ? (
                              <Skeleton width={"100%"} height={"50%"} />
                            ) : (
                              <>
                                <DateRangePicker
                                  ranges={predefinedRanges}
                                  format="dd/MM/yyyy"
                                  placeholder=" "

                                  onChange={(date: DateRange | null) =>
                                    this.setStartDateNew(date)
                                  }
                                />
                              </>
                            )}
                          </Col>
                          {/* this is display only in mobile */}
                          <MediaQuery
                            mobile={
                              <Col lg={6} className="">
                                {this.state.loading == true ? (
                                  <Skeleton width={"100%"} height={"50%"} />
                                ) : (
                                  <Select
                                  styles={{
                                    option: (provided: any, state: any) => ({
                                      ...provided,
                                      ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                      backgroundColor: state.isSelected
                                        ? "#10a37f"
                                        : "inherit",
                                    }),
                                  }}
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={this.state.isLoadingTab}
                                    isLoading={this.state.isLoadingTab}
                                    onChange={this.handleChangeTab}
                                    options={this.state.optionsTab}
                                    value={this.state.valueTab}
                                    name="tab"
                                    placeholder="Tab"
                                    components={{ Control }}
                                    className="react-select-container tab-cstm-drop"
                                    classNamePrefix="react-select"
                                  />
                                )}
                              </Col>
                            }
                            desktop={<></>}
                          />
                        </Row>
                      </AvForm>
                    </div>
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader className="cus-card">
                    <div className="row">
                      <div className="col-md-12 col-xl-7 order-xl-1">
                        {/* this is display only in desktop */}
                        <MediaQuery
                          desktop={<>
                            {this.state.loading == true ? (
                              <>
                                <li>
                                  <Skeleton width={"30%"} height={"100%"} />
                                </li>
                                <li>
                                  <Skeleton width={"30%"} height={"100%"} />
                                </li>
                                <li>
                                  <Skeleton width={"30%"} height={"100%"} />
                                </li>
                              </>
                            ) : (
                              <>
                                <ul className="values">
                                  <li
                                    className={
                                      this.state.activeTab == 1 ? "active" : ""
                                    }
                                  >
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: this.state.activeTab == 1,
                                      })}
                                      onClick={() => {
                                        this.toggleTab(1);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32.815"
                                        height="49.055"
                                        viewBox="0 0 32.815 49.055"
                                      >
                                        <path
                                          id="Path_441"
                                          data-name="Path 441"
                                          d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                          transform="translate(-1.415 49.055)"
                                        />
                                      </svg>
                                      <span>
                                        {number_format(this.state.totalAmount)}
                                      </span>
                                      <label>Total</label>
                                    </NavLink>
                                  </li>
                                  <li
                                    className={
                                      this.state.activeTab == 2 ? "active" : ""
                                    }
                                  >
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: this.state.activeTab == 2,
                                      })}
                                      onClick={() => {
                                        this.toggleTab(2);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32.815"
                                        height="49.055"
                                        viewBox="0 0 32.815 49.055"
                                      >
                                        <path
                                          id="Path_441"
                                          data-name="Path 441"
                                          d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                          transform="translate(-1.415 49.055)"
                                        />
                                      </svg>

                                      <span>
                                        {number_format(this.state.sumStatus1)}
                                      </span>
                                      <label>Generated</label>
                                    </NavLink>
                                  </li>
                                  <li
                                    className={
                                      this.state.activeTab == 3 ? "active" : ""
                                    }
                                  >
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: this.state.activeTab == 3,
                                      })}
                                      onClick={() => {
                                        this.toggleTab(3);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="32.815"
                                        height="49.055"
                                        viewBox="0 0 32.815 49.055"
                                      >
                                        <path
                                          id="Path_441"
                                          data-name="Path 441"
                                          d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                          transform="translate(-1.415 49.055)"
                                        />
                                      </svg>

                                      <span>
                                        {number_format(this.state.sumStatus4)}
                                      </span>
                                      <label>Invoiced</label>
                                    </NavLink>
                                  </li>
                                </ul>
                              </>
                            )}

                            <ul className="inv-count">
                              <li>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="31"
                                    height="31"
                                    viewBox="0 0 31 31"
                                  >
                                    <g
                                      id="Group_12"
                                      data-name="Group 12"
                                      transform="translate(-326 -668)"
                                    >
                                      <circle
                                        id="Ellipse_5"
                                        data-name="Ellipse 5"
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        transform="translate(326 668)"
                                        fill="#3296cd"
                                      />
                                      <path
                                        id="Path_10"
                                        data-name="Path 10"
                                        d="M3728.683,669l8.305,6.207,11.513-16.287"
                                        transform="translate(-3395.042 13.241)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeWidth="4"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                {this.state.totalSize} Estimates
                              </li>
                              <li>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="31"
                                    height="31"
                                    viewBox="0 0 31 31"
                                  >
                                    <g
                                      id="Group_12"
                                      data-name="Group 12"
                                      transform="translate(-326 -668)"
                                    >
                                      <circle
                                        id="Ellipse_5"
                                        data-name="Ellipse 5"
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        transform="translate(326 668)"
                                        fill="#b4b0ad"
                                      />
                                      <path
                                        id="Path_10"
                                        data-name="Path 10"
                                        d="M3728.683,669l8.305,6.207,11.513-16.287"
                                        transform="translate(-3395.042 13.241)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeWidth="4"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                <span>{this.state.generatedLen} Estimates</span>
                              </li>
                              <li>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="31"
                                    height="31"
                                    viewBox="0 0 31 31"
                                  >
                                    <g
                                      id="Group_12"
                                      data-name="Group 12"
                                      transform="translate(-326 -668)"
                                    >
                                      <circle
                                        id="Ellipse_5"
                                        data-name="Ellipse 5"
                                        cx="15.5"
                                        cy="15.5"
                                        r="15.5"
                                        transform="translate(326 668)"
                                        fill="#43aa77"
                                      />
                                      <path
                                        id="Path_10"
                                        data-name="Path 10"
                                        d="M3728.683,669l8.305,6.207,11.513-16.287"
                                        transform="translate(-3395.042 13.241)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeWidth="4"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                {this.state.invoicedLen} Estimates
                              </li>
                            </ul>
                          </>}
                          mobile={<></>}
                        />

                      </div>
                      <div className="col-md-12 col-xl-5 order-xl-1">
                        {this.state.permissions.find(
                          ({ action, is_active }) =>
                            action == "create" && is_active == 1
                        ) && (
                            <ul className="qck-link1">
                              <li>
                                <Link
                                /* to="/product_add" */ to="#"
                                  onClick={() => this.openModalItem()}
                                >
                                  <span>
                                    <ExpenseIcon />
                                  </span>
                                  <label>Add Item</label>
                                </Link>
                              </li>
                              <li>
                                <Link to="#" onClick={() => this.openCustModal()}>
                                  <span>
                                    <Addcustomer2Icon />
                                  </span>
                                  <label>Add Customer</label>
                                </Link>
                              </li>
                              <li>
                                <Link to="/invoice_add">
                                  <span>
                                    <Invoice2Icon />
                                  </span>
                                  <label>New Invoice</label>
                                </Link>
                              </li>
                              <li>
                                <Link to="/estimation_add">
                                  <span>
                                    <Estimate2Icon />
                                  </span>
                                  <label>New Estimate</label>
                                </Link>
                              </li>
                            </ul>
                          )}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    {this.state.loading == true ? (
                      <>
                        <CardTitle className="h4">
                          <Skeleton height={"10%"} width={"10%"} />
                        </CardTitle>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                              <th>
                                <Skeleton width={"100%"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                              <td>
                                <Skeleton height={"10%"} />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : (
                      <>
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="text-muted"
                        >
                          <TabPane tabId={1}>
                            <Row>
                              <Col sm="12">
                                <MediaQuery
                                  mobile={<EstimationAllMobile
                                    key={1}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                  desktop={<EstimationAll
                                    key={1}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={2}>
                            <Row>
                              <Col sm="12">                                
                                <MediaQuery
                                  mobile={<EstimationGeneratedMobile
                                    key={2}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                  desktop={<EstimationGenerated
                                    key={2}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={3}>
                            <Row>
                              <Col sm="12">                                
                                <MediaQuery
                                  mobile={<EstimationInvoicedMobile
                                    key={3}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                  desktop={<EstimationInvoiced
                                    key={3}
                                    valueCustomer={this.state.valueCustomer}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    refresh={this.state.refresh}
                                    setRefresh={this.setRefresh}
                                    setTotalAmount={this.setTotalAmount}
                                  />}
                                />
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.verifyCompanyData == true && (
          <CompanyForm
            isOpen={this.state.verifyCompanyData}
            getCompanies={this.getCompanies}
          />
        )}
        <EstimationDetailsPdf
          id={this.state.pdfId}
          companies={this.props.companies}
        />
        <SendEstModel
          id={this.state.id}
          invoice_no={this.state.active_est_no}
          isOpen={this.state.modal}
          toggle={this.toggleSend}
          email={this.state.email}
        />
        {/* <EstimationDetailsModel id={this.state.id} isOpen={this.state.modalView} toggle={this.toggleView} instance_id={this.state.instance_id} email={this.state.email} /> */}
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCompanies: () => {
      dispatch(getCompanies());
    },
  };
};
const mapStateToProps = (state: {
  customers: any;
  permissions: any;
  companies: any;
}) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
    companies: state.companies.companies,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EstimationList));
