import React from 'react';

const Invoice2Icon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
            <g id="icons_add_invoice-04" transform="translate(19.2 34.7)">
                <g id="Rectangle_6" data-name="Rectangle 6" transform="translate(-19.2 -34.7)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                    <rect width="44" height="44" stroke="none" />
                    <rect x="0.5" y="0.5" width="43" height="43" fill="none" />
                </g>
                <g id="Group_9" data-name="Group 9" transform="translate(-17 -33)">
                    <path id="Path_37" data-name="Path 37" d="M28.4,36.7a8.8,8.8,0,1,1,8.8-8.8A8.813,8.813,0,0,1,28.4,36.7Zm0-15.4A6.6,6.6,0,1,0,35,27.9,6.594,6.594,0,0,0,28.4,21.3Z" fill="#1d6f41" />
                    <path id="Path_38" data-name="Path 38" d="M28.4,32.6a1.11,1.11,0,0,1-1.1-1.1V23.9a1.1,1.1,0,1,1,2.2,0v7.6A1.11,1.11,0,0,1,28.4,32.6Z" fill="#1d6f41" />
                    <path id="Path_39" data-name="Path 39" d="M32.2,28.8H24.6a1.1,1.1,0,1,1,0-2.2h7.6a1.1,1.1,0,0,1,0,2.2Z" fill="#1d6f41" />
                    <path id="Path_40" data-name="Path 40" d="M20.3,36.6H4.9a1.8,1.8,0,0,1-1.5-.7,2.271,2.271,0,0,1-.6-1.6l1-20.8a2.165,2.165,0,0,1,2.1-2H26.3a2.094,2.094,0,0,1,2.1,2l.2,3.6a1.131,1.131,0,0,1-1.1,1.2,1.193,1.193,0,0,1-1.2-1.1l-.2-3.5H6.1L5,34.3H20.2a1.11,1.11,0,0,1,1.1,1.1A1.009,1.009,0,0,1,20.3,36.6Z" fill="#1d6f41" />
                    <path id="Path_41" data-name="Path 41" d="M10.2,12.5,8,11.9c2.2-8,6.4-8.6,8.1-8.6,3.6.2,6.6,3.7,7.5,8.5l-2.2.4c-.6-3.7-2.9-6.5-5.3-6.6C13.6,5.5,11.4,8,10.2,12.5Z" fill="#1d6f41" />
                </g>
            </g>
        </svg>

    );
}

export default Invoice2Icon;
