import React, { Component, Dispatch } from "react";
import { Alert, Label, Modal } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import RenewPlan from "../Payments/RenewPlan";
import { post, get } from "src/helpers/api_helper";
import { connect } from "react-redux";
import { getCustomers } from "src/store/actions";
import Skeleton from "react-loading-skeleton";

interface Params extends RouteComponentProps {
  newCustomerName?: string;
  modal_cust_is_open: boolean;
  closeModal: Function;
  countries?: Array<any>;
  states?: Array<any>;
  currencies?: Array<any>;
  getCustomers?: () => void;
  id?: string | undefined;
  parentCallback: Function;
}

type State = {
  newCustomerName: string;
  id: string | undefined;
  customer_name: string | undefined;
  billing_address1: string;
  billing_address2: string;
  billing_town_or_city: string;
  billing_country: string;
  billing_state: string;
  billing_pincode: string;
  shipping_address1: string;
  shipping_address2: string;
  shipping_town_or_city: string;
  shipping_country: string;
  shipping_state: string;
  shipping_pincode: string;
  phone: string;
  email: string;
  gstin: string;
  pan: string;
  tan: string;
  credit_period: number;
  credit_limit: number;
  loading: boolean;
  states: Array<any>;
  billing_is_indian_state: number;
  shipping_is_indian_state: number;
  contact_person: string;
  designation: string;
  countries: Array<any>;
  currencies: Array<any>;
  currency: string;
  currency_id: string;
  place_supply: number;
  isSameBillAddress: boolean;
  countries_supply: Array<any>;
  supply_country: string;
  supply_state: string;
  vendor_supplier_code: string;
  supply_country_validate: boolean;
  supply_state_validate: boolean;
  isProgress: number;
  home_country: string;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  modalPlan: boolean;
  isPlanExpired: number;
  activeButton: any;
  success: number;
  message: any;
  // reg_in_gst: any;
};

class CustomerCreateFullModal extends Component<Params, State> {
  static defaultProps: {
    id: string | undefined;
  };
  constructor(props: any) {
    super(props);
    this.state = {
      newCustomerName: "",
      id: this.props.id,
      customer_name: "",
      billing_address1: "",
      billing_address2: "",
      billing_town_or_city: "",
      billing_country: "99",
      billing_state: "",
      billing_pincode: "",
      shipping_address1: "",
      shipping_address2: "",
      shipping_town_or_city: "",
      shipping_country: "99",
      shipping_state: "",
      shipping_pincode: "",
      phone: "",
      email: "",
      gstin: "",
      pan: "",
      tan: "",
      credit_period: 0,
      credit_limit: 0,
      states: [],
      currencies: [],
      loading: false,
      billing_is_indian_state: 1,
      shipping_is_indian_state: 1,
      contact_person: "",
      designation: "",
      countries: [],
      currency: "INR",
      currency_id: "99",
      place_supply: 1,
      isSameBillAddress: true,
      countries_supply: [],
      supply_country: "99",
      supply_state: "",
      vendor_supplier_code: "",
      supply_country_validate: false,
      supply_state_validate: true,
      isProgress: 0,
      home_country: "99",
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      modalPlan: false,
      isPlanExpired: 0,
      activeButton: "taxInfo",
      success: 0,
      message: "",
      // reg_in_gst: null,
    };
  }

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };
  componentDidUpdate(prevProps: Params) {
    if (prevProps.newCustomerName !== this.props.newCustomerName) {
      this.setState({
        customer_name: this.props.newCustomerName,
      });
      this.loadData();
    }
  }
  successModalPlan = () => {
    this.setState({ isPlanExpired: 1, modalPlan: false });

    const getAuthUser: any = localStorage.getItem("authUser");
    const objUser = JSON.parse(getAuthUser);
    objUser.isPlanExpired = 1;
    localStorage.setItem("authUser", JSON.stringify(objUser));
  };

  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
    if (name == "supply_country") {
      this.setState({ billing_country: value });
      if (this.state.isSameBillAddress) {
        this.setState({ shipping_country: value });
        this.setState({ shipping_state: this.state.billing_state });
      }
    }
    if (name == "supply_state") {
      this.setState({ billing_state: value });
      if (this.state.isSameBillAddress) {
        this.setState({ shipping_state: value });
      }
    }
    if (name == "billing_state") {
      this.setState({ supply_state: value });
      if (this.state.isSameBillAddress) {
        this.setState({ shipping_state: value });
      }
    }
    if (name == "currency") {
      let index = e.nativeEvent.target.selectedIndex;
      let countrycode = e.nativeEvent.target[index].getAttribute("data-code");
      this.setState({ currency: countrycode });
      this.setState({ currency_id: value });
    }
    if (this.state.isSameBillAddress) {
      this.setState({
        shipping_address1: this.state.billing_address1,
        shipping_address2: this.state.billing_address2,
        shipping_town_or_city: this.state.billing_town_or_city,
        shipping_pincode: this.state.billing_pincode,
      });
    }
  };

  onChangehandlerSupply = (e: any) => {
    let value: number = e.target.value;
    if (value == 1) {
      let countries = this.state.countries.filter(
        country => country.id == this.state.home_country
      );
      this.setState({
        countries_supply: countries,
        supply_country: this.state.home_country,
        supply_country_validate: false,
        supply_state_validate: true,
        billing_is_indian_state: 1,
        billing_country: this.state.home_country,
        billing_state: "",
      });
      if (this.state.isSameBillAddress) {
        this.setState({
          shipping_state: "",
          shipping_is_indian_state: 1,
          shipping_country: this.state.home_country,
        });
      }
    } else {
      let countries = this.state.countries.filter(
        country => country.id != this.state.home_country
      );
      this.setState({
        countries_supply: countries,
        supply_country: "",
        supply_country_validate: true,
        supply_state_validate: false,
        billing_is_indian_state: 0,
        billing_country: "",
        billing_state: "",
      });
      if (this.state.isSameBillAddress) {
        this.setState({
          shipping_state: "",
          shipping_is_indian_state: 0,
          shipping_country: "",
        });
      }
    }
  };

  onChangeBillingCountryhandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
    if (this.state.isSameBillAddress) {
      this.setState({ shipping_country: value });
      if (value == this.state.home_country) {
        this.setState({ shipping_is_indian_state: 1, shipping_state: "" });
      } else {
        this.setState({ shipping_is_indian_state: 0, shipping_state: "" });
      }
    }
    if (value == this.state.home_country) {
      this.setState({ billing_is_indian_state: 1, billing_state: "" });
    } else {
      this.setState({ billing_is_indian_state: 0, billing_state: "" });
    }
  };

  onChangeShippingCountryhandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
    if (value == this.state.home_country) {
      this.setState({ shipping_is_indian_state: 1, shipping_state: "" });
    } else {
      this.setState({ shipping_is_indian_state: 0, shipping_state: "" });
    }
  };

  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isProgress: 1 });
    values["id"] = this.state.id;
    values["country_code"] = this.state.currency;
    values["billing_is_indian_state"] = this.state.billing_is_indian_state;
    values["shipping_is_indian_state"] = this.state.shipping_is_indian_state;
    values["billing_address1"] = this.state.billing_address1;
    values["billing_address2"] = this.state.billing_address2;
    values["billing_pincode"] = this.state.billing_pincode;
    values["billing_country"] = this.state.billing_country;
    values["billing_state"] = this.state.billing_state;
    values["billing_town_or_city"] = this.state.billing_town_or_city;
    values["gstin"] = this.state.gstin;
    values["pan"] = this.state.pan;
    values["tan"] = this.state.tan;
    values["contact_person"] = this.state.contact_person;
    values["currency"] = this.state.currency_id;
    values["email"] = this.state.email;
    values["phone"] = this.state.phone;
    values["credit_limit"] = this.state.credit_limit;
    values["credit_period"] = this.state.credit_period;
    values["vendor_supplier_code"] = this.state.vendor_supplier_code;

    if (this.state.isSameBillAddress) {
      values["shipping_address1"] = this.state.billing_address1;
      values["shipping_address2"] = this.state.billing_address2;
      values["shipping_pincode"] = this.state.billing_pincode;
      values["shipping_country"] = this.state.billing_country;
      values["shipping_state"] = this.state.billing_state;
      values["shipping_town_or_city"] = this.state.billing_town_or_city;
    } else {
      values["shipping_address1"] = this.state.shipping_address1;
      values["shipping_address2"] = this.state.shipping_address2;
      values["shipping_pincode"] = this.state.shipping_pincode;
      values["shipping_country"] = this.state.shipping_country;
      values["shipping_state"] = this.state.shipping_state;
      values["shipping_town_or_city"] = this.state.shipping_town_or_city;
    }
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/customer_save",
      values
    );
    if (resp.isRenew == 1) {
      this.openModalPlan();
    } else {
      if (resp.success === true) {
        this.setState({ isHideSuccessMgs: false });
        let customer = { customer_name: resp.customer_name, id: resp.id };
        this.props.getCustomers?.();
        this.props.parentCallback(customer);
      } else {
        this.setState({ isHideSuccessMgs: false });
      }
    }
  };
  handleInvalidSubmit = (event: any, errors: any, values: any) => {};
  handleChange = (event:any,errors: any, values: any) => {
    const { name, value } = event.target;
    if (value.length >= 190) {
      this.setState({ message: "Customer name must be 190 characters or less",
        success: 2,
       });
    } else {
      this.setState({ message: '',success: 1,
      });
    }
  };


  copyBillAddress = async (e: any) => {
    if (e.target.checked) {
      if (this.state.billing_country == this.state.home_country) {
        this.setState({ shipping_is_indian_state: 1, shipping_state: "" });
      } else {
        this.setState({ shipping_is_indian_state: 0, shipping_state: "" });
      }
      this.setState({
        shipping_address1: this.state.billing_address1,
        shipping_address2: this.state.billing_address2,
        shipping_town_or_city: this.state.billing_town_or_city,
        shipping_state: this.state.billing_state,
        shipping_pincode: this.state.billing_pincode,
        shipping_country: this.state.billing_country,
        isSameBillAddress: true,
      });
    } else {
      this.setState({
        shipping_address1: "",
        shipping_address2: "",
        shipping_town_or_city: "",
        shipping_state: "",
        shipping_pincode: "",
        shipping_country: "",
        isSameBillAddress: false,
      });
    }
  };
  loadData = async () => {
    let countries: any = this.props.countries;
    countries = countries.filter(
      (country: { id: string }) => country.id == this.state.home_country
    );

    let states: any = this.props.states;
    let countriesData: any = this.props.countries;
    let currencies: any = this.props.currencies;

    this.setState({
      states: states,
      countries: countriesData,
      countries_supply: countries,
      loading: false,
      currencies: currencies,
    });

    if (this.props.id != "0") {
      const respP = await get(
        process.env.REACT_APP_API_URL + "/api/customer_get/" + this.state.id
      );
      this.setState(respP.data);
      this.setState({ currency_id: respP.data.currency_country_id });

      if (
        respP.data.billing_address1 == respP.data.shipping_address1 &&
        respP.data.billing_address2 == respP.data.shipping_address2 &&
        respP.data.billing_town_or_city == respP.data.shipping_town_or_city &&
        respP.data.billing_state == respP.data.shipping_state &&
        respP.data.billing_pincode == respP.data.shipping_pincode &&
        respP.data.billing_country == respP.data.shipping_country
      ) {
        this.setState({ isSameBillAddress: true });
      } else {
        this.setState({ isSameBillAddress: false });
      }

      if (this.state.place_supply == 1) {
        let countries = this.state.countries.filter(
          country => country.id == this.state.home_country
        );
        this.setState({
          countries_supply: countries,
          supply_country_validate: false,
          supply_state_validate: true,
        });
      } else {
        let countries = this.state.countries.filter(
          country => country.id != this.state.home_country
        );
        this.setState({
          countries_supply: countries,
          supply_country_validate: true,
          supply_state_validate: false,
        });
      }
    }
  };
  componentDidMount() {
    this.loadData();
  }
  handleButtonClick = (buttonName: any) => {
    this.setState({ activeButton: buttonName });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal_cust_is_open}
          className="md-700 cusCreatePopup"
        >
            {this.state.success === 2 ? (
                  <Alert color="danger">{this.state.message}</Alert>
                ) : null}
          <AvForm
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="item_form border-none-sm pt-0 pb-0">
                  <div className="row">
                    <div className="heading">Customer Create</div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Customer name
                    </label>
                    <div className="col-sm-8">
                      <AvField
                        name="customer_name"
                        type="text"
                        placeholder="Enter Customer name"
                        errorMessage="Enter Customer name"
                        value={this.state.customer_name || ""}
                        validate={{ required: { value: true } }}
                        className="mb-1"
                        maxLength="190"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="progresspill-firstname-input"
                      className="col-sm-4 col-form-label"
                    >
                      Place of supply
                    </label>
                    <Col md={6} lg={6} className=" hstack gap-1">
                      <AvRadioGroup
                        inline
                        name="place_supply"
                        required
                        value={this.state.place_supply || ""}
                        onChange={this.onChangehandlerSupply}
                      >
                        <AvRadio label="Domestic" value={1} />
                        <AvRadio label="Export" value={2} />
                      </AvRadioGroup>
                    </Col>
                  </div>
                  <div style={{ paddingBottom: 7 }}>
                    <div>
                      {this.state.supply_country_validate && (
                        <div>
                          <div className="mb-3">
                            <Row>
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <div className="col-sm-4">
                                    <label htmlFor="progresspill-firstname-input">
                                      Supply Country
                                    </label>
                                  </div>

                                  <Col className="sm-8">
                                    <AvField
                                      type="select"
                                      name="supply_country"
                                      value={this.state.supply_country || ""}
                                      validate={{
                                        required: {
                                          value:
                                            this.state.supply_country_validate,
                                        },
                                      }}
                                      onChange={this.onChangehandler}
                                      onKeyUp={this.onChangehandler}
                                    >
                                      <option key={-1} value={""}>
                                        --Select--
                                      </option>
                                      {this.state.countries_supply.map(
                                        country => (
                                          <option
                                            value={country.id}
                                            key={country.id}
                                          >
                                            {country.name}
                                          </option>
                                        )
                                      )}
                                    </AvField>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </div>
                        </div>
                      )}

                      {this.state.supply_state_validate && (
                        <div>
                          <div className="mb-3">
                            <Row>
                              {this.state.loading ? (
                                <>
                                  <Skeleton width={100} />
                                  <Skeleton height={37} />
                                </>
                              ) : (
                                <>
                                  <div className="col-sm-4">
                                    <label
                                      htmlFor="progresspill-firstname-input"
                                      className="col-sm-4 col-form-label"
                                    >
                                      Bill To
                                    </label>
                                  </div>
                                  <Col className="sm-8">
                                    <AvField
                                      type="select"
                                      name="supply_state"
                                      value={this.state.supply_state || ""}
                                      validate={{
                                        required: {
                                          value:
                                            this.state.supply_state_validate,
                                        },
                                      }}
                                      onChange={this.onChangehandler}
                                      onKeyUp={this.onChangehandler}
                                    >
                                      <option key={-1} value={""}>
                                        --Select--
                                      </option>
                                      {this.state.states.map(state => (
                                        <option value={state.id} key={state.id}>
                                          {state.state}
                                        </option>
                                      ))}
                                    </AvField>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row tab-head cstm-tab">
                    <Col>
                      <Button
                        style={{
                          width: "25%",
                          border: "1px solid #CFCFCF",
                          borderRadius: "6px 6px 0 0",
                          boxShadow: "none",
                          backgroundColor:
                            this.state.activeButton === "taxInfo"
                              ? "#10a37f"
                              : "#F2F2F2",
                          color:
                            this.state.activeButton === "taxInfo"
                              ? "#F2F2F2"
                              : "#000",
                        }}
                        onClick={() => this.handleButtonClick("taxInfo")}
                      >
                        Tax Info
                      </Button>

                      <Button
                        style={{
                          width: "25%",
                          border: "1px solid #CFCFCF",
                          borderRadius: "6px 6px 0 0",
                          boxShadow: "none",
                          backgroundColor:
                            this.state.activeButton === "billingAddress"
                              ? "#10a37f"
                              : "#F2F2F2",
                          color:
                            this.state.activeButton === "billingAddress"
                              ? "#F2F2F2"
                              : "#000",
                        }}
                        onClick={() => this.handleButtonClick("billingAddress")}
                      >
                        Billing Address
                      </Button>

                      <Button
                        style={{
                          width: "25%",
                          border: "1px solid #CFCFCF",
                          borderRadius: "6px 6px 0 0",
                          boxShadow: "none",
                          backgroundColor:
                            this.state.activeButton === "shippingAddress"
                              ? "#10a37f"
                              : "#F2F2F2",
                          color:
                            this.state.activeButton === "shippingAddress"
                              ? "#F2F2F2"
                              : "#000",
                        }}
                        onClick={() =>
                          this.handleButtonClick("shippingAddress")
                        }
                      >
                        Shipping Address
                      </Button>

                      <Button
                        style={{
                          width: "25%",
                          border: "1px solid #CFCFCF",
                          borderRadius: "6px 6px 0 0",
                          boxShadow: "none",
                          backgroundColor:
                            this.state.activeButton === "moreDetails"
                              ? "#10a37f"
                              : "#F2F2F2",
                          color:
                            this.state.activeButton === "moreDetails"
                              ? "#F2F2F2"
                              : "#000",
                        }}
                        onClick={() => this.handleButtonClick("moreDetails")}
                      >
                        More Details
                      </Button>
                    </Col>
                  </div>

                  {this.state.activeButton == "taxInfo" && (
                    <>
                      {/* <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Registered in GST
                        </Label>
                        <Col md={6} lg={6} className=" hstack gap-1">
                          <AvRadioGroup
                            inline
                            name="reg_in_gst"
                            onChange={this.onChangehandler}
                          >
                            <AvRadio label="Yes" value={1} />
                            <AvRadio label="No" value={0} />
                          </AvRadioGroup>
                        </Col>
                      </Row> */}
                      <div className="row">
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          GST Number
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="gstin"
                            type="text"
                            placeholder="Enter GSTIN"
                            errorMessage="Enter GSTIN"
                            value={this.state.gstin || ""}
                            onChange={this.onChangehandler}
                            // disabled={this.state.reg_in_gst == 0}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          PAN Number
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="pan"
                            type="text"
                            placeholder="Enter PAN"
                            errorMessage="Enter PAN"
                            value={this.state.pan || ""}
                            onChange={this.onChangehandler}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          TAN Number
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="tan"
                            type="text"
                            placeholder="Enter TAN"
                            errorMessage="Enter TAN"
                            value={this.state.tan || ""}
                            onChange={this.onChangehandler}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.activeButton == "billingAddress" && (
                    <>
                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Flat/House/Building
                        </Label>
                        <Col>
                          <AvField
                            name="billing_address1"
                            type="text"
                            placeholder="Enter Address"
                            // errorMessage="Enter Address"
                            value={this.state.billing_address1 || ""}
                            // validate={{ required: { value: true } }}
                            onChange={this.onChangehandler}
                            onKeyUp={this.onChangehandler}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Area/Street/Village
                        </Label>
                        <Col>
                          <AvField
                            name="billing_address2"
                            type="text"
                            placeholder="Enter Address"
                            // errorMessage="Enter Address"
                            value={this.state.billing_address2 || ""}
                            // validate={{ required: { value: true } }}
                            onChange={this.onChangehandler}
                            onKeyUp={this.onChangehandler}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Town/City
                        </Label>
                        <Col>
                          <AvField
                            name="billing_town_or_city"
                            type="text"
                            placeholder="Enter Town Or City"
                            // errorMessage="Enter Town Or City"
                            value={this.state.billing_town_or_city || ""}
                            // validate={{ required: { value: true } }}
                            onChange={this.onChangehandler}
                            onKeyUp={this.onChangehandler}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Country
                        </Label>
                        {this.state.loading ? (
                          <>
                            <Skeleton width={100} />
                            <Skeleton height={37} />
                          </>
                        ) : (
                          <Col>
                            <AvField
                              type="select"
                              name="billing_country"
                              value={this.state.billing_country || ""}
                              validate={{ required: { value: true } }}
                              onChange={this.onChangeBillingCountryhandler}
                            >
                              <option key={-1} value={""}>
                                --Select--
                              </option>
                              {this.state.countries.map(country => (
                                <option value={country.id} key={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          State / Province
                        </Label>
                        {this.state.loading ? (
                          <>
                            <Skeleton width={100} />
                            <Skeleton height={37} />
                          </>
                        ) : (
                          <Col>
                            {this.state.billing_is_indian_state ? (
                              <AvField
                                type="select"
                                name="billing_state"
                                value={this.state.billing_state || ""}
                                validate={{ required: { value: true } }}
                                onChange={this.onChangehandler}
                                onKeyUp={this.onChangehandler}
                                disabled={true}
                              >
                                <option key={-1} value={""}>
                                  --Select--
                                </option>
                                {this.state.states.map(state => (
                                  <option value={state.id} key={state.id}>
                                    {state.state}
                                  </option>
                                ))}
                              </AvField>
                            ) : (
                              <AvField
                                name="billing_state"
                                type="text"
                                placeholder="Enter state or province"
                                // errorMessage="Enter state or province"
                                value={this.state.billing_state || ""}
                                // validate={{ required: { value: true } }}
                                onChange={this.onChangehandler}
                                onKeyUp={this.onChangehandler}
                              />
                            )}
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Label
                          htmlFor="horizontal-firstname-input"
                          className="col-md-4 col-lg-4 col-form-label"
                        >
                          Pincode
                        </Label>
                        <Col>
                          <AvField
                            name="billing_pincode"
                            type="text"
                            placeholder="Enter Pincode"
                            // errorMessage="Enter Pincode"
                            value={this.state.billing_pincode || ""}
                            // validate={{ required: { value: true } }}
                            onChange={this.onChangehandler}
                            onKeyUp={this.onChangehandler}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {this.state.activeButton == "shippingAddress" && (
                    <>
                      <div className="row">
                        {this.state.loading ? (
                          <>
                            <Skeleton width={200} />
                          </>
                        ) : (
                          <h5 className="mb-3">
                            Shiping Address
                            <span className="font-size-14">
                              {" "}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck1"
                                onChange={this.copyBillAddress}
                                checked={this.state.isSameBillAddress}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="formCheck1"
                              >
                                {" (Same as billing address) "}
                              </label>
                            </span>
                          </h5>
                        )}
                      </div>

                      <div>
                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            Flat/House/Building
                          </Label>
                          <Col>
                            <AvField
                              name="shipping_address1"
                              type="text"
                              placeholder="Enter Address"
                              // errorMessage="Enter Address"
                              value={this.state.shipping_address1 || ""}
                              disabled={this.state.isSameBillAddress}
                              validate={{
                                required: {
                                  value: !this.state.isSameBillAddress,
                                },
                              }}
                              onChange={this.onChangehandler}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            Area/Street/Village
                          </Label>
                          <Col>
                            <AvField
                              name="shipping_address2"
                              type="text"
                              placeholder="Enter Address"
                              // errorMessage="Enter Address"
                              value={this.state.shipping_address2 || ""}
                              // validate={{ required: { value: true } }}
                              onChange={this.onChangehandler}
                              disabled={this.state.isSameBillAddress}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            Town/City
                          </Label>
                          <Col>
                            <AvField
                              name="shipping_town_or_city"
                              type="text"
                              placeholder="Enter Town Or City"
                              // errorMessage="Enter Town Or City"
                              value={this.state.shipping_town_or_city || ""}
                              // validate={{ required: { value: true } }}
                              onChange={this.onChangehandler}
                              disabled={this.state.isSameBillAddress}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            Country
                          </Label>
                          {this.state.loading ? (
                            <>
                              <Skeleton width={100} />
                              <Skeleton height={37} />
                            </>
                          ) : (
                            <Col>
                              <AvField
                                type="select"
                                name="shipping_country"
                                value={this.state.shipping_country || ""}
                                validate={{
                                  required: {
                                    value: !this.state.isSameBillAddress,
                                  },
                                }}
                                onChange={this.onChangeShippingCountryhandler}
                                disabled={this.state.isSameBillAddress}
                              >
                                <option key={-1} value={""}>
                                  --Select--
                                </option>
                                {this.state.countries.map(country => (
                                  <option value={country.id} key={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            State / Province
                          </Label>
                          {this.state.loading ? (
                            <>
                              <Skeleton width={100} />
                              <Skeleton height={37} />
                            </>
                          ) : (
                            <Col>
                              {this.state.shipping_is_indian_state ? (
                                <AvField
                                  type="select"
                                  name="shipping_state"
                                  value={this.state.shipping_state || ""}
                                  onChange={this.onChangehandler}
                                  disabled={this.state.isSameBillAddress}
                                  validate={{
                                    required: {
                                      value: !this.state.isSameBillAddress,
                                    },
                                  }}
                                >
                                  <option key={-1} value={""}>
                                    --Select--
                                  </option>
                                  {this.state.states.map(state => (
                                    <option value={state.id} key={state.id}>
                                      {state.state}
                                    </option>
                                  ))}
                                </AvField>
                              ) : (
                                <AvField
                                  name="shipping_state"
                                  type="text"
                                  placeholder="Enter state or province"
                                  // errorMessage="Enter state or province"
                                  value={this.state.shipping_state || ""}
                                  onChange={this.onChangehandler}
                                  disabled={this.state.isSameBillAddress}
                                  // validate={{ required: { value: true } }}
                                />
                              )}
                            </Col>
                          )}
                        </Row>

                        <Row>
                          <Label
                            htmlFor="horizontal-firstname-input"
                            className="col-md-4 col-lg-4 col-form-label"
                          >
                            Pincode
                          </Label>
                          <Col>
                            <AvField
                              name="shipping_pincode"
                              type="text"
                              placeholder="Enter Pincode"
                              // errorMessage="Enter Pincode"
                              value={this.state.shipping_pincode || ""}
                              onChange={this.onChangehandler}
                              disabled={this.state.isSameBillAddress}
                              // validate={{ required: { value: true } }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  {this.state.activeButton == "moreDetails" && (
                    <>
                      <div className="row">
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Contact Person
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="contact_person"
                            type="text"
                            placeholder="Enter contact person"
                            // errorMessage="Enter contact person"
                            value={this.state.contact_person || ""}
                            onChange={this.onChangehandler}
                            // validate={{ required: { value: true } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="phone"
                            type="text"
                            placeholder="Enter phone"
                            // errorMessage="Enter phone"
                            value={this.state.phone || ""}
                            onChange={this.onChangehandler}
                            // validate={{ required: { value: true } }}
                          />
                        </div>
                      </div>
                      <Row>
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            // errorMessage="Enter email"
                            value={this.state.email || ""}
                            onChange={this.onChangehandler}
                            // validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Currency
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            type="select"
                            name="currency"
                            value={this.state.currency_id || ""}
                            onChange={this.onChangehandler}
                          >
                            <option key={-1} value={""}>
                              --Select--
                            </option>
                            {this.state.countries.map(currency => (
                              <option
                                value={currency.id}
                                key={currency.id}
                                data-code={currency.currency_code}
                              >
                                {currency.name} ({currency.currency_code})
                              </option>
                            ))}
                          </AvField>
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Credit Period(days)
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="credit_period"
                            type="number"
                            placeholder="Enter Credit Period"
                            errorMessage="Enter Credit Period"
                            value={this.state.credit_period || ""}
                            onChange={this.onChangehandler}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Credit Limit
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="credit_limit"
                            type="number"
                            placeholder="Enter Credit Limit"
                            errorMessage="Enter Credit Limit"
                            value={this.state.credit_limit || ""}
                            onChange={this.onChangehandler}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="progresspill-address-input"
                          className="col-sm-4 col-form-label"
                        >
                          Vendor/Supplier Code
                        </label>
                        <div className="col-sm-8">
                          <AvField
                            name="vendor_supplier_code"
                            type="text"
                            placeholder="Enter Vendor/Supplier Code"
                            errorMessage="Enter Vendor/Supplier Code"
                            value={this.state.vendor_supplier_code || ""}
                            onChange={this.onChangehandler}
                          />
                        </div>
                      </Row>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              {this.state.isProgress ? (
                <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit itemsubmit"
                    disabled={true}
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    Submitting
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => this.props.closeModal()}
                    className="bmt-btn-close itemsubmit"
                    data-dismiss="modal"
                  >
                    Close
                  </Button>{" "}
                  <Button type="submit" className="bmt-btn-submit itemsubmit">
                    Submit
                  </Button>
                </>
              )}
            </div>
          </AvForm>
        </Modal>
        <div>
          <RenewPlan
            modal_is_open={this.state.modalPlan}
            openModal={this.openModalPlan}
            closeModal={this.closeModalPlan}
            successModalPlan={this.successModalPlan}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: {
  countries: any;
  states: any;
  currencies: any;
}) => {
  return {
    states: state.states.states,
    countries: state.countries.countries,
    currencies: state.currencies.currencies,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCustomers: () => {
      dispatch(getCustomers());
    },
  };
};
CustomerCreateFullModal.defaultProps = {
  id: "0",
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerCreateFullModal));
