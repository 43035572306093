import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { connect } from "react-redux";
import { getProducts } from "src/store/actions";
import { PermissionModule } from "src/store/permissions/actionTypes";

interface Params {
    id: string;
}
interface Params2 extends RouteComponentProps<Params> {
    units: Array<any>;
    taxes: Array<any>;
    permissions: Array<any>;
    getProducts: () => void;
}
type RoleState = {
    id: string;
    name: string;
    description: string;
    rate: number;
    unit_id: number;
    tax_id: number;
    units: Array<any>;
    taxes: Array<any>;
    type: string;
    hsn_or_sac: string;
    permissions: Array<any>;
    loading: boolean;
    isProgress: number;
};

class ProductModify extends Component<Params2, RoleState> {
    constructor(props: any) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            id: id,
            name: "",
            description: "",
            unit_id: 0,
            tax_id: 0,
            rate: 0,
            units: [],
            taxes: [],
            type: "product",
            hsn_or_sac: "",
            permissions: [],
            loading: true,
            isProgress: 0 
        };
    }

    resetForm = () => {

        this.props.history.push("/products");
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    handleValidSubmit = (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;

        const resp = post(process.env.REACT_APP_API_URL + '/api/product_save', values);
        this.props.history.push("/products");
        this.props.getProducts();
        this.setState({ isProgress: 0 });
    }
    loadData = async() => {
        try {

            let productPermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.PRODUCT);
            const permission = productPermissions.find(({ action, is_active }) => action == "modify" && is_active == 1);

                if (!permission) {
                    this.props.history.push("/products");
                }
            this.setState({ units: this.props.units, taxes: this.props.taxes });
            const respP = await get(process.env.REACT_APP_API_URL + '/api/product_get/' + this.state.id);
            this.setState(respP.data);            

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
        this.setState({ loading: false });
    }
    async componentDidMount() {
        this.loadData();
    }
    componentWillReceiveProps(props: Params2) {
        this.loadData();
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Products</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Modify Product" breadcrumbItem="Modify Product" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader></CardHeader>



                                    <CardBody>

                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    Product name
                                                                </label>
                                                                <AvField
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder="Enter Product name"
                                                                    errorMessage="Enter Product name"
                                                                    value={this.state.name}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Product Type
                                                                </label>
                                                                <AvRadioGroup inline name="type" onChange={this.onChangehandler} required value={this.state.type}>
                                                                    <AvRadio label="Product" value="product" />
                                                                    <AvRadio label="Service" value="service" />
                                                                </AvRadioGroup>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    {this.state.type == "service" ? "SAC" : "HSN"}
                                                                </label>
                                                                <AvField
                                                                    name="hsn_or_sac"
                                                                    type="text"
                                                                    placeholder={this.state.type == "service" ? "Enter SAC" : "Enter HSN"}
                                                                    value={this.state.hsn_or_sac}
                                                                    onChange={this.onChangehandler}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <AvField type="select" name="tax_id" label="GST Tax Rate" errorMessage="Choose Tax"
                                                                    //validate={{ required: { value: true } }} 
                                                                    value={this.state.tax_id} onChange={this.onChangehandler} >
                                                                    <option value="" key="" >--Select--</option>
                                                                    {this.state.taxes.map((tax) => (
                                                                        <option value={tax.id} key={tax.id} >{tax.tax_name}</option>
                                                                    ))}
                                                                </AvField>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Rate
                                                                </label>
                                                                <AvField
                                                                    name="rate"
                                                                    type="number"
                                                                    placeholder="Enter rate"
                                                                    errorMessage="Enter rate"
                                                                    value={this.state.rate}
                                                                    onChange={this.onChangehandler}
                                                                // validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <AvField type="select" name="unit_id" label="Unit" errorMessage="Choose Unit"
                                                                    // validate={{ required: { value: true } }} 
                                                                    value={this.state.unit_id} onChange={this.onChangehandler} >
                                                                    <option value="" key="" >--Select--</option>
                                                                    {this.state.units.map((unit) => (
                                                                        <option value={unit.id} key={unit.id} >{unit.unit}</option>
                                                                    ))}
                                                                </AvField>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Description
                                                                </label>
                                                                <AvField
                                                                    name="description"
                                                                    type="text"
                                                                    placeholder="Enter description"
                                                                    errorMessage="Enter description"
                                                                    value={this.state.description}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: false } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-wrap gap-2">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                                    <>
                                                        {this.state.isProgress ?
                                                            <>
                                                                <Button type="submit" className="bmt-btn-submit" disabled={true}>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submitting
                                                                </Button>
                                                            </> :
                                                            <>
                                                                <Button type="reset" className="bmt-btn-close closesubmit" onClick={this.resetForm}>
                                                                    Cancel
                                                                </Button>
                                                                <Button type="submit" className="bmt-btn-submit closesubmit">
                                                                    Submit
                                                                </Button>{" "}
                                                                
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:{ units: any,taxes: any,permissions: any }) => {   
    return{  
        units: state.units.units,      
        taxes: state.taxes.taxes,
        permissions: state.permissions.permissions
    }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {   
    return{
        getProducts: () => {
            dispatch(getProducts());
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ProductModify));