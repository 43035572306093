import React, { Component } from "react";
import { Alert, Button, Col, Modal, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { post, get } from "src/helpers/api_helper";
import moment from "moment";
import DatePicker from "rsuite/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { number_format } from "src/helpers/common";
import { round } from "lodash";
import Skeleton from "react-loading-skeleton";

interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  exp_items: Array<any>;
  bankorcash: Array<any>;
  openModal?: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: () => void;
  callback: () => void;
  id?: string | undefined;
}

interface Option {
  label: string;
  value: string;
  type: number;
}

interface State {
  success: number;
  id: string | undefined;
  msg: string;
  dtls: Array<any>;
  items: Array<any>;
  journal_entry_date: Date | null;
  journal_entry_no: number;
  journal_entry_prefix: string;
  loading: boolean;
  isProgress: number;
  total_credit: number;
  total_debit: number;
  difference_credit: number;
  difference_debit: number;
  doc_no: string;
}
const createOption = (
  label: string,
  value: string,
  type: number,
  heads_id: number
) => ({
  label,
  value,
  type,
  heads_id,
});
class JournalEntryCreate extends Component<Params, State> {
  static defaultProps: {
    id: string | undefined;
  };
  _isMounted: boolean;
  constructor(props: Params) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: this.props.id,
      success: 0,
      msg: "",
      journal_entry_date: new Date(),
      journal_entry_no: 0,
      journal_entry_prefix: "",
      dtls: [
        {
          item_id: { label: "", value: "", type: "", heads_id: "" },
          debit_amount: 0,
          credit_amount: 0,
          description: "",
        },
        {
          item_id: { label: "", value: "", type: "", heads_id: "" },
          debit_amount: 0,
          credit_amount: 0,
          description: "",
        },
      ],
      items: [],
      loading: false,
      isProgress: 0,
      total_credit: 0,
      total_debit: 0,
      difference_credit: 0,
      difference_debit: 0,
      doc_no: "",
    };
  }

  closeThis = () => {
    this.props.closeModal();
  };

  handleItemChange = (newValue: Option | null, actionMeta: any, i: number) => {
    const dtls = [...this.state.dtls];
    dtls[i].item_id = newValue || { label: "", value: "" };
    this.setState({ dtls });
  };

  onChangehandlerDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let dtls = [...this.state.dtls];
    dtls[i][name] = value;
    this.setState({ dtls });
    if (name == "credit_amount") {
      dtls[i]["debit_amount"] = 0;
    }
    if (name == "debit_amount") {
      dtls[i]["credit_amount"] = 0;
    }
    this.setState({ dtls }, () => {
      this.calculateTotals();
    });
  };
  addRow = () => {
    const dtls = [...this.state.dtls];
    dtls.push({
      item_id: { label: "", value: "", type: "", heads_id: "" },
      debit_amount: 0,
      credit_amount: 0,
      amount: 0,
    });
    this.setState({ dtls });
    this.calculateTotals();
  };
  removeRow = (index: number) => {
    const dtls = [...this.state.dtls];
    if (dtls.length > 2) {
      dtls.splice(index, 1);
      this.setState({ dtls }, () => {
        this.calculateTotals();
      });
    }
  };
  calculateTotals = () => {
    const { dtls } = this.state;

    let total_credit = 0;
    let total_debit = 0;
    let difference_credit = 0;
    let difference_debit = 0;

    dtls.forEach(detail => {
      total_credit += parseFloat(detail.credit_amount) || 0;
      total_debit += parseFloat(detail.debit_amount) || 0;
    });

    difference_credit = total_credit - total_debit || 0;
    difference_debit = total_debit - total_credit || 0;
    if (difference_credit < 0) {
      difference_credit = Math.abs(difference_credit);
    } else {
      difference_credit = 0;
    }
    if (difference_debit < 0) {
      difference_debit = Math.abs(difference_debit);
    } else {
      difference_debit = 0;
    }

    this.setState({
      total_credit,
      total_debit,
      difference_credit,
      difference_debit,
    });
  };

  handleValidSubmit = async (event: any, values: any) => {
    if (
      this.state.difference_credit === 0 &&
      this.state.difference_debit === 0
    ) {
      this.setState({ isProgress: 1 });
      values["id"] = this.state.id;
      values["dtls"] = this.state.dtls;
      values["total"] = this.state.total_credit;
      values["journal_entry_date"] = this.state.journal_entry_date;
      values["journal_entry_no"] = this.state.journal_entry_no;
      values["journal_entry_prefix"] = this.state.journal_entry_prefix;
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/save_journal_entry",
        values
      );
      if (resp.success == true) {
        this.setState({
          success: 1,
          msg: resp.message,
          loading: false,
          isProgress: 0,
        });
        setTimeout(() => {
          this.props.closeModal();
          this.props.callback();
        }, 1000);
      } else {
        this.setState({
          success: 2,
          msg: resp.message,
          isProgress: 0,
        });
      }
      this.setState({ isProgress: 0 });
    } else {
      this.setState({
        success: 2,
        msg: "The Journal entry is not tally",
      });
    }
  };
  genExpItemOptions = () => {
    console.log(this.props.bankorcash);
    const bankOrCashOptions = this.props.bankorcash.map(
      (bankorcash: { name: string; id: string; heads_id: number }) =>
        createOption(bankorcash.name, bankorcash.id, 1, bankorcash.heads_id)
    );
    const productOptions = this.props.exp_items
      .sort((a: { category_id: string }, b: { category_id: string }) => {
        const catA = parseInt(a.category_id, 10);
        const catB = parseInt(b.category_id, 10);
        if (catA === 4 && catB !== 4) return -1;
        if (catA !== 4 && catB === 4) return 1;
        return 0;
      })
      .map((item: { item_name: string; category_name: string; id: string }) =>
        createOption(
          item.item_name + " (" + item.category_name + ")",
          item.id,
          2,
          0
        )
      );
    const options = [...bankOrCashOptions, ...productOptions];
    this.setState({ items: options });
  };
  setJournal_date = (date: any) => {
    if (date == null) {
      this.setState({ journal_entry_date: new Date() });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ journal_entry_date: formattedDate });
    }
  };
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  componentDidUpdate(prevProps: Params) {
    if (prevProps.exp_items.length !== this.props.exp_items.length) {
      this.genExpItemOptions();
    }
    if (prevProps.bankorcash.length !== this.props.bankorcash.length) {
      this.genExpItemOptions();
    }
  }
  loadData = async () => {
    if (this.props.id != undefined) {
      const respP = await get(
        process.env.REACT_APP_API_URL + "/api/get_journal/" + this.state.id
      );
      if (this._isMounted) {
        const journal_entry_date = moment(respP.data.journal_entry_date);
        this.setState({
          journal_entry_date: new Date(journal_entry_date.format("YYYY-MM-DD")),
        });
        const doc_no =
          respP.data.journal_entry_prefix + respP.data.journal_entry_no;
        this.setState({
          doc_no: doc_no,
          journal_entry_no: respP.data.journal_entry_no,
          journal_entry_prefix: respP.data.journal_entry_prefix,
        });
        var dtls: Array<any> = [];
        respP.data.dtls.map(
          async (prodtl: {
            item_id: string;
            debit_amount: number;
            credit_amount: number;
            amount: number;
            type: number;
          }) => {
            let item = this.state.items.find(
              ({ value }) => value === prodtl.item_id
            );
            const itemOption = createOption(
              item.label,
              item.value,
              item.type,
              item.heads_id
            );
            dtls.push({
              item_id: itemOption,
              debit_amount: parseFloat(prodtl.debit_amount.toString())
                .toFixed(2)
                .replace(/\.00$/, ""),
              credit_amount: parseFloat(prodtl.credit_amount.toString())
                .toFixed(2)
                .replace(/\.00$/, ""),
              amount: prodtl.amount,
              type: 2,
            });
          }
        );
        this.setState({ dtls: dtls }, () => {
          this.calculateTotals();
        });
      }
    } else {
      const respE: any = await get(
        process.env.REACT_APP_API_URL + "/api/get_je_docNo"
      );
      if (this._isMounted) {
        const doc_no =
          respE.data.journal_entry_prefix + respE.data.journal_entry_no;
        this.setState({
          doc_no: doc_no,
          journal_entry_no: respE.data.journal_entry_no,
          journal_entry_prefix: respE.data.journal_entry_prefix,
        });
      }
    }
  };
  componentDidMount() {
    const fetchData = async () => {
      try {
        this._isMounted = true;
        this.genExpItemOptions();
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <Modal
        isOpen={this.props.modal_is_open}
        toggle={this.props.openModal}
        className="modal-dialog modal-dialog-centered md-1000 my-0"
      >
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="modal-header d-block border-0 ledger-header justify-content-center py-3">
            {this.state.success === 2 ? (
              <Alert color="danger">{this.state.msg}</Alert>
            ) : null}
            {this.state.success === 1 ? (
              <Alert color="success">{this.state.msg}</Alert>
            ) : null}
            <Row>
              <Col lg={4} className="text-center align-self-center">
                <h4 className="modal-title mt-0 fw-normal">Journal Entry</h4>
              </Col>
              <Col lg={4} className="align-self-center">
                <AvField
                  name="doc_no"
                  value={this.state.doc_no}
                  readOnly={true}
                  className="ps-2"
                />
              </Col>
              <Col lg={4} className="align-self-center">
                <DatePicker
                  oneTap
                  onChange={this.setJournal_date}
                  name="journal_entry_date"
                  format="dd/MM/yyyy"
                  menuClassName="custom-datepicker-dropdown"
                  style={{ width: "100%" }}
                  value={
                    this.state.journal_entry_date
                      ? this.state.journal_entry_date
                      : null
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="modal-body py-0">
            <Row>
              <Col xs="12">
                <div className="form-inline">
                  <Row>
                    <div className="row pe-0">
                      <div className="table-responsive unset-overflow my-2">
                        <Table className="table mb-0 table-borderless -0 tbl-stock-conversion">
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>Item</th>
                              <th style={{ width: "15%" }}>Debit</th>
                              <th style={{ width: "15%" }}>Credit</th>
                              <th style={{ width: "25%" }}>Description</th>
                              <th style={{ width: "5%" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dtls.map((dtl, i) => (
                              <tr key={i}>
                                <td>
                                  <CreatableSelect
                                    isClearable
                                    onChange={(e, actionMeta) =>
                                      this.handleItemChange(e, actionMeta, i)
                                    }
                                    styles={{
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        ":hover": {
                                          backgroundColor: "#10a37f",
                                          color: "#fff",
                                        },
                                        backgroundColor: state.isSelected
                                          ? "#10a37f"
                                          : "inherit",
                                      }),
                                    }}
                                    options={this.state.items}
                                    value={dtl.item_id || ""}
                                    name="item_id"
                                    className="me-2"
                                  />
                                </td>
                                <td>
                                  <AvField
                                    name="debit_amount"
                                    type="number"
                                    errorMessage="Debit Amount"
                                    value={dtl.debit_amount || 0}
                                    onChange={(e: any) =>
                                      this.onChangehandlerDtl(e, i, dtl)
                                    }
                                    className="me-2 text-end"
                                  />
                                </td>
                                <td>
                                  <AvField
                                    name="credit_amount"
                                    type="number"
                                    errorMessage="Credit Amount"
                                    value={dtl.credit_amount || 0}
                                    onChange={(e: any) =>
                                      this.onChangehandlerDtl(e, i, dtl)
                                    }
                                    className="me-2 text-end"
                                  />
                                </td>
                                <td>
                                  <AvField
                                    name="description"
                                    type="text"
                                    errorMessage="Description"
                                    value={dtl.description}
                                    onChange={(e: any) =>
                                      this.onChangehandlerDtl(e, i, dtl)
                                    }
                                    className="me-2"
                                  />
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    className="btn tbl-btn"
                                    onClick={() => this.removeRow(i)}
                                  >
                                    <i className="fas fa-trash text-danger"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Table className="table mb-0 table-borderless -0 tbl-stock-conversion">
                          <tbody>
                            <tr>
                              <td style={{ width: "40%", textAlign: "right" }}>
                                Total &nbsp;
                              </td>
                              <td style={{ width: "15%", textAlign: "right" }}>
                                <div className="form-control">
                                  {number_format(this.state.total_debit) || 0}
                                </div>
                              </td>
                              <td style={{ width: "15%", textAlign: "right" }}>
                                <div className="form-control">
                                  {number_format(this.state.total_credit) || 0}
                                </div>
                              </td>
                              <td style={{ width: "25%" }}></td>
                              <td style={{ width: "5%" }}>
                                <Button
                                  className="btn btn-primary create"
                                  onClick={this.addRow}
                                >
                                  <i className="bx bx-plus"></i>
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "40%", textAlign: "right" }}>
                                Difference &nbsp;
                              </td>
                              <td style={{ width: "15%", textAlign: "right" }}>
                                <div
                                  className={`form-control ${
                                    this.state.difference_debit > 0
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  {number_format(this.state.difference_debit) ||
                                    0}
                                </div>
                              </td>
                              <td style={{ width: "15%", textAlign: "right" }}>
                                <div
                                  className={`form-control ${
                                    this.state.difference_credit > 0
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  {number_format(
                                    this.state.difference_credit
                                  ) || 0}
                                </div>
                              </td>
                              <td style={{ width: "25%" }}></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* Action Buttons */}
                    <div className="d-flex justify-content-center gap-2 mb-4 mt-1">
                      {this.state.loading ? (
                        <>
                          <Skeleton width={100} height={37} />{" "}
                          <Skeleton width={100} height={37} />
                        </>
                      ) : (
                        <>
                          {this.state.isProgress ? (
                            <>
                              <Button
                                type="submit"
                                className="bmt-btn-submit itemsubmit"
                                disabled={true}
                              >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                Submitting
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                type="reset"
                                className="bmt-btn-close itemsubmit"
                                onClick={this.closeThis}
                              >
                                Cancel
                              </Button>{" "}
                              <Button
                                type="submit"
                                className="bmt-btn-submit itemsubmit"
                              >
                                Submit
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    );
  }
}

const mapStateToProps = (state: {
  exp_items: any;
  companies: any;
  taxes: any;
  tdshead: any;
  bankorcash: any;
}) => ({
  exp_items: state.exp_items.exp_items,
  taxes: state.taxes.taxes,
  companies: state.companies.companies,
  tdshead: state.tdshead.tdshead,
  bankorcash: state.bankorcash.bankorcash,
});

export default connect(mapStateToProps)(withRouter(JournalEntryCreate));
