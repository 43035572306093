import {StatesTypes} from "./actionTypes"

const INIT_STATE = {
  states: [],  
  error: {},
}

const States = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case StatesTypes.GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload.data,
      }
    case StatesTypes.GET_STATES:
      return {
        ...state,
        states: state.states,
      }
    case StatesTypes.GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default States
