import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect } from "react-redux";
import { getProducts } from "src/store/actions";

interface Params extends RouteComponentProps {
  modal_is_open: boolean;
  openProductModal:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>;
  closeProductModal: Function;
}

class PurchaseLearnMore extends Component<Params> {
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal_is_open}
          toggle={this.props.openProductModal}
          className="md-700"
        >
          <ModalBody className="pb-0">
            <div className="row gst-km">
              <p>
                The "PURCHASE BILL" module is designed to record the purchase of{" "}
                <b>MATERIALS OR GOODS</b>, which can be used either for trading
                or for producing other materials. Here's a brief overview of how
                this module typically functions and its importance:
              </p>
              <h3>Purpose of the "PURCHASE BILL" Module:</h3>
              <div className="mx-0">
                <ul>
                  <li>Record Purchases</li>
                  <li>Inventory Management</li>
                  <li>Cost Tracking</li>
                </ul>
              </div>
              <h3>The "PURCHASE BILL" module SHOULD NOT BE USED for:</h3>
              <div className="mx-0">
                <ul>
                  <li>
                    Purchasing <b>FIXED ASSETS</b> that are not intended for
                    trading
                  </li>
                  <li>
                    Recording Expenses Like <b>RENT, PROFESSIONAL FEE, OFFICE
                    EXPENSES, OFFICE CONSUMABLES,</b> TRAVELLING EXPENSES...{" "}
                  </li>
                </ul>
              </div>
              <p>
                These types of transactions should be recorded using the:
                <b>"EXPENSE BILL MODULE"</b>
              </p>
            </div>
          </ModalBody>
          <ModalFooter style={{ textAlign: "center", display: "block" }}>
            <Button
              style={{ width: "130px" }}
              type="button"
              onClick={() => this.props.closeProductModal()}
              className="bmt-btn-close itemsubmit"
              data-dismiss="modal"
            >
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(PurchaseLearnMore);
