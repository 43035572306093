import {FilesTypes} from "./actionTypes"

const INIT_STATE = {
    isOtpVerified: 0
}

const Files = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case FilesTypes.IS_OTP_VERIFIED:        
      return {
        ...state,
        isOtpVerified: action.isOtpVerified,
      }    
    default:
      return state
  }
}

export default Files