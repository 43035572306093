import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Button, Modal, Row, Col } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Skeleton from "react-loading-skeleton";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { round } from "lodash";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import { number_format } from "src/helpers/common";

interface Params extends RouteComponentProps {
  valueCustomer: Option | null | undefined;
  currency_rate: number;
  currency_value: number;
  countries: Array<any>;
  taxes: Array<any>;
  states: Array<any>;
  customers: Array<any>;
  products: Array<any>;
  permissions: Array<any>;
  invoice_dtls: Array<any>;
  companies: Company;
  handleProductCreate: (inputValue: string) => void;
  parentCallback: (values: any) => void;
  mobile_view: boolean;
}
interface Option {
  label: string;
  value: string;
}
interface Company {
  is_indian_state: number;
  company_state: string | undefined;
  company_country: string | undefined;
  currency: string;
}
type State = {
  loading: boolean;
  item_modal_open: boolean;
  id: string | undefined;
  items_dtls: Array<any>;
  tax_dtls: Array<any>;
  products: Array<any>;
  customers: Array<any>;
  company: Company;
  taxes: Array<any>;
  net_total: number;
  tax_id: number;
  discount: number;
  gross_total: number;
  gross_total_base: number;
  invoice_number: number;
  invoice_prefix: string | undefined;
  data: Array<any>;
  success: number;
  msg: string | undefined;
  options: Array<any>;
  optionproduct: Array<any>;
  valueProduct: Option | null | undefined;
  valueCustomer: Option | null | undefined;
  is_success: boolean;
  isProgress: number;
  isModalProgress: number;
  isValid: boolean;
  cust_name: string | undefined;
  cust_id: string | undefined;
  prod_name: string | undefined;
  prod_id: string | undefined;
  tax_amount: number;
  customer_currency: string | undefined;
  company_currency: string | undefined;
  currency_rate: number;
  currency_value: number;
  permissions: Array<any>;
  countries: Array<any>;
  banks: Array<any>;
  states: Array<any>;
  mobile_view: boolean;
  specificIndex: number;
  is_edit: boolean;
  edit_data: any;
};
const createOptionProduct = (label: string, value: string) => ({
  label,
  value,
});
class ItemAddMobile extends Component<Params, State> {
  constructor(props: Params) {
    super(props);
    this.state = {
      loading: false,
      item_modal_open: false,

      id: "0",
      net_total: 0,
      tax_id: 0,
      // discount_type: "amount",
      discount: 0,
      gross_total: 0,
      gross_total_base: 0,
      items_dtls: [],
      tax_dtls: [],
      products: [],
      customers: [],
      company: {
        is_indian_state: 1,
        company_state: undefined,
        company_country: undefined,
        currency: "INR",
      },
      taxes: [],
      invoice_number: 0,
      invoice_prefix: undefined,
      data: [],
      success: 0,
      msg: undefined,
      options: [],
      valueCustomer: undefined,
      optionproduct: [],
      valueProduct: undefined,
      is_success: false,
      isProgress: 0,
      isModalProgress: 0,
      isValid: false,
      cust_name: undefined,
      cust_id: undefined,
      prod_name: undefined,
      prod_id: undefined,
      tax_amount: 0,
      customer_currency: "INR",
      company_currency: "INR",
      currency_rate: 0.0,
      currency_value: 0.0,
      permissions: [],
      countries: [],
      banks: [],
      states: [],
      mobile_view: false,
      specificIndex: 0,
      is_edit: false,
      edit_data: "",
    };
  }
  handleProductCreate = (inputValue: string) => {
    this.props.handleProductCreate(inputValue);
  };
  componentDidUpdate(prevProps: Params) {
    const {
      mobile_view,
      products,
      valueCustomer,
      currency_rate,
      currency_value,
    } = this.props;
    if (
      prevProps.mobile_view !== mobile_view ||
      prevProps.products.length !== products.length
    ) {
      let productOptions = products.map(product =>
        createOptionProduct(product.name, product.id)
      );
      this.setState({
        optionproduct: productOptions,
        products,
      });
    }
    if (prevProps.valueCustomer !== valueCustomer) {
      this.setState({ valueCustomer });
    }
    if (prevProps.currency_rate !== currency_rate) {
      this.setState({ currency_rate });
    }
    if (prevProps.currency_value !== currency_value) {
      this.setState({ currency_value });
    }
    if (prevProps.invoice_dtls.length !== this.props.invoice_dtls.length) {
      this.setState({ items_dtls: this.props.invoice_dtls });
    }
  }

  handleProductChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
    i: number,
    dtl: any
  ) => {
    this.setState({ valueProduct: newValue });
    let dtls = this.state.items_dtls;
    let value = newValue?.value;
    let product = this.state.products.find(({ id }) => id === value);

    if (product) {
      let taxes: Array<any> = [];
      if (this.state.valueCustomer) {
        const company = this.state.company;
        const comp_is_indian_state = company.is_indian_state;
        const company_state = company.company_state;

        const customer = await this.props.customers.find(
          ({ id }) => id === this.state.valueCustomer?.value
        );
        const billing_state = customer.billing_state;
        const place_supply = customer.place_supply;

        // if (comp_is_indian_state) {
        if (place_supply == 1) {
          if (company_state == billing_state) {
            taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
          } else {
            taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
          }
        } else {
          taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
        }
      } else {
        taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
      }
      dtl["tax_per"] = taxes.reduce(
        (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
        0
      );
      dtl["rate"] =
        this.state.currency_rate > 0
          ? this.state.currency_rate * product.rate
          : product.rate;
      dtl["rate"] = round(dtl.rate, 2);
      dtl["hsn_or_sac"] = product.hsn_or_sac;
      if (dtl.qty > 0) {
        dtl["amount"] = round(dtl.qty * dtl.rate, 2);
      } else {
        dtl["qty"] = 1;
        dtl["amount"] = round(1 * dtl.rate, 2);
      }

      dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
      dtl["amount"] = parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]);
      dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
      dtl["amount"] = round(dtl.amount, 2);
      if (product.tax_id == null) {
        dtl["tax_id"] = "";
      } else {
        dtl["tax_id"] = product.tax_id;
      }
      if (product.description == null) {
        dtl["product_description"] = "";
      } else {
        dtl["product_description"] = product.description;
      }
      dtl["tax_per"] = dtl.tax_per;

      dtl["product_id"] = newValue;
    } else {
      dtl["qty"] = 0;
      dtl["rate"] = 0;
      dtl["amount"] = 0;
      dtl["tax_amt"] = 0;
      dtl["tax_id"] = "";
      dtl["tax_per"] = 0;
      dtl["hsn_or_sac"] = "";
      dtl["product_description"] = "";
      dtl["product_id"] = { label: "", value: "" };
    }

    dtls[i] = dtl;
    this.setState({ items_dtls: dtls });
  };
  OnChangeItemsDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    const dtls = this.state.items_dtls;
    if (name === "tax_id") {
      dtl["tax_id"] = value;
      let product = this.state.products.find(
        ({ id }) => id === dtl.product_id.value
      );
      if (product) {
        let taxes: Array<any> = [];
        if (this.state.valueCustomer) {
          const company = this.state.company;
          const company_state: string | undefined = company.company_state;

          const customer = this.props.customers.find(
            ({ id }) => id === this.state.valueCustomer?.value
          );
          const place_supply: number = customer.place_supply;
          const billing_state: string = customer.billing_state;
          if (value != "") {
            if (place_supply == 1) {
              if (company_state == billing_state) {
                let rowTax: any = this.props.taxes.find(
                  (tax: any) => tax.id == value
                );
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
              } else {
                let rowTax: any = this.props.taxes.find(
                  (tax: any) => tax.id == value
                );
                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
              }
            } else {
              let rowTax: any = this.props.taxes.find(
                (tax: any) => tax.id == value
              );
              taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
            }
            dtl["tax_per"] = taxes.reduce(
              (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
              0
            );
          } else {
            dtl["tax_per"] = 0;
          }
          dtl["tax_amt"] = round(
            ((dtl.qty * dtl.rate - dtl.discount_amt) * dtl["tax_per"]) / 100,
            2
          );
          dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
          dtl["amount"] = round(
            dtl.qty * dtl.rate -
              parseFloat(dtl.discount_amt) +
              parseFloat(dtl["tax_amt"]),
            2
          );
          dtl["amount"] = dtl["amount"].toFixed(2);
        } else {
          if (value == "") {
            dtl["tax_per"] = 0;
            dtl["tax_amt"] = 0;
            dtl["amount"] = round(
              dtl.qty * dtl.rate - dtl.discount_amt + dtl["tax_amt"],
              2
            );
          } else {
            let rowTax: any = this.props.taxes.find(
              (tax: any) => tax.id == value
            );
            taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
            dtl["tax_per"] = taxes.reduce(
              (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
              0
            );
            dtl["tax_amt"] = round(
              ((dtl.qty * dtl.rate - dtl.discount_amt) * dtl["tax_per"]) / 100,
              2
            );
            dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
            dtl["amount"] = round(
              dtl.qty * dtl.rate -
                parseFloat(dtl.discount_amt) +
                parseFloat(dtl["tax_amt"]),
              2
            );
            dtl["amount"] = dtl["amount"].toFixed(2);
          }
        }
      }
    }
    if (name === "qty") {
      dtl["discount_amt"] = round(
        (value * dtl.rate * dtl.discount_per) / 100,
        2
      );
      dtl["amount"] = round(value * dtl.rate - dtl["discount_amt"], 2);
      dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
      dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
      dtl["amount"] = round(
        parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
        2
      );
      dtl["amount"] = dtl["amount"].toFixed(2);
    }
    if (name === "rate") {
      if (dtl.qty > 0) {
        dtl["discount_amt"] = round(
          (dtl.qty * value * dtl.discount_per) / 100,
          2
        );
        dtl["amount"] = round(dtl.qty * value - dtl["discount_amt"], 2);
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }
    if (name === "discount_per") {
      if (dtl.qty * dtl.rate > 0) {
        dtl["discount_amt"] = round((dtl.qty * dtl.rate * value) / 100, 2);
        dtl["amount"] = dtl.qty * dtl.rate - dtl["discount_amt"];
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }
    if (name === "discount_amt") {
      if (dtl.qty * dtl.rate > 0) {
        dtl["discount_per"] = round((value / (dtl.qty * dtl.rate)) * 100, 2);
        dtl["amount"] = round(dtl.qty * dtl.rate - value, 2);
        dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per) / 100, 2);
        dtl["tax_amt"] = dtl["tax_amt"].toFixed(2);
        dtl["amount"] = round(
          parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
        dtl["amount"] = dtl["amount"].toFixed(2);
      }
    }
    dtl[name] = value;
    dtls[i] = dtl;
    this.setState({ items_dtls: dtls });
  };
  additem = () => {
    this.setState({ is_edit: false });
    let newRaw = {
      product_id: { label: "", value: "" },
      product_description: "",
      hsn_or_sac: "",
      qty: 0,
      rate: 0,
      discount_per: 0,
      discount_amt: 0,
      tax_per: 0,
      tax_id: "",
      tax_amt: 0,
      amount: 0,
    };
    this.setState({ items_dtls: [...this.state.items_dtls, newRaw] });
    let specificIndex = 0;
    if (this.props.invoice_dtls.length > 0) {
      const conditionMet = this.props.invoice_dtls.some(
        item =>
          item.product_id &&
          ((item.product_id.label !== undefined &&
            item.product_id.label !== null) ||
            (item.product_id.value !== undefined &&
              item.product_id.value !== null))
      );

      if (conditionMet) {
        specificIndex = this.props.invoice_dtls.length;
      } else {
        specificIndex = this.props.invoice_dtls.length - 1;
      }
    }

    this.setState({ specificIndex });
    this.item_openModal();
  };

  editItem = (i: number, data: any) => {
    const editDataCopy = JSON.parse(JSON.stringify(data));
    this.setState(
      {
        is_edit: true,
        edit_data: editDataCopy,
        specificIndex: i,
      },
      () => {
        this.item_openModal();
      }
    );
  };

  item_openModal = () => {
    this.setState({ item_modal_open: true });
  };

  item_closeModal = (i: number) => {
    if (!this.state.is_edit) {
      const dtls = [...this.state.items_dtls];
      dtls.splice(i, 1);
      this.setState({ items_dtls: dtls, item_modal_open: false });
    } else {
      const dtls = [...this.state.items_dtls];
      dtls[i] = this.state.edit_data;
      this.setState({ items_dtls: dtls, item_modal_open: false });
      this.props.parentCallback(dtls);
    }
  };
  removeRow = (i: number) => {
    const dtls = this.state.items_dtls;
    dtls.splice(i, 1);
    this.setState({ items_dtls: dtls }, () => {
      this.props.parentCallback(dtls);
    });
  };

  handleValidSubmit = () => {
    const items_dtls = this.state.items_dtls;
    this.props.parentCallback(items_dtls);
    this.setState({ item_modal_open: false });
  };

  render() {
    return (
      <div className="row">
        <Row>
          <div className="col-xs-12 px-3">
            <ul className="product_list">
              <li className="d-flex justify-content-around align-items-center mb-1">
                <span style={{ width: "100%" }} className="fw-bold">
                  Product Name
                </span>
                <span style={{ width: "100%" }} className="fw-bold text-end">
                  Amount
                </span>
                <span className="fw-bold">&nbsp;</span>
              </li>
              {this.state.items_dtls.map((data, i) => (
                <li key={i} className="d-flex justify-content-between align-items-center">
                  <span
                    style={{ width: "100%" }}
                    onClick={() => this.editItem(i, data)}
                  >
                    {data.product_id.label}
                  </span>
                  <span style={{ width: "100%" }} className="text-end me-3">
                    {number_format(data.amount)}
                  </span>
                  <button
                    className="btn btn-sm tbl-btn"
                    onClick={() => this.removeRow(i)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-12">
            <div className="mb-4">
              {this.state.loading ? (
                <Skeleton height={20} width={100} />
              ) : (
                <button
                  className="btn-cus-fie inv-details w-100"
                  onClick={this.additem}
                  type="button"
                >
                  <i className="bx bx-plus me-1"></i>Add item
                </button>
              )}
            </div>
          </div>
        </Row>
        {this.state.item_modal_open && (
          <Modal
            isOpen={this.state.item_modal_open}
            toggle={this.item_openModal}
            className="md-400"
          >
            <AvForm>
              <div className="modal-body pt-1">
                <div className="row d-flex align-items-center">
                  <div className="col-md-12">
                    <div className="inv_form pt-0 pb-0">
                      <div className="row">
                        <div className="heading">Add item</div>
                      </div>
                      {this.state.items_dtls.map((dtl, i) => {
                        if (i !== this.state.specificIndex) return null;
                        return (
                          <React.Fragment key={i}>
                            <Row className="mb-3">
                              <Col xs="12">
                                <CreatableSelect
                                  isClearable
                                  placeholder="Select or Add your Item"
                                  onChange={(
                                    newValue: OnChangeValue<Option, false>,
                                    actionMeta: ActionMeta<Option>
                                  ) =>
                                    this.handleProductChange(
                                      newValue,
                                      actionMeta,
                                      i,
                                      dtl
                                    )
                                  }
                                  styles={{
                                    option: (provided: any, state: any) => ({
                                      ...provided,
                                      ":hover": {
                                        backgroundColor: "#10a37f",
                                        color: "#fff"
                                      },
                                      backgroundColor: state.isSelected
                                        ? "#10a37f"
                                        : "inherit",
                                    }),
                                  }}
                                  onCreateOption={
                                    this.props.handleProductCreate
                                  }
                                  value={dtl.product_id || ""}
                                  options={this.state.optionproduct}
                                  name="product_id"
                                  className="field-required"
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">HSN/SAC</div>
                              </Col>
                              <Col xs="9">
                                <AvField
                                  name="hsn_or_sac"
                                  type="text"
                                  placeholder="HSN/SAC"
                                  readOnly
                                  value={dtl.hsn_or_sac || ""}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">Quantity</div>
                              </Col>
                              <Col xs="9">
                                <AvField
                                  name="qty"
                                  type="number"
                                  placeholder="Enter qty"
                                  errorMessage="Enter qty"
                                  className="field-required"
                                  value={dtl.qty || ""}
                                  validate={{ required: { value: false } }}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">Rate</div>
                              </Col>
                              <Col xs="9">
                                <AvField
                                  name="rate"
                                  type="number"
                                  placeholder="Enter rate"
                                  errorMessage="Enter rate"
                                  className="field-required"
                                  value={dtl.rate || ""}
                                  validate={{ required: { value: false } }}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">Discount</div>
                              </Col>
                              <Col xs="5" className="pe-0">
                                <AvField
                                  name="discount_amt"
                                  type="number"
                                  placeholder="Amount"
                                  value={dtl.discount_amt || ""}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                />
                              </Col>
                              <Col xs="4" className="ps-0">
                                <AvField
                                  name="discount_per"
                                  type="number"
                                  placeholder="%"
                                  value={dtl.discount_per || ""}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">Tax</div>
                              </Col>
                              <Col xs="5" className="pe-0">
                                <AvField
                                  name="tax_amt"
                                  type="number"
                                  placeholder="Tax amount"
                                  errorMessage="Tax amount"
                                  value={dtl.tax_amt || ""}
                                  validate={{ required: { value: false } }}
                                  readOnly
                                />
                              </Col>
                              <Col xs="4" className="ps-0">
                                <AvField
                                  type="select"
                                  name="tax_id"
                                  value={dtl.tax_id || ""}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                >
                                  <option value="" key="">
                                    Tax Per
                                  </option>
                                  {this.props.taxes.map(tax => (
                                    <option value={tax.id || ""} key={tax.id}>
                                      {tax.tax_name}
                                    </option>
                                  ))}
                                </AvField>
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="3">
                                <div className="label">Amount</div>
                              </Col>
                              <Col xs="9">
                                <AvField
                                  name="amount"
                                  type="number"
                                  placeholder="Enter amount"
                                  errorMessage="Enter amount"
                                  value={dtl.amount || ""}
                                  validate={{ required: { value: false } }}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col xs="12">
                                <AvField
                                  name="product_description"
                                  type="text"
                                  placeholder="Description"
                                  maxLength={500}
                                  value={dtl.product_description || ""}
                                  onChange={(e: any) =>
                                    this.OnChangeItemsDtl(e, i, dtl)
                                  }
                                />
                              </Col>
                            </Row>
                            <div className="buttons">
                              <Button
                                type="button"
                                className="bmt-btn-close btn-sm itemsubmit"
                                onClick={() => this.item_closeModal(i)}
                              >
                                Close
                              </Button>{" "}
                              <Button
                                type="submit"
                                onClick={this.handleValidSubmit}
                                className="bmt-btn-submit btn-sm itemsubmit"
                              >
                                Save
                              </Button>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </AvForm>
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(ItemAddMobile);
