import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getProducts } from "src/store/actions";
import { connect } from "react-redux";
import { number_format } from "src/helpers/common";
import { PermissionModule } from "src/store/permissions/actionTypes";
import ProductCreateModalNew from "./ProductCreateModalNew";
import ProductUpdateModalNew from "./ProductUpdateModalNew";
import ConfirmationModal from "src/components/ConfirmationModal";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import SweetAlert from "react-bootstrap-sweetalert";

interface MyProps extends RouteComponentProps<any> {
  permissions: Array<any>;
  getProducts: () => void;
  setCount: (count: number) => void;
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  permissions: Array<any>;
  loading: boolean;
  modal_pro_is_open: boolean;
  modal_pro_edit_is_open: boolean;
  product_id: string;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  error_dlg: boolean;
  dynamic_title: string;
  dynamic_description: string;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

class AllList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "id",
      sortOrder: "asc",
      permissions: [],
      loading: true,
      modal_pro_is_open: false,
      modal_pro_edit_is_open: false,
      product_id: "",
      isOpenConformModal: false,
      Rowdelete: null,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/all_pdt_ser_list_grid`,
          param
        );

        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
            });
            this.props.setCount(resp.data.count);
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };
  loadData = async () => {
    try {
      let productPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.PRODUCT
      );
      if (this._isMounted) this.setState({ permissions: productPermissions, loading: false });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.permissions !== prevProps.permissions) {
      this.loadData();
    }
    if (this.props.refresh !== prevProps.refresh) {
      this.fetchData({
        page: 1,
        searchText: "",
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.fetchData(param);
  };
  handleEdit = (row: any) => {
    this.setState({ product_id: row.id, modal_pro_edit_is_open: true });
  };
  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        const resp: any = await del(
          process.env.REACT_APP_API_URL +
            "/api/product_delete/" +
            this.state.Rowdelete
        );
        this.props.setRefresh(!this.props.refresh);
        if (resp.success == true) {
          this.props.getProducts?.();
          this.setState({
            error_dlg: false,
            dynamic_title: "",
            dynamic_description: "",
          });
        } else {
          this.setState({
            error_dlg: true,
            dynamic_title: "Deletion Failed",
            dynamic_description: resp.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  callBack = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  };
  openProductModal = () => {
    this.setState({ modal_pro_is_open: true });
  };
  closeProductModal = () => {
    this.setState({ modal_pro_is_open: false });
  };
  openProductEditModal = () => {
    this.setState({ modal_pro_edit_is_open: true });
  };
  closeProductEditModal = () => {
    this.setState({ product_id: "", modal_pro_edit_is_open: false });
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "hsn_or_sac",
        text: "HSN/SAC",
        sort: true,
      },
      {
        dataField: "tax_name",
        text: "GST Rate",
        sort: true,
      },
      {
        dataField: "rate",
        text: "Price",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "type",
        text: "Product Type",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.type == "service" ? (
                <>
                  <span>
                    <button className="brown-btn btn-item myorder-complete">
                      Service
                    </button>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <button className="brown-btn btn-item myorder-onhold">
                      Product
                    </button>
                  </span>
                </>
              )}
            </>
          );
        },
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <div className="table-button">
              <button
                className="round-btn"
                title="Action"
                // onClick={this.preventParentAction}
                style={{
                  float: "right",
                  marginRight: 12,
                  width: 35,
                  height: 35,
                }}
              >
                <UncontrolledDropdown className="mt-4 mt-sm-0">
                  <DropdownToggle tag="a">
                    <EllipsisIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.permissions.find(
                      ({ action, is_active }) =>
                        action == "modify" && is_active == 1
                    ) && (
                      <DropdownItem
                        tag="span"
                        to="#"
                        onClick={() => this.handleEdit(row)}
                      >
                        <i
                          className="fas fa-edit"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit"
                          style={{ color: "#28a745" }}
                        />{" "}
                        Edit
                      </DropdownItem>
                    )}
                    {this.state.permissions.find(
                      ({ action, is_active }) =>
                        action == "delete" && is_active == 1
                    ) && (
                      <DropdownItem
                        tag="span"
                        to="#"
                        onClick={() => this.handleDelete(row)}
                      >
                        <i
                          className="fas fa-trash-alt"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                          style={{ color: "#dc3545" }}
                        />{" "}
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </button>
            </div>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card style={{ border: "none" }}>
              {this.state.loading == true ? (
                <Skeleton width={100} height={40} style={{ float: "right" }} />
              ) : (
                <>
                  {/* this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1) &&
                                        ( */}

                  {/*   )
                                    } */}
                </>
              )}

              <CardBody>
                {this.state.loading == true ? (
                  <>
                    <CardTitle className="h4">
                      <Skeleton height={"10%"} width={"10%"} />
                    </CardTitle>
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                          <th>
                            <Skeleton width={"100%"} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                          <td>
                            <Skeleton height={"10%"} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.data}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <div
                                  className="search-box me-2 mb-2 d-inline-block cstm-search"
                                  style={{
                                    position: "absolute",
                                    top: -125,
                                    left: 108,
                                  }}
                                >
                                  <SearchBar {...toolkitProps.searchProps} />
                                  {/* <i className="bx bx-search-alt search-icon" /> */}
                                </div>
                                {/* <Link to="/product_add" > */}
                                {/* <button type="button" className="btn btn-primary" onClick={this.openProductModal}><i className="fa fa-plus"></i> Create</button> */}
                                {/* </Link> */}
                              </div>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      onTableChange={this.handleTableChange}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={() => (
                                        <NoDataIndication />
                                      )}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.error_dlg ? (
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => {
              this.setState({ error_dlg: false });
            }}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        <ProductCreateModalNew
          modal_pro_is_open={this.state.modal_pro_is_open}
          openProductModal={this.openProductModal}
          closeProductModal={this.closeProductModal}
          parentCallback={this.callBack}
        />
        <ProductUpdateModalNew
          id={this.state.product_id}
          modal_pro_is_open={this.state.modal_pro_edit_is_open}
          openProductModal={this.openProductEditModal}
          closeProductModal={this.closeProductEditModal}
          parentCallback={this.callBack}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { permissions: any }) => {
  return {
    permissions: state.permissions.permissions,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AllList));
