import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  BreadcrumbItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import IncomeCreate from "./IncomeCreate";
import IncomeCreate from "./IncomeCreate";
import moment from "moment";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import IncomeIcon from "src/assets/images/icons/Expense";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import bannerImg from "../../assets/images/gst-banner.jpg";
// import ExpItemModal from "../ExpItems/ExpItemModal";
import { number_format, predefinedRanges } from "src/helpers/common";
import ExpItemModal from "../ExpItems/ExpItemModal";
import ExpCategoryModal from "../ExpCategories/ExpCategoryModal";
import IncomeEditModal from "./IncomeEditModal";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import ConfirmationModal from "src/components/ConfirmationModal";
import { connect } from "react-redux";
import { setbankorcashSuccess } from "src/store/actions";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { isNoEditableDisItem, isNoEditableItem } from "src/helpers/common";
import ItemLedger from "../ExpItems/itemLedger";
import GstLedger from "../ExpItems/GstLedger";

interface MyProps extends RouteComponentProps<any> {
  setCashOrBank: () => void;
  bankorcash?: any;
}
interface Option {
  label: string;
  value: string;
}
interface MyState {
  activeTab: number;
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  permissions: Array<any>;
  loading: boolean;
  modal_is_open: boolean;
  income_prefix: string;
  startDate: Date | null;
  endDate: Date | null;
  incomes: Array<any>;
  items: Array<any>;
  modes: Array<any>;
  Income: Option | null | undefined;
  item: Option | null | undefined;
  mode: Option | null | undefined;
  modalExpItem: boolean;
  modalExpCategory: boolean;
  modalIncome: boolean;
  incomeId: string;
  totalAmount: number;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  page: number;
  item_id: string;
  item_name: string;
  modelLedger: boolean;
  modelgstLedger: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});

class IncomeList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      activeTab: 1,
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "income_date",
      sortOrder: "desc",
      loading: true,
      permissions: [],
      modal_is_open: false,
      income_prefix: "",
      startDate: null,
      endDate: null,
      incomes: [],
      items: [],
      modes: [],
      Income: null,
      item: null,
      mode: null,
      modalExpItem: false,
      modalExpCategory: false,
      modalIncome: false,
      incomeId: "0",
      totalAmount: 0,
      isOpenConformModal: false,
      Rowdelete: null,
      page: 1,
      item_id: "",
      item_name: "",
      modelLedger: false,
      modelgstLedger: false,
    };
  }
  openModalExpItem = () => {
    this.setState({ modalExpItem: true });
  };

  closeModalExpItem = () => {
    this.setState({ modalExpItem: false });
  };
  openModalExpCategory = () => {
    this.setState({ modalExpCategory: true });
  };

  closeModalExpCategory = () => {
    this.setState({ modalExpCategory: false });
  };
  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };
  toggleTab = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  };
  openModalIncome: React.MouseEventHandler<any> = event => {
    const id = event.currentTarget.dataset.id; // Retrieve the incomeId from the event
    this.setState({ incomeId: id, modalIncome: true });
  };

  closeModalIncome = () => {
    this.setState({ modalIncome: false });
  };
  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/income_list_grid`,
          param
        );
        if (this._isMounted) {
          if (resp.success) {
            this.setState({
              totalSize: resp.data.count,
              totalAmount: resp.data.totalAmount,
              data: resp.data.results,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
  };
  genBankCashOptions = () => {
    let options: Option[] = [];
    this.props.bankorcash?.map((modes: { name: string; id: string }) => {
      let newOption = createOption(modes.name, modes.id);
      options.push(newOption);
    });
    this.setState({ modes: options });
  };
  sendGetRequest = async () => {
    try {
      if (this._isMounted) this.setState({ loading: false });
      const respPer = await get(
        process.env.REACT_APP_API_URL + `/api/permissions/7`
      );
      if (respPer.success) {
        if (this._isMounted) {
          this.setState({
            permissions: respPer.data,
          });
        }
        this.fetchData({
          page: 1,
          searchText: "",
          sizePerPage: this.state.sizePerPage,
          sortField: this.state.sortField,
          sortOrder: this.state.sortOrder,
        });
        const respE: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_income_no"
        );

        if (this._isMounted)
          this.setState({ income_prefix: respE.data.income_prefix });

        const resV: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_incomes"
        );
        resV.data.map((Income: { income_name: string; id: string }) => {
          let newOption = createOption(Income.income_name, Income.id);
          if (this._isMounted)
            this.setState({ incomes: [...this.state.incomes, newOption] });
        });

        const resI: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_exp_items"
        );
        resI.data.map((item: { item_name: string; id: string }) => {
          let newOption = createOption(item.item_name, item.id);
          if (this._isMounted)
            this.setState({ items: [...this.state.items, newOption] });
        });
        if (this._isMounted) this.genBankCashOptions();
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.sendGetRequest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.setState({ page: param.page });
    this.fetchData(param);
  };
  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/income_delete/" +
            this.state.Rowdelete
        );
        this.props.setCashOrBank();
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_is_open: false });
  };

  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
    this.fetchData({
      page: 1,
      searchText: "",
      item_id: this.state.item?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  }
  setStartDateNew(dateRange: any) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState({ startDate: startDate, endDate: endDate });
    this.fetchData({
      page: 1,
      searchText: "",
      item_id: this.state.item?.value,
      mode: this.state.mode?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  }
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    let name: any = actionMeta.name;
    let data: any = {};
    data[name] = newValue;
    this.setState(data);
    if (name == "item") {
      this.fetchData({
        page: 1,
        searchText: "",
        item_id: newValue?.value,
        mode: this.state.mode?.value,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  };
  handleChangemode = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    let name: any = actionMeta.name;
    let data: any = {};
    data[name] = newValue;
    this.setState(data);
    if (name == "mode") {
      this.fetchData({
        page: 1,
        searchText: "",
        item_id: this.state.item?.value,
        mode: newValue?.value,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  };
  ledgerOpen = (row: any) => {
    this.setState({
      modelLedger: true,
      item_id: row.item_id,
      item_name: row.item_name,
    });
  };
  GStledgerOpen = (row: any) => {
    this.setState({
      modelgstLedger: true,
      item_id: row.item_id,
      item_name: row.item_name,
    });
  };
  ledgerClose = () => {
    this.setState({ modelLedger: false });
  };
  ledgerToggle = () => {
    this.setState({ modelLedger: !this.state.modelLedger });
  };
  ledgerGstClose = () => {
    this.setState({ modelgstLedger: false });
  };
  ledgerGstToggle = () => {
    this.setState({ modelgstLedger: !this.state.modelgstLedger });
  };
  handleRefresh = () => {
    this.fetchData({
      page: 1,
      item_id: this.state.item?.value,
      mode: this.state.mode?.value,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "income_date",
        text: "Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {moment(cellContent).format("DD MMM YYYY")}
              <br />
              <span style={{ fontSize: "10px" }}>
                {moment(row.created_at).format("DD MMM YYYY h:mm:ss A")}
              </span>
            </>
          );
        },
      },
      {
        dataField: "income_no",
        text: "Income No",
        sort: true,
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {this.state.income_prefix
                ? this.state.income_prefix + row.income_no
                : "INC" + row.income_no}
            </>
          );
        },
      },
      {
        dataField: "item_name",
        text: "Item",
        sort: true,
      },
      {
        dataField: "bank_cashbook_name",
        text: "Mode",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
        headerAlign: "center",
        formatter: (cellContent: any, row: any) => {
          return (
            <div style={{ float: "right" }}>{`₹ ${number_format(
              cellContent
            )}`}</div>
          );
        },
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        headerAlign: "center",
        formatter: (cellContent: any, row: any) => {
          return (
            <div className="table-button text-center">
              <button
                className="round-btn"
                title="Action"
                style={{
                  marginRight: 12,
                  width: 35,
                  height: 35,
                }}
              >
                <UncontrolledDropdown className="mt-4 mt-sm-0">
                  <DropdownToggle tag="a">
                    <EllipsisIcon />
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      to="#"
                      onClick={this.openModalIncome}
                      data-id={row.id}
                    >
                      <DropdownItem to="#" tag="span">
                        <i
                          className="fas fa-edit"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit"
                          style={{ color: "#28a745" }}
                        />{" "}
                        Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      to="#"
                      tag="span"
                      onClick={() => this.handleDelete(row)}
                    >
                      <i
                        className="fas fa-trash-alt"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Delete"
                        style={{ color: "#dc3545" }}
                      />{" "}
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </button>
              {isNoEditableItem(row.item_id) ? (
                <button
                  onClick={() => this.GStledgerOpen(row)}
                  className="normal-btn hash-button"
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                  Ledger
                </button>
              ) : isNoEditableDisItem(row.item_id) ? (
                <button
                  className="normal-btn hash-button"
                  disabled
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                  Ledger
                </button>
              ) : (
                  <button
                    onClick={() => this.ledgerOpen(row)}
                    className="normal-btn hash-button"
                    title="View Ledger"
                    style={{ marginRight: 12 }}
                  >
                    Ledger
                  </button>
              )}
            </div>
          );
        },
      },
    ];
    const title = "Income";
    const breadcrumbItem = "Cash /Bank Incomes";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ minHeight: "685px", background: "#F1F1F1" }}
        >
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    <h4 className="mb-0 font-size-18">
                      {this.state.activeTab == 1
                        ? breadcrumbItem
                        : "Purchase bill List"}
                    </h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={4}>
                            <Select
                            styles={{
                              option: (provided: any, state: any) => ({
                                ...provided,
                                ":hover": {
                                  backgroundColor: "#10a37f",
                                  color: "#fff"
                                },
                                backgroundColor: state.isSelected
                                  ? "#10a37f"
                                  : "inherit",
                              }),
                            }}
                              isClearable={true}
                              onChange={this.handleChange}
                              options={this.state.items}
                              value={this.state.item}
                              name="item"
                              placeholder="Select Item"
                            />
                          </Col>
                          <Col lg={4}>
                            <DateRangePicker
                              ranges={predefinedRanges}
                              format="dd/MM/yyyy"
                              placeholder=" "
                              style={{ width: 280 }}
                              onChange={(date: DateRange | null) =>
                                this.setStartDateNew(date)
                              }
                            />
                          </Col>
                          <Col lg={4}>
                            <Select
                            styles={{
                              option: (provided: any, state: any) => ({
                                ...provided,
                                ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                backgroundColor: state.isSelected
                                  ? "#10a37f"
                                  : "inherit",
                              }),
                            }}
                              isClearable={true}
                              onChange={this.handleChangemode}
                              options={this.state.modes}
                              value={this.state.mode}
                              name="mode"
                              placeholder="Select Payment Mode"
                            />
                          </Col>
                          {/* <Col lg={4}> */}
                          {/* <ReactDatePicker
                              selected={this.state.startDate}
                              onChange={(date: Array<any>) =>
                                this.setStartDate(date)
                              }
                              selectsRange={true}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              dateFormat="yyyy-MM-dd"
                              className="form-control calendar"
                              isClearable={true}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              todayButton="Today"
                              monthsShown={2}
                            /> */}
                          {/* </Col> */}
                        </Row>
                      </AvForm>
                    </div>
                  </div>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{breadcrumbItem}</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardHeader className="cus-card">
                    <div className="row">
                      <div className="col-md-12 col-xl-3 order-xl-1">
                        {this.state.loading == true ? (
                          <Skeleton width={"30%"} height={"100%"} />
                        ) : (
                          <ul className="values">
                            <li
                              className="active"
                              style={{
                                width: "100%",
                              }}
                            >
                              <NavLink>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32.815"
                                  height="49.055"
                                  viewBox="0 0 32.815 49.055"
                                >
                                  <path
                                    id="Path_441"
                                    data-name="Path 441"
                                    d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                    transform="translate(-1.415 49.055)"
                                  />
                                </svg>

                                <span>
                                  {" "}
                                  {number_format(this.state.totalAmount)}
                                </span>
                                <label>Total</label>
                              </NavLink>
                            </li>
                          </ul>
                        )}
                        <ul className="inv-count">
                          <li>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="31"
                                viewBox="0 0 31 31"
                              >
                                <g
                                  id="Group_12"
                                  data-name="Group 12"
                                  transform="translate(-326 -668)"
                                >
                                  <circle
                                    id="Ellipse_5"
                                    data-name="Ellipse 5"
                                    cx="15.5"
                                    cy="15.5"
                                    r="15.5"
                                    transform="translate(326 668)"
                                    fill="#3296cd"
                                  />
                                  <path
                                    id="Path_10"
                                    data-name="Path 10"
                                    d="M3728.683,669l8.305,6.207,11.513-16.287"
                                    transform="translate(-3395.042 13.241)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="4"
                                  />
                                </g>
                              </svg>
                            </span>
                            {this.state.totalSize} Records
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-12 col-xl-9 order-xl-1 d-flex justify-content-end">
                        <div className="banner  text-xl-end">
                          <NavLink to="/">
                            <img
                              src={bannerImg}
                              alt=""
                              className="gst-banner"
                            />
                          </NavLink>
                        </div>
                        {this.state.permissions.find(
                          ({ action, is_active }) =>
                            action == "create" && is_active == 1
                        ) && (
                          <ul className="qck-link1">
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) =>
                                  this.openModalExpCategory()
                                }
                              >
                                <span>
                                  <IncomeIcon />
                                </span>
                                <label>Add Category</label>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) => this.openModalExpItem()}
                              >
                                <span>
                                  <IncomeIcon />
                                </span>
                                <label>Add Item</label>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e: any) => this.openModal()}
                              >
                                <span>
                                  <Estimate2Icon />
                                </span>
                                <label>Add Income</label>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="12">
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={this.state.data}
                              // search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          // responsive
                                          remote
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap tbl-cash_bank"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          onTableChange={this.handleTableChange}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={() => (
                                            <NoDataIndication />
                                          )}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ConfirmationModal
              isOpen={this.state.isOpenConformModal}
              message="Do you want to delete this record?"
              onClose={this.handleDeleteClose}
              onConfirm={this.handleDeleteConfirm}
            ></ConfirmationModal>
          </div>
        </div>
        {this.state.modal_is_open && (
          <IncomeCreate
            modal_is_open={this.state.modal_is_open}
            openModal={this.openModal}
            closeModal={this.closeModal}
            handleRefresh={this.handleRefresh}
          />
        )}
        {this.state.modalExpItem && (
          <ExpItemModal
            modal_is_open={this.state.modalExpItem}
            openModal={this.openModalExpItem}
            closeModal={this.closeModalExpItem}
            id={"0"}
          />
        )}
        {this.state.modalExpCategory && (
          <ExpCategoryModal
            modal_is_open={this.state.modalExpCategory}
            openModal={this.openModalExpCategory}
            closeModal={this.closeModalExpCategory}
            id={"0"}
          />
        )}
        {this.state.modalIncome && (
          <IncomeEditModal
            modal_is_open={this.state.modalIncome}
            openModal={this.openModalIncome}
            closeModal={this.closeModalIncome}
            id={this.state.incomeId}
            handleRefresh={this.handleRefresh}
          />
        )}
        {this.state.modelLedger && (
          <ItemLedger
            openModal={this.ledgerToggle}
            modal_is_open={this.state.modelLedger}
            closeModal={this.ledgerClose}
            item_name={this.state.item_name}
            item_id={this.state.item_id}
          />
        )}
        {this.state.modelgstLedger && (
          <GstLedger
            openModal={this.ledgerGstToggle}
            modal_is_open={this.state.modelgstLedger}
            closeModal={this.ledgerGstClose}
            item_name={this.state.item_name}
            item_id={this.state.item_id}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: { bankorcash: any; exp_items: any }) => {
  return {
    exp_items: state.exp_items.exp_items,
    bankorcash: state.bankorcash.bankorcash,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCashOrBank: () => {
      setbankorcashSuccess(dispatch);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IncomeList));
