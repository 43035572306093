import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import PdfIcon from "src/assets/images/icons/Pdf";
import { post } from "src/helpers/api_helper";
import { number_format, predefinedRanges } from "src/helpers/common";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";

interface Props {
  modal_is_open: boolean;
  openModal?:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
}

function SalesCustomer(props: Props) {
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [isProgress, setIsProgress] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [data, setData] = useState<Array<any>>([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const closeThis = () => {
    setStartDate(null);
    setEndDate(null);
    setDateRange([startOfMonth(new Date()), endOfMonth(new Date())]);
    setData([]);
    setIsSubmit(false);
    props.closeModal();
  };

  const setStartDateNew = (dateRange: any) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    setDateRange(dateRange);
    setStartDate(startDate ? new Date(startDate) : null);
    setEndDate(endDate ? new Date(endDate) : null);
  };
  const submit = async (startD: Date | null, endD: Date | null) => {
    setIsProgress(true);
    search(startD, endD);
  };
  const search = async (startD: Date | null, endD: Date | null) => {
    try {
      let start_date = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      let postValues = {
        startDate: start_date,
        endDate: end_date,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/sales_customer_statement`,
        postValues
      );
      if (resp.success) {
        setIsProgress(false);
        setData(resp.data);
        setIsSubmit(true);
      }
    } catch (err) {
      setIsProgress(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.modal_is_open) {
      let startD = startOfMonth(new Date());
      let endD = endOfMonth(new Date());
      setStartDate(startD);
      setEndDate(endD);
      search(startD, endD);
    }
  }, [props.modal_is_open]);

  const downloadPdf = async () => {
    let start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    let postValues = {
      startDate: start_date,
      endDate: end_date,
    };
    const pdfResp = await post(
      process.env.REACT_APP_API_URL + `/api/sales_customer_statement_pdf`,
      postValues
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfResp.file;
    downloadLink.download = pdfResp.fname;
    downloadLink.click();
  };

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  var total_taxable_amount = 0;
  var total_tax_amount = 0;
  var total_invoices_amount = 0;
  var total_invoices_count = 0;
  return (
    <Modal
      isOpen={props.modal_is_open}
      toggle={props.openModal}
      className={
        "md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
      }
    >
      <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
        <Row className="pb-2">
          <Col lg={12}>
            <h5 className="modal-title text-center mt-0 fw-normal">
              SALES - Customer Wise
            </h5>
          </Col>
          <Col lg={4} className="offset-lg-3 pe-0">
            <DateRangePicker
              ranges={predefinedRanges}
              format="dd/MM/yyyy"
              placeholder=" "
              value={dateRange}
              style={{ width: 330 }}
              onChange={(date: DateRange | null) => setStartDateNew(date)}
            />
          </Col>
          <Col lg={4}>
            {isProgress ? (
              <>
                <Button
                  type="submit"
                  className="bmt-btn-submit-1"
                  disabled={true}
                >
                    Submitting
                    <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  style={{ padding: "5px 25px" }}
                  className="bmt-btn-submit me-1"
                  onClick={() => submit(startDate, endDate)}
                >
                  Submit
                </Button>
              </>
            )}
            <Link to="#" onClick={(e: any) => downloadPdf()}>
              <button className="btn round-btn btn-pdf" title="PDF">
                <PdfIcon />
              </button>
            </Link>
          </Col>
        </Row>
        <button
          type="button"
          onClick={() => closeThis()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeButton"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pt-0">
        <div className="mb-3">
          <Row>
            <Col xs="12">
              <div>
                <div className="form-inline">
                  {isSubmit && (
                    <Row>
                      <Col lg={12}>
                        <Table
                          className="align-middle mb-0 ledger-table mt-1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>Name</th>
                              <th style={{ textAlign: "center", width: "11%" }}>
                                Invoice Count
                              </th>
                              <th style={{ textAlign: "center", width: "13%" }}>
                                Sales
                              </th>
                              <th style={{ textAlign: "center", width: "13%" }}>
                                GST
                              </th>
                              <th style={{ textAlign: "center", width: "13%" }}>
                                Sales With GST
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map(
                              (
                                statement: {
                                  customer_name: string;
                                  invoices_count: number;
                                  tax_amount: number;
                                  taxable_amount: number;
                                  invoices_amount: number;
                                },
                                index: number
                              ) => {
                                total_invoices_count =
                                  Number(total_invoices_count) +
                                  Number(statement.invoices_count);
                                total_taxable_amount =
                                  Number(total_taxable_amount) +
                                  Number(statement.taxable_amount);
                                total_tax_amount =
                                  Number(total_tax_amount) +
                                  Number(statement.tax_amount);
                                total_invoices_amount =
                                  Number(total_invoices_amount) +
                                  Number(statement.invoices_amount);
                                return (
                                  <tr key={index}>
                                    <td>{statement.customer_name}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {statement.invoices_count}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(statement.taxable_amount)}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(statement.tax_amount)}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {number_format(statement.invoices_amount)}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <b>{total_invoices_count}</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(total_taxable_amount)}</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(total_tax_amount)}</b>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <b>{number_format(total_invoices_amount)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(SalesCustomer);
