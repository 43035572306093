import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { post } from "src/helpers/api_helper";

interface Props {
  isOpen: boolean;
  getCompanies: () => void;
}

const CompanyForm = (props: Props) => {
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [isOpen, setisOpen] = useState(props.isOpen);
  const [is_indian_state, set_is_indian_state] = useState<number>(1);
  const [country, set_country] = useState<number>(99);
  const [errorMgs, setErrorMgs] = useState(
    "Somthing went wrong, Please try again"
  );
  const { companies, states, countries } = useSelector((state: any) => ({
    companies: state.companies.companies,
    states: state.states.states,
    countries: state.countries.countries,
  }));
  const onChangeCountryhandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value == 99) {
      set_is_indian_state(1);
    } else {
      set_is_indian_state(0);
    }
  };
  const handleValidSubmit = async (event: any, values: any) => {
    setIsModalProgress(true);
    values["id"] = companies.id;
    values["is_indian_state"] = is_indian_state;
    values["company_country"] = country;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/company_update",
      values
    );
    props.getCompanies();
    setIsModalProgress(false);
    setisOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        className={"md-700"}
      >
        <AvForm
          onValidSubmit={handleValidSubmit}
          encType="multipart/formData"
          id="company_form"
        >
          <div className="modal-body pt-1">
            <div className="row d-flex align-items-center">
              <div className="col-md-12">
                <div className="form-cmp pt-0 pb-0">
                  <div className="row">
                    <div className="heading"> Update your Business Address</div>
                  </div>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      Flat/House/Building
                    </Label>
                    <Col md={7} lg={7}>
                      <AvField
                        name="company_address1"
                        type="text"
                        errorMessage="Enter Company Address"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      Area/Street/Village/Road
                    </Label>
                    <Col md={7} lg={7}>
                      <AvField
                        name="company_address2"
                        type="text"
                        errorMessage="Enter Company Address"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      Town/City
                    </Label>
                    <Col md={7} lg={7}>
                      <AvField
                        name="company_town_or_city"
                        type="text"
                        errorMessage="Enter Town Or City"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      State / Province
                    </Label>
                    <Col md={7} lg={7}>
                      {is_indian_state == 1 ? (
                        <AvField
                          type="select"
                          name="company_state"
                          validate={{
                            required: { value: true },
                          }}
                        >
                          <option value="">--Select--</option>
                          {states.map(state => (
                            <option value={state.id} key={state.id}>
                              {state.state}
                            </option>
                          ))}
                        </AvField>
                      ) : (
                        <AvField
                          name="company_state"
                          type="text"
                          placeholder="Enter company state"
                          errorMessage="Enter company state"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      Pincode
                    </Label>
                    <Col md={7} lg={7}>
                      <AvField
                        name="company_pincode"
                        type="text"
                        errorMessage="Enter Pincode"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-firstname-input"
                      className="col-md-5 col-lg-5 col-form-label"
                    >
                      Country
                    </Label>
                    <Col md={7} lg={7}>
                      <AvField
                        type="select"
                        name="company_country"
                        validate={{
                          required: { value: true },
                        }}
                        value={country}
                        onChange={onChangeCountryhandler}
                        disabled // Add disabled attribute here
                      >
                        <option key={-1} value={""}>
                          --Select--
                        </option>
                        {countries.map(country => (
                          <option value={country.id} key={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  </Row>
                  <div className="d-block text-center pt-2">
                    {isModalProgress == true ? (
                      <Button
                        type="button"
                        className="bmt-btn-submit w-100bmt-btn-submit itemsubmit"
                        disabled={true}
                      >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        Submitting
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          className="bmt-btn-submit itemsubmit"
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default CompanyForm;
