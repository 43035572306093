import {PaymentMethodsTypes} from "./actionTypes"

export const getPaymentMethods = () => ({
  type: PaymentMethodsTypes.GET_PAYMENT_METHODS,
})

export const getPaymentMethodsSuccess = (PaymentMethods : any) =>{  
  return ({
    type: PaymentMethodsTypes.GET_PAYMENT_METHODS_SUCCESS,
    payload: PaymentMethods,
  })
} 

export const getPaymentMethodsFail = (error : any) => ({
  type: PaymentMethodsTypes.GET_PAYMENT_METHODS_FAIL,
  payload: error,
})



