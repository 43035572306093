import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { post, del } from "src/helpers/api_helper";
import "react-loading-skeleton/dist/skeleton.css";
import { number_format, predefinedRanges } from "src/helpers/common";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import moment from "moment";
import JournalEntryCreate from "./JournalEntryCreate";
import { AvForm, AvField } from "availity-reactstrap-validation";
import NoDataIndication from "../Datatable/NoDataIndication";
import ConfirmationModal from "src/components/ConfirmationModal";

interface Params extends RouteComponentProps {
  units: Array<any>;
  taxes: Array<any>;
  permissions: Array<any>;
  getProducts: () => void;
  isPaginationRequire?: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
  sortOrder: string;
  sortField: string;
}

type State = {
  sizePerPage: number;
  totalSize: number;
  page: number;
  data: any[];
  sortField: string;
  sortOrder: string;
  startDate: Date | null;
  endDate: Date | null;
  dateRange: [Date, Date];
  modelgstLedger: boolean;
  inventory_id: string;
  inventory_name: string;
  searchText: string;
  openModalCre: boolean;
  openModalEdit: boolean;
  isOpenConformModal: boolean;
  active_id: string;
};

class JournalEntry extends Component<Params, State> {
  static defaultProps = {
    isPaginationRequire: true,
  };

  _isMounted = false;

  constructor(props: Params) {
    super(props);
    const today = new Date();
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      page: 1,
      data: [],
      sortField: "journal_entry_date",
      sortOrder: "desc",
      startDate: startOfMonth(today),
      endDate: endOfMonth(today),
      dateRange: [startOfMonth(today), endOfMonth(today)],
      modelgstLedger: false,
      inventory_id: "",
      inventory_name: "",
      searchText: "",
      openModalCre: false,
      openModalEdit: false,
      isOpenConformModal: false,
      active_id: "",
    };

    this.JournalEntryCreateOpen = this.JournalEntryCreateOpen.bind(this);
    this.JournalEntryClose = this.JournalEntryClose.bind(this);
    this.setStartDateNew = this.setStartDateNew.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.prepareParams());
  }

  componentDidUpdate(prevProps: Params, prevState: State) {
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.searchText !== this.state.searchText
    ) {
      this.fetchData(this.prepareParams());
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareParams() {
    const {
      page,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      startDate,
      endDate,
    } = this.state;
    return {
      page,
      sizePerPage,
      sortField,
      sortOrder,
      searchText,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    };
  }

  fetchData = async (param: any) => {
    try {
      const resp = await post(
        `${process.env.REACT_APP_API_URL}/api/journal_list_grid`,
        param
      );
      if (this._isMounted && resp.success) {
        this.setState({
          totalSize: resp.data.count,
          data: resp.data.results,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  setStartDateNew(dateRange: [Date, Date] | null) {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    this.setState(
      {
        startDate,
        endDate,
        dateRange: dateRange || this.state.dateRange,
      },
      () => {
        this.fetchData(this.prepareParams());
      }
    );
  }
  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ active_id: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/delete_journal/" +
            this.state.active_id
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData(this.prepareParams());
    this.setState({ isOpenConformModal: false });
    this.setState({ active_id: "" });
  };
  handleDeleteClose = () => {
    this.setState({ isOpenConformModal: false });
    this.setState({ active_id: "" });
  };
  handleEdit = (row: any) => {
    this.JournalEntryEditOpen();
    this.setState({ active_id: row.id });
  };
  JournalEntryCreateOpen() {
    this.setState({ openModalCre: true });
  }
  JournalEntryEditOpen() {
    this.setState({ openModalEdit: true });
  }
  JournalEntryClose() {
    this.setState({ openModalCre: false });
    this.setState({ openModalEdit: false });
    this.setState({ active_id: "" });
  }

  JournalEntryCallback = () => {
    this.fetchData(this.prepareParams());
  };

  handleSearchInputChange(searchText: string) {
    this.setState({ searchText }, () => {
      this.fetchData(this.prepareParams());
    });
  }

  handleTableChange(
    type: string,
    { page, sizePerPage, sortField, sortOrder }: ChangeProp
  ) {
    this.setState({ page, sizePerPage, sortField, sortOrder }, () => {
      this.fetchData(this.prepareParams());
    });
  }
  preventParentAction = (event: any) => {
    event.stopPropagation();
  };
  render() {
    const { dateRange } = this.state;
    const title = "Journal";
    const pageOptions = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
    };
    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];
    const columns = [
      { dataField: "id", text: "Id", sort: true, hidden: true },
      {
        dataField: "journal_entry_date",
        text: "Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {moment(row.journal_entry_date).format("DD MMM YYYY")} <br />
              <span style={{ fontSize: "10px" }}>
                {moment(row.created_at).format("DD MMM YYYY h:mm:ss A")}
              </span>
            </>
          );
        },
      },
      { dataField: "journal_entry_no", text: "Journal Entry No", sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.journal_entry_prefix + row.journal_entry_no}
            </>
          );
        },
       },
      {
        dataField: "total_amount",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any) => `₹ ${number_format(cellContent)}`,
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button btns-inv">
                <div className="icon-wrapper">
                  <button
                    className="round-btn"
                    onClick={this.preventParentAction}
                  >
                    <UncontrolledDropdown className="mt-4 mt-sm-0">
                      <DropdownToggle tag="a">
                        <EllipsisIcon />
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleEdit(row)}
                        >
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleDelete(row)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </button>
                  <div className="tooltip-icon">Action</div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ minHeight: "685px", background: "#F1F1F1" }}
        >
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                  <div className="header">
                    <h4 className="mb-0 font-size-18">{title}</h4>
                    <div className="form-inline">
                      <AvForm>
                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="searchText"
                              type="text"
                              placeholder="Search..."
                              onChange={(e: any) =>
                                this.handleSearchInputChange(e.target.value)
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <DateRangePicker
                              ranges={predefinedRanges}
                              format="dd/MM/yyyy"
                              placeholder=" "
                              value={dateRange}
                              style={{ width: 280 }}
                              onChange={this.setStartDateNew}
                            />
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </div>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">{title}</Link>
                      </BreadcrumbItem>
                    </ol>
                    <Col lg={12}>
                      <Button
                        onClick={this.JournalEntryCreateOpen}
                        type="button"
                        className="btn btn-primary create"
                      >
                        Add Journal Entry
                      </Button>
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.data}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <div>
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                striped
                                classes={
                                  "table align-middle table-nowrap tbl-journal"
                                }
                                noDataIndication={() => <NoDataIndication />}
                                onTableChange={this.handleTableChange}
                              />
                              {this.props.isPaginationRequire && (
                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.openModalCre && (
          <JournalEntryCreate
            modal_is_open={this.state.openModalCre}
            openModal={this.JournalEntryCreateOpen}
            closeModal={this.JournalEntryClose}
            callback={this.JournalEntryCallback}
          />
        )}
        {this.state.openModalEdit && (
          <JournalEntryCreate
            id={this.state.active_id}
            modal_is_open={this.state.openModalEdit}
            openModal={this.JournalEntryEditOpen}
            closeModal={this.JournalEntryClose}
            callback={this.JournalEntryCallback}
          />
        )}
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
      </React.Fragment>
    );
  }
}

export default withRouter(JournalEntry);
