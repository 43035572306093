import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Table,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";
import { ActionMeta, OnChangeValue } from "react-select/dist/declarations/src";
import "react-datepicker/dist/react-datepicker.css";
import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SendEstModel from "./SendEstModel";
import EstimationDetailsPdf from "./PerformaDetailsPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";
import { getLetters, number_format, padLeadingZeros } from "src/helpers/common";
import ProductCreateModalNew from "../Products/ProductCreateModalNew";
import PerformaDetailsModel from "./PerformaDetailsModel";
import ConfirmationModal from "src/components/ConfirmationModal";

interface MyProps extends RouteComponentProps<any> {
  valueCustomer?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  // setTotalAmount: ({ }) => void;
  isPaginationRequire?: boolean;
  // setDownloadpdf: (id: string) => void;
  customers: Array<any>;
  permissions: Array<any>;
  companies: { orders: any; logoFile: string };
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  setTotalAmount: ({}) => void;
}

interface MyState {
  id: string;
  sizePerPage: number;
  totalSize: number;
  data: any;
  sortField: string;
  sortOrder: string;
  proforma_invoice_prefix: string;
  permissions: Array<any>;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  totalAmount: number;
  modal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  isPlanExpired: number;
  modalPlan: boolean;
  pdfId: string;
  active_est_no: string;
  modal_cust_is_open: boolean;
  modal_item_is_open: boolean;
  isOpenConformModal: boolean;
  convertToInvoiceModal: boolean;
  Rowdelete: string | null;
  RowConvert: string | null;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value,
});
interface Entry {
  status: number;
}
class ProformaInvoiced extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      id: "",
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      sortField: "proforma_invoice_no",
      sortOrder: "desc",
      proforma_invoice_prefix: "",
      permissions: [],
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      // startDate: new Date(),
      // endDate: new Date(moment().add(6, 'days').format("YYYY-MM-DD"))
      startDate: null,
      endDate: null,
      totalAmount: 0,
      modal: false,
      isSending: false,
      editorState: EditorState.createEmpty(),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      company_name: "",
      isPlanExpired: 0,
      pdfId: "",
      modalPlan: false,
      active_est_no: "",
      modal_cust_is_open: false,
      modal_item_is_open: false,
      isOpenConformModal: false,
      convertToInvoiceModal: false,
      Rowdelete: null,
      RowConvert: null,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  openModalPlan = () => {
    this.setState({ modalPlan: true });
  };

  closeModalPlan = () => {
    this.setState({ modalPlan: false });
  };

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        param["startDate"] = param["startDate"]
          ? moment(param["startDate"]).format("YYYY-MM-DD")
          : null;
        param["endDate"] = param["endDate"]
          ? moment(param["endDate"]).format("YYYY-MM-DD")
          : null;
        param["status"] = 4;
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/proforma_invoice_list_grid`,
          param
        );
        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              totalAmount: resp.data.totalAmount,
              data: resp.data.results,
              proforma_invoice_prefix: resp.data.proforma_invoice_prefix,
              instance_id: resp.data.instance_id,
              company_name: resp.data.company_name,
            });
            this.props.setTotalAmount({
              invoicedLen: resp.data.count,
              sumStatus4: resp.data.totalAmount,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: param.page,
      searchText: "",
      sizePerPage: param.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      customer: this.props.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/proforma_invoice_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };

  downloadPdf = (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ pdfId: row.id });
  };

  convertToInvoice = async (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ convertToInvoiceModal: true });
    this.setState({ RowConvert: row.id });
  };
  handleConvertToInvoiceConfirm = () => {
    this.props.history.push("/invoice_convert/" + this.state.RowConvert);
    this.setState({ convertToInvoiceModal: false });
    this.setState({ RowConvert: null });
  };
  handleConvertToInvoiceClose = () => {
    this.setState({ convertToInvoiceModal: false });
    this.setState({ RowConvert: null });
  };
  send = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let est_no = this.state.proforma_invoice_prefix
        ? this.state.proforma_invoice_prefix + row.proforma_invoice_number
        : "INV" + row.proforma_invoice_number;
      this.setState({
        id: row.id,
        active_est_no: est_no,
        email: row.email,
        modal: !this.state.modal,
      });
    } catch (err) {
      console.error(err);
    }
  };

  view = async (row: any) => {
    try {
      // this.setState({ id: row.id, modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  isPlanExpired = async (e: any, location: string) => {
    try {
      if (this.state.isPlanExpired) {
        this.props.history.push(location);
      } else {
        this.openModalPlan();
      }
    } catch (err) {
      console.error(err);
    }
  };

  toggleView = () => {
    try {
      this.setState({ modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  toggleSend = () => {
    try {
      this.setState({ modal: !this.state.modal });
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: newValue?.value,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
  };
  loadData = async () => {
    try {
      const getAuthUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(getAuthUser);
      if (this._isMounted) this.setState({ isPlanExpired: obj.isPlanExpired });

      let customers: any = this.props.customers;
      let customerOptions: Array<any> = [];

      customers.map((customer: any) => {
        let newOption = createOption(customer.customer_name, customer.id);
        customerOptions = [...customerOptions, newOption];
      });

      let estmationPermissions = this.props.permissions.filter(
        permission =>
          permission.module_permission_id === PermissionModule.ESTIMATION
      );
      if (this._isMounted) {
        this.setState({
          customers: customers,
          options: customerOptions,
          permissions: estmationPermissions,
        });
      }
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          loading: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  componentDidUpdate(prevProps: MyProps) {
    if (this.props.refresh !== prevProps.refresh) {
      this.fetchData({
        page: 1,
        searchText: "",
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  async componentWillReceiveProps(nextProps: MyProps) {
    await this.loadData();

    if (
      this.state.valueCustomer !== nextProps.valueCustomer ||
      this.state.startDate !== nextProps.startDate ||
      this.state.endDate !== nextProps.endDate
    ) {
      this.setState({
        valueCustomer: nextProps.valueCustomer,
        startDate: nextProps.startDate || null,
        endDate: nextProps.endDate || null,
      });

      this.fetchData({
        page: 1,
        searchText: "",
        customer: nextProps.valueCustomer?.value,
        startDate: nextProps.startDate || null,
        endDate: nextProps.endDate || null,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ startDate: startDate, endDate: endDate });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: this.state.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  }
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/proforma_invoice_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };

  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    // this.props.history.push("/proforma_details/" + row.id);
    this.setState({ id: row.id, modalView: !this.state.modalView });
  };

  preventParentAction = (event: any) => {
    event.stopPropagation();
  };
  openCustModal = () => {
    this.setState({ modal_cust_is_open: true });
  };
  closeCustModal = () => {
    this.setState({ modal_cust_is_open: false });
  };
  parentCallback = () => {
    this.closeCustModal();
  };
  openModalItem = () => {
    this.setState({ modal_item_is_open: true });
  };
  closeModalItem = () => {
    this.setState({ modal_item_is_open: false });
  };
  parentCallbackItem = () => {
    this.closeModalItem();
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
      showTotal: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columnsMob = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <span title={cellContent}>{getLetters(cellContent)}</span>;
        },
      },
      {
        dataField: "invoice_no",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {this.state.proforma_invoice_prefix
                ? this.state.proforma_invoice_prefix +
                  row.proforma_invoice_number
                : "PRO" + row.proforma_invoice_number}
              <br />
              {moment(row.invoice_date).format("DD/MM/YYYY")}
            </>
          );
        },
      },
      {
        dataField: "gross_total_base",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let due_date = moment(row.due_date);
          let cur_date = moment();
          let days = cur_date.diff(due_date, "days");
          let status;
          if (row.status == 4) {
            status = (
              <>
                <span className="status sent">Partially Paid</span>
                <label className="status-mess">{days} days due</label>
              </>
            );
          } else if (row.status == 5) {
            status = <span className="status partially">Paid</span>;
          } else {
            status = (
              <>
                <span className="status pending">Unpaid</span>
                <label className="status-mess">{days} days due</label>
              </>
            );
          }
          return <>{status}</>;
        },
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button btns-inv">
                <button
                  className="round-btn"
                  title="Actions"
                  onClick={this.preventParentAction}
                >
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem
                        to="#"
                        onClick={(e: any) => this.downloadPdf(e, row)}
                      >
                        <PdfIcon /> Download Pdf
                      </DropdownItem>
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={(e: any) => this.send(e, row)}
                      >
                        <SentIcon /> Send
                      </DropdownItem>
                      <a
                        target="_blank"
                        href={`https://api.whatsapp.com/send?text=Hello *${row.customer_name}*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${this.state.proforma_invoice_prefix}${row.invoice_no} here,%0a ${process.env.REACT_APP_URL}/estimation-view/${this.state.instance_id}/${row.id}%0a%0a%0a Thanks%0a *${this.state.company_name}* %0a Sent using ${process.env.REACT_APP_NAME}(https://www.bookmytax.in)`}
                        onClick={this.preventParentAction}
                      >
                        <DropdownItem to="#" tag="span">
                          <WhatsappIcon /> Whatsapp
                        </DropdownItem>
                      </a>
                      <Link to={"/invoice_edit/" + row.id}>
                        <DropdownItem to="#" tag="span">
                          <i
                            className="fas fa-edit"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit"
                            style={{ color: "#28a745" }}
                          />{" "}
                          View Pdf
                        </DropdownItem>
                      </Link>
                      <DropdownItem
                        to="#"
                        tag="span"
                        onClick={() => this.handleDelete(row)}
                      >
                        <i
                          className="fas fa-trash-alt"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Delete"
                          style={{ color: "#dc3545" }}
                        />{" "}
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
              </div>
            </>
          );
        },
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      /* {
                dataField: "due_date",
                text: "Due",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{moment(row.due_date).format("DD/MM/YYYY")}</>
                    )
                }
            }, */
      {
        dataField: "invoice_date",
        text: "Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {moment(row.invoice_date).format("DD MMM YYYY")}
              <br />
              <span style={{ fontSize: "10px" }}>
                {moment(row.created_at).format("DD MMM YYYY h:mm:ss A")}
              </span>
            </>
          );
        },
      },
      {
        dataField: "proforma_invoice_no",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.prefix
                ? row.prefix + row.proforma_invoice_no
                : "EST" + row.proforma_invoice_no}
            </>
          );
        },
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: cellContent
                  .toUpperCase()
                  .match(/.{1,30}/g)
                  .join("<br />"),
              }}
            ></span>
          );
        },
      },
      {
        dataField: "gross_total_base",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <span>{`₹ ${number_format(cellContent)}`}</span>
            </>
          );
        },
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let status;
          if (row.status == 4) {
            status = <span className="status partially">Invoiced</span>;
          } else if (row.status == 3) {
            status = <span className="status pending">Viewed</span>;
          } else if (row.status == 2) {
            status = <span className="status pending">Mail Sent</span>;
          } else {
            status = <span className="status pending">Generated</span>;
          }
          return <>{status}</>;
        },
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="icon-wrapper">
                <div className="table-button btns-inv">
                  <button
                    className="round-btn"
                    onClick={this.preventParentAction}
                  >
                    <UncontrolledDropdown className="mt-4 mt-sm-0">
                      <DropdownToggle tag="a">
                        <EllipsisIcon />
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link to={"/estimation_edit/" + row.id}>
                          <DropdownItem to="#" tag="span">
                            <i
                              className="fas fa-edit"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit"
                              style={{ color: "#28a745" }}
                            />{" "}
                            Edit
                          </DropdownItem>
                        </Link>
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleDelete(row)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </button>
                  <div className="tooltip-icon">Action</div>
                </div>
                {row.status != 4 && (
                  <div className="icon-wrapper">
                    <Link
                      to="#"
                      onClick={(e: any) => this.convertToInvoice(e, row)}
                    >
                      <button
                        type="button"
                        className="btn btn-statement normal-btn hash-button"
                      >
                        Convert
                      </button>
                    </Link>
                    <div className="tooltip-icon">Convert</div>
                  </div>
                )}
              </div>
            </>
          );
        },
      },
    ];

    const title = "Proforma";
    const breadcrumbItem = "Proforma List";

    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <>
            <CardTitle className="h4">
              <Skeleton height={"10%"} width={"10%"} />
            </CardTitle>
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                  <th>
                    <Skeleton width={"100%"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                  <td>
                    <Skeleton height={"10%"} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <></>
        )}
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={this.state.data}
              // search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive tbl-invoice">
                        <BootstrapTable
                          // responsive
                          selectRow={{
                            mode: "radio",
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: this.selectRow,
                          }}
                          remote
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          onTableChange={this.handleTableChange}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          noDataIndication={() => <NoDataIndication />}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
        <ConfirmationModal
          isOpen={this.state.convertToInvoiceModal}
          message="Do you want to convert as invoice?"
          onConfirm={this.handleConvertToInvoiceConfirm}
          onClose={this.handleConvertToInvoiceClose}
        ></ConfirmationModal>
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        <EstimationDetailsPdf
          id={this.state.pdfId}
          companies={this.props.companies}
        />
        <SendEstModel
          id={this.state.id}
          invoice_no={this.state.active_est_no}
          isOpen={this.state.modal}
          toggle={this.toggleSend}
          email={this.state.email}
        />
        {/* <EstimationDetailsModel id={this.state.id} isOpen={this.state.modalView} toggle={this.toggleView} instance_id={this.state.instance_id} email={this.state.email} /> */}
        {this.state.modalView == true && (
          <PerformaDetailsModel
            id={this.state.id}
            isOpen={this.state.modalView}
            toggle={this.toggleView}
          />
        )}
        {this.state.modal_cust_is_open == true && (
          <CustomerCreateFullModal
            modal_cust_is_open={this.state.modal_cust_is_open}
            closeModal={() => this.closeCustModal()}
            parentCallback={this.parentCallback}
          />
        )}
        {this.state.modal_item_is_open == true && (
          <ProductCreateModalNew
            modal_pro_is_open={this.state.modal_item_is_open}
            openProductModal={this.openModalItem}
            closeProductModal={this.closeModalItem}
            parentCallback={this.parentCallbackItem}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: {
  customers: any;
  permissions: any;
  companies: any;
}) => {
  return {
    customers: state.customers.customers,
    permissions: state.permissions.permissions,
    companies: state.companies.companies,
  };
};
export default connect(mapStateToProps)(withRouter(ProformaInvoiced));
