import { call, put, takeEvery } from "redux-saga/effects"

import {
  getProductsSuccess,
  getProductsFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { ProductsTypes } from "./actionTypes"

export const getProducts = () => get(process.env.REACT_APP_API_URL + '/api/products')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchProducts() {
  try {
    const response : apiRes = yield call(getProducts);  
    if (response.success) {
      yield put(getProductsSuccess(response))
    } 
  } catch (error) {
    yield put(getProductsFail(error))
  }
}


function* productSaga() {  
  yield takeEvery(ProductsTypes.GET_PRODUCTS, fetchProducts);
}

export {fetchProducts};
export default productSaga
