import {CurrenciesTypes} from "./actionTypes"

const INIT_STATE = {
  currencies: [],  
  error: {},
}

const Currencies = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case CurrenciesTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload.data,
      }
    case CurrenciesTypes.GET_CURRENCIES:
      return {
        ...state,
        currencies: state.currencies,
      }
    case CurrenciesTypes.GET_CURRENCIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Currencies
