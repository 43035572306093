import React from 'react';
import Html from 'react-pdf-html';
import ReactPDF, { Document, Page, Font } from '@react-pdf/renderer';

Font.register({ family: 'Karla', fonts: [ 
  { src: process.env.REACT_APP_URL + '/Karla.ttf', fontStyle: 'normal'},
  { src: process.env.REACT_APP_URL + '/Karla-Bold.ttf',  fontWeight: 'bold'}
]});


interface Props {
  html : string;
}

const HtmlPdf = (props: Props) => {   

  

    return (
        <Document>
        <Page>
          <Html resetStyles style={{ fontSize: 10 }}>{props.html}</Html>          
        </Page>
      </Document>
    );
};

export default HtmlPdf; 