import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { getAccessToken } from "src/helpers/api_helper";
import support from "src/assets/images/icons/support.svg";
import { Link } from "react-router-dom";

const Support: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [sumouseTab, setsumouseTab] = useState(false);
  const [semouseTab, setsemouseTab] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleValidSubmit = async (event: any, values: any) => {
    try {
      var token = getAccessToken();
      const formData = new FormData();
      formData.append("contact_person", values.contact_person);
      formData.append("mobile_no", values.mobile_no);
      formData.append("query", values.query);
      formData.append("attachment", event.target.attachment.files[0]);

      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/api/submit_query",
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      console.log(resp.data);
      handleCloseModal();
    } catch (error) {
      console.error("Error submitting query:", error);
    }
  };
  const SuMouseEnter = () => {
    setsumouseTab(true);
  };

  const SuMouseLeave = () => {
    setsumouseTab(false);
  };
  const SeMouseEnter = () => {
    setsemouseTab(true);
  };

  const SeMouseLeave = () => {
    setsemouseTab(false);
  };
  return (
    <>
      <span
        className="me-1 ms-1 d-inline-block"
        onClick={handleShowModal}
        style={{ cursor: "pointer", alignSelf: "center" }}
        data-bs-toggle="popover"
        onMouseEnter={SuMouseEnter}
        onMouseLeave={SuMouseLeave}
      >
        {/* <i className="fa fa-question-circle"></i> */}
        <div className="btn header-item d-flex align-items-center p-1">
          <div className="icon-block-01">
            <img src={support} alt="Support" />
            {sumouseTab && <div className="tooltip-header">Support</div>}
          </div>
        </div>
      </span>
      <span
        className="me-1 ms-1 d-inline-block"
        style={{ cursor: "pointer", alignSelf: "center" }}
        data-bs-toggle="popover"
        onMouseEnter={SeMouseEnter}
        onMouseLeave={SeMouseLeave}
      >
        {/* <i className="fa fa-question-circle"></i> */}

        <div className="btn header-item d-flex align-items-center p-1">
          <div className="icon-block-01">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#176955"
                strokeWidth="2"
              >
                <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
              </svg>
            </Link>
            {semouseTab && <div className="tooltip-header">Settings</div>}
          </div>
        </div>
      </span>

      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalBody>
          <AvForm
            onValidSubmit={handleValidSubmit}
            encType="multipart/formData"
            id="TermsForm"
          >
            <div
              className="green_form pt-0 pb-0"
              style={{
                maxWidth: "700px",
                margin: "0 auto",
              }}
            >
              <h5
                className="row"
                style={{
                  fontSize: 16,
                  justifyContent: "center",
                  marginBottom: "-5px",
                }}
              >
                <label
                  // className="col-sm-4 col-form-label"
                  style={{
                    color: "#00af50",
                    textAlign: "center",
                  }}
                >
                  Submit a Query
                </label>
              </h5>
              <Row>
                <label
                  className="col-sm-4 col-form-label"
                  style={{
                    textAlign: "start",
                    alignSelf: "center",
                  }}
                >
                  Contact Person
                </label>
                <Col>
                  <AvField
                    placeholder="name"
                    name="contact_person"
                    type="text"
                    required
                  ></AvField>
                </Col>
              </Row>
              <Row>
                <label
                  className="col-sm-4 col-form-label"
                  style={{
                    textAlign: "start",
                    alignSelf: "center",
                  }}
                >
                  Mobile No
                </label>
                <Col>
                  <AvField
                    placeholder="10 digit number"
                    required
                    name="mobile_no"
                    type="text"
                  ></AvField>
                </Col>
              </Row>
              <Row>
                <label
                  className="col-sm-4 col-form-label"
                  style={{
                    textAlign: "start",
                    alignSelf: "center",
                  }}
                >
                  Query
                </label>
                <Col>
                  <AvField
                    required
                    placeholder="query"
                    name="query"
                    type="textarea"
                  ></AvField>
                </Col>
              </Row>
            </div>
            <div
              style={{
                fontSize: "large",
                textAlign: "center",
                padding: "10px",
              }}
            ></div>
            <div
              className="d-flex flex-wrap justify-content-center gap-2"
              style={{ marginTop: "15px" }}
            >
              <Button
                type="reset"
                onClick={handleCloseModal}
                className="bmt-btn-close"
                style={{ width: "30%" }}
              >
                Close
              </Button>
              <label className="bmt-btn-submit">
                Attachement
                <input
                  type="file"
                  required
                  name="attachment"
                  style={{ display: "none" }}
                />
              </label>
              <Button
                type="submit"
                className="bmt-btn-submit"
                style={{ width: "30%" }}
              >
                Submit
              </Button>{" "}
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Support;
