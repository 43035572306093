import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbItem, Col, Container, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";
import DashboardContent from "./DashboardContent";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [isLoading, setLoading] = useState(false);


  const title = "Dashboard";
  const breadcrumbItem = "Dashboard";

  return (
    <React.Fragment>
      <div className="page-content" style={{backgroundColor:'#F1F1F1'}}>
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
            <div className="header">
              {" "}
              <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
              <div className="form-inline">
                <AvForm>
                  <Row>
                  </Row>
                </AvForm>
              </div>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to="#">{title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
          <DashboardContent />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
