import React, { memo, useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import TemplatesModal from "../Common/TemplatesModal";
import { Link } from "react-router-dom";
import { get, post } from "src/helpers/api_helper";

const PerformaDetailsModel = memo((props: any) => {
  const [invoice_template, set_invoice_template] = useState(1);
  const [templateModal, setTemplateModal] = useState(false);
  const [switch_template, set_switch_template] = useState(false);
  // const [modal,set_modal] = useState(false);
  const [display_template, set_display_template] = useState(false);
  const [id, setId] = useState("");
  const [blobUrl, setBlobUrl] = useState("");
  const changeTemplate = (tempid: any) => {
    try {
      set_switch_template(true);
      sendChageTemplate(tempid);
    } catch (err) {
      console.error(err);
    }
  };
  const sendChageTemplate = async (tempid: any) => {
    try {
      set_invoice_template(tempid);
      const resp: any = await post(
        process.env.REACT_APP_API_URL + "/api/switch_pro_template",
        { invoice_template: tempid, id }
      );
      setBlobUrl(resp.file);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  // const toggleDetailsModal = () => {
  //     setBlobUrl('');
  //     props.toggle;
  // }
  const toggleTemplate = () => {
    try {
      setTemplateModal(!templateModal);
    } catch (err) {
      console.error(err);
    }
  };
  const getData = async () => {
    try {
      const resp: any = await get(
        process.env.REACT_APP_API_URL + "/api/proforma_invoice_view/" + props.id
      );
      setBlobUrl(resp.file);
      set_invoice_template(resp.data.hdr.invoice_template_id);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setId(props.id);
    getData();
  }, [props.id]);

  useEffect(() => {
    return () => {
      set_invoice_template(1);
    };
  }, []);
  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        // size="lg"
        className="modal-xl"
        // toggle={toggleDetailsModal}
      >
        <div className="modal-content">
            
          <div className="modal-header">
            <h5>Performa Preview</h5>
            <Row className="mx-5">
              <Col lg="12">
                <AvForm>
                  {display_template ? (
                    <AvField
                      type="select"
                      name="invoice_template"
                      value={invoice_template}
                      onChange={changeTemplate}
                    >
                      <option value={1} key={1}>
                        Template 1
                      </option>
                      <option value={2} key={2}>
                        Template 2
                      </option>
                    </AvField>
                  ) : (
                    <Link to="#" onClick={toggleTemplate}>
                      Change Template
                    </Link>
                  )}
                </AvForm>
              </Col>
            </Row>
          </div>
          <div>
            <button
              onClick={props.toggle}
              type="button"
              className="close"
              aria-label="Close"
              style={{ fontSize: "16px" }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <ModalBody>
            <Row>
              <Col lg="12">
                <Card>
                  <iframe
                    id="pdf_preview"
                    src={blobUrl}
                    className="min-vh-100"
                  ></iframe>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <TemplatesModal
        tempid={invoice_template}
        isOpen={templateModal}
        toggle={() => setTemplateModal(!templateModal)}
        switchTemplate={changeTemplate}
      />
    </React.Fragment>
  );
});

export default PerformaDetailsModel;
