import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router";
import { get, post } from "src/helpers/api_helper";
import moment from "moment";
import Select from "react-select";
import { ActionMeta, OnChangeValue } from "react-select/dist/declarations/src";
import { setbankorcashSuccess } from "src/store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DatePicker from "rsuite/DatePicker";

interface ExpenseProps {
  modal_is_open: boolean;
  openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: Function;
  handleRefresh: Function;
  id: string;
}

interface Option {
  label: string;
  value: string;
}
interface Item {
  item_name: string;
  category_name: string;
  id: string;
  value: string;
}
interface DataState {
  expense_date: Date | null;
  expense_no: string;
  amount: number;
  description: string;
  item_id: { label: string; value: string };
  heads_id: { label: string; value: string };
}
const createPayOption = (label: string, value: string, heads_id: string) => ({
  label,
  value,
  heads_id,
});
const createItemOption = (label: string, value: string) => ({
  label,
  value,
});
const ExpenseModal = (props: ExpenseProps) => {
  const { bankorcash } = useSelector((state: any) => ({
    bankorcash: state.bankorcash.bankorcash,
  }));
  const [data, setData] = useState<DataState>({
    expense_date: null,
    expense_no: "",
    amount: 0,
    description: "",
    item_id: { label: "", value: "" },
    heads_id: { label: "", value: "" },
  });
  const [banksCash, setBanksCash] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
  const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
  const [item_id, setitem_id] = useState<any>([]);
  const [heads_id, setheads_id] = useState<any>([]);
  const [expense_prefix, setexpense_prefix] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorAmount, setErrorAmount] = useState("");
  const [errorMode, setErrorMode] = useState("");
  const [errorItem, setErrorItem] = useState("");

  const handleValidSubmit = async (event: any, values: any) => {
    let hasError = false;
    setErrorAmount("");
    setErrorMode("");
    setErrorItem("");
    if (!values.amount) {
      setErrorAmount("Please enter an amount");
      hasError = true;
    }
    if (!heads_id || !heads_id.value) {
      setErrorMode("Please select a mode");
      hasError = true;
    }
    if (!item_id || !item_id.value) {
      setErrorItem("Please select an Item");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setIsModalProgress(true);

    values["id"] = props.id;
    values["item_id"] = item_id;
    values["heads_id"] = heads_id.heads_id;
    values["bank_cashbook_id"] = heads_id.value;
    values["expense_date"] = data.expense_date;
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/expense_save",
      values
    );
    if (resp.success == true) {
      setIsHideSuccessMgs(false);
      setbankorcashSuccess(dispatch);
      props.closeModal();
      props.handleRefresh();
    } else {
      setIsHideErrorMgs(false);
    }
    setIsModalProgress(false);
  };
  const resetForm = () => {
    props.closeModal();
  };
  const handleItemChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    setitem_id(newValue);
  };
  const set_date_receipt = (date: any) => {
    if (date == null) {
      setData(prevData => ({ ...prevData, expense_date: null }));
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      setData(prevData => ({ ...prevData, expense_date: formattedDate }));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let urls = [
          process.env.REACT_APP_API_URL + "/api/get_exp_items",
          process.env.REACT_APP_API_URL + "/api/expense_get/" + props.id,
          process.env.REACT_APP_API_URL + "/api/get_expense_no",
        ];

        const [resI, resD, respE] = await Promise.all(
          urls.map(url => get(url))
        );
        const paymentMethods = bankorcash;
        const newBankCashbookOptions = paymentMethods.map(
          (method: { id: string; name: string; heads_id: string }) =>
            createPayOption(method.name, method.id, method.heads_id)
        );
        setBanksCash(newBankCashbookOptions);

        const defaultPaymentMethod = paymentMethods.find(
          (data: { id: string; name: string; heads_id: string }) =>
            data.id === resD.data.bank_cashbook_id
        );

        if (defaultPaymentMethod) {
          setheads_id(
            createPayOption(
              defaultPaymentMethod.name,
              defaultPaymentMethod.id,
              defaultPaymentMethod.heads_id
            )
          );
        }
        // }

        const items = resI.data;
        const newItemOptions = items
          .filter((item: { is_system: number }) => item.is_system === 0)
          .sort((a: { category_id: string }, b: { category_id: string }) => {
            const catA = parseInt(a.category_id, 10);
            const catB = parseInt(b.category_id, 10);
            if (catA === 3 && catB !== 3) return -1;
            if (catA !== 3 && catB === 3) return 1;
            return 0;
          })
          .map((item: Item) =>
            createItemOption(
              item.item_name + " (" + item.category_name + ")",
              item.id
            )
          );
        setItems(newItemOptions);
        const selectedItem = newItemOptions.find(
          (item: Item) => item.value === resD.data.item_id
        );
        if (selectedItem) {
          setitem_id(selectedItem);
        }
        setData(resD.data);
        const selectedDate = moment(resD.data.expense_date);
        const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
        setData(prevData => ({ ...prevData, expense_date: formattedDate }));
        setexpense_prefix(respE.data.expense_prefix);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.id]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className="md-700"
      >
        <AvForm onValidSubmit={handleValidSubmit}>
          <div className="modal-body">
            {/* Render the modal body here */}
            <div className="item_form pt-0 pb-0" style={{ borderLeft: "none" }}>
              <div className="row green_form pt-0 pb-1">
                <div className="heading">Edit Expense</div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-firstname-input"
                  >
                    Date
                  </label>
                  <div className="col-8">
                    <DatePicker
                      oneTap
                      onChange={set_date_receipt}
                      name="date_receipt"
                      format="dd/MM/yyyy"
                      placeholder="Enter Date of receipt"
                      menuClassName="custom-datepicker-dropdown"
                      style={{ width: "100%" }}
                      value={data.expense_date ? data.expense_date : null}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Expense No
                  </label>
                  <div className="col-8">
                    <AvField
                      name="expense_no"
                      type="text"
                      placeholder="Expense No"
                      value={expense_prefix + data.expense_no}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Item
                  </label>
                  <div className="col-8">
                    <Select
                    styles={{
                      option: (provided: any, state: any) => ({
                        ...provided,
                        ":hover": {
                          backgroundColor: "#10a37f",
                          color: "#fff"
                        },
                        backgroundColor: state.isSelected
                          ? "#10a37f"
                          : "inherit",
                      }),
                    }}
                      isClearable
                      options={items}
                      value={item_id}
                      name="item_id"
                      onChange={(newValue: any) => {
                        setitem_id(newValue);
                        if (!newValue) {
                          setErrorItem("Please select an Item");
                        } else {
                          setErrorItem("");
                        }
                      }}
                    />
                    {errorItem && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorItem}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label className="col-sm-4 col-form-label">Amount</label>
                  <div className="col-8">
                    <AvField
                      name="amount"
                      type="text"
                      placeholder="Enter Amount"
                      value={data.amount}
                      onChange={(e: any) => {
                        const amount = e.target.value;
                        if (!amount) {
                          setErrorAmount("Please enter an amount");
                        } else {
                          setErrorAmount("");
                        }
                      }}
                    />
                    {errorAmount && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorAmount}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label className="col-sm-4 col-form-label">Mode</label>
                  <div className="col-8">
                    <Select
                    styles={{
                      option: (provided: any, state: any) => ({
                        ...provided,
                        ":hover": {
                          backgroundColor: "#10a37f",
                          color: "#fff"
                        },
                        backgroundColor: state.isSelected
                          ? "#10a37f"
                          : "inherit",
                      }),
                    }}
                      required
                      isClearable
                      options={banksCash}
                      value={heads_id}
                      name="bank_cash_name"
                      onChange={(newValue: any) => {
                        setheads_id(newValue);
                        if (!newValue) {
                          setErrorMode("Please select a mode");
                        } else {
                          setErrorMode("");
                        }
                      }}
                    />
                    {errorMode && (
                      <div className="error-message" style={{ color: "red" }}>
                        {errorMode}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Description
                  </label>
                  <div className="col-8">
                    <AvField
                      name="description"
                      type="text"
                      placeholder="Description"
                      // validate={{ required: { value: true } }}
                      value={data.description}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Render the modal footer here */}
            <div className="modal-footer justify-content-center pb-0">
              {isModalProgress ? (
                <Button
                  type="submit"
                  className="bmt-btn-submit"
                  disabled={true}
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  Submitting
                </Button>
              ) : (
                <>
                  <Button
                    type="reset"
                    className="bmt-btn-close itemsubmit"
                    onClick={resetForm}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="submit" className="bmt-btn-submit itemsubmit">
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default ExpenseModal;
