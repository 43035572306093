import jsPDF from "jspdf";
import { split } from "lodash";
import { inWords } from "./inWords";
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from "moment";

export function padLeadingZeros(num: number, size: number) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function getLetters(name: string) {            
  let arrName = split(name, ' ');
  let rtnName = arrName[0].toLocaleUpperCase().charAt(0);
  if (arrName.length == 2) {
    rtnName += arrName[1].toLocaleUpperCase().charAt(0);
  }
  return rtnName;
}

export function convertNumberToWords(value: number, currency: string, dtext: string): string {
  let arrAmount = value.toString().split('.');
  let words = '';
  let num1 = Number(arrAmount[0]);
  let num2 = Number(arrAmount[1]);
  words += inWords(num1) + ` ${currency} `;
  if (arrAmount.length == 2 && num2 > 0) {
    words += ` And ` + inWords(num2) + ` ${dtext}`;
  } else {
    words += ` And Zero ${dtext}`;
  }
  words += ' Only';
  return words;
}

export function getMonthName(monthNumber: number) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'long' });
}

export const supplyTypes: any = [
  { id: 'Outward', name: 'Outward' }
  , { id: 'Inward', name: 'Inward' }
];

export const subSupplyTypes: any = [
  { id: 'Supply', name: 'Supply' }
  , { id: 'Export', name: 'Export' }
  , { id: 'Job Work', name: 'Job Work' }
  , { id: 'SKB/CKD', name: 'SKB/CKD' }
  , { id: 'Recipient Not Known', name: 'Recipient Not Known' }
  , { id: 'For Own Use', name: 'For Own Use' }
  , { id: 'Exhibition', name: 'Exhibition' }
  , { id: 'Line of Sales', name: 'Line of Sales' }
];

export const transportationMode: any = [
  { id: 'Road', name: 'Road' }
  , { id: 'Air', name: 'Air' }
  , { id: 'Rail', name: 'Rail' }
];

export function pdfCell(pdf: jsPDF, text: string, x: number, y: number, color: string = "", maxWidth: number = 0) {
  pdf.setTextColor(color);
  pdf.text(text, x, y, { maxWidth: maxWidth });
}

export const base64ToUint8 = (str: string): Uint8Array => Uint8Array.from(atob(str), (c) => c.charCodeAt(0));

export const number_format = (value: number | bigint): string  =>  new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);

export const predefinedRanges:Array<any> = [
  {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 30), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'Last FY',
      value: moment().month() > 2 ? [new Date(new Date().getFullYear() -1 , 3, 1), endOfMonth(new Date(new Date().getFullYear(),2,1))] :  [new Date(new Date().getFullYear() - 2, 3, 1), endOfMonth(new Date(new Date().getFullYear() -1,2,1))],
      placement: 'left'
    },
    {
      label: 'This FY',
      value: moment().month() > 2 ? [new Date(new Date().getFullYear(), 3, 1), endOfMonth(new Date(new Date().getFullYear() + 1 ,2,1))] :  [new Date(new Date().getFullYear() - 1, 3, 1), endOfMonth(new Date(new Date().getFullYear(),2,1))],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
];
export function get_addrress_type(type: number) {
  if (type== 1) {
    return "Ship";
  }
  else if (type== 2) {
    return "Supply";
  }
  else if (type== 3) {
    return "Service";
  }
}
export const addrress_type: any = [
  { id: '1', name: 'Ship To' }, 
  { id: '2', name: 'Supply To' },
  { id: '3', name: 'Service To' }
];
export const non_editable_item: number[] = [
  1, 2, 3, 4, 5, 6, 7,8,12, 13, 14, 15,16
];
export function isNoEditableItem(rowId: number | string): boolean {
  const numericRowId = Number(rowId);
  return non_editable_item.includes(numericRowId);
}
export const non_editable_disitem: number[] = [
  9,10,11
];
export function isNoEditableDisItem(rowId: number | string): boolean {
  const numericRowId = Number(rowId);
  return non_editable_disitem.includes(numericRowId);
}