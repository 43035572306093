import React, {
  useCallback,
  useState,
  Dispatch,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";

import {
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datatable from "../Datatable";
import { post, del } from "src/helpers/api_helper";
import { Link } from "react-router-dom";
import CustomerList from "./CustomerList";
import ActiveCustomers from "./ActiveCustomers";
import InactiveCustomers from "./InactiveCustomers";
import CustomerCreateFullModal from "./CustomerCreateFullModal";
import bannerImg from "../../assets/images/gst-banner.jpg";
import DatePicker from "rsuite/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { number_format, predefinedRanges } from "src/helpers/common";
import moment from "moment";
import { getCustomers } from "src/store/actions";
import { connect } from "react-redux";
import classnames from "classnames";
interface Props {
  getCustomers: () => void;
}
const Customers = (props: Props) => {
  const title = "Customer";
  const breadcrumbItem = "Customers List";
  const [activeTab, setActiveTab] = useState<number>(1);
  const [allCount, setAllCount] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [inactiveCount, setInactiveCount] = useState<number>(0);
  const [modal_pro_is_open, setCustomerModalOpen] = useState(false);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [processingCount, setProcessingCount] = useState<number>(0);
  const [refresh, setRefresh] = useState(false);
  const debounceTimeout = useRef<number | null>(null);
  const [SearchValue, setSearch] = useState<string>("");
  const [allTotal, setAllTotal] = useState<number>(0);
  const [activeTotal, setActiveTotal] = useState<number>(0);
  const [inactiveTotal, setInactiveTotal] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const setStartDateNew = (date: any) => {
    setStartDate(date);
    setEndDate(date);
    setRefresh(prev => !prev);
  };
  const Search = (value: string) => {
    setSearch(value);
    setRefresh(prev => !prev);
  };
  const openCustomerModal = () => {
    setCustomerModalOpen(true);
  };
  const closeCustomerModal = () => {
    setCustomerModalOpen(false);
  };
  const callBack = useCallback(() => {
    closeCustomerModal();
    setRefresh(prev => !prev);
  }, [props]);
  const handleChange = (e: any) => {
    const value = e.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = window.setTimeout(() => {
      Search(value);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ minHeight: "685px", background: "#F1F1F1" }}
      >
        <MetaTags>
          <title>{title}</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <div className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap">
                <div className="header" style={{ width: "100%" }}>
                  <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                  <div className="form-inline" style={{ width: "60%" }}>
                    <AvForm>
                      <Row>
                        <Col lg={4} className="px-0">
                          <AvField
                            name="search"
                            type="text"
                            onChange={handleChange}
                            style={{
                              paddingTop: "0.4rem",
                              paddingBottom: "0.4rem",
                            }}
                            className="form-control"
                            placeholder="Search"
                            value={SearchValue}
                          />
                        </Col>
                        <Col lg={4} className="pe-0">
                          <DatePicker
                            format="dd/MM/yyyy"
                            placeholder=" "
                            style={{ width: 280 }}
                            onChange={(date: any) =>
                              setStartDateNew(date)
                            }
                          />
                        </Col>
                        <Col lg={4}>
                          <Link
                            to="#"
                            style={{
                              position: "absolute",
                            }}
                          >
                            <button
                              type="button"
                              onClick={openCustomerModal}
                              style={{
                                paddingTop: "0.35rem",
                                paddingBottom: "0.35rem",
                              }}
                              className="btn btn-primary create"
                            >
                              <i className="fa fa-plus"></i> Create
                            </button>
                          </Link>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">{title}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{breadcrumbItem}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="cus-card">
                  <div className="row">
                    <div className="col-md-12 col-xl-7 order-xl-1">
                      <ul className="values-inv">
                        <li className={activeTab == 1 ? "active" : ""}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab == 1,
                            })}
                            onClick={() => {
                              setActiveTab(1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32.815"
                              height="49.055"
                              viewBox="0 0 32.815 49.055"
                            >
                              <path
                                id="Path_441"
                                data-name="Path 441"
                                d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                transform="translate(-1.415 49.055)"
                              />
                            </svg>
                            <span>{number_format(allTotal)}</span>
                            <label>All ({allCount})</label>
                          </NavLink>
                        </li>
                        <li className={activeTab == 2 ? "active" : ""}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab == 2,
                            })}
                            onClick={() => {
                              setActiveTab(2);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32.815"
                              height="49.055"
                              viewBox="0 0 32.815 49.055"
                            >
                              <path
                                id="Path_441"
                                data-name="Path 441"
                                d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                transform="translate(-1.415 49.055)"
                              />
                            </svg>
                            <span>{number_format(activeTotal)}</span>
                            <label>Active ({activeCount})</label>
                          </NavLink>
                        </li>
                        <li className={activeTab == 3 ? "active" : ""}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab == 3,
                            })}
                            onClick={() => {
                              setActiveTab(3);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32.815"
                              height="49.055"
                              viewBox="0 0 32.815 49.055"
                            >
                              <path
                                id="Path_441"
                                data-name="Path 441"
                                d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z"
                                transform="translate(-1.415 49.055)"
                              />
                            </svg>
                            <span>{number_format(inactiveTotal)}</span>
                            <label>Inactive ({inactiveCount})</label>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 col-xl-5 order-xl-1">
                      <div className="banner text-xl-end">
                        <NavLink to="/">
                          <img src={bannerImg} alt="" className="gst-banner" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId={1}>
                      <Row>
                        <Col sm="12">
                          <CustomerList
                            key={1}
                            refresh={refresh}
                            search={SearchValue}
                            startDate={startDate}
                            endDate={endDate}
                            setRefresh={setRefresh}
                            setTotal={setAllTotal}
                            setCount={setAllCount}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Row>
                        <Col sm="12">
                          <ActiveCustomers
                            key={2}
                            refresh={refresh}
                            search={SearchValue}
                            startDate={startDate}
                            endDate={endDate}
                            setRefresh={setRefresh}
                            setTotal={setActiveTotal}
                            setCount={setActiveCount}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={3}>
                      <Row>
                        <Col sm="12">
                          <InactiveCustomers
                            key={3}
                            refresh={refresh}
                            search={SearchValue}
                            startDate={startDate}
                            endDate={endDate}
                            setRefresh={setRefresh}
                            setTotal={setInactiveTotal}
                            setCount={setInactiveCount}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modal_pro_is_open == true && (
        <CustomerCreateFullModal
          modal_cust_is_open={modal_pro_is_open}
          closeModal={closeCustomerModal}
          parentCallback={callBack}
        />
      )}
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCustomers: () => {
      dispatch(getCustomers());
    },
  };
};
export default connect(mapDispatchToProps)(Customers);
