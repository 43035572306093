import React, { useCallback, useState } from "react";
import { useHistory } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  BreadcrumbItem,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datatable from "../Datatable";
import { post, del } from "src/helpers/api_helper";
import { Link } from "react-router-dom";
import ExpCategoryList from "./ExpCategoryList";

const ExpCategories = () => { 
  const title = "Categories";
  const breadcrumbItem = "Categories List";

  return (
    <React.Fragment>
    <div
      className="page-content"
      style={{ minHeight: "685px", background: "#F1F1F1" }}
    >
      <MetaTags>
        <title>{title}</title>
      </MetaTags>
      <div className="container-fluid">
        <Row>
          <Col xs="12">
            <div
              className="page-title-box title-block d-sm-flex align-items-center justify-content-between form-wrap"
              style={{ paddingTop: "8px", marginBottom: "8px" }}
            >
              <div className="header">
                {" "}
                <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="#">{title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pt-0">
                <Row>
                  <Col sm="12">
                    <ExpCategoryList />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
  );
};

export default ExpCategories;
