import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { useHistory } from "react-router";

import Select from "react-select";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";
import moment from "moment";

interface Option {
  label: string;
  value: string;
}
const createOption = (label: string, value: string) => ({
  label,
  value,
});
const ExpenseModify = (props: any) => {
  const { id } = props.match.params;
  const [data, setData] = useState({
    expense_date: "",
    expense_no: "",
    amount: 0,
    description: "",
    item_id: { label: "", value: "" },
    heads_id: { label: "", value: "" },
  });
  const [banksCash, setBanksCash] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [isModalProgress, setIsModalProgress] = useState(false);
  const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
  const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
  const [item_id, setitem_id] = useState<any>([]);
  const [heads_id, setheads_id] = useState<any>([]);
  const [expense_prefix, setexpense_prefix] = useState("");
  const history = useHistory();
  const handleValidSubmit = async (event: any, values: any) => {
    setIsModalProgress(true);
    values["id"] = id;
    values["item_id"] = item_id;
    values["heads_id"] = heads_id;

    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/expense_save",
      values
    );
    if (resp.success == true) {
      setIsHideSuccessMgs(false);
      history.push("/expenses");
    } else {
      setIsHideErrorMgs(false);
    }
    setIsModalProgress(false);
  };
  const resetForm = () => {
    history.push("/expenses");
  };
  const handleItemChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    setitem_id(newValue);
  };
  const handleBankCashChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    setheads_id(newValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let urls = [
          process.env.REACT_APP_API_URL + `/api/bank_list`,
          process.env.REACT_APP_API_URL + "/api/get_exp_items",
          process.env.REACT_APP_API_URL + "/api/expense_get/" + id,
          process.env.REACT_APP_API_URL + "/api/get_expense_no",
        ];

        Promise.all(urls.map(url => get(url))).then(
          ([respB, resI, resD, respE]) => {
            let newOption = createOption("Cash", "1");
            setBanksCash([...banksCash, newOption]);
            if ("1" == resD.data.heads_id) {
              resD.data.heads_id = newOption;
              setheads_id(newOption);
            }
            respB.data.map((bankCash: { bank_name: string; id: string }) => {
              let newOption = createOption(bankCash.bank_name, bankCash.id);
              setBanksCash([...banksCash, newOption]);
              if (bankCash.id == resD.data.heads_id) {
                resD.data.heads_id = newOption;
                setheads_id(newOption);
              }
            });
            resI.data.map((item: { item_name: string; id: string }) => {
              let newOption = createOption(item.item_name, item.id);
              setItems([...items, newOption]);
              if (item.id == resD.data.item_id) {
                resD.data.item_id = newOption;
                setitem_id(newOption);
              }
            });
            setData(resD.data);
            setexpense_prefix(respE.data.expense_prefix);
          }
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Expenses</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Modify Expense" breadcrumbItem="Modify Expense" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader></CardHeader>

                <CardBody>
                  <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
                    Expenses save successfully
                  </UncontrolledAlert>

                  <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
                    Somthing went wrong, Please try again
                  </UncontrolledAlert>
                  <AvForm onValidSubmit={handleValidSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-firstname-input">
                            Date
                          </label>
                          <AvField
                            name="expense_date"
                            type="date"
                            placeholder="Enter Date of receipt"
                            errorMessage="Enter Date of receipt"
                            validate={{ required: { value: true } }}
                            value={moment(data.expense_date).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-address-input">
                            Expense No
                          </label>
                          <AvField
                            name="expense_no"
                            type="text"
                            placeholder="Expense No"
                            value={expense_prefix + data.expense_no}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-address-input">
                            Item
                          </label>
                          <Select
                            isClearable
                            options={items}
                            value={item_id}
                            name="item_id"
                            styles={{
                              option: (provided: any, state: any) => ({
                                ...provided,
                                ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                backgroundColor: state.isSelected
                                  ? "#10a37f"
                                  : "inherit",
                              }),
                            }}
                            onChange={(
                              newValue: OnChangeValue<Option, false>,
                              actionMeta: ActionMeta<Option>
                            ) => handleItemChange(newValue, actionMeta)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-firstname-input">
                            Amount
                          </label>
                          <AvField
                            name="amount"
                            type="text"
                            placeholder="Enter Date of receipt"
                            errorMessage="Enter Date of receipt"
                            validate={{ required: { value: true } }}
                            value={data.amount}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-firstname-input">
                            Mode
                          </label>
                          <Select
                            required
                            isClearable
                            options={banksCash}
                            value={heads_id}
                            name="heads_id"
                            styles={{
                              option: (provided: any, state: any) => ({
                                ...provided,
                                ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                backgroundColor: state.isSelected
                                  ? "#10a37f"
                                  : "inherit",
                              }),
                            }}
                            onChange={(
                              newValue: OnChangeValue<Option, false>,
                              actionMeta: ActionMeta<Option>
                            ) => handleBankCashChange(newValue, actionMeta)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="progresspill-address-input">
                            Description
                          </label>
                          <AvField
                            name="description"
                            type="text"
                            placeholder="Description"
                            validate={{ required: { value: true } }}
                            value={data.description}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap gap-2">
                      {isModalProgress ? (
                        <Button
                          type="submit"
                          className="bmt-btn-submit"
                          disabled={true}
                        >
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          Submitting
                        </Button>
                      ) : (
                        <>
                          <Button
                            type="reset"
                            className="bmt-btn-close closesubmit"
                            onClick={resetForm}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="bmt-btn-submit closesubmit"
                          >
                            Submit
                          </Button>{" "}
                        </>
                      )}
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseModify;
