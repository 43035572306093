import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
interface Params extends RouteComponentProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

type State = {
  modalInfo: boolean;
  message: string;
};
class ConfirmationModal extends Component<Params, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      modalInfo: false,
      message: "",
    };
  }

  componentWillReceiveProps(props: Params) {
    this.setState({
      modalInfo: props.isOpen,
      message: props.message,
    });
  }
  onConfirm = () => {
    this.setState({ modalInfo: true });
  };

  onClose = () => {
    this.setState({ modalInfo: false });
  };

  render() {
    const { onClose, onConfirm } = this.props;
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modalInfo}
          role="dialog"
          autoFocus={true}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody style={{ paddingBottom: 0 }}>
              <h5
                className="row"
                style={{
                  fontSize: 16,
                  textTransform: "uppercase",
                  justifyContent: "center",
                  margin: "10px",
                }}
              >
                {this.state.message}
              </h5>
            </ModalBody>
            <ModalFooter style={{ textAlign: "center", display: "block" }}>
              <Button
                type="button"
                className="bmt-btn-close"
                style={{width: "100px"}}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="bmt-btn-submit"
                style={{width: "100px"}}
                onClick={onConfirm}
              >
                Yes
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(ConfirmationModal);
