import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import { post, get } from "src/helpers/api_helper";
import { number_format, predefinedRanges } from "src/helpers/common";

import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { Link } from "react-router-dom";
import PdfIcon from "src/assets/images/icons/Pdf";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { useSelector } from "react-redux";
import { isNull } from "lodash";
import InvoiceitemDetailsModel from "../Ledger/InvoiceitemDetailsModel";
import PurchaseDetailsModel from "../Purchase/PurchaseDetailsModel";
interface Props {
  modal_is_open: boolean;
  openModal?:
    | React.KeyboardEventHandler<any>
    | React.MouseEventHandler<any>
    | undefined;
  closeModal: any;
  invoice_id?: string;
  bank_name: string;
  bank_id: string;
}
interface Invoice {
  id: string;
  invoice_no: number;
  due_date: string;
}

interface Statement {
  invoice_no: number;
}

function DocumentBank(props: Props) {
  const [isProgress, setIsProgress] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const [data, setData] = useState<Array<any> | any>([]);
  const [openDate, setOpendate] = useState<Date | null>(
    startOfMonth(new Date())
  );
  const [openBal, setOpenBal] = useState<number>(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [hdr_id, sethdrId] = useState("");
  const [openModalInv, setModalInv] = useState(false);
  const [openModalPurch, setModalPurch] = useState(false);
  const { countries } = useSelector((state: any) => ({
    countries: state.countries.countries,
  }));
  const { states } = useSelector((state: any) => ({
    states: state.states.states,
  }));
  const [item, setItem] = useState({
    vendor_name: "",
    is_indian_state: 1,
    billing_address: "",
    gstin: "",
    state: "",
    country: "",
    address: "",
    phone: "",
    email: "",
    pan: "",
    invoice_no: "",
    invoice_date: null,
    id: "",
    bill_number: "",
  });
  const closeThis = () => {
    setStartDate(null);
    setEndDate(null);
    setData([]);
    setOpenBal(0);
    setIsSubmit(false);
    props.closeModal();
    setOpendate(null);
  };
  const setStartDateNew = (dateRange: any) => {
    const [startDate, endDate] = dateRange ? [...dateRange] : [null, null];
    let start_date = moment(startDate);
    const formatted_start_date = new Date(start_date.format("YYYY-MM-DD"));
    setDateRange(dateRange);
    setStartDate(formatted_start_date);
    let end_date = moment(endDate);
    const formatted_end_date = new Date(end_date.format("YYYY-MM-DD"));
    setEndDate(formatted_end_date);
  };
  const invoiceisOpen = () => {
    setModalInv(true);
  };
  const invoiceisClose = () => {
    setModalInv(false);
    sethdrId("");
  };
  const purchaseisOpen = () => {
    setModalPurch(true);
  };
  const purchaseisClose = () => {
    setModalPurch(false);
    sethdrId("");
  };
  const viewInfo = async (id: any, data_type: string, customer_id: string) => {
    sethdrId(id);
    if (data_type == "Sales") {
      invoiceisOpen();
    } else if (data_type == "Purchase") {
      const respS = await get(
        `${process.env.REACT_APP_API_URL}/api/vendor_get/${customer_id}`
      );
      const result = data.find(
        (statement: { hdr_id: string }) => statement.hdr_id === id
      );
      if (respS.success) {
        const item = respS.data;
        let billing_state = undefined;
        if (item.billing_country == "99") {
          billing_state = states.find(
            (state: { id: string; state: string }) =>
              state.id === item.billing_state
          ).state;
        } else {
          billing_state = item.state;
        }
        let billing_country = countries.find(
          (state: { id: string; country: string }) =>
            state.id === item.billing_country
        );
        let billing_address = "";
        if (!isNull(item.billing_address1)) {
          billing_address += `${item.billing_address1} `;
        }
        if (!isNull(item.billing_address2)) {
          billing_address += `${item.billing_address2} `;
        }
        if (!isNull(item.billing_town_or_city)) {
          billing_address += `${item.billing_town_or_city}\n`;
        }
        if (!isNull(billing_state)) {
          billing_address += `${billing_state} - `;
        }
        if (!isNull(item.billing_pincode)) {
          billing_address += `${item.billing_pincode} `;
        }
        if (billing_country !== undefined) {
          billing_address += `${billing_country.name}`;
        }
        setItem({
          vendor_name: item.vendor_name,
          billing_address: billing_address,
          is_indian_state: item.is_indian_state,
          gstin: item.gstin,
          state: item.state,
          country: item.country,
          address: item.address || "",
          phone: item.phone || "",
          email: item.email || "",
          pan: item.pan,
          id: item.customer_id,
          invoice_no: result.reference || "",
          invoice_date: result.date || "",
          bill_number: result.bill_number,
        });
        purchaseisOpen();
      }
    }
  };
  const search = async (startD: Date | null, endD: Date | null) => {
    try {
      let start_date = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      let postValues = {
        bank_cashbook_id: props.bank_id,
        startDate: start_date,
        endDate: end_date,
      };
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/bank_cashbook_statement`,
        postValues
      );
      if (resp.success) {
        setIsProgress(false);
        setOpendate(startDate);
        setData(resp.data.statements);
        setOpenBal(resp.data.openig_bal);
        setIsSubmit(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.modal_is_open) {
      let startD = startOfMonth(new Date());
      let endD = endOfMonth(new Date());
      setStartDate(startD);
      setEndDate(endD);
      search(startD, endD);
    }
  }, [props.modal_is_open, props.bank_id]);
  const submit = async (startD: Date | null, endD: Date | null) => {
    setIsProgress(true);
    search(startD, endD);
  };
  const downloadPdf = async () => {
    let start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    let end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    let postValues = {
      bank_cashbook_id: props.bank_id,
      startDate: start_date,
      endDate: end_date,
    };
    const pdfResp = await post(
      process.env.REACT_APP_API_URL + `/api/bank_cashbook_statement_pdf`,
      postValues
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfResp.file;
    downloadLink.download = pdfResp.fname;
    downloadLink.click();
  };

  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });

  var balance = openBal;
  var totalCredit = 0;
  var totalDebit = 0;
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className={
          "md-1000 ledger-modal modal-dialog-centered modal-dialog-scrollable h-100 my-0"
        }
      >
        <div className="modal-header d-block ledger-header border-0 justify-content-center pb-0">
          <Row className="pb-2">
            <Col lg={12}>
              <h5 className="modal-title text-center mt-0 fw-normal">
                Bank Cashbook Statement
              </h5>
            </Col>
            <Col lg={12}>
              <div
                className="text-center text-uppercase"
                style={{ fontSize: 20 }}
              >
                {props.bank_name}
              </div>
            </Col>
            <Col lg={4} className="offset-lg-3 pe-0">
              <DateRangePicker
                ranges={predefinedRanges}
                format="dd/MM/yyyy"
                placeholder=" "
                value={dateRange}
                style={{ width: 330 }}
                onChange={(date: DateRange | null) => setStartDateNew(date)}
              />
            </Col>
            <Col lg={4}>
              {isProgress ? (
                <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit-1"
                    disabled={true}
                  >
                    Submitting
                    <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  style={{ padding: "5px 25px" }}
                  className="bmt-btn-submit me-1"
                  onClick={() => submit(startDate, endDate)}
                >
                  Submit
                </Button>
              )}
              <Link to="#" onClick={(e: any) => downloadPdf()}>
                <button className="btn round-btn btn-pdf" title="PDF">
                  <PdfIcon />
                </button>
              </Link>
            </Col>
          </Row>
          <button
            type="button"
            onClick={() => closeThis()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closeButton"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <div className="mb-3">
            <Row>
              <Col xs="12">
                <div>
                  <div className="form-inline">
                    {isSubmit && (
                      <Row>
                        <Col lg={12}>
                          <Table
                            className="align-middle mb-0 ledger-table mt-1"
                            style={{
                              width: "100%",
                            }}
                            id="printRef"
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "110px" }}>Date</th>
                                <th>Reference</th>
                                <th
                                  style={{
                                    width: "50px",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Received
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Paid
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "120px",
                                  }}
                                >
                                  Balance
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{moment(openDate).format("DD/MM/YYYY")}</td>
                                <td>Opening Balance</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>
                                  {number_format(openBal)}
                                </td>
                              </tr>
                              {data.map(
                                (
                                  statement: {
                                    date: string;
                                    paid: number;
                                    received: number;
                                    type: number;
                                    heads_id: string;
                                    invoice_no: number;
                                    reference: string;
                                    status: number;
                                    closing_balance: number;
                                    data_type: string;
                                    hdr_id: string;
                                    customer_id: string;
                                  },
                                  index: number
                                ) => {
                                  let info: JSX.Element | string = "";
                                  let voucher: JSX.Element | string = "";
                                  voucher = statement.reference;
                                  balance =
                                    Number(balance) +
                                    Number(statement.received) -
                                    Number(statement.paid);
                                  totalCredit =
                                    Number(totalCredit) +
                                    Number(statement.received);
                                  totalDebit =
                                    Number(totalDebit) + Number(statement.paid);
                                  if (statement.data_type !== "Expense" && statement.data_type !== "Income") {
                                    info = (
                                      <span
                                        onClick={e =>
                                          viewInfo(
                                            statement.hdr_id,
                                            statement.data_type,
                                            statement.customer_id
                                          )
                                        }
                                        style={{ cursor: "pointer"}}
                                      >
                                        <i className="fas fa-info-circle info-icon"></i>
                                      </span>
                                    );
                                  }
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {moment(statement.date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        <div style={{ display: "flex" }}>
                                          {voucher}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                        }}
                                      >
                                        {info}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {statement.received > 0
                                          ? number_format(statement.received)
                                          : "-"}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {statement.paid > 0
                                          ? number_format(statement.paid)
                                          : "-"}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {number_format(balance)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(totalCredit)}</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(totalDebit)}</b>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <b>{number_format(balance)}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      {openModalInv && (
        <InvoiceitemDetailsModel
          id={hdr_id}
          isOpen={openModalInv}
          close={invoiceisClose}
        />
      )}
      {openModalPurch ? (
        <PurchaseDetailsModel
          info={0}
          id={hdr_id}
          vendor_id={item.id}
          vendor_name={item.vendor_name}
          billing_address={item.billing_address}
          invoice_no={item.bill_number}
          invoice_date={item.invoice_date}
          phone={item.phone}
          gstin={item.gstin}
          pan={item.pan}
          email={item.email}
          isOpen={openModalPurch}
          toggle={purchaseisClose}
        />
      ) : null}
    </React.Fragment>
  );
}
export default DocumentBank;
