import { call, put, takeLatest } from "redux-saga/effects"

import {
  getPermissionsSuccess,
  getPermissionsFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { PermissionsTypes } from "./actionTypes"

export const getPermissions = () => get(process.env.REACT_APP_API_URL + '/api/permissions')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

export function* fetchPermissions() {
  try {
    const response : apiRes = yield call(getPermissions);  
    if (response.success) {
      yield put(getPermissionsSuccess(response))
    } 
  } catch (error) {
    yield put(getPermissionsFail(error))
  }
}


export default  function* permissionSaga() {  
  yield takeLatest(PermissionsTypes.GET_PERMISSIONS, fetch);
}

