import { get } from "src/helpers/api_helper";
import {BankOrCashTypes} from "./actionTypes"
import { Dispatch } from "react";

export const getbankorcash = () => ({
  type: BankOrCashTypes.GET_BANK_OR_CASH,
})

export const getbankorcashSuccess = (bankorcash : any) => ({
  type: BankOrCashTypes.GET_BANK_OR_CASH_SUCCESS,
  payload: bankorcash,
})

export const getbankorcashFail = (error : any) => ({
  type: BankOrCashTypes.GET_BANK_OR_CASH_FAIL,
  payload: error,
})

export const getbankorcashs = () => get(
  process.env.REACT_APP_API_URL + `/api/all_bank_or_cash`
);

export const setbankorcashSuccess = async (dispatch: Dispatch<any>) => {  
  let bankorcash = await getbankorcashs();
  dispatch(getbankorcashSuccess(bankorcash));  
}



