import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { useHistory } from "react-router";

const ExpItemCreate = () => {
    const [id, setId] = useState("0");
    const [categories, setCategories] = useState([]);
    const history = useHistory();
    const handleValidSubmit = (event: any, values: any) => {
        values["id"] = id;

        const resp = post(process.env.REACT_APP_API_URL + '/api/exp_item_save', values);
        history.push("/exp_items");
    }
    const resetForm = () => {
        history.push("/exp_items");
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resC: any = await get(process.env.REACT_APP_API_URL + '/api/get_exp_categories');
                setCategories(resC.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Items</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Create Item" breadcrumbItem="Create Item" />

                    <Row>
                        <Col className="col-12">
                            <Card>

                                <CardHeader></CardHeader>



                                <CardBody>

                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-firstname-input">
                                                        Item name
                                                    </label>
                                                    <AvField
                                                        name="item_name"
                                                        type="text"
                                                        placeholder="Enter Item name"
                                                        errorMessage="Enter Item name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Category
                                                    </label>
                                                    <AvField type="select" name="category_id" errorMessage="Choose Category"  validate={{ required: { value: true } }} >
                                                        <option value="" key="" >--Select--</option>
                                                        {categories.map((category: { id: string; category_name: string; }) => (
                                                            <option value={category.id} key={category.id} >{category.category_name}</option>
                                                        ))}
                                                    </AvField>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                    Description
                                                    </label>
                                                    <AvField type="text" name="description" value="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" className="bmt-btn-submit closesubmit">
                                                Submit
                                            </Button>{" "}
                                            <Button type="reset" className="bmt-btn-close closesubmit" onClick={resetForm}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div></div>
        </React.Fragment>
    );
};

export default ExpItemCreate;