import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { get, post } from "src/helpers/api_helper";
import { useSelector } from "react-redux";

interface VendorProps {
  modal_is_open: boolean;
  openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: Function;
  id: string;
}
const VendorModal = (props: VendorProps) => {
  const CountryInd = 99;
  const [home_country] = useState(CountryInd);
  const [vendorName, setVendorName] = useState("");
  const [gstin, setGstin] = useState("");
  const [pan, setPan] = useState("");
  const [tan, setTan] = useState("");
  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingTownOrCity, setBillingTownOrCity] = useState("");
  const [billingCountry, setBillingCountry] = useState(CountryInd);
  const [billingState, setBillingState] = useState("");
  const [billingPincode, setBillingPincode] = useState("");
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingTownOrCity, setShippingTownOrCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState(CountryInd);
  const [shippingState, setShippingState] = useState("");
  const [shippingPincode, setShippingPincode] = useState("");
  const [supplyCountry, setSupplyCountry] = useState(CountryInd);
  const [supplyState, setSupplyState] = useState("");
  const [activeButton, setActiveButton] = useState("taxInfo");
  const [isIndianState, setIsIndianState] = useState(1);
  const [billingIsIndianState, setBillingIsIndianState] = useState(1);
  const [shipppingIsIndianState, setShipppingIsIndianState] = useState(1);
  const [isProgress, setIsProgress] = useState(0);
  const [placeSupply, setPlaceSupply] = useState(1);
  const [isSameBillAddress, setIsSameBillAddress] = useState(true);
  const [supplyCountryValidate, setSupplyCountryValidate] = useState(false);
  const [supplyStateValidate, setSupplyStateValidate] = useState(true);
  const { countries, states } = useSelector((state: any) => ({
    states: state.states.states,
    countries: state.countries.countries,
  }));
  const [countries_supply, setCountriesSupply] = useState<any[]>(countries);
  const onChangeHandlerSupply = (e: any) => {
    let value: number = e.target.value;
    console.log(value);
    if (value == 1) {
      let countries_supply = countries.filter(
        (country: any) => country.id == home_country
      );
      setCountriesSupply(countries_supply);
      setSupplyCountry(home_country);
      setSupplyState("");
      setSupplyStateValidate(true);
      setIsIndianState(1);
      setBillingIsIndianState(1);
      setSupplyCountryValidate(false);
      setBillingCountry(home_country);
      setBillingState("");
      if (isSameBillAddress) {
        setShipppingIsIndianState(1);
        setShippingCountry(home_country);
        setShippingState("");
      }
    } else {
      let countries_supply = countries.filter(
        (country: any) => country.id != home_country
      );
      setCountriesSupply(countries_supply);
      setSupplyStateValidate(false);
      setIsIndianState(0);
      setBillingIsIndianState(0);
      setSupplyCountry(0);
      setSupplyState("");
      setSupplyCountryValidate(true);
      setBillingCountry(0);
      setBillingState("");
      if (isSameBillAddress) {
        setShipppingIsIndianState(0);
        setShippingCountry(0);
        setShippingState("");
      }
    }
  };
  const copyBillAddress = async (e: any) => {
    if (e.target.checked) {
      setIsSameBillAddress(true);
      setShippingAddress1(billingAddress1);
      setShippingAddress2(billingAddress2);
      setShippingTownOrCity(billingTownOrCity);
      setShippingCountry(billingCountry);
      setShippingState(billingState);
      setShippingPincode(billingPincode);
    } else {
      setIsSameBillAddress(false);
      setShippingAddress1("");
      setShippingAddress2("");
      setShippingTownOrCity("");
      setShippingCountry(0);
      setShippingState("");
      setShippingPincode("");
    }
  };
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };
  const onChangeBillingCountryhandler = (e: any) => {
    let value = e.target.value;
    setBillingCountry(value);
    if (value == home_country) {
      setBillingIsIndianState(1);
      setBillingState(supplyState);
    } else {
      setBillingIsIndianState(0);
      setBillingState("");
    }
    if (isSameBillAddress) {
      setShippingCountry(value);
      if (value == home_country) {
        setShipppingIsIndianState(1);
        setShippingState(supplyState);
      } else {
        setShipppingIsIndianState(0);
        setShippingState("");
      }
    }
  };
  const onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "supply_state") {
      setSupplyState(value);
      setBillingState(value);
      if (isSameBillAddress) {
        setShippingState(value);
      }
    }
    if (name == "supply_country") {
      setSupplyCountry(value);
      setBillingCountry(value);
      if (isSameBillAddress) {
        setShippingCountry(value);
        setShippingState(billingState);
      }
    }
  };
  const handleValidSubmit = async (event: any, values: any) => {
    setIsProgress(1);
    values["id"] = props.id;
    values["is_indian_state"] = isIndianState;
    values["billing_is_indian_state"] = billingIsIndianState;
    values["shipping_is_indian_state"] = shipppingIsIndianState;
    values["billing_address1"] = billingAddress1;
    values["billing_address2"] = billingAddress2;
    values["billing_pincode"] = billingPincode;
    values["billing_country"] = billingCountry;
    values["billing_state"] = billingState;
    values["billing_town_or_city"] = billingTownOrCity;
    values["gstin"] = gstin;
    values["pan"] = pan;
    values["tan"] = tan;
    if (isSameBillAddress) {
      values["shipping_address1"] = billingAddress1;
      values["shipping_address2"] = billingAddress2;
      values["shipping_pincode"] = billingPincode;
      values["shipping_country"] = billingCountry;
      values["shipping_state"] = billingState;
      values["shipping_town_or_city"] = billingTownOrCity;
    } else {
      values["shipping_address1"] = shippingAddress1;
      values["shipping_address2"] = shippingAddress2;
      values["shipping_pincode"] = shippingPincode;
      values["shipping_country"] = shippingCountry;
      values["shipping_state"] = shippingState;
      values["shipping_town_or_city"] = shippingTownOrCity;
    }
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/vendor_save",
      values
    );
    if (resp.success) {
      props.closeModal();
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url =
          process.env.REACT_APP_API_URL + "/api/vendor_get/" + props.id;
        const resI = await get(url);
        const data = resI.data;
        setVendorName(data.vendor_name);
        setGstin(data.gstin);
        setPan(data.pan);
        setTan(data.tan);
        setBillingAddress1(data.billing_address1);
        setBillingAddress2(data.billing_address2);
        setBillingTownOrCity(data.billing_town_or_city);
        setBillingCountry(data.billing_country);
        setBillingState(data.billing_state);
        setBillingPincode(data.billing_pincode);
        setShippingAddress1(data.shipping_address1);
        setShippingAddress2(data.shipping_address2);
        setShippingTownOrCity(data.shipping_town_or_city);
        setShippingCountry(data.shipping_country);
        setShippingState(data.shipping_state);
        setShippingPincode(data.shipping_pincode);
        setIsIndianState(data.is_indian_state);
        setSupplyCountry(data.supply_country);
        setSupplyState(data.supply_state);
        setPlaceSupply(data.place_supply);
        if (
          data.billing_address1 == data.shipping_address1 &&
          data.billing_address2 == data.shipping_address2 &&
          data.billing_town_or_city == data.shipping_town_or_city &&
          data.billing_state == data.shipping_state &&
          data.billing_pincode == data.shipping_pincode &&
          data.billing_country == data.shipping_country
        ) {
          setIsSameBillAddress(true);
        } else {
          setIsSameBillAddress(false);
        }

        if (placeSupply == 1) {
          let countries_supply = countries.filter(
            (country: any) => country.id == home_country
          );
          setCountriesSupply(countries_supply);
          setSupplyStateValidate(true);
          setSupplyCountryValidate(false);
        } else {
          let countries_supply = countries.filter(
            (country: any) => country.id != home_country
          );
          setCountriesSupply(countries_supply);
          setSupplyStateValidate(false);
          setSupplyCountryValidate(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (props.id != "0") {
      fetchData();
    }
  }, [props.id]);
  useEffect(() => {
    if (isSameBillAddress) {
      setShippingAddress1(billingAddress1);
      setShippingAddress2(billingAddress2);
      setShippingTownOrCity(billingTownOrCity);
      setShippingCountry(billingCountry);
      setShippingState(billingState);
      setShippingPincode(billingPincode);
    }
  }, [
    billingAddress1,
    billingAddress2,
    billingTownOrCity,
    billingCountry,
    billingState,
    billingPincode,
    isSameBillAddress,
  ]);
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className="md-700"
      >
        <AvForm onValidSubmit={handleValidSubmit}>
          <div className="modal-body">
            <div className="item_form pt-0 pb-1" style={{ borderLeft: "none" }}>
              <div className="row green_form pt-0 pb-1">
                <div className="heading">Create Vendor</div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-firstname-input"
                  >
                    Vendor name
                  </label>
                  <div className="col-sm-8">
                    <AvField
                      name="vendor_name"
                      type="text"
                      placeholder="Enter Vendor name"
                      errorMessage="Enter Vendor name"
                      validate={{ required: { value: true } }}
                      value={vendorName || ""}
                      onChange={(e: any) => setVendorName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <label
                  htmlFor="progresspill-firstname-input"
                  className="col-sm-4 col-form-label"
                >
                  Source of supply
                </label>
                <Col md={6} lg={6} className=" hstack gap-1">
                  <AvRadioGroup
                    inline
                    name="place_supply"
                    required
                    value={placeSupply || ""}
                    onChange={onChangeHandlerSupply}
                  >
                    <AvRadio label="Domestic" value={1} />
                    <AvRadio label="Import" value={2} />
                  </AvRadioGroup>
                </Col>
              </div>
              {supplyCountryValidate && (
                <div>
                  <Row>
                    <>
                      <label
                        htmlFor="progresspill-firstname-input"
                        className="col-sm-4 col-form-label"
                      >
                        Source of supply
                      </label>
                      <Col className="sm-8">
                        <AvField
                          type="select"
                          name="supply_country"
                          value={supplyCountry || ""}
                          validate={{
                            required: {
                              value: supplyCountryValidate,
                            },
                          }}
                          onChange={onChangehandler}
                          onKeyUp={onChangehandler}
                        >
                          <option key={-1} value={""}>
                            --Select--
                          </option>
                          {countries_supply.map(
                            (country: { id: string; name: string }) => (
                              <option value={country.id} key={country.id}>
                                {country.name}
                              </option>
                            )
                          )}
                        </AvField>
                      </Col>
                    </>
                  </Row>
                </div>
              )}
              {supplyStateValidate && (
                <div>
                  <Row>
                    <>
                      <div className="col-sm-4">
                        <label
                          htmlFor="progresspill-firstname-input"
                          className="col-sm-4 col-form-label"
                        >
                          Bill From
                        </label>
                      </div>
                      <Col className="sm-8">
                        <AvField
                          type="select"
                          name="supply_state"
                          value={supplyState || ""}
                          validate={{
                            required: {
                              value: supplyStateValidate,
                            },
                          }}
                          onChange={onChangehandler}
                          onKeyUp={onChangehandler}
                        >
                          <option key={-1} value={""}>
                            --Select--
                          </option>
                          {states.map(
                            (state: { id: string; state: string }) => (
                              <option value={state.id} key={state.id}>
                                {state.state}
                              </option>
                            )
                          )}
                        </AvField>
                      </Col>
                    </>
                  </Row>
                </div>
              )}
              <div className="row tab-head cstm-tab">
                <Col>
                  <Button
                    style={{
                      width: "33.333%",
                      border: "1px solid #CFCFCF",
                      borderRadius: "6px 6px 0 0",
                      boxShadow: "none",
                      backgroundColor:
                        activeButton === "taxInfo" ? "#10a37f" : "#F2F2F2",
                      color: activeButton === "taxInfo" ? "#F2F2F2" : "#000",
                    }}
                    onClick={() => handleButtonClick("taxInfo")}
                  >
                    Tax Info
                  </Button>
                  <Button
                    style={{
                      width: "33.333%",
                      border: "1px solid #CFCFCF",
                      borderRadius: "6px 6px 0 0",
                      boxShadow: "none",
                      backgroundColor:
                        activeButton === "billingAddress"
                          ? "#10a37f"
                          : "#F2F2F2",
                      color:
                        activeButton === "billingAddress" ? "#F2F2F2" : "#000",
                    }}
                    onClick={() => handleButtonClick("billingAddress")}
                  >
                    Billing Address
                  </Button>
                  <Button
                    style={{
                      width: "33.333%",
                      border: "1px solid #CFCFCF",
                      borderRadius: "6px 6px 0 0",
                      boxShadow: "none",
                      backgroundColor:
                        activeButton === "shippingAddress"
                          ? "#10a37f"
                          : "#F2F2F2",
                      color:
                        activeButton === "shippingAddress" ? "#F2F2F2" : "#000",
                    }}
                    onClick={() => handleButtonClick("shippingAddress")}
                  >
                    Shipping Address
                  </Button>
                </Col>
              </div>
              {activeButton == "taxInfo" && (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        GSTIN
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          name="gstin"
                          type="text"
                          placeholder="Enter GSTIN"
                          value={gstin || ""}
                          onChange={(e: any) => setGstin(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        PAN
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          name="pan"
                          type="text"
                          placeholder="Enter PAN"
                          value={pan || ""}
                          onChange={(e: any) => setPan(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        TAN
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          name="tan"
                          type="text"
                          placeholder="Enter TAN"
                          value={tan || ""}
                          onChange={(e: any) => setTan(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeButton == "billingAddress" && (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Flat/House/Building
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="billing_address1"
                          value={billingAddress1 || ""}
                          onChange={(e: any) =>
                            setBillingAddress1(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Flat/House/Building
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="billing_address2"
                          value={billingAddress2 || ""}
                          onChange={(e: any) =>
                            setBillingAddress2(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Town/City
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="billing_town_or_city"
                          value={billingTownOrCity || ""}
                          onChange={(e: any) =>
                            setBillingTownOrCity(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Country
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="select"
                          name="billing_country"
                          value={billingCountry || ""}
                          onChange={(e: any) =>
                            onChangeBillingCountryhandler(e)
                          }
                        >
                          <option key={-1} value={""}>
                            --Select--
                          </option>
                          {countries.map(
                            (country: { id: string; name: string }) => (
                              <option value={country.id} key={country.id}>
                                {country.name}
                              </option>
                            )
                          )}
                        </AvField>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        State / Province
                      </label>
                      {billingIsIndianState ? (
                        <div className="col-sm-8">
                          <AvField
                            type="select"
                            name="state"
                            value={billingState || ""}
                            onChange={(e: any) =>
                              setBillingState(e.target.value)
                            }
                            disabled={true}
                          >
                            <option key={-1} value={""}>
                              --Select--
                            </option>
                            {states.map(
                              (state: { id: string; state: string }) => (
                                <option value={state.id} key={state.id}>
                                  {state.state}
                                </option>
                              )
                            )}
                          </AvField>
                        </div>
                      ) : (
                        <div className="col-sm-8">
                          <AvField
                            name="state"
                            type="text"
                            placeholder="Enter state or province"
                            value={billingState || ""}
                            onChange={(e: any) =>
                              setBillingState(e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Pincode
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="billing_pincode"
                          value={billingPincode || ""}
                          onChange={(e: any) =>
                            setBillingPincode(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeButton == "shippingAddress" && (
                <>
                  <div className="row">
                    <h5 className="mb-3">
                      Shiping Address
                      <span className="font-size-14">
                        {" "}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck1"
                          onChange={copyBillAddress}
                          checked={isSameBillAddress}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="formCheck1"
                        >
                          {" (Same as billing address) "}
                        </label>
                      </span>
                    </h5>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Flat/House/Building
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="shipping_address1"
                          disabled={isSameBillAddress}
                          value={shippingAddress1 || ""}
                          onChange={(e: any) =>
                            setShippingAddress1(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Flat/House/Building
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="shipping_address2"
                          disabled={isSameBillAddress}
                          value={shippingAddress2 || ""}
                          onChange={(e: any) =>
                            setShippingAddress2(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Town/City
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="shipping_town_or_city"
                          disabled={isSameBillAddress}
                          value={shippingTownOrCity || ""}
                          onChange={(e: any) =>
                            setShippingTownOrCity(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Country
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="select"
                          name="shipping_country"
                          disabled={isSameBillAddress}
                          value={shippingCountry || ""}
                          onChange={(e: any) =>
                            setShippingCountry(e.target.value)
                          }
                        >
                          <option key={-1} value={""}>
                            --Select--
                          </option>
                          {countries.map(
                            (country: { id: string; name: string }) => (
                              <option value={country.id} key={country.id}>
                                {country.name}
                              </option>
                            )
                          )}
                        </AvField>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        State / Province
                      </label>
                      {shipppingIsIndianState ? (
                        <div className="col-sm-8">
                          <AvField
                            type="select"
                            disabled={isSameBillAddress}
                            name="state"
                            value={shippingState || ""}
                            onChange={(e: any) =>
                              setShippingState(e.target.value)
                            }
                          >
                            <option key={-1} value={""}>
                              --Select--
                            </option>
                            {states.map(
                              (state: { id: string; state: string }) => (
                                <option value={state.id} key={state.id}>
                                  {state.state}
                                </option>
                              )
                            )}
                          </AvField>
                        </div>
                      ) : (
                        <div className="col-sm-8">
                          <AvField
                            name="state"
                            type="text"
                            disabled={isSameBillAddress}
                            placeholder="Enter state or province"
                            value={shippingState || ""}
                            onChange={(e: any) =>
                              setShippingState(e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <label
                        htmlFor="progresspill-address-input"
                        className="col-sm-4 col-form-label"
                      >
                        Pincode
                      </label>
                      <div className="col-sm-8">
                        <AvField
                          type="text"
                          name="shipping_pincode"
                          disabled={isSameBillAddress}
                          value={shippingPincode || ""}
                          onChange={(e: any) =>
                            setShippingPincode(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer justify-content-center pb-0">
              {isProgress ? (
                <>
                  <Button
                    type="submit"
                    className="bmt-btn-submit"
                    disabled={true}
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    Submitting
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => props.closeModal()}
                    className="bmt-btn-close itemsubmit"
                    data-dismiss="modal"
                  >
                    Close
                  </Button>{" "}
                  <Button type="submit" className="bmt-btn-submit itemsubmit">
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default VendorModal;
