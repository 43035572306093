import React, { useCallback, useState } from "react";
import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import EstimationList from "./EstimationList";

const Estimations = () => {  

  return (
    <React.Fragment>
          <EstimationList />           
    </React.Fragment>
  );
};

export default Estimations; 
