import React, { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import { post, get } from "src/helpers/api_helper";
import { getExpItems } from "src/store/exp_items/actions";
import Select from "react-select";

interface ItemProps {
  modal_is_open: boolean;
  openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  closeModal: Function;
  id: string;
  item_name?: string;
}

const ExpItemModal = (props: ItemProps) => {
  const dispatch = useDispatch();
  const [isProgress, setIsProgress] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [item, setItem] = useState<{
    item_name: string | undefined;
    category_id: string | null;
    description: string | null;
  }>({
    item_name: props.item_name,
    category_id: null,
    description: null,
  });

  const handleValidSubmit = async (event: any, values: any) => {
    setIsProgress(true);
    values["id"] = props.id;
    values["category_id"] = item.category_id; // Set the category_id from the item state
  
    await post(process.env.REACT_APP_API_URL + "/api/exp_item_save", values);
    dispatch(getExpItems());
    props.closeModal();
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resCs: any = await get(
          process.env.REACT_APP_API_URL + "/api/get_exp_categories"
        );
        setCategories(resCs.data);

        if (props.id !== "0") {
          const resI: any = await get(
            process.env.REACT_APP_API_URL + "/api/exp_item_get/" + props.id
          );
          setItem(resI.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.id]);

  const handleCategoryChange = (selectedOption: any) => {
    setItem((prevItem) => ({
      ...prevItem,
      category_id: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setItem((prevItem) => ({
      ...prevItem,
      description: event.target.value,
    }));
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.openModal}
        className="md-700"
      >
        <AvForm onValidSubmit={handleValidSubmit}>
          <div className="modal-body">
            <div className="item_form pt-0 pb-0" style={{ borderLeft: "none" }}>
              <div className="row green_form pt-0 pb-1">
                <div className="heading">
                  {props.id === "0" ? "Create Item" : "Modify Item"}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-firstname-input"
                  >
                    Item name
                  </label>
                  <div className="col-8">
                    <AvField
                      name="item_name"
                      type="text"
                      placeholder="Enter Item name"
                      errorMessage="Enter Item name"
                      validate={{ required: { value: true } }}
                      value={item.item_name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Category
                  </label>
                  <div className="col-8">
                    <Select
                    styles={{
                      option: (provided: any, state: any) => ({
                        ...provided,
                        ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                        backgroundColor: state.isSelected
                          ? "#10a37f"
                          : "inherit",
                      }),
                    }}
                      options={categories.map((category: any) => ({
                        value: category.id,
                        label: category.category_name,
                      }))}
                      value={
                        item.category_id
                          ? {
                              value: item.category_id,
                              label: categories.find(
                                (category) => category.id === item.category_id
                              )?.category_name,
                            }
                          : null
                      }
                      onChange={handleCategoryChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mb-3">
                  <label
                    style={{ color: "#000" }}
                    className="col-sm-4 col-form-label"
                    htmlFor="progresspill-address-input"
                  >
                    Description
                  </label>
                  <div className="col-8">
                    <AvField
                      type="text"
                      name="description"
                      value={item.description || ""}
                      onChange={handleDescriptionChange}
                    />
                  </div>
                </div>
              </div>
              {/* Add more rows for other form fields here */}
            </div>
            <div className="modal-footer justify-content-center p-0">
              {isProgress ? (
                <Button
                  type="button"
                  className="bmt-btn-submit"
                  disabled={true}
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  Submitting
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => props.closeModal()}
                    className="bmt-btn-close itemsubmit"
                    data-dismiss="modal"
                  >
                    Close
                  </Button>{" "}
                  <Button
                    type="submit"
                    className="bmt-btn-submit itemsubmit"
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default ExpItemModal;
