import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logo from "../../assets/images/logo_login.png";

//Import config
// import CarouselPage from "./CarouselPage";
import CarouselPageNew from "./CarouselPageNew";
import { loginUserOtp } from "src/store/actions";
import { authentication } from "src/helpers/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import axios from 'axios';

const checkFb = ({ history }) => {
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [otp, setotp] = useState('');
  const [isVerification, SetIsVerification] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgress, setIsProgress] = useState(0);
  const dispatch = useDispatch();

  const appName = process.env.REACT_APP_NAME;

  const { error } = useSelector((state) => ({
    error: state.login.error,
  }));

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    document.body.style.backgroundColor = "#d2dadfb8";
  }, [])

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.

      }
    }, authentication);
  }
  const resendOTP = () => {
    if (phoneNumber.length >= 12) {
      SetIsVerification(true);
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then(confirmationResult => {
          window.confirmationResult = confirmationResult;
          setIsProgress(true);
          setErrorMessage('');
          setSuccessMessage('OPT sent your phone');
        }).catch((error) => {
          setSuccessMessage('');
          setErrorMessage('OTP sending failed, try again');
          SetIsVerification(false);
          console.log('opt sending..', error);
        });
    }
  }
  const requestOTP = async () => {
    if (isVerification) {
      // verifyOTP();
    } else {
      if (phoneNumber.length >= 12) {
        /* const resp = await axios.get(process.env.REACT_APP_API_URL + '/api/check_mob_exists/' + phoneNumber);
        if (resp.data.data.exists) { */
          SetIsVerification(true);
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
            .then(confirmationResult => {
              window.confirmationResult = confirmationResult;
              setIsProgress(true);
              setErrorMessage('');
              setSuccessMessage('OPT sent your phone');
              console.log('opt success', confirmationResult);
            }).catch((error) => {
              setSuccessMessage('');
              setErrorMessage('OTP sending failed, try again');
              SetIsVerification(false);
              console.log('opt sending..', error);
            });
        /* } else {
          setSuccessMessage('');
          setErrorMessage('Your mobile is not registered with us');
          SetIsVerification(false);
        } */
      }
    }
  }

  const verifyOTP = async () => {
    let confirmationResult = window.confirmationResult;
    confirmationResult.confirm(otp).then((result) => {
      //user signed successfully
      setErrorMessage('');
      setSuccessMessage('OPT verified successfully');
      dispatch(loginUserOtp({ phone: phoneNumber }, history));
    }).catch((error) => {
      setSuccessMessage('');
      setErrorMessage('OTP verification failed, try again');
      SetIsVerification(false);
      console.log('opt verifying..', error);     
    });
  }

  return (
    <React.Fragment>

      <AvForm
        className="needs-validation custom-form mt-2 pt-2"
        onValidSubmit={(e, v) => {
          requestOTP();
        }}
      >

        {successMessage && (
          <Alert color="success">
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger">
            {errorMessage}
          </Alert>
        )}

        <div className="mb-3">
          <AvField
            name="phoneNumber"
            label="Phone Number"
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            placeholder="Phone Number"
          />
        </div>

        {isVerification && (
          <div className="mb-3">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <label className="form-label">Enter Your OTP</label>
              </div>
              <div className="flex-shrink-0">
                <div className="">
                  <Link
                    to="#"
                    onClick={(e) => { resendOTP() }}
                    className="text-muted"
                  >
                    Resend OTP?
                  </Link>
                </div>
              </div>
            </div>
            <AvField
              name="opt"
              // label="Enter Your OTP"
              type="text"
              value={otp}
              onChange={(e) => setotp(e.target.value)}
              required
              placeholder="Enter Your OTP"
            />
          </div>
        )}

        <div className="mb-3">

          <button
            className="btn blk-btn w-100 waves-effect waves-light"
            type="submit"
          >
            {isVerification ? 'Verify OTP' : 'Send OTP'}
          </button>

        </div>
      </AvForm>
      <div id="sign-in-button"></div>

    </React.Fragment >
  );
};

export default withRouter(checkFb);
