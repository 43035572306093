import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import { compact, isEmpty, map } from "lodash";
import { post, get } from "src/helpers/api_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//redux
import moment from "moment";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { stringify } from "querystring";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  base64ToUint8,
  convertNumberToWords,
  padLeadingZeros,
  pdfCell,
} from "src/helpers/common";
import logo from "../../assets/images/logo_login.png";
import { connect } from "react-redux";
import ReactPDF, { Image, PDFViewer } from "@react-pdf/renderer";
import HtmlPdf from "../Common/HtmlPdf";

import { renderToString } from "react-dom/server";
import TemplatesModal from "../Common/TemplatesModal";

interface Params {
  id: string;
}
interface ThisProps extends RouteComponentProps<Params> {
  companies: { orders: any; logoFile: string };
}
type State = {
  id: string;
  customer_name: string;
  billing_address1: string;
  billing_address2: string;
  billing_town_or_city: string;
  billing_state_name: string;
  billing_country_name: string;
  billing_pincode: string;
  shipping_address1: string;
  shipping_address2: string;
  shipping_town_or_city: string;
  shipping_state_name: string;
  shipping_country_name: string;
  shipping_pincode: string;
  invoice_date: string;
  due_date: string;
  invoice_details: Array<any>;
  sub_total: number;
  proforma_invoice_no: number;
  tax_amount: number;
  discount: number;
  gross_total: number;
  gross_total_base: number;
  proforma_invoice_number_prefix: string;
  tax_details: Array<any>;
  company_name: string;
  company_address1: string;
  company_address2: string;
  company_town_or_city: string;
  company_state_name: string;
  company_country_name: string;
  company_pincode: string;
  modal: boolean;
  editorState: EditorState;
  isSending: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  logo: string;
  seal: string;
  terms_and_conditions: string;
  footer_note: string;
  email: string;
  currency_symbol: string;
  exchange_rate: number;
  invoice_light_color: string;
  invoice_dark_color: string;
  permissions: Array<any>;
  shipping_address: string;
  loading: boolean;
  invoice_template: number;
  gst_number: string;
  company_telephone: string;
  display_template: boolean;
  bank_name: string;
  account_no: string;
  account_name: string;
  ifsc_code: string;
  branch: string;
  swift_code: string;
  iban: string;
  customer_currency: string;
  cust_gst_number: string;
  currency_name: string;
  currency_dtext: string;
  blobUrl: string;
  filename: string;
  modalInfo: boolean;
  reference1: string;
  reference2: string;
  reference3: string;
  reference4: string;
  to_addrress_type: string;
  rounded_amount: number;
  is_rounded: number;
  is_authorized: number;
  vendor_supplier_code: string;
  taxable_amount: number;
  shipping_company: string;
};

class ProformaDetails extends Component<ThisProps, State> {
  constructor(props: any) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      id: id,
      customer_name: "",
      billing_address1: "",
      billing_address2: "",
      billing_town_or_city: "",
      billing_state_name: "",
      billing_country_name: "",
      billing_pincode: "",
      shipping_address1: "",
      shipping_address2: "",
      shipping_town_or_city: "",
      shipping_state_name: "",
      shipping_country_name: "",
      shipping_pincode: "",
      invoice_date: "",
      due_date: "",
      invoice_details: [],
      sub_total: 0,
      proforma_invoice_no: 0,
      tax_amount: 0,
      discount: 0,
      gross_total: 0,
      gross_total_base: 0,
      proforma_invoice_number_prefix: "",
      tax_details: [],
      company_name: "",
      company_address1: "",
      company_address2: "",
      company_town_or_city: "",
      company_state_name: "",
      company_country_name: "",
      company_pincode: "",
      modal: false,
      editorState: EditorState.createEmpty(),
      isSending: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      logo: "",
      seal: "",
      terms_and_conditions: "",
      footer_note: "",
      email: "",
      currency_symbol: "₹",
      exchange_rate: 0,
      invoice_light_color: "",
      invoice_dark_color: "",
      permissions: [],
      loading: false,
      shipping_address: "",
      invoice_template: 1,
      gst_number: "",
      company_telephone: "",
      display_template: false,
      bank_name: "",
      account_no: "",
      account_name: "",
      ifsc_code: "",
      branch: "",
      swift_code: "",
      iban: "",
      customer_currency: "INR",
      cust_gst_number: "",
      currency_name: "",
      currency_dtext: "",
      blobUrl: "",
      filename: "",
      modalInfo: false,
      reference1: "",
      reference2: "",
      reference3: "",
      reference4: "",
      to_addrress_type: "",
      rounded_amount: 0,
      is_rounded: 0,
      is_authorized: 0,
      taxable_amount: 0,
      vendor_supplier_code: "",
      shipping_company: "",
    };
  }

  toggleInfo = () => {
    try {
      this.setState({ modalInfo: !this.state.modalInfo });
    } catch (err) {
      console.error(err);
    }
  };

  toggleTemplate = () => {
    try {
      // this.setState({ display_template: !this.state.display_template });
      this.setState({ modalInfo: !this.state.modalInfo });
    } catch (err) {
      console.error(err);
    }
  };

  changeTemplate = (tempid: any) => {
    try {
      this.sendChageTemplate(tempid);
    } catch (err) {
      console.error(err);
    }
  };

  sendChageTemplate = async (tempid: any) => {
    try {
      console.log(tempid);
      // let value = Number(e.target.value);
      // this.setState({ invoice_template: tempid });
      // this.setState({ display_template: !this.state.display_template });
      // const resp: any = await get(process.env.REACT_APP_API_URL + '/api/change_est_template/' + this.state.id + '/' + value);
      this.setState({ invoice_template: tempid }, async () => {
        const resp: any = await post(
          process.env.REACT_APP_API_URL + "/api/switch_pro_template",
          this.state
        );
        this.setState({ blobUrl: resp.file });
      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  async componentDidMount() {
    try {
      const respPer: any = await get(
        process.env.REACT_APP_API_URL + `/api/permissions/4`
      );
      this.setState({ permissions: respPer.data });

      const resp: any = await get(
        process.env.REACT_APP_API_URL + "/api/proforma_invoice_view/" + this.state.id
      );
      this.setState({ blobUrl: resp.file });
      this.setState({ filename: resp.filename });
      this.setState({ customer_name: resp.data.customer.customer_name });
      this.setState({ billing_address1: resp.data.customer.billing_address1 });
      this.setState({ billing_address2: resp.data.customer.billing_address2 });
      this.setState({
        billing_town_or_city: resp.data.customer.billing_town_or_city,
      });
      this.setState({
        billing_state_name: resp.data.customer.billing_state_name,
      });
      this.setState({
        billing_country_name: resp.data.customer.billing_country_name,
      });
      this.setState({ billing_pincode: resp.data.customer.billing_pincode });
      this.setState({
        shipping_address1: resp.data.customer.shipping_address1,
      });
      this.setState({
        shipping_address2: resp.data.customer.shipping_address2,
      });
      this.setState({
        shipping_town_or_city: resp.data.customer.shipping_town_or_city,
      });
      this.setState({
        shipping_state_name: resp.data.customer.shipping_state_name,
      });
      this.setState({
        shipping_country_name: resp.data.customer.shipping_country_name,
      });
      this.setState({ shipping_pincode: resp.data.customer.shipping_pincode });
      this.setState({ invoice_date: resp.data.hdr.invoice_date });
      this.setState({ due_date: resp.data.hdr.due_date });
      this.setState({ proforma_invoice_no: resp.data.hdr.proforma_invoice_no });
      this.setState({ sub_total: resp.data.hdr.net_total });
      this.setState({ tax_amount: resp.data.hdr.tax_amount });
      this.setState({ shipping_company: resp.data.hdr.shipping_company });
      this.setState({ reference1: resp.data.hdr.reference1 });
      this.setState({ taxable_amount: resp.data.hdr.taxable_amount });
      this.setState({ reference2: resp.data.hdr.reference2 });
      this.setState({ reference3: resp.data.hdr.reference3 });
      this.setState({ reference4: resp.data.hdr.reference4 });
      this.setState({ to_addrress_type: resp.data.hdr.to_addrress_type });
      this.setState({ rounded_amount: resp.data.hdr.rounded_amount });
      this.setState({ is_rounded: resp.data.hdr.is_rounded });
      this.setState({ is_authorized: resp.data.hdr.is_authorized });
      this.setState({ discount: resp.data.hdr.discount });
      this.setState({ gross_total: resp.data.hdr.gross_total });
      this.setState({ gross_total_base: resp.data.hdr.gross_total_base });
      this.setState({ exchange_rate: resp.data.hdr.exchange_rate });
      this.setState({ invoice_details: resp.data.dtls });
      this.setState({ proforma_invoice_number_prefix: resp.data.proforma_invoice_number_prefix });
      this.setState({ tax_details: resp.data.tax_dtls });
      this.setState({ company_name: resp.data.company.company_name });
      this.setState({ company_address1: resp.data.company.company_address1 });
      this.setState({ company_address2: resp.data.company.company_address2 });
      this.setState({
        company_town_or_city: resp.data.company.company_town_or_city,
      });
      this.setState({
        company_state_name: resp.data.company.company_state_name,
      });
      this.setState({
        company_country_name: resp.data.company.company_country_name,
      });
      this.setState({ company_pincode: resp.data.company.company_pincode });
      this.setState({ logo: resp.data.company.logo });
      this.setState({ seal: resp.data.company.seal });
      if (resp.data.hdr.terms_and_conditions !== null) {
        this.setState({
          terms_and_conditions: resp.data.hdr.terms_and_conditions,
        });
      } else {
        this.setState({
          terms_and_conditions: resp.data.company.est_terms_and_conditions,
        });
      }
      this.setState({ footer_note: resp.data.hdr.footer_note });
      this.setState({ email: resp.data.company.company_email });
      this.setState({ currency_symbol: resp.data.hdr.currency_symbol });
      this.setState({ currency_name: resp.data.hdr.currency_name });
      this.setState({ currency_dtext: resp.data.hdr.currency_dtext });
      this.setState({
        invoice_light_color: resp.data.company.invoice_light_color,
      });
      this.setState({
        invoice_dark_color: resp.data.company.invoice_dark_color,
      });
      this.setState({ invoice_template: resp.data.hdr.invoice_template_id });
      this.setState({ company_telephone: resp.data.company.company_telephone });
      this.setState({ gst_number: resp.data.company.gst_number });
      if (resp.data.hdr.shipping_address)
        this.setState({
          shipping_address: compact(
            resp.data.hdr.shipping_address.split("\n")
          ).join("\n"),
        });
      if (resp.data.bank) {
        this.setState({ bank_name: resp.data.bank.bank_name });
        this.setState({ account_no: resp.data.bank.account_no });
        this.setState({ account_name: resp.data.bank.account_name });
        this.setState({ ifsc_code: resp.data.bank.ifsc_code });
        this.setState({ branch: resp.data.bank.branch });
        this.setState({ swift_code: resp.data.bank.swift_code });
        this.setState({ iban: resp.data.bank.iban });
      }
      this.setState({ customer_currency: resp.data.hdr.customer_currency });
      this.setState({ cust_gst_number: resp.data.customer.gstin });
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  }

  pdf = async () => {
    const element: any = document.getElementById("printRef");
    const canvas = await html2canvas(element, {
      onclone: (document: any, element: HTMLElement) => {
        // element.style.display = "block";
        // document.getElementById("pdfTitle").innerHTML = "test";
      },
    });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF({ compress: true });
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    const pdfHeightAct = pdf.internal.pageSize.getHeight();
    const logoX = pdfWidth / 2 - 20 / 2;
    const logoY = pdfHeightAct - 25;
    const logoWidth = 20;
    const logoHeight = 10;

    const txtX = 35;
    const txtY = pdfHeightAct - 7;
    const txt1 = "India’s Most Simplest GST Invoicing Generator powered by ";
    const txt2 = "Makemyinvoice Private Limited";
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(10);

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    if (this.props.companies.orders[0].plan_id == 1) {
      pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
      pdf.link(logoX, logoY, logoWidth, logoHeight, {
        url: process.env.REACT_APP_URL,
      });
      pdf.text(txt1, txtX, txtY);
      pdf.setTextColor("#00b050");
      pdf.textWithLink(txt2, txtX + 100, txtY, {
        url: process.env.REACT_APP_URL,
      });
    }
    // pdf.addPage();
    // pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    // pdf.save('print.pdf');
    var blobPDF = new Blob([pdf.output("blob")], { type: "application/pdf" });
    var blobUrl = URL.createObjectURL(blobPDF);

    window.open(blobUrl);
  };

  pdfDownload = async () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = this.state.blobUrl;
    downloadLink.download = this.state.filename;
    downloadLink.click();
  };
  pdf_new = async () => {
    let darkColor = this.state.invoice_dark_color;
    let fontSize = 10;

    let html = renderToString(
      <html>
        <body>
          <style
            dangerouslySetInnerHTML={{
              __html: ` 
          body{font-family: 'Karla';background:#fff;color:#454543}   
          .logo { margin-top: 5px; }     
      .logo-wrap { } 
      .logo-wrap h4{
        font-size:${fontSize + 2}px;color: ${darkColor};  
        display: block;
        margin-left: auto;
        margin-right: 20px;
      }
      .logo-wrap .logo{margin-bottom: 20px;}
      .logo-wrap .logo img{
        border: 1px solid #CFCFCF;
        border-radius: 18px; 
        width: 103px; 
        height:60px; 
        display: block;
        margin-left: auto;
        margin-right: 20px;
      }
      .addres { margin-bottom:10px; }
      .addres h4{font-size:${
        fontSize + 6
      }px;color:${darkColor} ;margin-bottom: 10px;}
      .addres h5{font-size:${fontSize + 4}px;margin-bottom: 0;color:#000000}
      .addres h6{color:#000000;font-size:${
        fontSize + 4
      }px;margin-bottom:0!important}
      .addres span{display: block;font-size:${fontSize + 2}px}
      .addres span.sec2{margin-top: 23px;}

      .inv-det{border-top:1px solid ${darkColor};border-bottom:1px solid ${darkColor}}
      .inv-det h2{font-size:${fontSize}px;margin:5px 0;color:${darkColor}}

      .bill-ad{ margin-top:10px }
      .bill-ad h3{font-size:${fontSize}px;margin-bottom:20px;color:#000000;margin-bottom: 5px;}
      .bill-ad h4{font-size:${fontSize + 2}px;margin-bottom:0;color:#000000}
      .bill-ad p{font-size:${fontSize}px;color:#000000}

      .table2 thead{border-bottom: hidden; margin-top: 10px; border-top:1px solid ${darkColor};border-bottom:1px solid ${darkColor}}
      .table2 th{background: #ffffff!important;color:${darkColor};font-size:${fontSize}px;padding:5px 8px!important}
      .table2 td span{display: block;color: #000;font-size: ${fontSize}px;}
      .table2 td{color:#000000;border-color:#000000;padding:5px 8px!important}
      .table2 td label{color:#000000;font-size: ${fontSize}px;}
      .table tbody tr td{color:#000000; }
      .table tbody{margin-bottom:10px;}
      .border_bottom { border-bottom:1px solid #C7C7C7; } 

      .bankdet h4{font-size:${fontSize}px;color:#000;margin-bottom: 10px;}
      .bankdet{font-size:${fontSize}px;}

      .words{font-size:${
        fontSize + 2
      }px;color:${darkColor}; padding-top: 10px; padding-bottom: 10px; } 
      .words h4{font-size:${fontSize + 2}px;color:#000;margin-bottom: 5px;}

      .totals-value tr{margin-bottom:5px;margin-top:10px;}
      .totals-value td{padding:2px}
      .totals-value td span{font-size:${fontSize + 2}px;}
      .totals-value .total{background:none}
      .totals-value .total td div span{color: ${darkColor};font-size: ${
                fontSize + 2
              }px;}

      .total_span { font-size:${fontSize + 2}px !important; }
     
      .memo h2{font-size:${fontSize + 2}px;color:#000;margin-bottom: 10px;}
      .bold{font-weight:500;}
      .memo p{margin-bottom: 2px;font-size: ${fontSize}px;}

      .terms {text-align:left;padding:10px 0 0 0;margin-top:10px}
      .terms h4{font-size:${fontSize}px;color:#000;margin-bottom: 10px;}
      .terms p{font-size:${fontSize - 2}px;color:#000; }
      
      .terms_border{color:#000000; border-top:1px solid #C7C7C7;}
      .root { margin: 10px 20px; };        

      .th_width_rate { width: 50% !important; }
      .text-right { text-align: right; font-size:${
        fontSize + 2
      }px !important;  }      
      .bold{font-weight:bold;}     

      `,
            }}
          />
          <table className="root">
            <tr>
              <td>
                <div className="addres">
                  <h4>Proforma</h4>
                  <h5 style={{ textTransform: "uppercase" }}>
                    {this.state.company_name}
                  </h5>
                  <h6>GSTIN {this.state.gst_number}</h6>
                  <span className="sec1">
                    {this.state.company_address1}, {this.state.company_address2}
                    ,{this.state.company_town_or_city},<br />
                    {this.state.company_state_name} -{" "}
                    {this.state.company_pincode},
                    {this.state.company_country_name}
                  </span>
                  <span className="sec1 mb-2">
                    Mobile {this.state.company_telephone}
                  </span>
                </div>
              </td>
              <td>
                <div className="logo-wrap">
                  <h4>ORIGINAL FOR RECIPIENT</h4>
                  <div className="logo">
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}${this.state.logo}`}
                    />
                    {/* <img src={`${this.props.companies.logoFile}`} />                   */}
                  </div>
                </div>
              </td>
            </tr>
            <tr className="inv-det">
              <td>
                <h2>
                  Invoice No :{" "}
                  <span>
                    {this.state.proforma_invoice_number_prefix}
                    {this.state.proforma_invoice_no}
                  </span>
                </h2>
              </td>
              <td>
                <h2>
                  Invoice Date:{" "}
                  <span>
                    {moment(this.state.invoice_date).format("DD MMM YYYY")}
                  </span>
                </h2>
              </td>
              <td>
                <h2>
                  Due Date:{" "}
                  <span>
                    {moment(this.state.due_date).format("DD MMM YYYY")}
                  </span>
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                <div className="bill-ad">
                  <h3>Bill To :</h3>
                  <h4>{this.state.customer_name}</h4>
                  <p>
                    {this.state.billing_address1 &&
                      `${this.state.billing_address1},`}
                    {this.state.billing_address2 &&
                      `${this.state.billing_address2},`}
                    {this.state.billing_town_or_city && (
                      <>
                        {this.state.billing_town_or_city} <br />
                      </>
                    )}
                    {this.state.billing_state_name &&
                      `${this.state.billing_state_name} -`}{" "}
                    {this.state.shipping_pincode &&
                      `${this.state.shipping_pincode},`}
                    {this.state.billing_country_name &&
                      `${this.state.billing_country_name}`}{" "}
                    <br />
                    {this.state.cust_gst_number && (
                      <b>{"GSTIN " + this.state.cust_gst_number}</b>
                    )}
                  </p>
                </div>
              </td>
              <td>
                <div className="bill-ad">
                  <h3>Ship To :</h3>
                  <h4>{this.state.customer_name}</h4>
                  <p className="mb-2">
  {this.state.shipping_address.split("\n").map(function (item, index) {
    return (
      <React.Fragment key={index}>
        {item}
        {index === 2 ? <br /> : ","}
      </React.Fragment>
    );
  })}
</p>

                </div>
              </td>
            </tr>
            <tr>
              <td>
                <table className="table table2">
                  <thead>
                    <tr style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                      <th>
                        <div
                          style={{
                            position: "absolute",
                            width: "20px",
                            textAlign: "center",
                          }}
                        >
                          No
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "140px",
                            left: "20px",
                          }}
                        >
                          Items
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "50px",
                            left: "160px",
                          }}
                        >
                          SAC
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            left: "210px",
                          }}
                        >
                          Qty
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            left: "280px",
                          }}
                        >
                          Rate({this.state.customer_currency})
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            left: "350px",
                          }}
                        >
                          Discount
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            left: "420px",
                          }}
                        >
                          Tax
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            left: "490px",
                          }}
                        >
                          Amount({this.state.customer_currency})
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.invoice_details.map((dtls, index) => (
                      <tr
                        className="border_bottom"
                        key={index}
                        style={{
                          paddingTop: "5px",
                          paddingBottom:
                            "20px" /* , backgroundColor: 'green'  */,
                        }}
                      >
                        <td>
                          <div
                            style={{
                              position: "absolute",
                              width: "20px",
                              textAlign: "center",
                            }}
                          >
                            {index + 1}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "140px",
                              left: "20px",
                            }}
                          >
                            <span className="bold">{dtls.name}</span>
                            <label className="bold">
                              {dtls.product_description}
                            </label>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "50px",
                              left: "160px",
                            }}
                          >
                            {dtls.hsn_or_sac}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              left: "210px",
                            }}
                          >
                            <span style={{ fontWeight: 400 }}>{dtls.qty} </span>
                            <label style={{ fontWeight: 400 }}>
                              {dtls.unitcode && `(${dtls.unitcode})`}
                            </label>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              left: "280px",
                            }}
                          >
                            {/* this.state.currency_symbol */}
                            {dtls.rate}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              left: "350px",
                            }}
                          >
                            {/* this.state.currency_symbol */}
                            {dtls.discount_amt}
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              left: "420px" /* , backgroundColor: 'red' */,
                            }}
                          >
                            <span style={{ fontWeight: 400 }}>
                              {/* this.state.currency_symbol */}
                              {dtls.tax_amt}
                            </span>
                            <label style={{ fontWeight: 400 }}>
                              ({dtls.tax_name})
                            </label>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              left: "490px",
                            }}
                          >
                            {/* this.state.currency_symbol */}
                            {dtls.amount}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <div className="bankdet">
                  <h4>Bank Account Details </h4>
                  Bank Name: {this.state.bank_name}
                  <br />
                  A/C No: {this.state.account_no}
                  <br />
                  A/C Name: {this.state.account_name}
                  <br />
                  IFSC Code: {this.state.ifsc_code}
                </div>
                <div className="words">
                  <h4>Amount In Words</h4>
                  {convertNumberToWords(
                    this.state.gross_total,
                    this.state.currency_name,
                    this.state.currency_dtext
                  )}
                </div>
              </td>
              <td>
                <div>
                  <table>
                    <tbody className="totals-value">
                      <tr>
                        <td>
                          <div
                            style={{
                              marginBottom: "10px",
                              position: "absolute",
                              width: "100px",
                              left: "80px",
                              borderBottom: "1px solid #C7C7C7",
                            }}
                          >
                            <span className="total_span">
                              Total({this.state.customer_currency})
                            </span>
                          </div>
                          <div
                            className="text-right"
                            style={{
                              position: "absolute",
                              width: "100px",
                              left: "180px",
                              borderBottom: "1px solid #C7C7C7",
                            }}
                          >
                            <span className="total_span">
                              {/* this.state.currency_symbol */}
                              {this.state.gross_total}
                            </span>
                          </div>
                        </td>
                      </tr>
                      {this.state.exchange_rate > 0 ? (
                        <tr>
                          <td>
                            <div
                              style={{
                                color: darkColor,
                                marginBottom: "10px",
                                position: "absolute",
                                width: "100px",
                                left: "80px",
                                borderBottom: "1px solid #C7C7C7",
                              }}
                            >
                              <span className="total_span">
                                Amount Due (INR)
                              </span>
                            </div>
                            <div
                              className="text-right"
                              style={{
                                color: darkColor,
                                position: "absolute",
                                width: "100px",
                                left: "180px",
                                borderBottom: "1px solid #C7C7C7",
                              }}
                            >
                              <span className="total_span">
                                &#8377;{this.state.gross_total_base}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <div
                              style={{
                                color: darkColor,
                                marginBottom: "10px",
                                position: "absolute",
                                width: "100px",
                                left: "80px",
                                borderBottom: "1px solid #C7C7C7",
                              }}
                            >
                              <span className="total_span">
                                Amount Due (INR)
                              </span>
                            </div>
                            <div
                              className="text-right"
                              style={{
                                color: darkColor,
                                position: "absolute",
                                width: "100px",
                                left: "180px",
                                borderBottom: "1px solid #C7C7C7",
                              }}
                            >
                              <span className="total_span">
                                {/* this.state.currency_symbol */}
                                {this.state.gross_total}
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                      {this.state.tax_details.map((tax_detail, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  position: "absolute",
                                  width: "100px",
                                  left: "80px",
                                  borderBottom: "1px solid #C7C7C7",
                                }}
                              >
                                <span className="total_span">
                                  {tax_detail.label} {tax_detail.tax_rate}%
                                </span>
                              </div>
                              <div
                                className="text-right"
                                style={{
                                  position: "absolute",
                                  width: "100px",
                                  left: "180px",
                                  borderBottom: "1px solid #C7C7C7",
                                }}
                              >
                                <span className="total_span">
                                  {/* this.state.currency_symbol */}
                                  {tax_detail.tax_amount}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="memo">
                  {this.state.footer_note && (
                    <p>
                      <h2>Note/Memo</h2>{" "}
                      {this.state.footer_note
                        .split("\n")
                        .map(function (item, idx) {
                          return (
                            <>
                              {item}
                              <br />
                            </>
                          );
                        })}
                    </p>
                  )}
                </div>
              </td>
            </tr>
            <tr style={{ marginBottom: "10px" }}>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    width: "200px",
                    position: "absolute",
                    left: "350px",
                  }}
                >
                  <h2>Authorized Signature</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="terms terms_border">
                  <h4>Terms And Conditions</h4>
                  {this.state.terms_and_conditions && (
                    <p>
                      {this.state.terms_and_conditions
                        .split("\n")
                        .map(function (item, idx) {
                          return (
                            <>
                              {item}
                              <br />
                            </>
                          );
                        })}
                    </p>
                  )}
                </div>
              </td>
            </tr>
          </table>
        </body>
      </html>
    );

    const pdfBlob = await ReactPDF.pdf(<HtmlPdf html={html} />).toBlob();

    var blobPDF = new Blob([pdfBlob], { type: "application/pdf" });
    var blobUrl = URL.createObjectURL(blobPDF);

    window.open(blobUrl);
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };

  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/proforma_invoice_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };

  render() {
    var lightColor = this.state.invoice_light_color;
    var darkColor = this.state.invoice_dark_color;
    const invoice_template = this.state.invoice_template;
    let body;

    body = (
      <Col lg="12">
        <Card>
          <CardBody>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  body{font-family: 'Fira Sans', sans-serif;background:#F1F1F1;color:#000000}

                  .logo-wrap h4{font-size:26px;color: ${darkColor};font-weight:500;}
                  .logo-wrap .logo{margin-bottom: 20px;}
                  .logo-wrap .logo img{margin-bottom: 20px;border: 1px solid #CFCFCF;border-radius: 18px;}
                  .addres h4{font-size:32px;font-weight: 500;color:${darkColor} ;margin-bottom: 10px;}
                  .addres h5{font-size:28px;font-weight: 500;margin-bottom: 0;color:#000000}
                  .addres h6{color:#000000;font-size:28px;font-weight: 500;margin-bottom:0!important}
                  .addres span{display: block;font-size:20px}
                  .addres span.sec2{margin-top: 25px;}
                  
                  .blocks{margin:25px 0 0 0;padding: 0;}
                  .blocks li{list-style-type: none;display: inline-block;padding: 10px;background: #FBC587;text-align: center;min-width: 130px;float: left;}
                  .blocks li:nth-child(2){background: #F7921C;}
                  .blocks li label{display: block;}
                  .blocks li span{display: block;font-weight: 500;}
                  
                  .header{}
                  
                  .bill-ad{margin-top:20px}
                  .bill-ad h3{font-size:18px;font-weight: 400;margin-bottom:20px;color:#000000;margin-bottom: 5px;}
                  .bill-ad h4{font-size:26px;font-weight: 500;margin-bottom:0;color:#000000}
                  .bill-ad p{font-size:20px;color:#000000}
                  
                  table thead{border-bottom: hidden;}
                  table th{background: #ffffff!important;font-weight: 400!important;border-top:2px ${darkColor} solid;border-bottom:2px ${darkColor} solid!important;color:${darkColor};font-size:18px;padding:5px 8px!important}
                  table > thead{border-bottom: 1px ${darkColor} solid;}
                  table td span{display: block;font-weight: 500;color: #000;font-size: 16px;}
                  table td{color:#000000;border-color:#000000;padding:5px 8px!important}
                  table td label{color:#000000;font-size: 16px;}
                  .table table tbody tr td{padding: 25px 5px;color:#000000}
                  
                  .totals-value td{padding:2px}
                  .totals-value td span{font-size:22px;font-weight:400}
                  .totals-value .total{background:none}
                  .totals-value .total td div span{color: ${darkColor};font-size: 20px;font-weight: 500;}
                  
                  .memo h2{font-size:18px;font-weight:500;color:#000}
                  .bold{font-weight:500;}
                  .memo p{margin-bottom: 2px;font-size: 15px;}
                  
                  .btm-cont{border-bottom: 1px ${darkColor} solid;margin-bottom: 25px;font-size: 14px ;color:#707070;padding:8px 0 8px 0;}
                  .btm-cont span{display: inline-block;padding-left: 25px;}
                  
                  .bold{font-weight:700;}
                  .inv-det{border-top:2px solid ${darkColor};border-bottom:2px solid ${darkColor}}
                  .inv-det h2{font-size:22px;font-weight: 400;margin:8px 0;color:${darkColor}}
                  
                  .due-date{font-size: 16px;}
                  .due-date span{font-weight: 600;}                  
                  
                  .bankdet h4{font-size:17px;font-weight:500;color:#000}

                  .terms {text-align:left;border-top:#C7C7C7 1px solid;padding:15px 0 0 0;margin-top:15px}
                  .terms h4{font-size:18px;font-weight:500;color:#000}
                  .terms p{font-size:15px;color:#000}

                  .total_span { font-size:23px !important; }

                  .bankdet{font-size:17px;}  
                  
                  .words{font-size:21px;color:${darkColor} } 
                  .words h4{font-size:21px;font-weight:500;color:#000}

                  @media screen and (max-width:1199px) {
                      .blocks li{min-width: auto;}
                      .blocks li label{font-size: 14px;}
                      .blocks li span{font-size: 14px;}
                  }
                  @media screen and (max-width:767px) {
                      .blocks li{float: none;display: block;}
                  } `,
              }}
            />
            <div className="container mt-2">
              <div className="d-flex justify-content-center row">
                <div className="col-md-10 col-lg-12">
                  <div className="bg-white rounded">
                    <div className="p-2 pb-0 header">
                      <div className="row">
                        <div className="col-md-4 col-lg-6 addres">
                          <h4>Proforma</h4>
                          <h5 style={{ textTransform: "uppercase" }}>
                            {this.state.company_name}
                          </h5>
                          <h6>GSTIN {this.state.gst_number}</h6>
                          <span className="sec1">
                            {this.state.company_address1},{" "}
                            {this.state.company_address2},
                            {this.state.company_town_or_city}
                            ,<br />
                            {this.state.company_state_name} -{" "}
                            {this.state.company_pincode},
                            {this.state.company_country_name}
                          </span>
                          <span className="sec1 mb-2">
                            Mobile {this.state.company_telephone}
                          </span>
                        </div>
                        <div className="col-md-8 col-lg-6 text-md-end logo-wrap">
                          <h4>ORIGINAL FOR RECIPIENT</h4>
                          <div className="logo">
                            {this.state.loading ? (
                              <Skeleton width={50} height={24} />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}${this.state.logo}`}
                                width="163px"
                                height="110px"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 p-2 pb-0">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row inv-det">
                            <div className="col-md-4">
                              <h2>
                                Proforma No:{" "}
                                <span>
                                  {this.state.proforma_invoice_number_prefix}
                                  {this.state.proforma_invoice_no}
                                </span>
                              </h2>
                            </div>
                            <div className="col-md-4">
                              <h2>
                              Proforma Date:{" "}
                                <span>
                                  {moment(this.state.invoice_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </h2>
                            </div>
                            <div className="col-md-4">
                              <h2>
                                Due Date:{" "}
                                <span>
                                  {moment(this.state.due_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </h2>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="bill-ad">
                                <h3>Bill To :</h3>
                                <h4>{this.state.customer_name}</h4>
                                <p className="mb-2">
                                  {this.state.billing_address1 &&
                                    `${this.state.billing_address1},`}
                                  {this.state.billing_address2 &&
                                    `${this.state.billing_address2},`}
                                  {this.state.billing_town_or_city && (
                                    <>
                                      {this.state.billing_town_or_city} <br />
                                    </>
                                  )}
                                  {this.state.billing_state_name &&
                                    `${this.state.billing_state_name} -`}{" "}
                                  {this.state.shipping_pincode &&
                                    `${this.state.shipping_pincode},`}
                                  {this.state.billing_country_name &&
                                    `${this.state.billing_country_name}`}{" "}
                                  <br />
                                  {this.state.cust_gst_number && (
                                    <b>
                                      {"GSTIN " + this.state.cust_gst_number}
                                    </b>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="bill-ad">
                                <h3>Ship To :</h3>
                                <h4>{this.state.customer_name}</h4>
                                <p className="mb-2">
                                  {this.state.shipping_address
                                    .split("\n")
                                    .map(function (item, index) {
                                      return (
                                        <>
                                          {item}
                                          {index === 2 ? <br /> : ","}
                                        </>
                                      );
                                    })}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Items</th>
                              <th>SAC</th>
                              <th>Qty</th>
                              <th>Rate({this.state.customer_currency})</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Amount({this.state.customer_currency})</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.invoice_details.map((dtls, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td style={{ width: "40%" }}>
                                  <span>{dtls.name}</span>
                                  <label>{dtls.product_description}</label>
                                </td>
                                <td>{dtls.hsn_or_sac}</td>
                                <td>
                                  <span style={{ fontWeight: 400 }}>
                                    {dtls.qty}{" "}
                                  </span>
                                  <label style={{ fontWeight: 400 }}>
                                    {dtls.unitcode && `(${dtls.unitcode})`}
                                  </label>
                                </td>
                                <td>
                                  {this.state.currency_symbol}
                                  {dtls.rate}
                                </td>
                                <td>
                                  {this.state.currency_symbol}
                                  {dtls.discount_amt}
                                </td>
                                <td>
                                  <span style={{ fontWeight: 400 }}>
                                    {this.state.currency_symbol}
                                    {dtls.tax_amt}
                                  </span>
                                  <label style={{ fontWeight: 400 }}>
                                    ({dtls.tax_name})
                                  </label>
                                </td>
                                <td>
                                  {this.state.currency_symbol}
                                  {dtls.amount}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-8">
                          <div className="bankdet">
                            <h4>Bank Account Details </h4>
                            Bank Name: {this.state.bank_name}
                            <br />
                            A/C No: {this.state.account_no}
                            <br />
                            A/C Name: {this.state.account_name}
                            <br />
                            IFSC Code: {this.state.ifsc_code}
                          </div>
                          <div className="words my-2">
                            <h4>Amount In Words</h4>
                            {convertNumberToWords(
                              this.state.gross_total,
                              this.state.currency_name,
                              this.state.currency_dtext
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <table className="table table-borderless">
                            <tbody className="totals-value">
                              <tr>
                                <td>
                                  <div className="text-left">
                                    <span className="total_span">
                                      Total({this.state.customer_currency})
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="text-right ">
                                    <span className="total_span">
                                      {this.state.currency_symbol}
                                      {this.state.gross_total}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              {this.state.exchange_rate > 0 ? (
                                <tr>
                                  <td>
                                    <div className="text-left">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        Amount Due (INR)
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-right ">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        &#8377;{this.state.gross_total_base}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <div className="text-left">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        Amount Due (INR)
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-right ">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        {this.state.currency_symbol}
                                        {this.state.gross_total}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )}

                              {this.state.tax_details.map((tax_detail, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div className="text-left">
                                        <span>
                                          {tax_detail.label}{" "}
                                          {tax_detail.tax_rate}%
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-right">
                                        <span>
                                          {this.state.currency_symbol}
                                          {tax_detail.tax_amount}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="memo">
                            {this.state.footer_note && (
                              <p>
                                <h2>Note/Memo</h2>{" "}
                                {this.state.footer_note
                                  .split("\n")
                                  .map(function (item, idx) {
                                    return (
                                      <>
                                        {item}
                                        <br />
                                      </>
                                    );
                                  })}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="terms">
                            <h4>Terms And Conditions</h4>
                            {this.state.terms_and_conditions && (
                              <p>
                                {this.state.terms_and_conditions
                                  .split("\n")
                                  .map(function (item, idx) {
                                    return (
                                      <>
                                        {item}
                                        <br />
                                      </>
                                    );
                                  })}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>Proforma Detail | {process.env.REACT_APP_NAME}</MetaTags>
          <Container fluid>
            <div>
              <span style={{ float: "right" }}>
                {" "}
              </span>
              <span>
                <Breadcrumbs
                  title="Proforma"
                  breadcrumbItem="Proforma Detail"
                />
              </span>
            </div>
            <Row id="printRef">{body}</Row>
            {/* <Row >
              <PDFViewer className="min-vh-100" style={{ display: 'flex' }}>
                <HtmlPdf html={html} />
              </PDFViewer>
            </Row> */}
            {(this.state.permissions.find(
              ({ action, is_active }) => action == "download" && is_active == 1
            ) ||
              this.state.permissions.find(
                ({ action, is_active }) => action == "mail" && is_active == 1
              )) && (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="d-print-none mt-4">
                        <div className="col-md-2">
                          <AvForm>
                            {this.state.display_template ? (
                              <AvField
                                type="select"
                                name="invoice_template"
                                value={this.state.invoice_template}
                                onChange={this.changeTemplate}
                              >
                                <option value={1} key={1}>
                                  Template 1
                                </option>
                                <option value={2} key={2}>
                                  Template 2
                                </option>
                              </AvField>
                            ) : (
                              <Link
                                to="#"
                                onClick={this.toggleTemplate}
                                className="me-2"
                              >
                                Change Template
                              </Link>
                            )}
                          </AvForm>
                        </div>
                        <div className="float-end">
                          {this.state.loading == true ? (
                            <>
                              <Skeleton width={100} />
                            </>
                          ) : (
                            <>
                              {this.state.permissions.find(
                                ({ action, is_active }) =>
                                  action == "download" && is_active == 1
                              ) && (
                                <Link
                                  to="#"
                                  onClick={this.pdfDownload}
                                  className="btn btn-success me-2"
                                >
                                  <i className="fa fa-print" />
                                </Link>
                              )}
                              {this.state.permissions.find(
                                ({ action, is_active }) =>
                                  action == "mail" && is_active == 1
                              ) && (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    this.setState({ modal: !this.state.modal });
                                  }}
                                  className="bmt-btn-submit w-md "
                                  style={{ color: "white" }}
                                >
                                  Send
                                </Link>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={() => {
            this.setState({ modal: !this.state.modal });
          }}
        >
          <div className="modal-content">
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <ModalHeader
                toggle={() => {
                  this.setState({ modal: !this.state.modal });
                }}
              >
                Compose
              </ModalHeader>
              <ModalBody>
                <UncontrolledAlert
                  hidden={this.state.isHideSuccessMgs}
                  color="success"
                >
                  Mail send successfully
                </UncontrolledAlert>

                <UncontrolledAlert
                  hidden={this.state.isHideErrorMgs}
                  color="danger"
                >
                  Somthing went wrong, Please try again
                </UncontrolledAlert>

                <div className="mb-3">
                  <AvField
                    name="email"
                    type="email"
                    placeholder="To"
                    value={this.state.email}
                    validate={{ required: { value: true } }}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    value={""}
                    validate={{ required: { value: true } }}
                  />
                </div>
                <AvField
                  name="content"
                  tag={Editor}
                  placeholder="content"
                  value={""}
                  validate={{ required: { value: true } }}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  className="bmt-btn-close closesubmit"
                  onClick={() => {
                    this.setState({ modal: !this.state.modal });
                  }}
                >
                  Close
                </Button>
                {this.state.isSending ? (
                  <Button
                    type="submit"
                    className="bmt-btn-submit"
                    disabled={true}
                  >
                    Sending{" "}
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </Button>
                ) : (
                  <Button type="submit" className="bmt-btn-submit closesubmit">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                  </Button>
                )}
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
        <TemplatesModal
          tempid={this.state.invoice_template}
          isOpen={this.state.modalInfo}
          toggle={this.toggleInfo}
          switchTemplate={this.changeTemplate}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: { companies: any }) => {
  return {
    companies: state.companies.companies,
  };
};
export default connect(mapStateToProps)(withRouter(ProformaDetails));
