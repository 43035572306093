import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, CardLink, CardText, Modal } from "reactstrap";
import ShippingAddressSaveModal from "./ShippingAddressSaveModal";
import { get, post } from "src/helpers/api_helper";
import { useSelector } from "react-redux";
import ConfirmationModal from "src/components/ConfirmationModal";

function ShippngAddressListModal(props: any) {
  const [active_address_id, set_active_address_id] = useState("");
  const [isOpen, set_conform_modal] = useState(false);
  const [address, set_address] = useState<any>([]);
  const { states, countries } = useSelector((state: any) => ({
    states: state.states.states,
    countries: state.countries.countries,
  }));
  // Ref to track if the component is mounted
  const isMountedRef = useRef<boolean>(true);
  const getAddress = async () => {
    if (props.active_customer) {
      try {
        const gstResp = await get(
          process.env.REACT_APP_API_URL +
            "/api/shipping_address_list/" +
            props.active_customer
        );
        if (isMountedRef.current) {
          set_address(gstResp.data);
        }
      } catch (error) {
        console.error("Error fetching shipping addresses:", error);
      }
    }
  };
  const handleDeleteConfirm = async () => {
    const values = {
      id: active_address_id,
    };
    try {
      const resp = await post(
        process.env.REACT_APP_API_URL + "/api/shipping_address_delete",
        values
      );
      if (resp.success === true) {
        set_conform_modal(false);
        getAddress();
      }
    } catch (error) {
      console.error("Error deleting shipping address:", error);
    }
  };

  const create_address = () => {
    set_active_address_id("");
    props.saveOpen();
  };
  const saveClose = () => {
    getAddress();
    props.saveClose();
  };
  const edit_address = (addr_id: string) => {
    set_active_address_id(addr_id);
    props.saveOpen();
  };
  const delete_address = (addr_id: string) => {
    set_active_address_id(addr_id);
    set_conform_modal(true);
  };
  const handleDeleteClose = () => {
    set_active_address_id("");
    set_conform_modal(false);
  };
  const use_shipping_addres = (addr_id: string) => {
    props.parentCallback(addr_id);
  };
  useEffect(() => {
    isMountedRef.current = true;
    getAddress();

    return () => {
      isMountedRef.current = false;
    };
  }, [props.active_customer]);
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal_is_open}
        toggle={props.close}
        className="md-400 ShippingPopup modal-dialog-centered"
      >
        <div className="row d-flex align-items-center">
          <div className="col-md-12">
            <div className="item_form pt-0 pb-0">
              <div className="row mb-2">
                <div
                  className="heading"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Address List
                </div>
              </div>
              {address.map((addr: any) => {
                let shipping_country;
                let shipping_state;
                shipping_country = countries.find(
                  ({ id }) => id === addr.shipping_country
                );
                if (addr.shipping_country === "99") {
                  shipping_state = states.find(
                    ({ id }) => id === addr.shipping_state
                  ).state;
                } else {
                  shipping_state = addr.shipping_state;
                }

                return (
                  <div className="d-flex justify-content-center" key={addr.id}>
                    <div className="col-md-12">
                      <Card key={addr.id} className="mb-2">
                        <CardBody className="p-2">
                          <CardText className="mb-0">
                            {addr.customer_name} <br />
                            {addr.shipping_address1} {addr.shipping_address2}{" "}
                            {addr.shipping_town_or_city} <br />
                            {shipping_state} - {addr.shipping_pincode} <br />
                            {shipping_country.name}
                          </CardText>
                          <CardLink
                            href="#"
                            onClick={() => edit_address(addr.id)}
                          >
                            <i
                              className="fas fa-edit"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit"
                              style={{ color: "#28a745" }}
                            />{" "}
                            Edit
                          </CardLink>
                          <CardLink
                            href="#"
                            onClick={() => delete_address(addr.id)}
                          >
                            <i
                              className="fas fa-trash-alt"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete"
                              style={{ color: "#dc3545" }}
                            />{" "}
                            Delete
                          </CardLink>
                          <Button
                            className="btn-sm btn-select mx-2"
                            onClick={() => use_shipping_addres(addr.id)}
                          >
                            Select
                          </Button>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-center">
                <div className="col-md-12 text-center">
                  <Card className="mb-2">
                    <CardBody className="p-2">
                      <CardLink href="#" onClick={() => create_address()}>
                        <i
                          className="fas fa-plus-circle"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Shipping Details"
                          style={{ color: "#28a745" }}
                        />{" "}
                        Add Address
                      </CardLink>
                      <CardLink href="#" onClick={props.close}>
                        <i
                          className="fas fa-window-close"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Close"
                          style={{ color: "#dc3545" }}
                        />{" "}
                        Close
                      </CardLink>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={isOpen}
        message="Do you want to delete this record?"
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      />
      <ShippingAddressSaveModal
        modal_is_open={props.modal_is_save_open}
        closeModal={saveClose}
        active_address_id={active_address_id}
        active_customer={props.active_customer}
      />
    </React.Fragment>
  );
}

export default ShippngAddressListModal;
