import { call, put, takeEvery } from "redux-saga/effects"

import {
  getCustomersSuccess,
  getCustomersFail, 
} from "./actions"
import { get } from "src/helpers/api_helper"
import { CustomersTypes } from "./actionTypes"

export const getCustomers = () => get(process.env.REACT_APP_API_URL + '/api/customers')
interface apiRes {
  success : boolean;
  message : string;
  data : JSON;
}

function* fetchCustomers() {
  try {
    const response : apiRes = yield call(getCustomers);  
    if (response.success) {
      yield put(getCustomersSuccess(response))
    } 
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}


function* customerSaga() {  
  yield takeEvery(CustomersTypes.GET_CUSTOMERS, fetchCustomers);
}

export {fetchCustomers};
export default customerSaga
