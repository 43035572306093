import {CustomersTypes} from "./actionTypes"

const INIT_STATE = {
  customers: [],  
  error: {},
}

const Customers = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case CustomersTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.data,
      }
    case CustomersTypes.GET_CUSTOMERS:
      return {
        ...state,
        customers: state.customers,
      }
    case CustomersTypes.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default Customers
