import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container, Label, Table, UncontrolledAlert } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Alert,
  Modal,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import {
  ActionMeta,
  OnChangeValue,
  Options,
} from "react-select/dist/declarations/src";

import Moment from "moment";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isEmpty, isNull, round } from "lodash";
import ExpItemModal from "../ExpItems/ExpItemModal";
import { connect } from "react-redux";
import { number_format } from "src/helpers/common";
import DatePicker from "rsuite/esm/DatePicker";
import moment from "moment";

interface Params extends RouteComponentProps {
  exp_items: Array<any>;
  taxes: Array<any>;
  companies: Company;
  allvendors: Array<any>;
  items: Array<any>;
  tdshead?: any;
}
interface Option {
  label: string;
  value: string;
}
interface TbsHead {
  id: string;
  heads_name: string;
}
interface Company {
  is_indian_state: number;
  company_state: string;
  company_country: string;
  currency: string;
}
interface State {
  allvendors: Array<any>;
  vendors: Array<any>;
  tdshead: Array<any>;
  taxes: Array<any>;
  items: Array<any>;
  banks: Array<any>;
  dtls: Array<any>;
  invoice_no: string;
  invoice_date: Date | null;
  payment_date: string;
  heads_id: { label: string; value: string };
  vendor_id: { label: string; value: string };
  desciption: string;
  amount: number;
  isProgress: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  subTotal: number;
  tax: number;
  discount: number;
  modalExpItem: boolean;
  isTbsApplicable: boolean;
  tds_type: any;
  newItemName: string;
  valueItem: Option | null | undefined;
  tdsvalue: number;
  tdsbalance: number;
  taxbasevalue: number;
}

interface States extends State {
  id: string;
  taxes: Array<any>;
  tax_dtls: Array<any>;
  currency_rate: number;
  tax_amount: number;
  net_total: number;
  gross_total: number;
  gross_total_base: number;
  customer_currency: string;
  company_currency: string;
  company: Company;
  message: any;
  success: number;
}
const createOption = (label: string, value: string) => ({
  label,
  value,
});

class ExpenseBillCreate extends Component<Params, States> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: "0",
      net_total: 0,
      vendors: [],
      tdshead: [],
      allvendors: [],
      items: [],
      banks: [],
      dtls: [
        {
          hdr_id: "",
          item_id: { label: "", value: "" },
          item_description: "",
          qty: 0,
          rate: 0,
          discount: 0,
          tax: 0,
          tax_id: "",
          tax_per: 0,
          tax_amt: 0,
          amount: 0,
        },
      ],
      company: {
        is_indian_state: 1,
        company_state: "",
        company_country: "",
        currency: "INR",
      },
      gross_total: 0,
      gross_total_base: 0,
      tax_dtls: [],
      taxes: [],
      invoice_no: "",
      invoice_date: new Date(),
      payment_date: "",
      heads_id: { label: "", value: "" },
      vendor_id: { label: "", value: "" },
      desciption: "",
      amount: 0,
      isProgress: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      subTotal: 0,
      tax: 0,
      discount: 0,
      tax_amount: 0,
      currency_rate: 0.0,
      valueItem: undefined,
      modalExpItem: false,
      isTbsApplicable: false,
      newItemName: "",
      tds_type: "",
      customer_currency: "INR",
      company_currency: "INR",
      tdsvalue: 0,
      tdsbalance: 0,
      taxbasevalue: 0,
      message: "",
      success: 0,
    };
  }
  handleValidSubmit = async (event: any, values: any) => {
    values["dtls"] = this.state.dtls;
    values["vendor_id"] = this.state.vendor_id.value;
    values["heads_id"] = this.state.heads_id.value;
    values["amount"] = this.state.amount;
    values["tax_dtls"] = this.state.tax_dtls;
    values["invoice_date"] = this.state.invoice_date;
    values["net_total"] = this.state.net_total;
    values["discount"] = this.state.discount;
    values["tax_amount"] = this.state.tax_amount;
    values["gross_total"] = this.state.gross_total;
    values["tds_hdr_id"] = this.state.tds_type.value;
    values["tds_value"] = this.state.tdsvalue;
    if (this.state.isTbsApplicable == true) {
      values["is_tds_applicable"] = 2;
    } else if (this.state.isTbsApplicable == false) {
      values["is_tds_applicable"] = 1;
    }
    values["tds_balance"] = this.state.tdsbalance;
    values["taxbasevalue"] = this.state.taxbasevalue;
    values["id"] = 0;
    this.setState({ isProgress: true });
    const resp: any = await post(
      process.env.REACT_APP_API_URL + "/api/expense_bill_save",
      values
    );
    if (resp.success == true) {
      this.setState({
        isHideSuccessMgs: false,
      });
      this.props.history.push("/expense_bill");
    } else {
      this.setState({
        success: 2,
        message: resp.message,
        isHideErrorMgs: false,
      });
    }
    this.setState({ isProgress: false });
  };
  removeRow = (i: number) => {
    const dtls = this.state.dtls;
    dtls.splice(i, 1);
    this.setState({ dtls: dtls });
  };
  tdsdeducted = async (e: any) => {
    if (e.target.checked) {
      this.setState({
        isTbsApplicable: true,
      });
      this.calcTotal();
    } else {
      this.setState({
        isTbsApplicable: false,
        tds_type: [],
        tdsvalue: 0,
        tdsbalance: 0,
        taxbasevalue: 0,
      });
    }
  };
  handelTaxbasevalue = (e: any) => {
    this.setState({ taxbasevalue: e.target.value });
    let value = e.target.value;
    const selectedtdshead = this.props.tdshead.find(
      (tdshead: { id: any }) => tdshead.id == this.state.tds_type.value
    );
    if (selectedtdshead) {
      const heads_per = selectedtdshead.heads_per;
      const tdsvalue = (value * heads_per) / 100;
      this.setState({
        tdsvalue: tdsvalue,
        tdsbalance: this.state.gross_total - tdsvalue,
      });
    }
  };
  addRow = () => {
    let newRaw = {
      hdr_id: "",
      item_id: { label: "", value: "" },
      item_description: "",
      qty: 0,
      rate: 0,
      discount: 0,
      tax: 0,
      tax_per: 0,
      tax_id: "",
      amount: 0,
    };
    this.setState({ dtls: [...this.state.dtls, newRaw] });
  };
  resetForm = () => {
    this.props.history.push("/purchases");
  };
  componentDidUpdate(prevProps: Params) {
    if (prevProps.exp_items.length != this.props.exp_items.length) {
      this.genExpItemOptions();
    }
    if (prevProps.tdshead.length != this.props.tdshead.length) {
      this.gentdsOptions();
    }
    if (prevProps.taxes.length != this.props.taxes.length) {
      this.setState({ taxes: this.props.taxes });
    }
  }
  gentdsOptions = () => {
    let options: Option[] = [];
    this.props.tdshead.map((tdshead: { heads_name: string; id: string }) => {
      let newOption = createOption(tdshead.heads_name, tdshead.id);
      options.push(newOption);
    });
    this.setState({ tdshead: options });
  };
  genExpItemOptions = () => {
    let options: Option[] = [];
    this.props.exp_items
      ?.filter((item: { is_system: number }) => item.is_system === 0)
      .sort((a: { category_id: string }, b: { category_id: string }) => {
        const catA = parseInt(a.category_id, 10);
        const catB = parseInt(b.category_id, 10);
        if (catA === 3 && catB !== 3) return -1;
        if (catA !== 3 && catB === 3) return 1;
        return 0;
      })
      .map((item: { item_name: string; category_name: string; id: string }) => {
        let newOption = createOption(
          item.item_name + " (" + item.category_name + ")",
          item.id
        );
        options.push(newOption);
      });
    this.setState({ items: options });
  };
  componentDidMount() {
    const fetchData = async () => {
      try {
        this.genExpItemOptions();
        let urls = [
          process.env.REACT_APP_API_URL + `/api/bank_list`,
          process.env.REACT_APP_API_URL + "/api/get_vendors",
        ];
        Promise.all(urls.map(url => get(url))).then(
          ([respB, /* resI, */ resV]) => {
            let newOption = createOption("Cash", "1");
            this.setState({ banks: [...this.state.banks, newOption] });
            respB.data.map((bank: { bank_name: string; id: string }) => {
              let newOption = createOption(bank.bank_name, bank.id);
              this.setState({ banks: [...this.state.banks, newOption] });
            });
            /* resI.data.map((item: { item_name: string, id: string }) => {
                        let newOption = createOption(item.item_name, item.id);
                        this.setState({ items: [...this.state.items, newOption] });
                    }
                    ) */
            resV.data.map((vendor: { vendor_name: string; id: string }) => {
              let newOption = createOption(vendor.vendor_name, vendor.id);
              this.setState({ vendors: [...this.state.vendors, newOption] });
            });
            this.setState({ allvendors: resV.data });
          }
        );
        this.gentdsOptions();
        this.setState({ taxes: this.props.taxes });
        const respCom: Company = this.props.companies;
        this.setState({ company: respCom, company_currency: respCom.currency });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }
  handleItemChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
    i: number,
    dtl: any
  ) => {
    let dtls = this.state.dtls;
    dtl.item_id = newValue;
    let value = dtl.item_id?.value;
    let items = this.props.exp_items.find(({ id }) => id === value);
    if (items) {
      if (dtl.qty > 0) {
        dtl["amount"] = round(dtl.qty * dtl.rate, 2);
      } else {
        dtl["qty"] = 1;
        dtl["amount"] = round(1 * dtl.rate, 2);
      }
    }
    else {
      dtl["qty"] = 0;
      dtl["rate"] = 0;
      dtl["amount"] = 0;
      dtl["tax_amt"] = 0;
      dtl["tax_id"] = "";
      dtl["tax_per"] = 0;
      dtl["item_description"] = "";
      dtl["hsn_or_sac"] = "";
      dtl["item_id"] = { label: "", value: "" };
    }
    dtls[i] = dtl;
    this.setState({ dtls: dtls });
    this.calcTotal();
  };
  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    let name: any = actionMeta.name;
    let data: any = {};
    data[name] = newValue;
    this.setState(data);
    this.calcTotal();
  };
  handleChangeTDS = async (
    newValue: OnChangeValue<Option, false> | null,
    actionMeta: ActionMeta<Option>
  ) => {
    if (!newValue) {
      this.setState({
        tdsvalue: 0,
        tdsbalance: this.state.gross_total,
      });
      return;
    }
    let name: any = actionMeta.name;
    let data: any = {};
    data[name] = newValue;
    this.setState(data);
    const selectedtdshead = this.props.tdshead.find(
      (tdshead: { id: any }) => tdshead.id == newValue.value
    );
    if (selectedtdshead) {
      const heads_per = selectedtdshead.heads_per;
      const tdsvalue = (this.state.taxbasevalue * heads_per) / 100;
      this.setState({
        tdsvalue: tdsvalue,
        tdsbalance: this.state.gross_total - tdsvalue,
      });
    }
  };
  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  };
  onChangehandlerDtl = (e: any, i: number, dtl: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let dtls = this.state.dtls;
    if (name === "tax_id") {
      dtl["tax_id"] = value;
      let taxes: Array<any> = [];
      let rowTax: any = this.state.taxes.find((tax: any) => tax.id == value);
      if (value != "") {
        taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
        dtl["tax_per"] = taxes.reduce(
          (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.split_per)),
          0
        );
      } else {
        dtl["tax_per"] = 0;
      }

      dtl["dis_amount"] = round(dtl.qty * dtl.rate - dtl["discount"], 2);
      dtl["tax_amt"] = round((dtl["dis_amount"] * dtl["tax_per"]) / 100, 2);
      dtl["amount"] = round(
        parseFloat(dtl["dis_amount"]) + parseFloat(dtl["tax_amt"]),
        2
      );
    }
    if (name === "qty") {
      dtl["dis_amount"] = round(value * dtl.rate - dtl["discount"], 2);
      dtl["tax_amt"] = round((dtl["dis_amount"] * dtl.tax_per) / 100, 2);
      dtl["amount"] = round(
        parseFloat(dtl["dis_amount"]) + parseFloat(dtl["tax_amt"]),
        2
      );
    }
    if (name === "rate") {
      if (dtl.qty > 0) {
        dtl["dis_amount"] = round(dtl.qty * value - dtl["discount"], 2);
        dtl["tax_amt"] = round((dtl["dis_amount"] * dtl.tax_per) / 100, 2);
        dtl["amount"] = round(
          parseFloat(dtl["dis_amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
      }
    }
    if (dtl.item_description == null) {
      dtl["item_description"] = "";
    } else {
      dtl["item_description"] = dtl.item_description;
    }
    if (name === "discount") {
      if (value == "") {
        value = 0;
      }
      if (dtl.qty * dtl.rate > 0) {
        dtl["dis_amount"] = round(dtl.qty * dtl.rate - value, 2);
        dtl["tax_amt"] = round((dtl["dis_amount"] * dtl.tax_per) / 100, 2);
        dtl["amount"] = round(
          parseFloat(dtl["dis_amount"]) + parseFloat(dtl["tax_amt"]),
          2
        );
      }
    }
    dtl[name] = value;
    dtls[i] = dtl;
    this.setState({ dtls: dtls });
    this.calcTotal();
  };
  calcTotal = async () => {
    const dtls = this.state.dtls;
    const net_total: number = await dtls.reduce(
      (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.amount)),
      0
    );
    //tax calculation
    const company = await this.state.company;
    const comp_is_indian_state: number = company.is_indian_state;
    const company_state: string = company.company_state;
    if (this.state.vendor_id && this.state.vendor_id.value !== "") {
      const vendor = await this.state.allvendors.find(
        ({ id }) => id === this.state.vendor_id?.value
      );

      const billing_is_indian_state: number = vendor.is_indian_state;
      const billing_country: string = vendor.country;
      const billing_state: string = vendor.state;
      let tax_dtls: Array<any> = [];
      dtls.forEach(async item => {
        let taxes: Array<any> = [];

        if (company_state == billing_state) {
          let rowTax: any = this.state.taxes.find(
            (tax: any) => tax.id == item.tax_id
          );
          if (rowTax) {
            taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
          }
          // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
        } else {
          let rowTax: any = this.state.taxes.find(
            (tax: any) => tax.id == item.tax_id
          );
          if (rowTax) {
            taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
          }
          // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
        }

        if (item.qty > 0 && item.rate > 0) {
          taxes.forEach(async tax => {
            let split_name = tax.split_name;
            let split_per = tax.split_per;
            let row_total = item.qty * item.rate - parseFloat(item.discount);
            let tax_amount = (row_total * split_per) / 100;
            tax_amount = parseFloat(tax_amount.toFixed(2));
            let newRaw = {
              label: split_name,
              tax_rate: split_per,
              tax_amount: tax_amount,
            };
            tax_dtls.push(newRaw);
          });
        }
      });

      //sum the tax per
      let tax_dtls2: Array<any> = [];
      tax_dtls.forEach((a, index) => {
        let tax_amount = tax_dtls
          .filter(el => {
            return el.label == a.label && el.tax_rate == a.tax_rate;
          })
          .reduce(
            (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount)),
            0
          );

        if (isNaN(tax_amount)) {
          tax_amount = 0;
        }

        tax_dtls2.push({
          label: a.label,
          tax_rate: a.tax_rate,
          tax_amount: tax_amount,
        });
      });

      tax_dtls = tax_dtls2.filter(
        (arr, index, self) =>
          index ===
          self.findIndex(
            t => t.label === arr.label && t.tax_rate === arr.tax_rate
          )
      );

      this.setState({ tax_dtls: tax_dtls });
    }
    const tax_amount: number = await this.state.tax_dtls.reduce(
      (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount)),
      0
    );
    this.setState({ tax_amount: tax_amount });
    // const discAmt: number = await this.state.discount_type == "per" ? (this.state.net_total * this.state.discount / 100) : this.state.discount;
    let discAmt: number = await round(
      this.state.dtls.reduce(
        (Pre, Cur) => (Pre = parseFloat(Pre) + parseFloat(Cur.discount)),
        0
      ),
      2
    );
    if (isNaN(discAmt)) {
      discAmt = 0;
    }
    const gross_total: number = await net_total; /*+ tax_amount  - discAmt */
    const inr_rate: number = 1 / this.state.currency_rate;
    const gross_total_base: number =
      this.state.currency_rate > 0 ? gross_total * inr_rate : 0.0;
    this.setState({
      discount: discAmt,
      net_total: net_total,
      gross_total: gross_total,
      amount: gross_total,
      gross_total_base: gross_total_base,
      taxbasevalue: gross_total - tax_amount,
      tdsbalance: gross_total,
    });
    if (this.state.isTbsApplicable) {
      if (this.state.tds_type !== "") {
        const selectedtdshead = this.props.tdshead.find(
          (tdshead: { id: any }) => tdshead.id == this.state.tds_type.value
        );
        if (selectedtdshead) {
          const heads_per = selectedtdshead.heads_per;
          const tdsvalue = ((gross_total - tax_amount) * heads_per) / 100;
          this.setState({
            tdsvalue: tdsvalue,
            tdsbalance: gross_total - tdsvalue,
          });
        }
      }
    }
  };
  openModalExpItem = () => {
    this.setState({ modalExpItem: true });
  };
  openModalExpItemCreate = (inputValue: string) => {
    this.setState({ modalExpItem: true, newItemName: inputValue });
  };
  closeModalExpItem = () => {
    this.setState({ modalExpItem: false });
  };
  setInvoice_date = (date: any) => {
    if (date == null) {
      this.setState({ invoice_date: new Date() });
    } else {
      const selectedDate = moment(date);
      const formattedDate = new Date(selectedDate.format("YYYY-MM-DD"));
      this.setState({ invoice_date: formattedDate });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Expense Bill</title>
          </MetaTags>
          <div className="container-fluid">
            <div>
              <span style={{ float: "right" }}> </span>
              <span>
                <Breadcrumbs
                  title="Create Expense Bill"
                  breadcrumbItem="Create Expense Bill"
                />
              </span>
            </div>
            <Row>
              <Col className="col-12">
                <UncontrolledAlert
                  hidden={this.state.isHideSuccessMgs}
                  color="success"
                >
                  Expenses save successfully
                </UncontrolledAlert>

                {/* <UncontrolledAlert
                  hidden={this.state.isHideErrorMgs}
                  color="danger"
                >
                  Somthing went wrong, Please try again
                </UncontrolledAlert> */}
                 {this.state.success === 2 ? (
                  <Alert color="danger">{this.state.message}</Alert>
                ) : null}
                <AvForm onValidSubmit={this.handleValidSubmit}>
                  <div className="form-head mb-3">
                    <Row>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-lg-6">
                            <label htmlFor="progresspill-address-input">
                              Vendor
                            </label>
                            <Select
                              isClearable
                              options={this.state.vendors}
                              value={this.state.vendor_id}
                              onChange={this.handleChange}
                              styles={{
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                  backgroundColor: state.isSelected
                                    ? "#10a37f"
                                    : "inherit",
                                }),
                              }}
                              name="vendor_id"
                              validate={{ required: { value: true } }}
                              className="field-required"
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label htmlFor="progresspill-address-input">
                                Bill Number
                              </label>
                              <AvField
                                name="invoice_no"
                                type="text"
                                placeholder="Enter Bill Number"
                                errorMessage="Enter Bill Number"
                                className="field-required"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="row">
                          <div className="col-lg-6">
                          <label htmlFor="progresspill-address-input">
                              Bill date
                            </label>
                            <div className="row">
                              <DatePicker
                                oneTap
                                onChange={this.setInvoice_date}
                                name="invoice_date"
                                format="dd/MM/yyyy"
                                value={
                                  this.state.invoice_date
                                    ? this.state.invoice_date
                                    : null
                                }
                              />
                              </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="row">
                    <div className="table-responsive unset-overflow">
                      <Table className="table mb-0 cstm-tbl">
                        <thead className="table-light">
                          <tr>
                            <th style={{ width: "2%" }}>#</th>
                            <th>Item</th>
                            <th style={{ width: "10%" }}>Qty</th>
                            <th style={{ width: "10%" }}>Rate</th>
                            <th style={{ width: "10%" }}>Discount</th>
                            <th style={{ width: "10%" }}>Tax</th>
                            <th style={{ width: "10%" }}>Amount</th>
                            <th style={{ width: "2%" }}>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dtls.map((dtl, i) => {
                            return (
                              <tr key={i}>
                                <th scope="row" className="count">
                                  {i + 1}
                                </th>
                                <td>
                                  <div className="mb-3">
                                    {/* <Select
                                      isClearable
                                      options={this.state.items}
                                      value={dtl.item_id}
                                      onChange={(
                                        newValue: OnChangeValue<Option, false>,
                                        actionMeta: ActionMeta<Option>
                                      ) =>
                                        this.handleItemChange(
                                          newValue,
                                          actionMeta,
                                          i,
                                          dtl
                                        )
                                      }
                                      name="item_id"
                                    /> */}
                                    <CreatableSelect
                                    styles={{
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                        backgroundColor: state.isSelected
                                          ? "#10a37f"
                                          : "inherit",
                                      }),
                                    }}
                                      isClearable={true}
                                      onChange={(
                                        newValue: OnChangeValue<Option, false>,
                                        actionMeta: ActionMeta<Option>
                                      ) =>
                                        this.handleItemChange(
                                          newValue,
                                          actionMeta,
                                          i,
                                          dtl
                                        )
                                      }
                                      onCreateOption={
                                        this.openModalExpItemCreate
                                      }
                                      options={this.state.items}
                                      value={this.state.valueItem}
                                      name="item_id"
                                      className="field-required"
                                    />
                                  </div>
                                  <Row>
                                    <AvField
                                      name="item_description"
                                      type="text"
                                      placeholder="Description"
                                      maxLength={500}
                                      value={dtl.item_description}
                                      onChange={(e: any) =>
                                        this.onChangehandlerDtl(e, i, dtl)
                                      }
                                    />
                                  </Row>
                                </td>
                                <td>
                                  <AvField
                                    name="qty"
                                    type="number"
                                    placeholder="Enter qty"
                                    errorMessage="Enter qty"
                                    value={dtl.qty}
                                    onChange={(e: any) =>
                                      this.onChangehandlerDtl(e, i, dtl)
                                    }
                                    className="field-required"
                                  />
                                </td>
                                <td>
                                  <AvField
                                    name="rate"
                                    type="number"
                                    placeholder="Enter rate"
                                    errorMessage="Enter rate"
                                    value={dtl.rate}
                                    onChange={(e: any) =>
                                      this.onChangehandlerDtl(e, i, dtl)
                                    }
                                    className="field-required"
                                  />
                                </td>
                                <td>
                                  <div className="mb-3">
                                    <AvField
                                      name="discount"
                                      type="number"
                                      placeholder="Amount"
                                      value={dtl.discount_amt}
                                      onChange={(e: any) =>
                                        this.onChangehandlerDtl(e, i, dtl)
                                      }
                                    />
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="mb-3">
                                    <AvField
                                      name="tax"
                                      type="number"
                                      placeholder="Tax amount"
                                      errorMessage="Tax amount"
                                      value={dtl.tax_amt}
                                      onChange={(e: any) =>
                                        this.onChangehandlerDtl(e, i, dtl)
                                      }
                                    />
                                  </div>
                                </td> */}
                                <td>
                                  <Row>
                                    <div className="mb-3">
                                      <AvField
                                        name="tax_amt"
                                        type="number"
                                        placeholder="Tax amount"
                                        errorMessage="Tax amount"
                                        value={dtl.tax_amt}
                                        validate={{
                                          required: { value: false },
                                        }}
                                        readOnly={true}
                                      />
                                    </div>
                                  </Row>
                                  <Row>
                                    <AvField
                                      type="select"
                                      name="tax_id"
                                      value={dtl.tax_id}
                                      onChange={(e: any) =>
                                        this.onChangehandlerDtl(e, i, dtl)
                                      }
                                    >
                                      <option value="" key="">
                                        Tax Per
                                      </option>
                                      {this.state.taxes.map(tax => (
                                        <option value={tax.id} key={tax.id}>
                                          {tax.tax_name}
                                        </option>
                                      ))}
                                    </AvField>
                                  </Row>
                                </td>
                                <td>
                                  <AvField
                                    name="amount"
                                    type="number"
                                    placeholder="Enter amount"
                                    errorMessage="Enter amount"
                                    value={dtl.amount}
                                    validate={{
                                      required: { value: false },
                                    }}
                                    readOnly={true}
                                  />
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    className="btn tbl-btn"
                                    onClick={() => this.removeRow(i)}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-5 add-row">
                        <Link
                          to="#"
                          className="btn"
                          onClick={() => this.addRow()}
                        >
                          <i className="bx bx-plus me-1"></i> Add Row
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-end">
                    {/* <div className="col-lg-4"></div> */}
                    <div className="col-lg-4">
                      <div className="total">
                        <ul>
                          <li>
                            <label>
                              Gross Total ({this.state.customer_currency})
                            </label>
                            <span>{number_format(this.state.gross_total)}</span>
                          </li>
                          {this.state.currency_rate > 0 && (
                            <li>
                              <label>Gross Total (INR)</label>
                              <span className="bold">
                                {number_format(this.state.gross_total_base)}
                              </span>
                            </li>
                          )}
                          {this.state.tax_dtls.length > 0 && (
                            <li>
                              <label>Tax</label>
                              <span>&nbsp;</span>
                            </li>
                          )}
                          {this.state.tax_dtls.map((tax, i) => {
                            return (
                              <li key={i}>
                                <label>
                                  {tax.label} ({number_format(tax.tax_rate)}%)
                                </label>
                                <span>{number_format(tax.tax_amount)}</span>
                              </li>
                            );
                          })}
                          <li>
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={this.tdsdeducted}
                                checked={this.state.isTbsApplicable}
                              />{" "}
                              TDS
                            </label>
                            <span>{number_format(this.state.tdsvalue)}</span>
                          </li>
                          {this.state.isTbsApplicable && (
                            <li>
                              <label>Tax Base Value</label>
                              <span>
                                <input
                                  type="number"
                                  className="form-control text-end  p-1 w-100"
                                  onChange={(e: any) =>
                                    this.handelTaxbasevalue(e)
                                  }
                                  value={this.state.taxbasevalue}
                                />
                              </span>
                            </li>
                          )}
                          {this.state.isTbsApplicable && (
                            <li>
                              <div className="row">
                                <div className="col-sm-12">
                                  <Select
                                    id="tds_heads_input"
                                    options={this.state.tdshead}
                                    value={this.state.tds_type}
                                    onChange={this.handleChangeTDS}
                                    styles={{
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        ":hover": {
                                      backgroundColor: "#10a37f",
                                      color: "#fff"
                                    },
                                        backgroundColor: state.isSelected
                                          ? "#10a37f"
                                          : "inherit",
                                      }),
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    name="tds_type"
                                  />
                                </div>
                              </div>
                            </li>
                          )}
                          <li>
                            <label>Net Payable</label>
                            <span className="bold">
                              {number_format(this.state.tdsbalance)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center gap-2 mb-4 mt-2">
                    {this.state.isProgress ? (
                      <>
                        <Skeleton width={100} height={37} />{" "}
                        <Skeleton width={100} height={37} />
                      </>
                    ) : (
                      <>
                        <Button
                          type="reset"
                          className="bmt-btn-close closesubmit"
                          onClick={this.resetForm}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="bmt-btn-submit closesubmit"
                        >
                          Submit
                        </Button>{" "}
                      </>
                    )}
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.modalExpItem && (
          <ExpItemModal
            modal_is_open={this.state.modalExpItem}
            openModal={this.openModalExpItem}
            closeModal={this.closeModalExpItem}
            id={"0"}
            item_name={this.state.newItemName}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: {
  exp_items: any;
  companies: any;
  taxes: any;
  tdshead: any;
}) => {
  return {
    exp_items: state.exp_items.exp_items,
    taxes: state.taxes.taxes,
    companies: state.companies.companies,
    tdshead: state.tdshead.tdshead,
  };
};

export default connect(mapStateToProps)(withRouter(ExpenseBillCreate));
