import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import moment from "moment";
import { del, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import "../Datatable/datatables.scss";

import { ActionMeta, OnChangeValue } from "react-select/dist/declarations/src";

import "react-datepicker/dist/react-datepicker.css";

import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import PdfIcon from "src/assets/images/icons/Pdf";
import RecordPaymentHistory from "../Payments/RecordPaymentHistory";
import RecordPaymentModal from "../Payments/RecordPaymentModal";
import CompanyInfoModal from "./CompanyInfoModal";
import DownloadModal from "./DownloadModal";
import InvoiceDetailsModel from "./InvoiceDetailsModel";
import SendInvModel from "./SendInvModel";
import SendReceiptModel from "./SendReceiptModel";
import { number_format } from "src/helpers/common";
import { get_addrress_type } from "src/helpers/common";
import ConfirmationModal from "src/components/ConfirmationModal";
import { connect } from "react-redux";
import { isNull } from "lodash";
import InvAlertModal from "./InvAlertModal";

interface MyProps extends RouteComponentProps<any> {
  valueCustomer?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  setTotalAmount: ({}) => void;
  refresh: boolean;
  setRefresh: (flag: boolean) => void;
  isPaginationRequire?: boolean;
  setDownloadpdf: (id: string) => void;
  countries: Array<any>;
  states: Array<any>;
}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  page: number;
  data: any;
  payData: any;
  sortField: string;
  sortOrder: string;
  invoice_prefix: string;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  modal_is_open: boolean;
  active_inv_id: string;
  active_cust_id: string;
  active_cust_name: string;
  active_invoice_no: string;
  active_invoice_amount: number;
  modal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  pdfId: string;
  active_inv_no: string;
  modalInfo: boolean;
  modalDownload: boolean;
  isOpenPayModal: boolean;
  phone: string;
  gstin: string;
  shipping_company: string;
  shipping_address: string;
  billing_address: string;
  to_addrress_type: any;
  pan: string;
  active_rec_id: string;
  active_rec_no: string;
  receiptModal: boolean;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  log_data: Array<any>;
  alertCantDelClose: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
  sortOrder: string;
  sortField: string;
}

interface Option {
  label: string;
  value: string;
}

class InvoiceListUnpaid extends Component<MyProps, MyState> {
  static defaultProps: {
    isPaginationRequire: boolean;
  };
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      page: 1,
      data: [],
      payData: [],
      sortField: "invoice_date",
      sortOrder: "desc",
      invoice_prefix: "",
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      startDate: null,
      endDate: null,
      modal_is_open: false,
      active_inv_id: "",
      active_cust_name: "",
      active_cust_id: "",
      active_invoice_no: "",
      active_invoice_amount: 0,
      modal: false,
      isSending: false,
      editorState: EditorState.createEmpty(),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      company_name: "",
      pdfId: "",
      active_inv_no: "",
      modalInfo: false,
      modalDownload: false,
      isOpenPayModal: false,
      phone: "",
      gstin: "",
      shipping_company: "",
      shipping_address: "",
      billing_address: "",
      to_addrress_type: "",
      pan: "",
      active_rec_id: "",
      active_rec_no: "",
      receiptModal: false,
      isOpenConformModal: false,
      Rowdelete: null,
      log_data: [],
      alertCantDelClose: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    // this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/invoice_unpaid_list_grid`,
          param
        );
        if (this._isMounted) {
          if (resp.success) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
              invoice_prefix: resp.data.invoice_prefix,
              instance_id: resp.data.instance_id,
              company_name: resp.data.company_name,
            });
            this.props.setTotalAmount({
              totalAllUnpaid: resp.data.total,
              countAllUnpaid: resp.data.count,
              unpaid: resp.data.unpaid,
              un_partially_paid: resp.data.un_partially_paid,
              isLoadedTabUnpaid: true,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.setState({ page: param.page });
    this.setState({ sortField: param.sortField });
    this.setState({ sortOrder: param.sortOrder });
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: param.page,
      searchText: "",
      sizePerPage: param.sizePerPage,
      sortField: param.sortField,
      sortOrder: param.sortOrder,
      customer: this.props.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
    });
  };
  handleCantDel = () => {
    this.setState({ alertCantDelClose : true });
  };
  handleCantDelClose = () => {
    this.setState({ alertCantDelClose : false });
  };
  handleDelete = (row: any) => {
    this.setState({ isOpenConformModal: true });
    this.setState({ Rowdelete: row.id });
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/invoice_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: this.state.page,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      customer: this.props.valueCustomer,
      startDate: startDate,
      endDate: endDate,
    });
    this.props.setRefresh(true);
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  handleDeleteClose = () => {
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  recordPayment = (
    event: any,
    row: {
      id: string;
      customer_name: string;
      invoice_no: number;
      gross_total_base: number;
    }
  ) => {
    event.stopPropagation();
    let paystate = {
      active_inv_id: row.id,
      active_cust_name: row.customer_name,
      active_invoice_amount: row.gross_total_base,
    };
    this.setState({ payData: paystate });
    this.setState({
      active_inv_id: row.id,
      active_cust_name: row.customer_name,
      active_invoice_no: this.state.invoice_prefix + row.invoice_no,
      active_invoice_amount: row.gross_total_base,
    });
    this.openModal();
  };

  openModal = () => {
    this.setState({ modal_is_open: true });
  };

  closeModal = () => {
    this.setState({ modal_is_open: false });
  };

  send = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let inv_no = this.state.invoice_prefix
        ? this.state.invoice_prefix + row.invoice_no
        : "INV" + row.invoice_no;
      this.setState({
        active_inv_id: row.id,
        active_inv_no: inv_no,
        email: row.email,
        modal: !this.state.modal,
      });
    } catch (err) {
      console.error(err);
    }
  };

  viewInfo = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let postValues = {
        invoice_id: row.id,
      };
      let inv_no = row.prefix
        ? row.prefix + row.invoice_no
        : "INV" + row.invoice_no;
      let billing_state = undefined;
      if (row.billing_country == "99") {
        billing_state = this.props.states.find(
          ({ id }) => id === row.billing_state
        ).state;
      } else {
        billing_state = row.billing_state;
      }
      let billing_country = this.props.countries.find(
        ({ id }) => id === row.billing_country
      );
      let billing_address = "";
      if (!isNull(row.billing_address1)) {
        billing_address += `${row.billing_address1} `;
      }
      if (!isNull(row.billing_address2)) {
        billing_address += `${row.billing_address2} `;
      }
      if (!isNull(row.billing_town_or_city)) {
        billing_address += `${row.billing_town_or_city}\n`;
      }
      billing_address += `${billing_state} - `;
      if (!isNull(row.billing_pincode)) {
        billing_address += `${row.billing_pincode} `;
      }
      billing_address += `${billing_country.name}`;
      this.setState({
        active_cust_id: row.customer_id,
        active_cust_name: row.customer_name,
        email: row.email,
        phone: row.phone,
        gstin: row.gstin,
        shipping_company: row.shipping_company,
        shipping_address: row.shipping_address,
        billing_address: billing_address,
        to_addrress_type: get_addrress_type(row.to_addrress_type),
        log_data: [],
        active_inv_no: inv_no,
        pan: row.pan,
        modalInfo: !this.state.modalInfo,
      });
      const resp = await post(
        process.env.REACT_APP_API_URL + `/api/invoice_log_details`,
        postValues
      );
      this.setState({
        log_data: resp.data,
      });
    } catch (err) {
      console.error(err);
    }
  };
  modaldownloadPdf = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let inv_no = this.state.invoice_prefix
        ? this.state.invoice_prefix + row.invoice_no
        : "INV" + row.invoice_no;
      this.setState({
        active_inv_id: row.id,
        active_cust_name: row.customer_name,
        modalDownload: !this.state.modalDownload,
        active_inv_no: inv_no,
      });
    } catch (err) {
      console.error(err);
    }
  };
  PayHistory = async (event: any, row: any) => {
    try {
      event.stopPropagation();

      this.setState({
        active_inv_id: row.id,
        active_cust_id: row.customer_id,
        isOpenPayModal: !this.state.isOpenPayModal,
        active_cust_name: row.customer_name,
        active_invoice_no: this.state.invoice_prefix + row.invoice_no,
        active_invoice_amount: row.gross_total_base,
      });
    } catch (err) {
      console.error(err);
    }
  };
  togglePay = () => {
    try {
      this.setState({ isOpenPayModal: !this.state.isOpenPayModal });
    } catch (err) {
      console.error(err);
    }
  };
  view = async (row: any) => {
    try {
      this.setState({
        active_inv_id: row.id,
        modalView: !this.state.modalView,
      });
    } catch (err) {
      console.error(err);
    }
  };

  toggleView = () => {
    try {
      this.setState({ modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };
  toggleModalDownload = () => {
    try {
      this.setState({ modalDownload: !this.state.modalDownload });
    } catch (err) {
      console.error(err);
    }
  };
  toggleSend = () => {
    try {
      this.setState({ modal: !this.state.modal });
    } catch (err) {
      console.error(err);
    }
  };

  toggleInfo = () => {
    try {
      this.setState({ modalInfo: !this.state.modalInfo });
    } catch (err) {
      console.error(err);
    }
  };

  padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      this.props.valueCustomer !== prevProps.valueCustomer ||
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.refresh !== prevProps.refresh
    ) {
      let startDate = this.props.startDate
        ? moment(this.props.startDate).format("YYYY-MM-DD")
        : null;
      let endDate = this.props.endDate
        ? moment(this.props.endDate).format("YYYY-MM-DD")
        : null;
      this.fetchData({
        page: this.state.page,
        searchText: "",
        customer: this.props.valueCustomer?.value,
        startDate: startDate,
        endDate: endDate,
        sizePerPage: this.state.sizePerPage,
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      });
    }
  }

  handleChange = async (
    newValue: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ valueCustomer: newValue });
    let startDate = this.props.startDate
      ? moment(this.props.startDate).format("YYYY-MM-DD")
      : null;
    let endDate = this.props.endDate
      ? moment(this.props.endDate).format("YYYY-MM-DD")
      : null;
    this.fetchData({
      page: 1,
      searchText: "",
      customer: newValue?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
  };

  async componentDidMount() {
    try {
      this._isMounted = true;
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  setStartDate(dateRange: Array<any>) {
    const [startDate, endDate] = dateRange;
    this.setState({ page: 1 });
    this.setState({ startDate: startDate, endDate: endDate });
    this.fetchData({
      page: 1,
      searchText: "",
      customer: this.state.valueCustomer?.value,
      startDate: startDate,
      endDate: endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
  }
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.active_inv_id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/invoice_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };
  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    this.view(row);
  };
  preventParentAction = (event: any) => {
    event.stopPropagation();
  };
  downloadPdf = (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ pdfId: row.id });
    this.props.setDownloadpdf(row.id);
  };
  handleRefresh = () => {
    this.fetchData({
      page: 1,
      searchText: "",
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });
    this.props.setRefresh(true);
  };
  setActiveRec = (id: string, no: string) => {
    this.setState({ active_rec_id: id, active_rec_no: no });
  };
  setMailOpen = () => {
    this.setState({ receiptModal: true });
  };
  toggleReceipt = () => {
    try {
      this.setState({ receiptModal: !this.state.receiptModal });
    } catch (err) {
      console.error(err);
    }
  };
  render() {
    const pageOptions: any = {
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "invoice_date",
        text: "Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {moment(row.invoice_date).format("DD MMM YYYY")}
              <br />
              <span style={{ fontSize: "10px" }}>
                {moment(row.created_at).format("DD MMM YYYY h:mm:ss A")}
              </span>
            </>
          );
        },
      },
      {
        dataField: "invoice_no",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.prefix
                ? row.prefix + row.invoice_no
                : "INV" + row.invoice_no}
            </>
          );
        },
      },
      {
        dataField: "customer_name",
        text: "Customer",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: cellContent
                    .toUpperCase()
                    .match(/.{1,30}/g)
                    .join("<br />"),
                }}
              ></span>
              <div style={{ float: "right" }}>
                <span
                  onClick={(e: any) => this.viewInfo(e, row)}
                  className="status info"
                >
                  Info
                </span>
              </div>
            </>
          );
        },
      },
      {
        dataField: "gross_total_base",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          let due_date = row.due_date ? moment(row.due_date) : null;
          let cur_date = moment();
          let days = due_date ? cur_date.diff(due_date, "days") : null;
          let status;
          let label = null;
          if (due_date !== null) {
            label = <label className="status-mess">{days} days due</label>;
          }
          if (row.status === 4) {
            status = (
              <>
                <span
                  className="status sent"
                  onClick={e => this.PayHistory(e, row)}
                >
                  Partially Paid
                </span>
                {label}
              </>
            );
          } else if (row.status == 5) {
            status = (
              <span
                onClick={(e: any) => this.PayHistory(e, row)}
                className="status partially"
              >
                Paid
              </span>
            );
          } else {
            status = (
              <>
                <span className="status pending">Unpaid</span>
                {label}
              </>
            );
          }
          return <>{status}</>;
        },
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button btns-inv">
                {this.props.isPaginationRequire && (
                  <div className="icon-wrapper">
                    <Link
                      to="#"
                      onClick={(e: any) => this.modaldownloadPdf(e, row)}
                    >
                      <button className="round-btn">
                        <PdfIcon />
                      </button>
                    </Link>
                    <div className="tooltip-icon">Download</div>
                  </div>
                )}
                <div className="icon-wrapper">
                  <Link to="#" onClick={(e: any) => this.send(e, row)}>
                    <button className="round-btn">
                      <SentIcon />
                    </button>
                  </Link>
                  <div className="tooltip-icon">Send Mail</div>
                </div>
                <div className="icon-wrapper">
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?text=Hello *${row.customer_name}*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${this.state.invoice_prefix}${row.invoice_no} here,%0a ${process.env.REACT_APP_URL}/invoice-view/${this.state.instance_id}/${row.id}%0a%0a%0a Thanks%0a *${this.state.company_name}* %0a Sent using ${process.env.REACT_APP_NAME}(https://www.bookmytax.in)`}
                    onClick={this.preventParentAction}
                  >
                    <button className="round-btn whatsapp">
                      <WhatsappIcon />
                    </button>
                  </a>
                  <div className="tooltip-icon">Send to Whatsapp</div>
                </div>
                <div className="icon-wrapper">
                  <button
                    className="round-btn"
                    onClick={this.preventParentAction}
                  >
                    <UncontrolledDropdown className="mt-4 mt-sm-0">
                      <DropdownToggle tag="a">
                        <EllipsisIcon />
                      </DropdownToggle>

                      <DropdownMenu>
                        {/* <DropdownItem to="#" onClick={() => this.downloadPdf(row)} ><i className="fa fa-file-pdf" data-toggle="tooltip" data-placement="bottom" title="Download" style={{ color: '#dc3545' }}></i> Download</DropdownItem> */}
                        <Link to={"/invoice_edit/" + row.id}>
                          <DropdownItem to="#" tag="span">
                            <i
                              className="fas fa-edit"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit"
                              style={{ color: "#28a745" }}
                            />{" "}
                            Edit
                          </DropdownItem>
                        </Link>
                        {row.status !== 4 && row.status !== 5 ? (
                          <DropdownItem
                            to="#"
                            tag="span"
                            onClick={() => this.handleDelete(row)}
                          >
                            <i
                              className="fas fa-trash-alt"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete"
                              style={{ color: "#dc3545" }}
                            />{" "}
                            Delete
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            to="#"
                            tag="span"
                            onClick={() => this.handleCantDel()}
                          >
                            <i
                              className="fas fa-trash-alt"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Delete"
                              style={{ color: "#dc3545" }}
                            />{" "}
                            Delete
                          </DropdownItem>
                        )}
                        <Link to={"/eway_create/" + row.id}>
                          <DropdownItem to="#" tag="span">
                            <i
                              className="fas fa-file-export"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="E-Way Bill"
                              style={{ color: "#28a745" }}
                            />{" "}
                            Create E-Way Bill
                          </DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </button>
                  <div className="tooltip-icon">Action</div>
                </div>
                <div className="icon-wrapper">
                  <Link to="#" onClick={(e: any) => this.recordPayment(e, row)}>
                    <button className="normal-btn">
                      Record <i className="fas fa-solid fa-arrow-down"></i>
                    </button>
                  </Link>
                  <div className="tooltip-icon" style={{marginLeft: '-43px'}}>Record Payment</div>
                </div>
              </div>
            </>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            selectRow={{
                              mode: "radio",
                              hideSelectColumn: true,
                              clickToSelect: true,
                              onSelect: this.selectRow,
                            }}
                            remote
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap tbl-invoice"
                            }
                            headerWrapperClasses={"thead-light"}
                            onTableChange={this.handleTableChange}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Col>
                    </Row>
                    {this.props.isPaginationRequire && (
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                            <PaginationTotalStandalone {...paginationProps} />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        <RecordPaymentModal
          customer_id={this.state.active_cust_id}
          modal_is_open={this.state.modal_is_open}
          openModal={this.openModal}
          closeModal={this.closeModal}
          data={this.state.payData}
          invoice_id={this.state.active_inv_id}
          invoice_amount={this.state.active_invoice_amount}
          handleRefresh={this.handleRefresh}
          setActive={this.setActiveRec}
          setMailOpen={this.setMailOpen}
        />
        <SendInvModel
          id={this.state.active_inv_id}
          invoice_no={this.state.active_inv_no}
          isOpen={this.state.modal}
          toggle={this.toggleSend}
          email={this.state.email}
        />
        <DownloadModal
          id={this.state.active_inv_id}
          customer_name={this.state.active_cust_name}
          customer_id={this.state.active_cust_id}
          invoice_no={this.state.active_inv_no}
          isOpen={this.state.modalDownload}
          toggle={this.toggleModalDownload}
        />
        <CompanyInfoModal
          id={this.state.active_inv_id}
          customer_name={this.state.active_cust_name}
          customer_id={this.state.active_cust_id}
          phone={this.state.phone}
          gstin={this.state.gstin}
          shipping_company={this.state.shipping_company}
          shipping_address={this.state.shipping_address}
          billing_address={this.state.billing_address}
          to_addrress_type={this.state.to_addrress_type}
          pan={this.state.pan}
          invoice_no={this.state.active_inv_no}
          log_data={this.state.log_data}
          invoice_id={this.state.active_inv_id}
          isOpen={this.state.modalInfo}
          toggle={this.toggleInfo}
          email={this.state.email}
        />
        <InvoiceDetailsModel
          key={1}
          id={this.state.active_inv_id}
          isOpen={this.state.modalView}
          toggle={this.toggleView}
          instance_id={this.state.instance_id}
          email={this.state.email}
        />
        <RecordPaymentHistory
          invoice_id={this.state.active_inv_id}
          customer_id={this.state.active_cust_id}
          isOpen={this.state.isOpenPayModal}
          toggle={this.togglePay}
          invoice_amount={this.state.active_invoice_amount}
          handleRefresh={this.handleRefresh}
        />
        <SendReceiptModel
          id={this.state.active_rec_id}
          receipt_no={this.state.active_rec_no}
          isOpen={this.state.receiptModal}
          toggle={this.toggleReceipt}
          email={this.state.email}
        />
        <InvAlertModal
          isOpen={this.state.alertCantDelClose}
          onClose={this.handleCantDelClose}
        ></InvAlertModal>
      </React.Fragment>
    );
  }
}

InvoiceListUnpaid.defaultProps = {
  isPaginationRequire: true,
};
const mapStateToProps = (state: { countries: any; states: any }) => {
  return {
    countries: state.countries.countries,
    states: state.states.states,
  };
};
export default connect(mapStateToProps)(withRouter(InvoiceListUnpaid));
