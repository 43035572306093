import {ProductsTypes} from "./actionTypes"

export const getProducts = () => ({
  type: ProductsTypes.GET_PRODUCTS,
})

export const getProductsSuccess = (products : any) => ({
  type: ProductsTypes.GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = (error : any) => ({
  type: ProductsTypes.GET_PRODUCTS_FAIL,
  payload: error,
})



