export enum PermissionsTypes {
    GET_PERMISSIONS = '@@states/GET_PERMISSIONS',
    GET_PERMISSIONS_SUCCESS = '@@states/GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_FAIL = '@@states/GET_PERMISSIONS_FAIL',
    GET_PERMISSIONS_REQUESTED = '@@states/GET_PERMISSIONS_REQUESTED'
}

export enum PermissionModule {
    USER = 1,
    CUSTOMER = 2,
    INVOICE = 3,
    ESTIMATION = 4,
    ROLE = 5,
    PRODUCT = 6,
    BANK = 7,
    EXPENSES = 8,
    VENDOR = 9,
    EXP_ITEMS = 10,
    CATEGORIES = 11
}
