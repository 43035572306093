import React from "react";

//import components
import SidebarContent from "./SidebarContent";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="cert d-flex justify-content-between">
          <div className="secure">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13.091" viewBox="0 0 11 13.091">
              <g id="_8127673981537184110" data-name="8127673981537184110" transform="translate(0 0)">
                <path id="Path_280" data-name="Path 280" d="M28.1,3.371c-.007-.353-.013-.687-.013-1.01a.458.458,0,0,0-.458-.458A6.318,6.318,0,0,1,22.931.13a.458.458,0,0,0-.639,0A6.317,6.317,0,0,1,17.6,1.9a.458.458,0,0,0-.458.458c0,.323-.006.657-.013,1.011-.063,3.289-.149,7.794,5.336,9.695a.458.458,0,0,0,.3,0C28.246,11.165,28.161,6.66,28.1,3.371Zm-5.486,8.776c-4.7-1.709-4.632-5.452-4.569-8.758,0-.2.007-.391.01-.579a7.066,7.066,0,0,0,4.559-1.728,7.067,7.067,0,0,0,4.56,1.728c0,.188.006.381.01.579C27.245,6.7,27.316,10.438,22.612,12.147Z" transform="translate(-17.112 0)" fill="#9a9a9a" />
                <path id="Path_281" data-name="Path 281" d="M72.447,79.02l-2.212,2.211-.944-.944a.458.458,0,0,0-.648.648L69.911,82.2a.458.458,0,0,0,.648,0l2.536-2.535a.458.458,0,1,0-.648-.648Z" transform="translate(-65.369 -74.066)" fill="#9a9a9a" />
              </g>
            </svg>

            100% Secure
          </div>
          <div className="iso">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.962" height="12.54" viewBox="0 0 13.962 12.54">
              <g id="_3563915481595156041" data-name="3563915481595156041" transform="translate(-2.6 -5.6)">
                <circle id="Ellipse_14" data-name="Ellipse 14" cx="2.474" cy="2.474" r="2.474" transform="translate(9.185 8.109)" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8" />
                <path id="Path_282" data-name="Path 282" d="M22.131,23v4.993l-1.565-.749L19,27.993V23" transform="translate(-8.79 -10.253)" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8" />
                <line id="Line_9" data-name="Line 9" x2="2.474" transform="translate(5.474 8.109)" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8" />
                <line id="Line_10" data-name="Line 10" x2="1.237" transform="translate(5.474 10.583)" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8" />
                <path id="Path_283" data-name="Path 283" d="M10.125,15.5,3,15.584V6H16.162v9.584H14.137" fill="none" stroke="#9a9a9a" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8" />
              </g>
            </svg>

            ISO Certified
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
