import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Table } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, post, get } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import SentIcon from "src/assets/images/icons/Sent";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {number_format } from "src/helpers/common";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SendInvModel from "./SentInvModel";
import PaymentPdf from "./PaymentPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import CompanyInfoModal from "./Info";
import SendReceiptModel from "../Invoices/SendReceiptModel";

interface MyProps extends RouteComponentProps<any> {
  valueCustomer?: Option | null | undefined;
  startDate?: Date | null;
  endDate?: Date | null;
  setTotalAmount: ({}) => void;
  isPaginationRequire?: boolean;
  setDownloadpdf: (id: string) => void;
}

interface MyState {
  sizePerPage: number;
  totalSize: number;
  data: any;
  payData: any;
  sortField: string;
  sortOrder: string;
  invoice_prefix: string;
  isLoading: boolean;
  options: Option[];
  valueCustomer: Option | null | undefined;
  customers: Array<any>;
  startDate: Date | null;
  endDate: Date | null;
  modal_is_open: boolean;
  active_inv_id: string;
  active_cust_id: string;
  active_cust_name: string;
  active_invoice_no: string;
  active_invoice_amount: any;
  modal: boolean;
  modalInfo: boolean;
  isOpenPayModal: boolean;
  isSending: boolean;
  editorState: EditorState;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  email: string;
  loading: boolean;
  instance_id: string;
  modalView: boolean;
  company_name: string;
  pdfId: string;
  active_inv_no: string;
  active_ref_no: string;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Option {
  label: string;
  value: string;
}

class PaymentReceviedList extends Component<MyProps, MyState> {
  static defaultProps: {
    isPaginationRequire: boolean;
  };
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      totalSize: 0,
      data: [],
      payData: [],
      sortField: "invoice_no",
      sortOrder: "desc",
      invoice_prefix: "",
      isLoading: true,
      options: [],
      valueCustomer: null,
      customers: [],
      startDate: null,
      endDate: null,
      modal_is_open: false,
      active_inv_id: "",
      active_cust_id: "",
      active_cust_name: "",
      active_invoice_no: "",
      active_invoice_amount: "",
      modal: false,
      modalInfo: false,
      isOpenPayModal: false,
      isSending: false,
      editorState: EditorState.createEmpty(),
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      email: "",
      loading: true,
      instance_id: "",
      modalView: false,
      pdfId: "",
      company_name: "",
      active_inv_no: "",
      active_ref_no: "",
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = (param: any) => {
    const payload = {
      endDate: null,
      page: param.page,
      searchText: param.searchText,
      sizePerPage: param.sizePerPage,
      sortField: "invoice_no",
      sortOrder: "desc",
      startDate: null,
    };
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/list_all_payment_records`,
          payload
        );

        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.total,
              data: resp.data,
            });
            this.props.setTotalAmount({
              totalAll: resp.total,
              countAll: resp.total,
              isLoadedTabAll: true,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.fetchData(param);
  };

  send = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      let inv_no = this.state.invoice_prefix
        ? this.state.invoice_prefix + row.invoice_number
        : "INV" + row.invoice_number;
      this.setState({
        active_inv_id: row.id,
        active_inv_no: inv_no,
        active_ref_no: row.payment_reference_no,
        email: row.email,
        modal: !this.state.modal,
      });
    } catch (err) {
      console.error(err);
    }
  };

  PayHistory = async (event: any, row: any) => {
    try {
      event.stopPropagation();
      this.setState({
        active_inv_id: row.id,
        active_cust_id: row.info,
        isOpenPayModal: !this.state.isOpenPayModal,
        active_cust_name: row.customer_name,
        active_invoice_no:
          this.state.invoice_prefix +
          row.invoice_number,
        active_invoice_amount: row,
      });
    } catch (err) {
      console.error(err);
    }
  };

  view = async (row: any) => {
    try {
      this.setState({
        active_inv_id: row.id,
        active_ref_no: row.payment_reference_no,
        email: row.email,
        modalView: !this.state.modalView,
      });
    } catch (err) {
      console.error(err);
    }
  };

  toggleView = () => {
    try {
      this.setState({ modalView: !this.state.modalView });
    } catch (err) {
      console.error(err);
    }
  };

  toggleSend = () => {
    try {
      this.setState({ modal: !this.state.modal });
    } catch (err) {
      console.error(err);
    }
  };

  toggleInfo = () => {
    try {
      this.setState({ modalInfo: !this.state.modalInfo });
    } catch (err) {
      console.error(err);
    }
  };

  togglePay = () => {
    try {
      this.setState({ isOpenPayModal: !this.state.isOpenPayModal });
    } catch (err) {
      console.error(err);
    }
  };

  padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  componentDidUpdate(prevProps: MyProps) {}

  async componentDidMount() {
    this._isMounted = true;
    this.fetchData({
      page: 1,
      searchText: "",
      customer: this.props.valueCustomer?.value,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
    });

    try {
      this.setState({ loading: false });
    } catch (err) {
      console.error(err);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleValidSubmit = async (event: any, values: any) => {
    this.setState({ isSending: true });
    let content = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    values["content"] = content;
    values["code"] = this.state.active_inv_id;
    const resp = await post(
      process.env.REACT_APP_API_URL + "/api/invoice_sent",
      values
    );
    if (resp.success) {
      this.setState({ isHideSuccessMgs: false });
      setTimeout(() => {
        this.setState({ modal: false });
      }, 2000);
    } else {
      this.setState({ isHideErrorMgs: false });
    }
    this.setState({ isSending: false });
  };
  selectRow = (row: any, isSelect: any, rowIndex: any) => {
    this.view(row);
  };

  downloadPdf = (event: any, row: any) => {
    event.stopPropagation();
    this.setState({ pdfId: row.id });
    this.props.setDownloadpdf(row.id);
  };
  handleRefresh = () => {
    console.log("handleRefresh all");
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.totalSize,
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "date",
        text: "Date",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{moment(row.date).format("DD/MM/YYYY")}</>;
        },
      },
      {
        dataField: "payment_reference_no",
        text: "Reference No",
        sort: true,
      },
      {
        dataField: "invoice_number",
        text: "Number",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              {row.prefix
                ? row.prefix +
                  row.invoice_number
                : "INV" + row.invoice_number}
            </>
          );
        },
      },
      {
        dataField: "customer_name",
        text: "Customer Name",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: cellContent.match(/.{1,30}/g).join("<br />"),
                }}
              ></span>
            </>
          );
        },
      },
      /* {
        dataField: "info",
        text: "Info",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div>
                <span
                  onClick={(e: any) => this.PayHistory(e, row)}
                  className="status info"
                >
                  Info
                </span>
              </div>
            </>
          );
        },
      }, */
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
        formatter: (cellContent: any, row: any) => {
          return <>{`₹ ${number_format(cellContent)}`}</>;
        },
      },
      {
        dataField: "mode_name",
        text: "Mode",
        sort: true,
      },
      {
        dataField: "action",
        text: "Action",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          return (
            <>
              <div className="table-button btns-inv">
              <span
                  onClick={(e: any) => this.PayHistory(e, row)}
                  className="status info mx-2"
                >
                  Info
                </span>
                {this.props.isPaginationRequire && (
                  <Link to="#" onClick={(e: any) => this.downloadPdf(e, row)}>
                    <button className="round-btn" title="View">
                      <PdfIcon />
                    </button>
                  </Link>
                )}
                <Link to="#" onClick={(e: any) => this.send(e, row)}>
                  <button className="round-btn" title="Send">
                    <SentIcon />
                  </button>
                </Link>
              </div>
            </>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div
                          className="search-box me-2 mb-2 d-inline-block cstm-search"
                          style={{
                            position: "absolute",
                            top: -50,
                            left: 180,
                          }}
                        >
                          <SearchBar {...toolkitProps.searchProps} />
                        </div>
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            selectRow={{
                              mode: "radio",
                              hideSelectColumn: true,
                              clickToSelect: true,
                              onSelect: this.selectRow,
                            }}
                            remote
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap tbl-invoice"
                            }
                            headerWrapperClasses={"thead-light"}
                            onTableChange={this.handleTableChange}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                      </Col>
                    </Row>

                    {this.props.isPaginationRequire && (
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                            <PaginationTotalStandalone {...paginationProps} />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}

        {/* <SendInvModel
          id={this.state.active_inv_id}
          invoice_no={this.state.active_inv_no}
          isOpen={this.state.modal}
          toggle={this.toggleSend}
          email={this.state.email}
        /> */}
        <SendReceiptModel 
          id={this.state.active_inv_id} 
          receipt_no={this.state.active_ref_no} 
          isOpen={this.state.modal} 
          toggle={this.toggleSend} 
          email={this.state.email} 
        />
        <CompanyInfoModal
          infoRes={this.state.data}
          invoice_id={this.state.active_inv_id}
          customer_id={this.state.active_cust_id}
          isOpen={this.state.isOpenPayModal}
          toggle={this.togglePay}
          invoice_amount={this.state.active_invoice_amount}
        />
        <PaymentPdf
          id={this.state.pdfId}
          isDownload={false}
          isDownloadCompleted={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </React.Fragment>
    );
  }
}

PaymentReceviedList.defaultProps = {
  isPaginationRequire: true,
};

export default withRouter(PaymentReceviedList);
