import React from 'react';
import { useMediaQuery } from 'react-responsive'

interface Props {
    mobile: any;
    desktop: any;
}
function MediaQuery(props:Props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width:767px)' })
    if (isTabletOrMobile) {       
        document.body.classList.remove("sidebar-enable");
    }
    return (
        <>
        {isTabletOrMobile ? props.mobile : props.desktop }
        </>
    );
}

export default MediaQuery;