import {TdsHeadTypes} from "./actionTypes"

const INIT_STATE = {
  tdshead: [],  
  error: {},
}

const tdshead = (state = INIT_STATE, action : any) => {
  switch (action.type) {
    case TdsHeadTypes.GET_TDSHEAD_SUCCESS:
      return {
        ...state,
        tdshead: action.payload.data,
      }
    case TdsHeadTypes.GET_TDSHEAD:
      return {
        ...state,
        tdshead: state.tdshead,
      }
    case TdsHeadTypes.GET_TDSHEAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }  
    default:
      return state
  }
}

export default tdshead
