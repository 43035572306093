import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ExpItemModal from "./ExpItemModal";
import ConfirmationModal from "src/components/ConfirmationModal";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import SweetAlert from "react-bootstrap-sweetalert";
import ItemLedger from "./itemLedger";
import GstLedger from "./GstLedger";
import classnames from "classnames";
import { isNoEditableDisItem, isNoEditableItem } from "src/helpers/common";
import DocumentBank from "../Banks/StatementBank";

interface MyProps extends RouteComponentProps<any> {}

interface MyState {
  sizePerPage: number;
  searchText: string;
  totalSize: number;
  data: any;
  activeTab: number;
  sortField: string;
  sortOrder: string;
  permissions: Array<any>;
  loading: boolean;
  modalExpItem: boolean;
  expItemId: string;
  isOpenConformModal: boolean;
  Rowdelete: string | null;
  blockdel: boolean;
  item_id: string;
  item_name: string;
  modelLedger: boolean;
  page: number;
  modelgstLedger: boolean;
  modelBankLedger: boolean;
}

interface ChangeProp {
  page: number;
  sizePerPage: number;
  searchText: string;
}

class ExpItemList extends Component<MyProps, MyState> {
  _isMounted: boolean;
  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.state = {
      sizePerPage: 10,
      searchText: "",
      page: 1,
      activeTab: 1,
      totalSize: 0,
      data: [],
      sortField: "item_name",
      sortOrder: "asc",
      loading: true,
      permissions: [],
      modalExpItem: false,
      expItemId: "0",
      isOpenConformModal: false,
      Rowdelete: null,
      blockdel: false,
      item_id: "",
      item_name: "",
      modelLedger: false,
      modelgstLedger: false,
      modelBankLedger: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  openModalExpItem = () => {
    this.setState({ modalExpItem: true, expItemId: "0" });
  };

  closeModalExpItem = () => {
    this.fetchData({
      page: 1,
      searchText: this.state.searchText,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ modalExpItem: false });
  };
  ledgerOpen = (row: any) => {
    this.setState({
      modelLedger: true,
      item_id: row.id,
      item_name: row.item_name,
    });
  };
  GStledgerOpen = (row: any) => {
    this.setState({
      modelgstLedger: true,

      item_id: row.id,
      item_name: row.item_name,
    });
  };
  BankledgerOpen = (row: any) => {
    this.setState({
      modelBankLedger: true,
      item_id: row.id,
      item_name: row.item_name,
    });
  };
  BankledgerClose = () => {
    this.setState({ modelBankLedger: false });
  };
  BankledgerToggle = () => {
    this.setState({ modelBankLedger: !this.state.modelBankLedger });
  };
  ledgerClose = () => {
    this.setState({ modelLedger: false });
  };
  ledgerToggle = () => {
    this.setState({ modelLedger: !this.state.modelLedger });
  };
  ledgerGstClose = () => {
    this.setState({ modelgstLedger: false });
  };
  ledgerGstToggle = () => {
    this.setState({ modelgstLedger: !this.state.modelgstLedger });
  };
  fetchData = (param: any) => {
    const sendGetRequest = async () => {
      try {
        const resp = await post(
          process.env.REACT_APP_API_URL + `/api/exp_item_list_grid`,
          param
        );
        if (resp.success) {
          if (this._isMounted) {
            this.setState({
              totalSize: resp.data.count,
              data: resp.data.results,
            });
          }
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();
  };

  sendGetRequest = async () => {
    try {
      if (this._isMounted) this.setState({ loading: false });
      const respPer = await get(
        process.env.REACT_APP_API_URL + `/api/permissions/7`
      );
      if (respPer.success) {
        if (this._isMounted) {
          this.setState({
            permissions: respPer.data,
          });
        }
        this.fetchData({
          page: 1,
          searchText: this.state.searchText,
          sizePerPage: this.state.sizePerPage,
          sortField: this.state.sortField,
          sortOrder: this.state.sortOrder,
          activeTab: this.state.activeTab,
        });
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  componentDidMount() {
    this._isMounted = true;
    this.sendGetRequest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleTableChange = (type: string, param: ChangeProp) => {
    this.setState({ sizePerPage: param.sizePerPage });
    this.setState({ page: param.page });
    this.setState({ searchText: param.searchText });
    this.fetchData({
      page: param.page,
      searchText: param.searchText,
      sizePerPage: param.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
  };
  handleEdit = (row: any) => {
    this.setState({ expItemId: row.id, modalExpItem: true });
  };
  handleDelete = (row: any) => {
    if (row.item_count == 0) {
      this.setState({ isOpenConformModal: true });
      this.setState({ Rowdelete: row.id });
    } else {
      this.setState({ blockdel: true });
    }
  };
  handleDeleteConfirm = () => {
    const sendGetRequest = async () => {
      try {
        del(
          process.env.REACT_APP_API_URL +
            "/api/exp_item_delete/" +
            this.state.Rowdelete
        );
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    this.fetchData({
      page: 1,
      searchText: this.state.searchText,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };

  showactiveTab = (activeTab: any) => {
    this.setState({ page: 1 });
    this.fetchData({
      page: 1,
      searchText: this.state.searchText,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: activeTab,
    });
  };
  handleDeleteClose = () => {
    this.fetchData({
      page: 1,
      searchText: this.state.searchText,
      sizePerPage: this.state.sizePerPage,
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder,
      activeTab: this.state.activeTab,
    });
    this.setState({ isOpenConformModal: false });
    this.setState({ Rowdelete: null });
  };
  render() {
    const pageOptions: any = {
      sizePerPage: this.state.sizePerPage,
      page: this.state.page,
      totalSize: this.state.totalSize, // replace later with size(customers),
      custom: true,
    };

    const { SearchBar } = Search;

    const defaultSorted: any = [
      {
        dataField: this.state.sortField,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
        hidden: true,
      },
      {
        dataField: "item_name",
        text: "Item Name",
        sort: true,
      },
      {
        dataField: "category_name",
        text: "Category",
        sort: true,
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cellContent: any, row: any) => {
          if(row.type === 1){
            return (
              <div className="table-button py-1">
                <button
                  onClick={() => this.BankledgerOpen(row)}
                  className="normal-btn hash-button"
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                   Ledger
                </button>
              </div>
            );
          }
          else if (isNoEditableItem(row.id)) {
            return (
              <div className="table-button py-1">
                <button
                  onClick={() => this.GStledgerOpen(row)}
                  className="normal-btn hash-button"
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                   Ledger
                </button>
              </div>
            );
          } else if (isNoEditableDisItem(row.id)) {
            return (
              <div className="table-button py-1">
                <button
                  className="normal-btn hash-button"
                  disabled
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                   Ledger
                </button>
              </div>
            );
          } else {
            return (
              <div className="table-button">
                <button
                  onClick={() => this.ledgerOpen(row)}
                  className="normal-btn hash-button"
                  title="View Ledger"
                  style={{ marginRight: 12 }}
                >
                   Ledger
                </button>
                <button
                  className="round-btn"
                  title="Action"
                  style={{
                    marginRight: 12,
                    width: 35,
                    height: 35,
                  }}
                >
                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a">
                      <EllipsisIcon />
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "modify" && is_active == 1
                      ) && (
                        <Link to="#" onClick={() => this.handleEdit(row)}>
                          <DropdownItem to="#" tag="span">
                            <i
                              className="fas fa-edit"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit"
                              style={{ color: "#28a745" }}
                            />{" "}
                            Edit
                          </DropdownItem>
                        </Link>
                      )}
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "delete" && is_active == 1
                      ) && (
                        <DropdownItem
                          to="#"
                          tag="span"
                          onClick={() => this.handleDelete(row)}
                        >
                          <i
                            className="fas fa-trash-alt"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Delete"
                            style={{ color: "#dc3545" }}
                          />{" "}
                          Delete
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </button>
              </div>
            );
          }
        },
      },
    ];
    return (
      <React.Fragment>
        {this.state.loading == true ? (
          <Table className="table mb-0">
            <thead>
              <tr>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
                <th>
                  <Skeleton width={100} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
                <td>
                  <Skeleton height={20} />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={this.state.data}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <div
                        className="search-box me-2 mb-2 d-inline-block cstm-search"
                        style={{
                          position: "absolute",
                          top: -50,
                          left: 108,
                        }}
                      >
                        <SearchBar {...toolkitProps.searchProps} />
                        {/* <i className="bx bx-search-alt search-icon" /> */}
                      </div>
                      {this.state.permissions.find(
                        ({ action, is_active }) =>
                          action == "create" && is_active == 1
                      ) && (
                        <Link
                          to="#"
                          onClick={this.openModalExpItem}
                          style={{
                            position: "absolute",
                            top: -50,
                            left: 353,
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary create"
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-plus"></i> Create
                          </button>
                        </Link>
                      )}
                    </div>
                    <Row className="mt-3">
                      <Col xl={2} lg={3}>
                        <Nav
                          className="custom-tab"
                          style={{
                            minHeight: "400px",
                          }}
                        >
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 1,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 1 });
                                this.showactiveTab(1);
                              }}
                            >
                              Assets
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 2,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 2 });
                                this.showactiveTab(2);
                              }}
                            >
                              Liabilities
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 3,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 3 });
                                this.showactiveTab(3);
                              }}
                            >
                              Expense
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: this.state.activeTab === 4,
                              })}
                              onClick={() => {
                                this.setState({ activeTab: 4 });
                                this.showactiveTab(4);
                              }}
                            >
                              Income
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col xl={10} lg={9}>
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            remote
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap tbl-invoice"
                            }
                            headerWrapperClasses={"thead-light"}
                            onTableChange={this.handleTableChange}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={() => <NoDataIndication />}
                          />
                        </div>
                        {/* <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}
        {this.state.blockdel && (
          <div className="response-message">
            <SweetAlert
              confirmBtnText="Ok"
              show={this.state.blockdel}
              title=""
              onConfirm={() => {
                this.setState({
                  blockdel: false,
                });
              }}
            >
              <h5>Can't delete it</h5>
              <small>It is already used!</small>
            </SweetAlert>
          </div>
        )}
        <ConfirmationModal
          isOpen={this.state.isOpenConformModal}
          message="Do you want to delete this record?"
          onClose={this.handleDeleteClose}
          onConfirm={this.handleDeleteConfirm}
        ></ConfirmationModal>
        {this.state.modelLedger && (
          <ItemLedger
            openModal={this.ledgerToggle}
            modal_is_open={this.state.modelLedger}
            closeModal={this.ledgerClose}
            item_name={this.state.item_name}
            item_id={this.state.item_id}
          />
        )}
        {this.state.modelgstLedger && (
          <GstLedger
            openModal={this.ledgerGstToggle}
            modal_is_open={this.state.modelgstLedger}
            closeModal={this.ledgerGstClose}
            item_name={this.state.item_name}
            item_id={this.state.item_id}
          />
        )}
        {this.state.modalExpItem && (
          <ExpItemModal
            modal_is_open={this.state.modalExpItem}
            openModal={this.openModalExpItem}
            closeModal={this.closeModalExpItem}
            id={this.state.expItemId}
          />
        )}
        {this.state.modelBankLedger ? (
          <DocumentBank
            openModal={this.BankledgerToggle}
            modal_is_open={this.state.modelBankLedger} 
            closeModal={this.BankledgerClose}
            bank_name={this.state.item_name}
            bank_id={this.state.item_id}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(ExpItemList);
