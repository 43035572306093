import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import { compact, isEmpty, map, size } from "lodash";
import { post, get } from "src/helpers/api_helper";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//redux
import moment from "moment";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

// Import Editor
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { stringify } from "querystring";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";
import SendInvModel from "./SendInvModel";
import {
  base64ToUint8,
  convertNumberToWords,
  pdfCell,
} from "src/helpers/common";

import logo from "../../assets/images/logo_login.png";
import { connect } from "react-redux";

import ReactPDF, {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import HtmlPdf from "../Common/HtmlPdf";
import TemplatesModal from "../Common/TemplatesModal";

interface Params extends RouteComponentProps {
  id: string;
  isOpen: boolean;
  toggle: () => void;
  instance_id: string;
  email: string;
  companies?: { orders: any; logoFile: string };
  units?: Array<any>;
  taxes?: Array<any>;
}

type State = {
  id: string;
  customer_name: string;
  billing_address1: string;
  billing_address2: string;
  billing_town_or_city: string;
  billing_state_name: string;
  billing_country_name: string;
  billing_pincode: string;
  shipping_address1: string;
  shipping_address2: string;
  shipping_town_or_city: string;
  shipping_state_name: string;
  shipping_country_name: string;
  shipping_pincode: string;
  invoice_date: string;
  due_date: string;
  invoice_details: Array<any>;
  sub_total: number;
  invoice_no: number;
  tax_amount: number;
  discount: number;
  gross_total: number;
  gross_total_base: number;
  invoice_prefix: string;
  tax_details: Array<any>;
  itemUnit: Array<any>;
  itemTax: Array<any>;
  company_name: string;
  company_address1: string;
  company_address2: string;
  company_town_or_city: string;
  company_state_name: string;
  company_country_name: string;
  company_pincode: string;
  modal: boolean;
  editorState: EditorState;
  isSending: boolean;
  isHideSuccessMgs: boolean;
  isHideErrorMgs: boolean;
  logo: string;
  seal: string;
  signature: string;
  terms_and_conditions: string;
  footer_note: string;
  email: string;
  currency_symbol: string;
  exchange_rate: number;
  invoice_light_color: string;
  invoice_dark_color: string;
  shipping_address: string;
  loading: boolean;
  blobUrl: string;
  blobUrl2: string;
  isCustomerChecked: boolean;
  isTransportChecked: boolean;
  isSupplierChecked: boolean;
  isdeliverynoteChecked: boolean;
  isDeliChallanChecked: boolean;
  modalSend: boolean;
  invoice_template: number;
  gst_number: string;
  company_telephone: string;
  display_template: boolean;
  switch_template: boolean;
  bank_name: string;
  account_no: string;
  account_name: string;
  ifsc_code: string;
  branch: string;
  swift_code: string;
  iban: string;
  customer_currency: string;
  cust_gst_number: string;
  currency_name: string;
  currency_dtext: string;
  templateModal: boolean;
  plan_id: string;
  invoice_status: number;
  company_email_active: number;
  company_name_active: number;
  business_name_active: number;
  website_active: number;
  pan_number_active: number;
  gst_number_active: number;
  company_telephone_active: number;
  business_name: string;
  website: string;
  pan_number: string;
  shipping_company: string;
  po_or_estimate_no: string;
  po_or_estimate_date: string;
  document_type: string;
  to_addrress_type: string;
  reference1: string;
  reference2: string;
  reference3: string;
  reference4: string;
  rounded_amount: number;
  is_rounded: number;
  is_authorized: number;
  vendor_supplier_code: string;
  taxable_amount: number;
};

class InvoiceDetailsModel extends Component<Params, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      customer_name: "",
      billing_address1: "",
      billing_address2: "",
      billing_town_or_city: "",
      billing_state_name: "",
      billing_country_name: "",
      billing_pincode: "",
      shipping_address1: "",
      shipping_address2: "",
      shipping_town_or_city: "",
      shipping_state_name: "",
      shipping_country_name: "",
      shipping_pincode: "",
      invoice_date: "",
      due_date: "",
      invoice_details: [],
      sub_total: 0,
      invoice_no: 0,
      tax_amount: 0,
      discount: 0,
      gross_total: 0,
      gross_total_base: 0,
      invoice_prefix: "",
      tax_details: [],
      itemUnit: props.units,
      itemTax: props.taxes,
      company_name: "",
      company_address1: "",
      company_address2: "",
      company_town_or_city: "",
      company_state_name: "",
      company_country_name: "",
      company_pincode: "",
      modal: false,
      editorState: EditorState.createEmpty(),
      isSending: false,
      isHideSuccessMgs: true,
      isHideErrorMgs: true,
      logo: "",
      seal: "",
      signature: "",
      terms_and_conditions: "",
      footer_note: "",
      email: "",
      currency_symbol: "₹",
      exchange_rate: 0,
      invoice_light_color: "",
      invoice_dark_color: "",
      loading: true,
      shipping_address: "",
      blobUrl: "",
      blobUrl2: "",
      isCustomerChecked: true,
      isTransportChecked: false,
      isSupplierChecked: false,
      isdeliverynoteChecked: false,
      isDeliChallanChecked: false,
      modalSend: false,
      invoice_template: 1,
      gst_number: "",
      company_telephone: "",
      display_template: false,
      switch_template: false,
      bank_name: "",
      account_no: "",
      account_name: "",
      ifsc_code: "",
      branch: "",
      swift_code: "",
      iban: "",
      customer_currency: "INR",
      cust_gst_number: "",
      currency_name: "",
      currency_dtext: "",
      templateModal: false,
      plan_id: "",
      invoice_status: 0,
      company_email_active: 0,
      company_name_active: 0,
      business_name_active: 0,
      website_active: 0,
      pan_number_active: 0,
      gst_number_active: 0,
      company_telephone_active: 0,
      pan_number: "",
      website: "",
      business_name: "",
      shipping_company: "",
      po_or_estimate_no: "",
      po_or_estimate_date: "",
      document_type: "",
      to_addrress_type: "",
      reference1: "",
      reference2: "",
      reference3: "",
      reference4: "",
      rounded_amount: 0,
      is_rounded: 0,
      is_authorized: 0,
      taxable_amount: 0,
      vendor_supplier_code: "",
    };
  }

  toggleView = () => {
    try {
      this.setState({ modalSend: !this.state.modalSend });
    } catch (err) {
      console.error(err);
    }
  };

  toggleTemplate = () => {
    try {
      // this.setState({ display_template: !this.state.display_template });
      this.setState({ templateModal: !this.state.templateModal });
    } catch (err) {
      console.error(err);
    }
  };

  toggleTemplateModal = () => {
    try {
      this.setState({ switch_template: false });
      this.setState({ templateModal: !this.state.templateModal });
    } catch (err) {
      console.error(err);
    }
  };

  // toggleDetailsModal = () => {
  //   this.setState({ blobUrl: "" });
  //   this.props.toggle;
  // };

  componentDidUpdate(prevProps: Params, prevState: State) {
    if (this.state.invoice_template !== prevState.invoice_template) {
      // this.pdf();
    }
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen == true) {
        this.setState({
          id: this.props.id,
          modal: this.props.isOpen,
          blobUrl: "",
          blobUrl2: "",
          isCustomerChecked: true,
          isdeliverynoteChecked: false,
          isTransportChecked: false,
          isSupplierChecked: false,
        });
        this.sendGetRequest(this.props.id);
      } else {
        this.setState({
          id: this.props.id,
          modal: this.props.isOpen,
          blobUrl: "",
          blobUrl2: "",
        });
      }
    }
  }

  changeTemplate = (tempid: any) => {
    try {
      // this.pdf();
      this.setState({ switch_template: true });
      this.sendChageTemplate(tempid);
    } catch (err) {
      console.error(err);
    }
  };

  sendChageTemplate = async (tempid: any) => {
    try {
      // let value = Number(e.target.value);
      // this.setState({ display_template: !this.state.display_template });
      // const resp: any = await get(process.env.REACT_APP_API_URL + '/api/change_inv_template/' + this.state.id + '/' + this.state.invoice_template);
      this.setState({ invoice_template: tempid }, async () => {
        const resp: any = await post(
          process.env.REACT_APP_API_URL + "/api/switch_template",
          this.state
        );
        this.setState({ blobUrl: resp.file });
      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  generateCopies = async () => {
    try {
      this.setState({ switch_template: false }, async () => {
        const resp: any = await post(
          process.env.REACT_APP_API_URL + "/api/switch_template",
          this.state
        );
        this.setState({ blobUrl: resp.file });
      });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  componentWillReceiveProps(props: Params) {
    /* this.setState({
            id: props.id,
            modal: props.isOpen,
            blobUrl: "",
            blobUrl2: ""
        });
        if (props.id && this.state.switch_template==false && props.isOpen==true) {           

            this.sendGetRequest(props.id);
        } */
  }

  sendGetRequest = async (id: string) => {
    try {
      const resp: any = await get(
        process.env.REACT_APP_API_URL + "/api/invoice_view/" + id
      );
      this.setState({ loading: false });
      this.setState({ blobUrl: resp.file });

      this.setState({ customer_name: resp.data.customer.customer_name });
      this.setState({ billing_address1: resp.data.customer.billing_address1 });
      this.setState({ billing_address2: resp.data.customer.billing_address2 });
      this.setState({
        billing_town_or_city: resp.data.customer.billing_town_or_city,
      });
      this.setState({
        billing_state_name: resp.data.customer.billing_state_name,
      });
      this.setState({
        billing_country_name: resp.data.customer.billing_country_name,
      });
      this.setState({ billing_pincode: resp.data.customer.billing_pincode });
      this.setState({
        shipping_address1: resp.data.customer.shipping_address1,
      });
      this.setState({
        shipping_address2: resp.data.customer.shipping_address2,
      });
      this.setState({
        shipping_town_or_city: resp.data.customer.shipping_town_or_city,
      });
      this.setState({
        shipping_state_name: resp.data.customer.shipping_state_name,
      });
      this.setState({
        shipping_country_name: resp.data.customer.shipping_country_name,
      });
      this.setState({ shipping_pincode: resp.data.customer.shipping_pincode });
      this.setState({
        vendor_supplier_code: resp.data.customer.vendor_supplier_code,
      });
      this.setState({ invoice_date: resp.data.hdr.invoice_date });
      this.setState({ due_date: resp.data.hdr.due_date });
      this.setState({ invoice_no: resp.data.hdr.invoice_no });
      this.setState({ sub_total: resp.data.hdr.net_total });
      this.setState({ tax_amount: resp.data.hdr.tax_amount });
      this.setState({ shipping_company: resp.data.hdr.shipping_company });
      this.setState({ po_or_estimate_no: resp.data.po_or_estimate_no });
      this.setState({ po_or_estimate_date: resp.data.hdr.po_or_estimate_date });
      this.setState({ document_type: resp.data.hdr.document_type });
      this.setState({ to_addrress_type: resp.data.hdr.to_addrress_type });
      this.setState({ reference1: resp.data.hdr.reference1 });
      this.setState({ taxable_amount: resp.data.hdr.taxable_amount });
      this.setState({ reference2: resp.data.hdr.reference2 });
      this.setState({ reference3: resp.data.hdr.reference3 });
      this.setState({ reference4: resp.data.hdr.reference4 });
      this.setState({ rounded_amount: resp.data.hdr.rounded_amount });
      this.setState({ is_rounded: resp.data.hdr.is_rounded });
      this.setState({ is_authorized: resp.data.hdr.is_authorized });
      this.setState({ discount: resp.data.hdr.discount });
      this.setState({ gross_total: resp.data.hdr.gross_total });
      this.setState({ gross_total_base: resp.data.hdr.gross_total_base });
      this.setState({ exchange_rate: resp.data.hdr.exchange_rate });
      this.setState({ invoice_details: resp.data.dtls });
      this.setState({
        invoice_prefix: resp.data.company.invoice_number_prefix,
      });
      this.setState({ tax_details: resp.data.tax_dtls });
      this.setState({ company_name: resp.data.company.company_name });
      this.setState({ company_address1: resp.data.company.company_address1 });
      this.setState({ company_address2: resp.data.company.company_address2 });
      this.setState({
        company_town_or_city: resp.data.company.company_town_or_city,
      });
      this.setState({
        company_state_name: resp.data.company.company_state_name,
      });
      this.setState({
        company_country_name: resp.data.company.company_country_name,
      });
      this.setState({ company_pincode: resp.data.company.company_pincode });
      this.setState({ logo: resp.data.company.logo });
      this.setState({ seal: resp.data.company.seal });
      this.setState({ signature: resp.data.company.signature });
      if (resp.data.hdr.terms_and_conditions !== null) {
        this.setState({
          terms_and_conditions: resp.data.hdr.terms_and_conditions,
        });
      } else {
        this.setState({
          terms_and_conditions: resp.data.company.terms_and_conditions,
        });
      }
      this.setState({ footer_note: resp.data.hdr.footer_note });
      this.setState({ email: resp.data.company.company_email });
      this.setState({ currency_symbol: resp.data.hdr.currency_symbol });
      this.setState({ currency_name: resp.data.hdr.currency_name });
      this.setState({ currency_dtext: resp.data.hdr.currency_dtext });
      this.setState({
        invoice_light_color: resp.data.company.invoice_light_color,
      });
      this.setState({
        invoice_dark_color: resp.data.company.invoice_dark_color,
      });
      this.setState({ invoice_template: resp.data.hdr.invoice_template_id });
      this.setState({ company_telephone: resp.data.company.company_telephone });
      this.setState({ gst_number: resp.data.company.gst_number });
      this.setState({ plan_id: this.props.companies?.orders[0].plan_id });
      this.setState({ invoice_status: resp.data.hdr.status });
      this.setState({
        company_email_active: resp.data.company.company_email_active,
      });
      this.setState({
        company_name_active: resp.data.company.company_name_active,
      });
      this.setState({
        business_name_active: resp.data.company.business_name_active,
      });
      this.setState({ website_active: resp.data.company.website_active });
      this.setState({ pan_number_active: resp.data.company.pan_number_active });
      this.setState({ gst_number_active: resp.data.company.gst_number_active });
      this.setState({
        company_telephone_active: resp.data.company.company_telephone_active,
      });
      this.setState({ pan_number: resp.data.company.pan_number });
      this.setState({ website: resp.data.company.website });
      this.setState({ business_name: resp.data.company.business_name });
      if (resp.data.hdr.shipping_address)
        this.setState({
          shipping_address: compact(
            resp.data.hdr.shipping_address.split("\n")
          ).join("\n"),
        });
      if (resp.data.bank) {
        this.setState({ bank_name: resp.data.bank.bank_name });
        this.setState({ account_no: resp.data.bank.account_no });
        this.setState({ account_name: resp.data.bank.account_name });
        this.setState({ ifsc_code: resp.data.bank.ifsc_code });
        this.setState({ branch: resp.data.bank.branch });
        this.setState({ swift_code: resp.data.bank.swift_code });
        this.setState({ iban: resp.data.bank.iban });
      }
      this.setState({ customer_currency: resp.data.hdr.customer_currency });
      this.setState({ cust_gst_number: resp.data.customer.gstin });

      // this.pdf();
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  padLeadingZeros(num: number, size: number) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  changeChallan = (e: any) => {
    this.setState({ isDeliChallanChecked: e.target.checked });
    // this.pdf();
  };

  changeSupplier = (e: any) => {
    this.setState({ isSupplierChecked: e.target.checked }, () => {
      this.generateCopies();
    });
    // this.pdf();
  };

  changeTransport = (e: any) => {
    this.setState({ isTransportChecked: e.target.checked }, () => {
      this.generateCopies();
    });
    // this.pdf();
  };
  changedeliverynote = (e: any) => {
    this.setState({ isdeliverynoteChecked: e.target.checked }, () => {
      this.generateCopies();
    });
    // this.pdf();
  };

  send = async () => {
    try {
      this.setState({ modalSend: true });
    } catch (err) {
      console.error(err);
    }
  };

  changeCustomer = (e: any) => {
    this.setState({ isCustomerChecked: e.target.checked }, () => {
      this.generateCopies();
    });
  };

  pdf = async () => {
    const element: any = document.getElementById("printRef");
    const canvas1 = await html2canvas(element, {
      onclone: (document: any, element: HTMLElement) => {
        element.style.display = "block";
      },
    });

    const data1 = canvas1.toDataURL("image/png");

    const pdf = new jsPDF({ compress: true });
    const imgProperties = pdf.getImageProperties(data1);
    // const logoProperties = pdf.getImageProperties(logo);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeightAct = pdf.internal.pageSize.getHeight();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    const logoX = pdfWidth / 2 - 20 / 2;
    const logoY = pdfHeightAct - 25;
    const logoWidth = 20;
    const logoHeight = 10;

    const txtX = 35;
    const txtY = pdfHeightAct - 7;
    const txt1 = "India’s Most Simplest GST Invoicing Generator powered by ";
    const txt2 = "Makemyinvoice Private Limited";
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(10);

    if (this.state.isCustomerChecked) {
      pdf.addImage(data1, "PNG", 0, 0, pdfWidth, pdfHeight);
      if (this.props.companies?.orders[0].plan_id == 1) {
        pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
        pdf.link(logoX, logoY, logoWidth, logoHeight, {
          url: process.env.REACT_APP_URL,
        });
        pdf.text(txt1, txtX, txtY);
        pdf.setTextColor("#00b050");
        pdf.textWithLink(txt2, txtX + 100, txtY, {
          url: process.env.REACT_APP_URL,
        });
      }
    } else {
      pdf.deletePage(1);
    }

    if (this.state.isTransportChecked) {
      const canvas2 = await html2canvas(element, {
        onclone: (document: any, element: HTMLElement) => {
          element.style.display = "block";
          document.getElementById("pdfTitle").innerHTML =
            "DUPLICATE FOR TRANSPORTER";
        },
      });
      const data2 = canvas2.toDataURL("image/png");

      pdf.addPage();
      pdf.addImage(data2, "PNG", 0, 0, pdfWidth, pdfHeight);
      if (this.props.companies?.orders[0].plan_id == 1) {
        pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
        pdf.link(logoX, logoY, logoWidth, logoHeight, {
          url: process.env.REACT_APP_URL,
        });
        pdf.text(txt1, txtX, txtY);
        pdf.setTextColor("#00b050");
        pdf.textWithLink(txt2, txtX + 100, txtY, {
          url: process.env.REACT_APP_URL,
        });
      }
    }

    if (this.state.isSupplierChecked) {
      const canvas3 = await html2canvas(element, {
        onclone: (document: any, element: HTMLElement) => {
          element.style.display = "block";
          document.getElementById("pdfTitle").innerHTML =
            "TRIPLICATE FOR SUPPLIER";
        },
      });
      const data3 = canvas3.toDataURL("image/png");

      pdf.addPage();
      pdf.addImage(data3, "PNG", 0, 0, pdfWidth, pdfHeight);
      if (this.props.companies?.orders[0].plan_id == 1) {
        pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
        pdf.link(logoX, logoY, logoWidth, logoHeight, {
          url: process.env.REACT_APP_URL,
        });
        pdf.text(txt1, txtX, txtY);
        pdf.setTextColor("#00b050");
        pdf.textWithLink(txt2, txtX + 100, txtY, {
          url: process.env.REACT_APP_URL,
        });
      }
    }

    if (this.state.isDeliChallanChecked) {
      const canvas4 = await html2canvas(element, {
        onclone: (document: any, element: HTMLElement) => {
          element.style.display = "block";
          document.getElementById("pdfTitle").innerHTML = "DELIVERY CHALLAN";
          document.getElementById("footer1").style.display = "none";
          document.getElementById("footer2").style.display = "none";
        },
      });
      const data4 = canvas4.toDataURL("image/png");

      const imgProperties2 = pdf.getImageProperties(data4);
      const pdfWidth2 = pdf.internal.pageSize.getWidth();
      const pdfHeight2 =
        (imgProperties2.height * pdfWidth2) / imgProperties2.width;

      pdf.addPage();
      pdf.addImage(data4, "PNG", 0, 0, pdfWidth2, pdfHeight2);
      if (this.props.companies?.orders[0].plan_id == 1) {
        pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
        pdf.link(logoX, logoY, logoWidth, logoHeight, {
          url: process.env.REACT_APP_URL,
        });
        pdf.text(txt1, txtX, txtY);
        pdf.setTextColor("#00b050");
        pdf.textWithLink(txt2, txtX + 100, txtY, {
          url: process.env.REACT_APP_URL,
        });
      }
    }

    var blobPDF = new Blob([pdf.output("blob")], { type: "application/pdf" });
    var blobUrl = URL.createObjectURL(blobPDF);

    // this.setState({ blobUrl });
  };

  pdf1 = async () => {
    /* var darkColor = this.state.invoice_dark_color;
        
        const logoUrl = `${process.env.REACT_APP_IMG_URL}${this.state.logo}`;
        // const logoUrl = `http://localhost:3000/storage/images/m9OmvLBOxOQLhmww9qVDnKPPRMdP91mCnBTZyY9S.png`;
        // const logoImageBytes = await fetch(logoUrl, { mode: "no-cors" }).then((res) => res.arrayBuffer())
        // const logoUint8Array = new Uint8Array(logoImageBytes);*/
    /* var blobPDFString = await ReactPDF.pdf(
            <Pdf />
        ).toBlob();  */
    /*var blobPDF = new Blob([blobPDFString], { type: 'application/pdf' });
        var blobUrl2 = URL.createObjectURL(blobPDF);

        this.setState({ blobUrl2 }); */
  };

  render() {
    var lightColor = this.state.invoice_light_color;
    var darkColor = this.state.invoice_dark_color;
    const invoice_template = this.state.invoice_template;
    let inv_no = this.state.invoice_prefix
      ? this.state.invoice_prefix + this.state.invoice_no
      : "INV" + this.state.invoice_no;
    let body;
    // if (invoice_template == 2) {
    body = (
      <Col lg="12">
        <Card>
          <CardBody>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                                    body{/* font-family: 'Fira Sans', sans-serif; */background:#F1F1F1;color:#000000}

                                    .logo-wrap h4{font-size:26px;color: ${darkColor};font-weight:500;}
                                    .logo-wrap .logo{margin-bottom: 20px;}
                                    .logo-wrap .logo img{margin-bottom: 20px;border: 1px solid #CFCFCF;border-radius: 18px;}
                                    .addres h4{font-size:32px;font-weight: 500;color:${darkColor} ;margin-bottom: 10px;}
                                    .addres h5{font-size:28px;font-weight: 500;margin-bottom: 0;color:#000000}
                                    .addres h6{color:#000000;font-size:28px;font-weight: 500;margin-bottom:0!important}
                                    .addres span{display: block;font-size:20px}
                                    .addres span.sec2{margin-top: 25px;}
                                    
                                    .blocks{margin:25px 0 0 0;padding: 0;}
                                    .blocks li{list-style-type: none;display: inline-block;padding: 10px;background: #FBC587;text-align: center;min-width: 130px;float: left;}
                                    .blocks li:nth-child(2){background: #F7921C;}
                                    .blocks li label{display: block;}
                                    .blocks li span{display: block;font-weight: 500;}
                                    
                                    .header2{}
                                    
                                    .bill-ad{margin-top:20px}
                                    .bill-ad h3{font-size:18px;font-weight: 400;margin-bottom:20px;color:#000000;margin-bottom: 5px;}
                                    .bill-ad h4{font-size:26px;font-weight: 500;margin-bottom:0;color:#000000}
                                    .bill-ad p{font-size:20px;color:#000000}
                                    
                                    .table2 thead{border-bottom: hidden;}
                                    .table2 th{background: #ffffff!important;font-weight: 400!important;border-top:2px ${darkColor} solid;border-bottom:2px ${darkColor} solid!important;color:${darkColor};font-size:18px;padding:5px 8px!important}
                                    .table2 > thead{border-bottom: 1px ${darkColor} solid;}
                                    .table2 td span{display: block;font-weight: 500;color: #000;font-size: 16px;}
                                    .table2 td{color:#000000;border-color:#000000;padding:5px 8px!important}
                                    .table2 td label{color:#000000;font-size: 16px;}
                                    .table table tbody tr td{padding: 25px 5px;color:#000000}
                                    
                                    .totals-value td{padding:2px}
                                    .totals-value td span{font-size:22px;font-weight:400}
                                    .totals-value .total{background:none}
                                    .totals-value .total td div span{color: ${darkColor};font-size: 20px;font-weight: 500;}
                                    
                                    .memo h2{font-size:18px;font-weight:500;color:#000}
                                    .bold{font-weight:500;}
                                    .memo p{margin-bottom: 2px;font-size: 15px;}
                                    
                                    .btm-cont{border-bottom: 1px ${darkColor} solid;margin-bottom: 25px;font-size: 14px ;color:#707070;padding:8px 0 8px 0;}
                                    .btm-cont span{display: inline-block;padding-left: 25px;}
                                    
                                    .bold{font-weight:700;}
                                    .inv-det{border-top:2px solid ${darkColor};border-bottom:2px solid ${darkColor}}
                                    .inv-det h2{font-size:22px;font-weight: 400;margin:8px 0;color:${darkColor}}
                                    
                                    .due-date{font-size: 16px;}
                                    .due-date span{font-weight: 600;}
                                    
                                    .bankdet h4{font-size:17px;font-weight:500;color:#000}
                  
                                    .terms {text-align:left;border-top:#C7C7C7 1px solid;padding:15px 0 0 0;margin-top:15px}
                                    .terms h4{font-size:18px;font-weight:500;color:#000}
                                    .terms p{font-size:16px;color:#000}

                                    .bankdet{font-size:17px;}

                                    .total_span { font-size:23px !important; }

                                    .words{font-size:21px;color:${darkColor} } 
                                    .words h4{font-size:21px;font-weight:500;color:#000}

                                    @media screen and (max-width:1199px) {
                                        .blocks li{min-width: auto;}
                                        .blocks li label{font-size: 14px;}
                                        .blocks li span{font-size: 14px;}
                                    }
                                    @media screen and (max-width:767px) {
                                        .blocks li{float: none;display: block;}
                                    } `,
              }}
            />
            <div className="container mt-2">
              <div className="d-flex justify-content-center row">
                <div className="col-md-10 col-lg-12">
                  <div className="bg-white rounded">
                    <div className="p-2 pb-0 header2">
                      <div className="row">
                        <div className="col-md-4 col-lg-6 addres">
                          <h4>TAX INVOICE</h4>
                          <h5 style={{ textTransform: "uppercase" }}>
                            {this.state.company_name}
                          </h5>
                          <h6>GSTIN {this.state.gst_number}</h6>
                          <span className="sec1">
                            {this.state.company_address1},{" "}
                            {this.state.company_address2},
                            {this.state.company_town_or_city}
                            ,<br />
                            {this.state.company_state_name} -{" "}
                            {this.state.company_pincode},
                            {this.state.company_country_name}
                          </span>
                          <span className="sec1 mb-2">
                            Mobile {this.state.company_telephone}
                          </span>
                        </div>
                        <div className="col-md-8 col-lg-6 text-md-end logo-wrap">
                          <h4 id="pdfTitle">ORIGINAL FOR RECIPIENT</h4>
                          <div className="logo">
                            {this.state.loading ? (
                              <Skeleton width={50} height={24} />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}${this.state.logo}`}
                                width="163px"
                                height="110px"
                                id="pdf_logo"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 p-2 pb-0">
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="row inv-det">
                            <div className="col-md-4">
                              <h2>
                                Invoice No :{" "}
                                <span>
                                  {this.state.invoice_prefix}
                                  {this.state.invoice_no}
                                </span>
                              </h2>
                            </div>
                            <div className="col-md-4">
                              <h2>
                                Invoice Date:{" "}
                                <span>
                                  {moment(this.state.invoice_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </h2>
                            </div>
                            <div className="col-md-4">
                              <h2>
                                Due Date:{" "}
                                <span>
                                  {moment(this.state.due_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </h2>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="bill-ad">
                                <h3>Bill To :</h3>
                                <h4>{this.state.customer_name}</h4>
                                <p className="mb-2">
                                  {this.state.billing_address1 &&
                                    `${this.state.billing_address1},`}
                                  {this.state.billing_address2 &&
                                    `${this.state.billing_address2},`}
                                  {this.state.billing_town_or_city && (
                                    <>
                                      {this.state.billing_town_or_city} <br />
                                    </>
                                  )}
                                  {this.state.billing_state_name &&
                                    `${this.state.billing_state_name} -`}{" "}
                                  {this.state.shipping_pincode &&
                                    `${this.state.shipping_pincode},`}
                                  {this.state.billing_country_name &&
                                    `${this.state.billing_country_name}`}{" "}
                                  <br />
                                  {this.state.cust_gst_number && (
                                    <b>
                                      {"GSTIN " + this.state.cust_gst_number}
                                    </b>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="bill-ad">
                                <h3>Ship To :</h3>
                                <h4>{this.state.customer_name}</h4>
                                <p className="mb-2">
                                  {this.state.shipping_address
                                    .split("\n")
                                    .map(function (item, index) {
                                      return (
                                        <React.Fragment key={index}>
                                          {item}
                                          {index === 2 ? <br /> : ","}
                                        </React.Fragment>
                                      );
                                    })}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="table-responsive">
                        <table className="table table2">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Items</th>
                              <th>SAC</th>
                              <th>Qty</th>
                              <th>Rate({this.state.customer_currency})</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Amount({this.state.customer_currency})</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.invoice_details.map((dtls, index) => {
                              let tax = this.props.taxes?.find(
                                ({ id }) => id === dtls.tax_id
                              );
                              let unit = this.props.units?.find(
                                ({ id }) => id === dtls.unit_id
                              );
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td style={{ width: "40%" }}>
                                    <span>{dtls.name}</span>
                                    <label>{dtls.product_description}</label>
                                  </td>
                                  <td>{dtls.hsn_or_sac}</td>
                                  <td>
                                    <span style={{ fontWeight: 400 }}>
                                      {dtls.qty}{" "}
                                    </span>
                                    <label style={{ fontWeight: 400 }}>
                                      {unit && `(${unit.code})`}
                                    </label>
                                  </td>
                                  <td>
                                    {this.state.currency_symbol}
                                    {dtls.rate}
                                  </td>
                                  <td>
                                    {this.state.currency_symbol}
                                    {dtls.discount_amt}
                                  </td>
                                  <td>
                                    <span style={{ fontWeight: 400 }}>
                                      {this.state.currency_symbol}
                                      {dtls.tax_amt}
                                    </span>
                                    <label style={{ fontWeight: 400 }}>
                                      {tax && `(${tax.tax_name})`}
                                    </label>
                                  </td>
                                  <td>
                                    {this.state.currency_symbol}
                                    {dtls.amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-8">
                          <div className="bankdet">
                            <h4>Bank Account Details </h4>
                            Bank Name: {this.state.bank_name}
                            <br />
                            A/C No: {this.state.account_no}
                            <br />
                            A/C Name: {this.state.account_name}
                            <br />
                            IFSC Code: {this.state.ifsc_code}
                          </div>
                          <div className="words my-2">
                            <h4>Amount In Words</h4>
                            {convertNumberToWords(
                              this.state.gross_total,
                              this.state.currency_name,
                              this.state.currency_dtext
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <table className="table table-borderless">
                            <tbody className="totals-value">
                              <tr>
                                <td>
                                  <div className="text-left">
                                    <span className="total_span">
                                      Total({this.state.customer_currency})
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="text-right ">
                                    <span className="total_span">
                                      {this.state.currency_symbol}
                                      {this.state.gross_total}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              {this.state.exchange_rate > 0 ? (
                                <tr>
                                  <td>
                                    <div className="text-left">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        Amount Due (INR)
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-right ">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        &#8377;{this.state.gross_total_base}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <div className="text-left">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        Amount Due (INR)
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-right ">
                                      <span
                                        className="font-weight-bold total_span"
                                        style={{ color: darkColor }}
                                      >
                                        {this.state.currency_symbol}
                                        {this.state.gross_total}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* <tr>
                                                                <td>
                                                                    <div className="text-left">
                                                                        <span>Subtotal</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-right">
                                                                        <span>{this.state.currency_symbol}{this.state.sub_total}</span>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                              {this.state.tax_details.map((tax_detail, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <div className="text-left">
                                        <span>
                                          {tax_detail.label}{" "}
                                          {tax_detail.tax_rate}%
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-right">
                                        <span>
                                          {this.state.currency_symbol}
                                          {tax_detail.tax_amount}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                              {/* {this.state.discount && (
                                                                <tr>
                                                                    <td>
                                                                        <div className="text-left">
                                                                            <span>Discount</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-right ">
                                                                            <span>{this.state.currency_symbol}{this.state.discount ? this.state.discount : "0.00"}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )} */}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="memo">
                            {this.state.footer_note && (
                              <p>
                                <h2>Note/Memo</h2>{" "}
                                {this.state.footer_note
                                  .split("\n")
                                  .map(function (item, idx) {
                                    return (
                                      <React.Fragment key={idx}>
                                        {item}
                                        <br />
                                      </React.Fragment>
                                    );
                                  })}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="terms">
                            <h4>Terms And Conditions</h4>
                            {this.state.terms_and_conditions && (
                              <p>
                                {this.state.terms_and_conditions
                                  .split("\n")
                                  .map(function (item, idx) {
                                    return (
                                      <React.Fragment key={idx}>
                                        {item}
                                        <br />
                                      </React.Fragment>
                                    );
                                  })}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
    /* } else {
            body =
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html: `
                        body{font-family: 'Karla', sans-serif;background:#F1F1F1;}
                        .logo{margin-bottom: 20px;}
                        .addres h4{font-size:32px;font-weight: 500;}
                        .addres h5{font-size:18px;font-weight: 500;}
                        .addres span{display: block;}
                        .addres span.sec2{margin-top: 25px;}
                        
                        .blocks{margin:25px 0 0 0;padding: 0;}
                        .blocks li{list-style-type: none;display: inline-block;padding: 10px;background: ${lightColor};text-align: center;min-width: 130px;float: left;}
                        .blocks li:nth-child(2){background: ${darkColor};}
                        .blocks li label{display: block;}
                        .blocks li span{display: block;font-weight: 500;}
                        
                        .header2{border-bottom:1px solid ${lightColor};}                        
                        
                        .bill-ad h3{font-size:18px;font-weight: 400;}
                        .bill-ad h4{font-size:18px;font-weight: 500;margin-bottom: 0;}
                        .bill-ad p{font-size:16px;}
                        
                        .table2 thead{border-bottom: hidden;}
                        .table2 th{background: ${lightColor}!important;font-weight: 500;}
                        .table2 td span{display: block;font-weight: 500;color: #000;font-size: 16px;}
                        .table2 td label{color: #575757;font-size: 16px;}
                        .table table tbody tr td{padding: 25px 5px;}
                        
                        .total{background: #EBEBEB;}
                        .total td div span{color: ${darkColor};}
                        
                        .memo h2{font-size:18px;font-weight:500;}
                        .bold{font-weight:500;}
                        .memo p{margin-bottom: 2px;font-size: 14px;}
                        @media screen and (max-width:1199px) {
                            .blocks li{min-width: auto;}
                            .blocks li label{font-size: 14px;}
                            .blocks li span{font-size: 14px;}
                        }
                        @media screen and (max-width:767px) {
                            .blocks li{float: none;display: block;}
                        }          `
                                }}
                            />
                            <div className="col-lg-12" >
                                <div className="d-flex justify-content-center row">
                                    <div className="col-md-10 col-lg-12">
                                        <div className="bg-white rounded">
                                            <div className="p-4 header2">
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-12 text-md-end mt-3 addres">
                                                        <h5 id="pdfTitle">ORIGINAL FOR RECIPIENT</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-8">
                                                        <div className="logo">
                                                           
                                                            {this.state.loading ? <Skeleton width={50} height={24} /> : <img src={`${process.env.REACT_APP_IMG_URL}${this.state.logo}`} alt="logo" height={24} />}

                                                        </div>
                                                        <div className="billed">
                                                            <span className="font-weight-bold text-uppercase">{this.state.loading ? <Skeleton width={100} height={24} /> : "Invoice No :"}</span><span className="ml-1">
                                                                {this.state.loading ? <Skeleton width={50} height={24} /> : <>{this.state.invoice_prefix}{this.state.invoice_no}</>}
                                                            </span></div>
                                                        
                                                        <ul className="blocks">
                                                            {this.state.loading ? (
                                                                <>
                                                                    <li>
                                                                        <label><Skeleton baseColor={lightColor} /> </label>
                                                                        <span><Skeleton baseColor={lightColor} /></span>
                                                                    </li>
                                                                    <li>
                                                                        <label><Skeleton baseColor={darkColor} /> </label>
                                                                        <span><Skeleton baseColor={darkColor} /></span>
                                                                    </li>
                                                                    <li>
                                                                        <label><Skeleton baseColor={lightColor} /> </label>
                                                                        <span><Skeleton baseColor={lightColor} /></span>
                                                                    </li>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <li>
                                                                        <label>INVOICE DATE</label>
                                                                        <span>{moment(this.state.invoice_date).format("YYYY-MM-DD")}</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>AMOUNT</label>
                                                                        <span>{this.state.currency_symbol}{this.state.gross_total}</span>
                                                                    </li>
                                                                    <li>
                                                                        <label>DUE DATE</label>
                                                                        <span>{moment(this.state.due_date).format("YYYY-MM-DD")}</span>
                                                                    </li>
                                                                </>
                                                            )}


                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4 col-lg-4 text-md-end mt-3 addres">
                                                        {this.state.loading ?
                                                            (
                                                                <Skeleton count={4} />
                                                            ) : (
                                                                <>
                                                                    <h4 className="mb-0">INVOICE</h4>
                                                                    <h5>{this.state.company_name}</h5>
                                                                    <span className="sec1">
                                                                        {this.state.company_address1}
                                                                        <br />
                                                                        {this.state.company_address2}
                                                                        <br />
                                                                        {this.state.company_town_or_city}
                                                                        <br />
                                                                        {this.state.company_state_name} - {this.state.company_pincode}
                                                                        <br />
                                                                        {this.state.company_country_name}
                                                                    </span>
                                                                </>
                                                            )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-4">
                                                <div className="row">
                                                    <div className="col-md-12 col-lg-7">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="bill-ad">

                                                                    {this.state.loading ?
                                                                        <>
                                                                            <Skeleton count={4} />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <h3>BILL TO</h3>
                                                                            <h4>{this.state.customer_name}</h4>
                                                                            <p>
                                                                                {this.state.billing_address1},
                                                                                {this.state.billing_address2},
                                                                                {this.state.billing_town_or_city},
                                                                                {this.state.billing_state_name} - {this.state.shipping_pincode},
                                                                                {this.state.billing_country_name}
                                                                            </p>
                                                                        </>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="bill-ad">
                                                                    {this.state.loading ?
                                                                        <>
                                                                            <Skeleton count={4} />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <h3>SHIP TO</h3>
                                                                            <h4>{this.state.customer_name}</h4>
                                                                            <p>
                                                                                {this.state.shipping_address.split("\n").map((item) => {
                                                                                    return (
                                                                                        <>
                                                                                            {item}
                                                                                            <br />
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </p>
                                                                        </>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-4">
                                                <div className="table-responsive">

                                                    {this.state.loading == true ?
                                                        <>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                        <th><Skeleton width={'100%'} baseColor={lightColor} /></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                    <td><Skeleton height={'10%'} /></td>
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        : <>
                                                            <table className="table table2">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>Items</th>
                                                                        <th>SAC</th>
                                                                        <th>Qty</th>
                                                                        <th>Rate</th>
                                                                        <th>Discount</th>
                                                                        <th>Tax</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.invoice_details.map((dtls, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td style={{ width: '40%' }}>
                                                                                <span>{dtls.name}</span>
                                                                                <label>
                                                                                    {dtls.product_description}
                                                                                </label>
                                                                            </td>
                                                                            <td>{dtls.hsn_or_sac}</td>
                                                                            <td>{dtls.qty} </td>
                                                                            <td>{this.state.currency_symbol}{dtls.rate}</td>
                                                                            <td >{this.state.currency_symbol}{dtls.discount_amt}</td>
                                                                            <td >{this.state.currency_symbol}{dtls.tax_amt}</td>
                                                                            <td className="text-end">{this.state.currency_symbol}{dtls.amount}</td>
                                                                        </tr>

                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </>}
                                                </div>
                                                <div className="row d-flex justify-content-end">
                                                    <div className="col-md-5">
                                                        <table className="table table-borderless">
                                                            <tbody className="totals">
                                                                {this.state.loading == true ?
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="text-left">
                                                                                    <span><Skeleton /></span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-right text-end">
                                                                                    <span><Skeleton /></span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </> :
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="text-left">
                                                                                    <span>Subtotal</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-right text-end">
                                                                                    <span>{this.state.currency_symbol}{this.state.sub_total}</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {this.state.tax_details.map((tax_detail, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <div className="text-left">
                                                                                            <span>{tax_detail.label}{" "}{tax_detail.tax_rate}%</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-right text-end">
                                                                                            <span>{this.state.currency_symbol}{tax_detail.tax_amount}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                        {this.state.discount && (
                                                                            <tr>
                                                                                <td>
                                                                                    <div className="text-left">
                                                                                        <span>Discount</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="text-right text-end">
                                                                                        <span>{this.state.currency_symbol}{this.state.discount ? this.state.discount : "0.00"}</span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                        <tr>
                                                                            <td>
                                                                                <div className="text-left">
                                                                                    <span>Total</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-right text-end">
                                                                                    <span>{this.state.currency_symbol}{this.state.gross_total}</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {this.state.exchange_rate > 0 ? (
                                                                            <tr className="total">
                                                                                <td>
                                                                                    <div className="text-left">
                                                                                        <span className="font-weight-bold">Amount Due (INR)</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="text-right text-end">
                                                                                        <span className="font-weight-bold">&#8377;{this.state.gross_total_base}</span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr className="total">
                                                                                <td>
                                                                                    <div className="text-left">
                                                                                        <span className="font-weight-bold">Amount Due (INR)</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="text-right text-end">
                                                                                        <span className="font-weight-bold">{this.state.currency_symbol}{this.state.gross_total}</span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="memo">
                                                            {this.state.footer_note && (<p className="text-wrap text-justify">
                                                                <h2><b>Note:</b></h2> {this.state.footer_note.split("\n").map(function (item, idx) {
                                                                    return (
                                                                        <>
                                                                            {item}
                                                                            <br />
                                                                        </>
                                                                    )
                                                                })}
                                                            </p>)}
                                                            {this.state.terms_and_conditions && (<p className="text-wrap text-justify">
                                                                <h2><b>Terms & Conditions:</b></h2> {this.state.terms_and_conditions.split("\n").map(function (item, idx) {
                                                                    return (
                                                                        <>
                                                                            {item}
                                                                            <br />
                                                                        </>
                                                                    )
                                                                })}
                                                            </p>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
        } */
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          // size="lg"
          className="modal-xl"
          // toggle={this.toggleDetailsModal}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5>Invoice Preview</h5>
              <Row className="mx-5">
                <Col lg="12">
                  <AvForm>
                    {this.state.display_template ? (
                      <AvField
                        type="select"
                        name="invoice_template"
                        value={this.state.invoice_template}
                        onChange={this.changeTemplate}
                      >
                        <option value={1} key={1}>
                          Template 1
                        </option>
                        <option value={2} key={2}>
                          Template 2
                        </option>
                      </AvField>
                    ) : (
                      <Link to="#" onClick={this.toggleTemplate}>
                        Change Template
                      </Link>
                    )}
                  </AvForm>
                </Col>
              </Row>
            </div>
            <div>
                <button
                  onClick={this.props.toggle}
                  type="button"
                  className="close"
                  aria-label="Close"
                  style={{ fontSize: "16px" }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            <ModalBody>
              <Row>
                <Col lg="12">
                  <Card>
                    <iframe
                      id="pdf_preview"
                      src={this.state.blobUrl}
                      className="min-vh-100"
                    ></iframe>
                  </Card>
                </Col>
              </Row>
              {/* <Row>
                                <Col lg="12">
                                    <Card>
                                        
                                        <PDFViewer className="min-vh-100">
                                        <HtmlPdf /> 
                                    </PDFViewer>
                                    </Card>
                                </Col>
                            </Row> */}
              <Row id="printRef" style={{ display: "none" }}>
                {body}
              </Row>
              <SendInvModel
                id={this.state.id}
                invoice_no={inv_no}
                isOpen={this.state.modalSend}
                toggle={this.toggleView}
                email={this.state.email}
              />
            </ModalBody>
          </div>
        </Modal>
        <TemplatesModal
          tempid={this.state.invoice_template}
          isOpen={this.state.templateModal}
          toggle={this.toggleTemplateModal}
          switchTemplate={this.changeTemplate}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: {
  companies: any;
  units: any;
  taxes: any;
  permissions: any;
}) => {
  return {
    units: state.units.units,
    taxes: state.taxes.taxes,
    companies: state.companies.companies,
  };
};
export default connect(mapStateToProps)(withRouter(InvoiceDetailsModel));
